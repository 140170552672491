.bg-white {
    background-color: #fff !important
}
.bg-purple {
    background-color: #5c60f5 !important;
        color:#fff !important
}
.bg-gray-50 {
    background-color: #fafafa !important
}

.bg-gray-100 {
    background-color: #f5f9fc !important
}

.bg-gray-200 {
    background-color: #e7eaf0 !important
}

.bg-gray-300 {
    background-color: #cfd6df !important
}

.bg-gray-400 {
    background-color: #abb6c5 !important
}

.bg-gray-500 {
    background-color: #8898a9 !important
}

.bg-gray-600 {
    background-color: #6b7b93 !important
}

.bg-gray-700 {
    background-color: #525f7f !important
}

.bg-gray-800 {
    background-color: #2d3748 !important
}

.bg-gray-900 {
    background-color: #16192c !important
}

.bg-black {
    background-color: #000 !important
}

.bg-blue-100 {
    background-color: #ccebff !important
}

.bg-blue-200 {
    background-color: #99d6ff !important
}

.bg-blue-300 {
    background-color: #66c2ff !important
}

.bg-blue-400 {
    background-color: #33adff !important
}

.bg-blue-500 {
    background-color: #09f !important
}

.bg-blue-600 {
    background-color: #007acc !important
}

.bg-blue-700 {
    background-color: #005c99 !important
}

.bg-blue-800 {
    background-color: #003d66 !important
}

.bg-blue-900 {
    background-color: #001f33 !important
}

.bg-indigo-100 {
    background-color: #dedffd !important
}

.bg-indigo-200 {
    background-color: #bebffb !important
}

.bg-indigo-300 {
    background-color: #9da0f9 !important
}

.bg-indigo-400 {
    background-color: #7d80f7 !important
}

.bg-indigo-500 {
    background-color: #5c60f5 !important
}

.bg-indigo-600 {
    background-color: #4a4dc4 !important
}

.bg-indigo-700 {
    background-color: #373a93 !important
}

.bg-indigo-800 {
    background-color: #252662 !important
}

.bg-indigo-900 {
    background-color: #121331 !important
}

.bg-purple-100 {
    background-color: #e7ddff !important
}

.bg-purple-200 {
    background-color: #d0bcff !important
}

.bg-purple-300 {
    background-color: #b89aff !important
}

.bg-purple-400 {
    background-color: #a179ff !important
}

.bg-purple-500 {
    background-color: #8957ff !important
}

.bg-purple-600 {
    background-color: #6e46cc !important
}

.bg-purple-700 {
    background-color: #523499 !important
}

.bg-purple-800 {
    background-color: #372366 !important
}

.bg-purple-900 {
    background-color: #1b1133 !important
}

.bg-pink-100 {
    background-color: #ffddeb !important
}

.bg-pink-200 {
    background-color: #ffbcd7 !important
}

.bg-pink-300 {
    background-color: #ff9ac2 !important
}

.bg-pink-400 {
    background-color: #ff79ae !important
}

.bg-pink-500 {
    background-color: #ff579a !important
}

.bg-pink-600 {
    background-color: #cc467b !important
}

.bg-pink-700 {
    background-color: #99345c !important
}

.bg-pink-800 {
    background-color: #66233e !important
}

.bg-pink-900 {
    background-color: #33111f !important
}

.bg-magenta-100 {
    background-color: #fcd6ff !important
}

.bg-magenta-200 {
    background-color: #f8adff !important
}

.bg-magenta-300 {
    background-color: #f585ff !important
}

.bg-magenta-400 {
    background-color: #f15cff !important
}

.bg-magenta-500 {
    background-color: #e3f !important
}

.bg-magenta-600 {
    background-color: #be29cc !important
}

.bg-magenta-700 {
    background-color: #8f1f99 !important
}

.bg-magenta-800 {
    background-color: #5f1466 !important
}

.bg-magenta-900 {
    background-color: #300a33 !important
}

.bg-red-100 {
    background-color: #ffd6e0 !important
}

.bg-red-200 {
    background-color: #ffadc2 !important
}

.bg-red-300 {
    background-color: #ff85a3 !important
}

.bg-red-400 {
    background-color: #ff5c85 !important
}

.bg-red-500 {
    background-color: #f36 !important
}

.bg-red-600 {
    background-color: #cc2952 !important
}

.bg-red-700 {
    background-color: #991f3d !important
}

.bg-red-800 {
    background-color: #661429 !important
}

.bg-red-900 {
    background-color: #330a14 !important
}

.bg-orange-100 {
    background-color: #ffe8cc !important
}

.bg-orange-200 {
    background-color: #ffd199 !important
}

.bg-orange-300 {
    background-color: #ffba66 !important
}

.bg-orange-400 {
    background-color: #ffa333 !important
}

.bg-orange-500 {
    background-color: #ff8c00 !important
}

.bg-orange-600 {
    background-color: #cc7000 !important
}

.bg-orange-700 {
    background-color: #995400 !important
}

.bg-orange-800 {
    background-color: #663800 !important
}

.bg-orange-900 {
    background-color: #331c00 !important
}

.bg-yellow-100 {
    background-color: #fff1cc !important
}

.bg-yellow-200 {
    background-color: #ffe499 !important
}

.bg-yellow-300 {
    background-color: #ffd666 !important
}

.bg-yellow-400 {
    background-color: #ffc933 !important
}

.bg-yellow-500 {
    background-color: #fb0 !important
}

.bg-yellow-600 {
    background-color: #cc9600 !important
}

.bg-yellow-700 {
    background-color: #997000 !important
}

.bg-yellow-800 {
    background-color: #664b00 !important
}

.bg-yellow-900 {
    background-color: #332500 !important
}

.bg-green-100 {
    background-color: #ccf5e7 !important
}

.bg-green-200 {
    background-color: #99ebcf !important
}

.bg-green-300 {
    background-color: #66e0b8 !important
}

.bg-green-400 {
    background-color: #33d6a0 !important
}

.bg-green-500 {
    background-color: #0c8 !important
}

.bg-green-600 {
    background-color: #00a36d !important
}

.bg-green-700 {
    background-color: #007a52 !important
}

.bg-green-800 {
    background-color: #005236 !important
}

.bg-green-900 {
    background-color: #00291b !important
}

.bg-teal-100 {
    background-color: #d3f8f8 !important
}

.bg-teal-200 {
    background-color: #a7f1f1 !important
}

.bg-teal-300 {
    background-color: #7aebeb !important
}

.bg-teal-400 {
    background-color: #4ee4e4 !important
}

.bg-teal-500 {
    background-color: #2dd !important
}

.bg-teal-600 {
    background-color: #1bb1b1 !important
}

.bg-teal-700 {
    background-color: #148585 !important
}

.bg-teal-800 {
    background-color: #0e5858 !important
}

.bg-teal-900 {
    background-color: #072c2c !important
}

.bg-cyan-100 {
    background-color: #ccf6ff !important
}

.bg-cyan-200 {
    background-color: #9ef !important
}

.bg-cyan-300 {
    background-color: #66e5ff !important
}

.bg-cyan-400 {
    background-color: #3df !important
}

.bg-cyan-500 {
    background-color: #00d4ff !important
}

.bg-cyan-600 {
    background-color: #0ac !important
}

.bg-cyan-700 {
    background-color: #007f99 !important
}

.bg-cyan-800 {
    background-color: #056 !important
}

.bg-cyan-900 {
    background-color: #002a33 !important
}

.bg-tint-primary {
    background-color: #7478f7 !important
}

.bg-shade-primary {
    background-color: #4e52d0 !important
}

.bg-soft-primary {
    background-color: #dedffd !important
}

.bg-tint-secondary {
    background-color: #d6dce4 !important
}

.bg-shade-secondary {
    background-color: #b0b6be !important
}

.bg-soft-secondary {
    background-color: #f5f7f9 !important
}

.bg-tint-tertiary {
    background-color: #ff70a9 !important
}

.bg-shade-tertiary {
    background-color: #d94a83 !important
}

.bg-soft-tertiary {
    background-color: #ffddeb !important
}

.bg-tint-success {
    background-color: #26d49a !important
}

.bg-shade-success {
    background-color: #00ad74 !important
}

.bg-soft-success {
    background-color: #ccf5e7 !important
}

.bg-tint-info {
    background-color: #26daff !important
}

.bg-shade-info {
    background-color: #00b4d9 !important
}

.bg-soft-info {
    background-color: #ccf6ff !important
}

.bg-tint-warning {
    background-color: #ff9d26 !important
}

.bg-shade-warning {
    background-color: #d97700 !important
}

.bg-soft-warning {
    background-color: #ffe8cc !important
}

.bg-tint-danger {
    background-color: #ff527d !important
}

.bg-shade-danger {
    background-color: #d92b57 !important
}

.bg-soft-danger {
    background-color: #ffd6e0 !important
}

.bg-tint-white {
    background-color: #fff !important
}

.bg-shade-white {
    background-color: #d9d9d9 !important
}

.bg-soft-white, .bg-tint-light {
    background-color: #fff !important
}

.bg-shade-light {
    background-color: #d9d9d9 !important
}

.bg-soft-light {
    background-color: #fff !important
}

.bg-tint-dark {
    background-color: #393c4c !important
}

.bg-shade-dark {
    background-color: #131525 !important
}

.bg-soft-dark {
    background-color: #d0d1d5 !important
}

.bg-surface-primary {
    background-color: #fff !important
}

.bg-surface-secondary {
    background-color: #F9FAFC !important
}

.bg-surface-tertiary {
    background-color: #fafafa !important
}

.bg-surface-light {
    background-color: #e7eaf0 !important
}

.bg-surface-dark {
    background-color: #141729 !important
}

.bg-fade-light {
    background-image: linear-gradient(180deg,rgba(243,248,255,0),#f3f8ff)
}

.bg-light-boxed-right {
    background: no-repeat 0 -webkit-gradient(linear,left top,right top,from(#f3f8ff))
}

@media (min-width:1200px) {
    .bg-light-boxed-right {
        background-size: calc(570px + 50vw) 100%
    }
}

.bg-checkered {
    background-image: radial-gradient(transparent,transparent 50%,#fff),linear-gradient(90deg,transparent,transparent 32px,#eaf2fe 0,#eaf2fe),linear-gradient(180deg,transparent,transparent 32px,#eaf2fe 0,#eaf2fe);
    background-repeat: no-repeat,repeat,repeat;
    background-size: 100% 100%,33px 33px,33px 33px
}

.bg-hero-gradient:after {
    background: linear-gradient(180deg,#fff,hsla(0,0%,100%,.966) 13.11%,hsla(0,0%,100%,.874) 24.72%,hsla(0,0%,100%,.741) 35.26%,hsla(0,0%,100%,.583) 45.16%,hsla(0,0%,100%,.417) 54.84%,hsla(0,0%,100%,.259) 64.74%,hsla(0,0%,100%,.126) 75.28%,hsla(0,0%,100%,.034) 86.89%,hsla(0,0%,100%,0));
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%
}

.bg-translucent-white {
    background-color: hsla(0,0%,100%,.05) !important
}

.bg-translucent-dark {
    background-color: rgba(22,25,44,.05) !important
}

.bg-none {
    background-image: none !important
}

.bg-between {
    background-position: 0,100%;
    background-repeat: no-repeat;
    background-size: auto 90%
}

@media (min-width:576px) {
    .bg-sm-none {
        background-image: none !important
    }

    .bg-sm-between {
        background-position: 0,100%;
        background-repeat: no-repeat;
        background-size: auto 90%
    }
}

@media (min-width:768px) {
    .bg-md-none {
        background-image: none !important
    }

    .bg-md-between {
        background-position: 0,100%;
        background-repeat: no-repeat;
        background-size: auto 90%
    }
}

@media (min-width:992px) {
    .bg-lg-none {
        background-image: none !important
    }

    .bg-lg-between {
        background-position: 0,100%;
        background-repeat: no-repeat;
        background-size: auto 90%
    }
}

@media (min-width:1200px) {
    .bg-xl-none {
        background-image: none !important
    }

    .bg-xl-between {
        background-position: 0,100%;
        background-repeat: no-repeat;
        background-size: auto 90%
    }
}

@media (min-width:1400px) {
    .bg-xxl-none {
        background-image: none !important
    }

    .bg-xxl-between {
        background-position: 0,100%;
        background-repeat: no-repeat;
        background-size: auto 90%
    }
}

.gradient-top {
    background-image: linear-gradient(to top,var(--x-gradient)) !important
}

.gradient-top-right {
    background-image: linear-gradient(to top right,var(--x-gradient)) !important
}

.gradient-right {
    background-image: linear-gradient(to right,var(--x-gradient)) !important
}

.gradient-bottom-right {
    background-image: linear-gradient(to bottom right,var(--x-gradient)) !important
}

.gradient-bottom {
    background-image: linear-gradient(to bottom,var(--x-gradient)) !important
}

.gradient-bottom-left {
    background-image: linear-gradient(to bottom left,var(--x-gradient)) !important
}

.gradient-left {
    background-image: linear-gradient(to left,var(--x-gradient)) !important
}

.gradient-top-left {
    background-image: linear-gradient(to top left,var(--x-gradient)) !important
}

.start-white, .start-white-hover:hover {
    --x-start-color: #fff;
    --x-gradient: var(--x-start-color),var(--x-end-color,hsla(0,0%,100%,0))
}

.start-gray-50, .start-gray-50-hover:hover {
    --x-start-color: #fafafa;
    --x-gradient: var(--x-start-color),var(--x-end-color,hsla(0,0%,98%,0))
}

.start-gray-100, .start-gray-100-hover:hover {
    --x-start-color: #f5f9fc;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(245,249,252,0))
}

.start-gray-200, .start-gray-200-hover:hover {
    --x-start-color: #e7eaf0;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(231,234,240,0))
}

.start-gray-300, .start-gray-300-hover:hover {
    --x-start-color: #cfd6df;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(207,214,223,0))
}

.start-gray-400, .start-gray-400-hover:hover {
    --x-start-color: #abb6c5;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(171,182,197,0))
}

.start-gray-500, .start-gray-500-hover:hover {
    --x-start-color: #8898a9;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(136,152,169,0))
}

.start-gray-600, .start-gray-600-hover:hover {
    --x-start-color: #6b7b93;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(107,123,147,0))
}

.start-gray-700, .start-gray-700-hover:hover {
    --x-start-color: #525f7f;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(82,95,127,0))
}

.start-gray-800, .start-gray-800-hover:hover {
    --x-start-color: #2d3748;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(45,55,72,0))
}

.start-gray-900, .start-gray-900-hover:hover {
    --x-start-color: #16192c;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(22,25,44,0))
}

.start-black, .start-black-hover:hover {
    --x-start-color: #000;
    --x-gradient: var(--x-start-color),var(--x-end-color,transparent)
}

.start-blue-100, .start-blue-100-hover:hover {
    --x-start-color: #ccebff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(204,235,255,0))
}

.start-blue-200, .start-blue-200-hover:hover {
    --x-start-color: #99d6ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(153,214,255,0))
}

.start-blue-300, .start-blue-300-hover:hover {
    --x-start-color: #66c2ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(102,194,255,0))
}

.start-blue-400, .start-blue-400-hover:hover {
    --x-start-color: #33adff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(51,173,255,0))
}

.start-blue-500, .start-blue-500-hover:hover {
    --x-start-color: #09f;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,153,255,0))
}

.start-blue-600, .start-blue-600-hover:hover {
    --x-start-color: #007acc;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,122,204,0))
}

.start-blue-700, .start-blue-700-hover:hover {
    --x-start-color: #005c99;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,92,153,0))
}

.start-blue-800, .start-blue-800-hover:hover {
    --x-start-color: #003d66;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,61,102,0))
}

.start-blue-900, .start-blue-900-hover:hover {
    --x-start-color: #001f33;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,31,51,0))
}

.start-indigo-100, .start-indigo-100-hover:hover {
    --x-start-color: #dedffd;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(222,223,253,0))
}

.start-indigo-200, .start-indigo-200-hover:hover {
    --x-start-color: #bebffb;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(190,191,251,0))
}

.start-indigo-300, .start-indigo-300-hover:hover {
    --x-start-color: #9da0f9;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(157,160,249,0))
}

.start-indigo-400, .start-indigo-400-hover:hover {
    --x-start-color: #7d80f7;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(125,128,247,0))
}

.start-indigo-500, .start-indigo-500-hover:hover {
    --x-start-color: #5c60f5;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(92,96,245,0))
}

.start-indigo-600, .start-indigo-600-hover:hover {
    --x-start-color: #4a4dc4;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(74,77,196,0))
}

.start-indigo-700, .start-indigo-700-hover:hover {
    --x-start-color: #373a93;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(55,58,147,0))
}

.start-indigo-800, .start-indigo-800-hover:hover {
    --x-start-color: #252662;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(37,38,98,0))
}

.start-indigo-900, .start-indigo-900-hover:hover {
    --x-start-color: #121331;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(18,19,49,0))
}

.start-purple-100, .start-purple-100-hover:hover {
    --x-start-color: #e7ddff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(231,221,255,0))
}

.start-purple-200, .start-purple-200-hover:hover {
    --x-start-color: #d0bcff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(208,188,255,0))
}

.start-purple-300, .start-purple-300-hover:hover {
    --x-start-color: #b89aff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(184,154,255,0))
}

.start-purple-400, .start-purple-400-hover:hover {
    --x-start-color: #a179ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(161,121,255,0))
}

.start-purple-500, .start-purple-500-hover:hover {
    --x-start-color: #8957ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(137,87,255,0))
}

.start-purple-600, .start-purple-600-hover:hover {
    --x-start-color: #6e46cc;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(110,70,204,0))
}

.start-purple-700, .start-purple-700-hover:hover {
    --x-start-color: #523499;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(82,52,153,0))
}

.start-purple-800, .start-purple-800-hover:hover {
    --x-start-color: #372366;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(55,35,102,0))
}

.start-purple-900, .start-purple-900-hover:hover {
    --x-start-color: #1b1133;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(27,17,51,0))
}

.start-pink-100, .start-pink-100-hover:hover {
    --x-start-color: #ffddeb;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,221,235,0))
}

.start-pink-200, .start-pink-200-hover:hover {
    --x-start-color: #ffbcd7;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,188,215,0))
}

.start-pink-300, .start-pink-300-hover:hover {
    --x-start-color: #ff9ac2;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,154,194,0))
}

.start-pink-400, .start-pink-400-hover:hover {
    --x-start-color: #ff79ae;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,121,174,0))
}

.start-pink-500, .start-pink-500-hover:hover {
    --x-start-color: #ff579a;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,87,154,0))
}

.start-pink-600, .start-pink-600-hover:hover {
    --x-start-color: #cc467b;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(204,70,123,0))
}

.start-pink-700, .start-pink-700-hover:hover {
    --x-start-color: #99345c;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(153,52,92,0))
}

.start-pink-800, .start-pink-800-hover:hover {
    --x-start-color: #66233e;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(102,35,62,0))
}

.start-pink-900, .start-pink-900-hover:hover {
    --x-start-color: #33111f;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(51,17,31,0))
}

.start-magenta-100, .start-magenta-100-hover:hover {
    --x-start-color: #fcd6ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(252,214,255,0))
}

.start-magenta-200, .start-magenta-200-hover:hover {
    --x-start-color: #f8adff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(248,173,255,0))
}

.start-magenta-300, .start-magenta-300-hover:hover {
    --x-start-color: #f585ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(245,133,255,0))
}

.start-magenta-400, .start-magenta-400-hover:hover {
    --x-start-color: #f15cff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(241,92,255,0))
}

.start-magenta-500, .start-magenta-500-hover:hover {
    --x-start-color: #e3f;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(238,51,255,0))
}

.start-magenta-600, .start-magenta-600-hover:hover {
    --x-start-color: #be29cc;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(190,41,204,0))
}

.start-magenta-700, .start-magenta-700-hover:hover {
    --x-start-color: #8f1f99;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(143,31,153,0))
}

.start-magenta-800, .start-magenta-800-hover:hover {
    --x-start-color: #5f1466;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(95,20,102,0))
}

.start-magenta-900, .start-magenta-900-hover:hover {
    --x-start-color: #300a33;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(48,10,51,0))
}

.start-red-100, .start-red-100-hover:hover {
    --x-start-color: #ffd6e0;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,214,224,0))
}

.start-red-200, .start-red-200-hover:hover {
    --x-start-color: #ffadc2;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,173,194,0))
}

.start-red-300, .start-red-300-hover:hover {
    --x-start-color: #ff85a3;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,133,163,0))
}

.start-red-400, .start-red-400-hover:hover {
    --x-start-color: #ff5c85;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,92,133,0))
}

.start-red-500, .start-red-500-hover:hover {
    --x-start-color: #f36;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,51,102,0))
}

.start-red-600, .start-red-600-hover:hover {
    --x-start-color: #cc2952;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(204,41,82,0))
}

.start-red-700, .start-red-700-hover:hover {
    --x-start-color: #991f3d;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(153,31,61,0))
}

.start-red-800, .start-red-800-hover:hover {
    --x-start-color: #661429;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(102,20,41,0))
}

.start-red-900, .start-red-900-hover:hover {
    --x-start-color: #330a14;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(51,10,20,0))
}

.start-orange-100, .start-orange-100-hover:hover {
    --x-start-color: #ffe8cc;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,232,204,0))
}

.start-orange-200, .start-orange-200-hover:hover {
    --x-start-color: #ffd199;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,209,153,0))
}

.start-orange-300, .start-orange-300-hover:hover {
    --x-start-color: #ffba66;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,186,102,0))
}

.start-orange-400, .start-orange-400-hover:hover {
    --x-start-color: #ffa333;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,163,51,0))
}

.start-orange-500, .start-orange-500-hover:hover {
    --x-start-color: #ff8c00;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,140,0,0))
}

.start-orange-600, .start-orange-600-hover:hover {
    --x-start-color: #cc7000;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(204,112,0,0))
}

.start-orange-700, .start-orange-700-hover:hover {
    --x-start-color: #995400;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(153,84,0,0))
}

.start-orange-800, .start-orange-800-hover:hover {
    --x-start-color: #663800;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(102,56,0,0))
}

.start-orange-900, .start-orange-900-hover:hover {
    --x-start-color: #331c00;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(51,28,0,0))
}

.start-yellow-100, .start-yellow-100-hover:hover {
    --x-start-color: #fff1cc;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,241,204,0))
}

.start-yellow-200, .start-yellow-200-hover:hover {
    --x-start-color: #ffe499;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,228,153,0))
}

.start-yellow-300, .start-yellow-300-hover:hover {
    --x-start-color: #ffd666;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,214,102,0))
}

.start-yellow-400, .start-yellow-400-hover:hover {
    --x-start-color: #ffc933;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,201,51,0))
}

.start-yellow-500, .start-yellow-500-hover:hover {
    --x-start-color: #fb0;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(255,187,0,0))
}

.start-yellow-600, .start-yellow-600-hover:hover {
    --x-start-color: #cc9600;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(204,150,0,0))
}

.start-yellow-700, .start-yellow-700-hover:hover {
    --x-start-color: #997000;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(153,112,0,0))
}

.start-yellow-800, .start-yellow-800-hover:hover {
    --x-start-color: #664b00;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(102,75,0,0))
}

.start-yellow-900, .start-yellow-900-hover:hover {
    --x-start-color: #332500;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(51,37,0,0))
}

.start-green-100, .start-green-100-hover:hover {
    --x-start-color: #ccf5e7;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(204,245,231,0))
}

.start-green-200, .start-green-200-hover:hover {
    --x-start-color: #99ebcf;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(153,235,207,0))
}

.start-green-300, .start-green-300-hover:hover {
    --x-start-color: #66e0b8;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(102,224,184,0))
}

.start-green-400, .start-green-400-hover:hover {
    --x-start-color: #33d6a0;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(51,214,160,0))
}

.start-green-500, .start-green-500-hover:hover {
    --x-start-color: #0c8;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,204,136,0))
}

.start-green-600, .start-green-600-hover:hover {
    --x-start-color: #00a36d;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,163,109,0))
}

.start-green-700, .start-green-700-hover:hover {
    --x-start-color: #007a52;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,122,82,0))
}

.start-green-800, .start-green-800-hover:hover {
    --x-start-color: #005236;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,82,54,0))
}

.start-green-900, .start-green-900-hover:hover {
    --x-start-color: #00291b;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,41,27,0))
}

.start-teal-100, .start-teal-100-hover:hover {
    --x-start-color: #d3f8f8;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(211,248,248,0))
}

.start-teal-200, .start-teal-200-hover:hover {
    --x-start-color: #a7f1f1;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(167,241,241,0))
}

.start-teal-300, .start-teal-300-hover:hover {
    --x-start-color: #7aebeb;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(122,235,235,0))
}

.start-teal-400, .start-teal-400-hover:hover {
    --x-start-color: #4ee4e4;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(78,228,228,0))
}

.start-teal-500, .start-teal-500-hover:hover {
    --x-start-color: #2dd;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(34,221,221,0))
}

.start-teal-600, .start-teal-600-hover:hover {
    --x-start-color: #1bb1b1;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(27,177,177,0))
}

.start-teal-700, .start-teal-700-hover:hover {
    --x-start-color: #148585;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(20,133,133,0))
}

.start-teal-800, .start-teal-800-hover:hover {
    --x-start-color: #0e5858;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(14,88,88,0))
}

.start-teal-900, .start-teal-900-hover:hover {
    --x-start-color: #072c2c;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(7,44,44,0))
}

.start-cyan-100, .start-cyan-100-hover:hover {
    --x-start-color: #ccf6ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(204,246,255,0))
}

.start-cyan-200, .start-cyan-200-hover:hover {
    --x-start-color: #9ef;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(153,238,255,0))
}

.start-cyan-300, .start-cyan-300-hover:hover {
    --x-start-color: #66e5ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(102,229,255,0))
}

.start-cyan-400, .start-cyan-400-hover:hover {
    --x-start-color: #3df;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(51,221,255,0))
}

.start-cyan-500, .start-cyan-500-hover:hover {
    --x-start-color: #00d4ff;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,212,255,0))
}

.start-cyan-600, .start-cyan-600-hover:hover {
    --x-start-color: #0ac;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,170,204,0))
}

.start-cyan-700, .start-cyan-700-hover:hover {
    --x-start-color: #007f99;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,127,153,0))
}

.start-cyan-800, .start-cyan-800-hover:hover {
    --x-start-color: #056;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,85,102,0))
}

.start-cyan-900, .start-cyan-900-hover:hover {
    --x-start-color: #002a33;
    --x-gradient: var(--x-start-color),var(--x-end-color,rgba(0,42,51,0))
}

.middle-white, .middle-white-hover:hover {
    --x-gradient: var(--x-start-color),#fff,var(--x-end-color,hsla(0,0%,100%,0))
}

.middle-gray-50, .middle-gray-50-hover:hover {
    --x-gradient: var(--x-start-color),#fafafa,var(--x-end-color,hsla(0,0%,98%,0))
}

.middle-gray-100, .middle-gray-100-hover:hover {
    --x-gradient: var(--x-start-color),#f5f9fc,var(--x-end-color,rgba(245,249,252,0))
}

.middle-gray-200, .middle-gray-200-hover:hover {
    --x-gradient: var(--x-start-color),#e7eaf0,var(--x-end-color,rgba(231,234,240,0))
}

.middle-gray-300, .middle-gray-300-hover:hover {
    --x-gradient: var(--x-start-color),#cfd6df,var(--x-end-color,rgba(207,214,223,0))
}

.middle-gray-400, .middle-gray-400-hover:hover {
    --x-gradient: var(--x-start-color),#abb6c5,var(--x-end-color,rgba(171,182,197,0))
}

.middle-gray-500, .middle-gray-500-hover:hover {
    --x-gradient: var(--x-start-color),#8898a9,var(--x-end-color,rgba(136,152,169,0))
}

.middle-gray-600, .middle-gray-600-hover:hover {
    --x-gradient: var(--x-start-color),#6b7b93,var(--x-end-color,rgba(107,123,147,0))
}

.middle-gray-700, .middle-gray-700-hover:hover {
    --x-gradient: var(--x-start-color),#525f7f,var(--x-end-color,rgba(82,95,127,0))
}

.middle-gray-800, .middle-gray-800-hover:hover {
    --x-gradient: var(--x-start-color),#2d3748,var(--x-end-color,rgba(45,55,72,0))
}

.middle-gray-900, .middle-gray-900-hover:hover {
    --x-gradient: var(--x-start-color),#16192c,var(--x-end-color,rgba(22,25,44,0))
}

.middle-black, .middle-black-hover:hover {
    --x-gradient: var(--x-start-color),#000,var(--x-end-color,transparent)
}

.middle-blue-100, .middle-blue-100-hover:hover {
    --x-gradient: var(--x-start-color),#ccebff,var(--x-end-color,rgba(204,235,255,0))
}

.middle-blue-200, .middle-blue-200-hover:hover {
    --x-gradient: var(--x-start-color),#99d6ff,var(--x-end-color,rgba(153,214,255,0))
}

.middle-blue-300, .middle-blue-300-hover:hover {
    --x-gradient: var(--x-start-color),#66c2ff,var(--x-end-color,rgba(102,194,255,0))
}

.middle-blue-400, .middle-blue-400-hover:hover {
    --x-gradient: var(--x-start-color),#33adff,var(--x-end-color,rgba(51,173,255,0))
}

.middle-blue-500, .middle-blue-500-hover:hover {
    --x-gradient: var(--x-start-color),#09f,var(--x-end-color,rgba(0,153,255,0))
}

.middle-blue-600, .middle-blue-600-hover:hover {
    --x-gradient: var(--x-start-color),#007acc,var(--x-end-color,rgba(0,122,204,0))
}

.middle-blue-700, .middle-blue-700-hover:hover {
    --x-gradient: var(--x-start-color),#005c99,var(--x-end-color,rgba(0,92,153,0))
}

.middle-blue-800, .middle-blue-800-hover:hover {
    --x-gradient: var(--x-start-color),#003d66,var(--x-end-color,rgba(0,61,102,0))
}

.middle-blue-900, .middle-blue-900-hover:hover {
    --x-gradient: var(--x-start-color),#001f33,var(--x-end-color,rgba(0,31,51,0))
}

.middle-indigo-100, .middle-indigo-100-hover:hover {
    --x-gradient: var(--x-start-color),#dedffd,var(--x-end-color,rgba(222,223,253,0))
}

.middle-indigo-200, .middle-indigo-200-hover:hover {
    --x-gradient: var(--x-start-color),#bebffb,var(--x-end-color,rgba(190,191,251,0))
}

.middle-indigo-300, .middle-indigo-300-hover:hover {
    --x-gradient: var(--x-start-color),#9da0f9,var(--x-end-color,rgba(157,160,249,0))
}

.middle-indigo-400, .middle-indigo-400-hover:hover {
    --x-gradient: var(--x-start-color),#7d80f7,var(--x-end-color,rgba(125,128,247,0))
}

.middle-indigo-500, .middle-indigo-500-hover:hover {
    --x-gradient: var(--x-start-color),#5c60f5,var(--x-end-color,rgba(92,96,245,0))
}

.middle-indigo-600, .middle-indigo-600-hover:hover {
    --x-gradient: var(--x-start-color),#4a4dc4,var(--x-end-color,rgba(74,77,196,0))
}

.middle-indigo-700, .middle-indigo-700-hover:hover {
    --x-gradient: var(--x-start-color),#373a93,var(--x-end-color,rgba(55,58,147,0))
}

.middle-indigo-800, .middle-indigo-800-hover:hover {
    --x-gradient: var(--x-start-color),#252662,var(--x-end-color,rgba(37,38,98,0))
}

.middle-indigo-900, .middle-indigo-900-hover:hover {
    --x-gradient: var(--x-start-color),#121331,var(--x-end-color,rgba(18,19,49,0))
}

.middle-purple-100, .middle-purple-100-hover:hover {
    --x-gradient: var(--x-start-color),#e7ddff,var(--x-end-color,rgba(231,221,255,0))
}

.middle-purple-200, .middle-purple-200-hover:hover {
    --x-gradient: var(--x-start-color),#d0bcff,var(--x-end-color,rgba(208,188,255,0))
}

.middle-purple-300, .middle-purple-300-hover:hover {
    --x-gradient: var(--x-start-color),#b89aff,var(--x-end-color,rgba(184,154,255,0))
}

.middle-purple-400, .middle-purple-400-hover:hover {
    --x-gradient: var(--x-start-color),#a179ff,var(--x-end-color,rgba(161,121,255,0))
}

.middle-purple-500, .middle-purple-500-hover:hover {
    --x-gradient: var(--x-start-color),#8957ff,var(--x-end-color,rgba(137,87,255,0))
}

.middle-purple-600, .middle-purple-600-hover:hover {
    --x-gradient: var(--x-start-color),#6e46cc,var(--x-end-color,rgba(110,70,204,0))
}

.middle-purple-700, .middle-purple-700-hover:hover {
    --x-gradient: var(--x-start-color),#523499,var(--x-end-color,rgba(82,52,153,0))
}

.middle-purple-800, .middle-purple-800-hover:hover {
    --x-gradient: var(--x-start-color),#372366,var(--x-end-color,rgba(55,35,102,0))
}

.middle-purple-900, .middle-purple-900-hover:hover {
    --x-gradient: var(--x-start-color),#1b1133,var(--x-end-color,rgba(27,17,51,0))
}

.middle-pink-100, .middle-pink-100-hover:hover {
    --x-gradient: var(--x-start-color),#ffddeb,var(--x-end-color,rgba(255,221,235,0))
}

.middle-pink-200, .middle-pink-200-hover:hover {
    --x-gradient: var(--x-start-color),#ffbcd7,var(--x-end-color,rgba(255,188,215,0))
}

.middle-pink-300, .middle-pink-300-hover:hover {
    --x-gradient: var(--x-start-color),#ff9ac2,var(--x-end-color,rgba(255,154,194,0))
}

.middle-pink-400, .middle-pink-400-hover:hover {
    --x-gradient: var(--x-start-color),#ff79ae,var(--x-end-color,rgba(255,121,174,0))
}

.middle-pink-500, .middle-pink-500-hover:hover {
    --x-gradient: var(--x-start-color),#ff579a,var(--x-end-color,rgba(255,87,154,0))
}

.middle-pink-600, .middle-pink-600-hover:hover {
    --x-gradient: var(--x-start-color),#cc467b,var(--x-end-color,rgba(204,70,123,0))
}

.middle-pink-700, .middle-pink-700-hover:hover {
    --x-gradient: var(--x-start-color),#99345c,var(--x-end-color,rgba(153,52,92,0))
}

.middle-pink-800, .middle-pink-800-hover:hover {
    --x-gradient: var(--x-start-color),#66233e,var(--x-end-color,rgba(102,35,62,0))
}

.middle-pink-900, .middle-pink-900-hover:hover {
    --x-gradient: var(--x-start-color),#33111f,var(--x-end-color,rgba(51,17,31,0))
}

.middle-magenta-100, .middle-magenta-100-hover:hover {
    --x-gradient: var(--x-start-color),#fcd6ff,var(--x-end-color,rgba(252,214,255,0))
}

.middle-magenta-200, .middle-magenta-200-hover:hover {
    --x-gradient: var(--x-start-color),#f8adff,var(--x-end-color,rgba(248,173,255,0))
}

.middle-magenta-300, .middle-magenta-300-hover:hover {
    --x-gradient: var(--x-start-color),#f585ff,var(--x-end-color,rgba(245,133,255,0))
}

.middle-magenta-400, .middle-magenta-400-hover:hover {
    --x-gradient: var(--x-start-color),#f15cff,var(--x-end-color,rgba(241,92,255,0))
}

.middle-magenta-500, .middle-magenta-500-hover:hover {
    --x-gradient: var(--x-start-color),#e3f,var(--x-end-color,rgba(238,51,255,0))
}

.middle-magenta-600, .middle-magenta-600-hover:hover {
    --x-gradient: var(--x-start-color),#be29cc,var(--x-end-color,rgba(190,41,204,0))
}

.middle-magenta-700, .middle-magenta-700-hover:hover {
    --x-gradient: var(--x-start-color),#8f1f99,var(--x-end-color,rgba(143,31,153,0))
}

.middle-magenta-800, .middle-magenta-800-hover:hover {
    --x-gradient: var(--x-start-color),#5f1466,var(--x-end-color,rgba(95,20,102,0))
}

.middle-magenta-900, .middle-magenta-900-hover:hover {
    --x-gradient: var(--x-start-color),#300a33,var(--x-end-color,rgba(48,10,51,0))
}

.middle-red-100, .middle-red-100-hover:hover {
    --x-gradient: var(--x-start-color),#ffd6e0,var(--x-end-color,rgba(255,214,224,0))
}

.middle-red-200, .middle-red-200-hover:hover {
    --x-gradient: var(--x-start-color),#ffadc2,var(--x-end-color,rgba(255,173,194,0))
}

.middle-red-300, .middle-red-300-hover:hover {
    --x-gradient: var(--x-start-color),#ff85a3,var(--x-end-color,rgba(255,133,163,0))
}

.middle-red-400, .middle-red-400-hover:hover {
    --x-gradient: var(--x-start-color),#ff5c85,var(--x-end-color,rgba(255,92,133,0))
}

.middle-red-500, .middle-red-500-hover:hover {
    --x-gradient: var(--x-start-color),#f36,var(--x-end-color,rgba(255,51,102,0))
}

.middle-red-600, .middle-red-600-hover:hover {
    --x-gradient: var(--x-start-color),#cc2952,var(--x-end-color,rgba(204,41,82,0))
}

.middle-red-700, .middle-red-700-hover:hover {
    --x-gradient: var(--x-start-color),#991f3d,var(--x-end-color,rgba(153,31,61,0))
}

.middle-red-800, .middle-red-800-hover:hover {
    --x-gradient: var(--x-start-color),#661429,var(--x-end-color,rgba(102,20,41,0))
}

.middle-red-900, .middle-red-900-hover:hover {
    --x-gradient: var(--x-start-color),#330a14,var(--x-end-color,rgba(51,10,20,0))
}

.middle-orange-100, .middle-orange-100-hover:hover {
    --x-gradient: var(--x-start-color),#ffe8cc,var(--x-end-color,rgba(255,232,204,0))
}

.middle-orange-200, .middle-orange-200-hover:hover {
    --x-gradient: var(--x-start-color),#ffd199,var(--x-end-color,rgba(255,209,153,0))
}

.middle-orange-300, .middle-orange-300-hover:hover {
    --x-gradient: var(--x-start-color),#ffba66,var(--x-end-color,rgba(255,186,102,0))
}

.middle-orange-400, .middle-orange-400-hover:hover {
    --x-gradient: var(--x-start-color),#ffa333,var(--x-end-color,rgba(255,163,51,0))
}

.middle-orange-500, .middle-orange-500-hover:hover {
    --x-gradient: var(--x-start-color),#ff8c00,var(--x-end-color,rgba(255,140,0,0))
}

.middle-orange-600, .middle-orange-600-hover:hover {
    --x-gradient: var(--x-start-color),#cc7000,var(--x-end-color,rgba(204,112,0,0))
}

.middle-orange-700, .middle-orange-700-hover:hover {
    --x-gradient: var(--x-start-color),#995400,var(--x-end-color,rgba(153,84,0,0))
}

.middle-orange-800, .middle-orange-800-hover:hover {
    --x-gradient: var(--x-start-color),#663800,var(--x-end-color,rgba(102,56,0,0))
}

.middle-orange-900, .middle-orange-900-hover:hover {
    --x-gradient: var(--x-start-color),#331c00,var(--x-end-color,rgba(51,28,0,0))
}

.middle-yellow-100, .middle-yellow-100-hover:hover {
    --x-gradient: var(--x-start-color),#fff1cc,var(--x-end-color,rgba(255,241,204,0))
}

.middle-yellow-200, .middle-yellow-200-hover:hover {
    --x-gradient: var(--x-start-color),#ffe499,var(--x-end-color,rgba(255,228,153,0))
}

.middle-yellow-300, .middle-yellow-300-hover:hover {
    --x-gradient: var(--x-start-color),#ffd666,var(--x-end-color,rgba(255,214,102,0))
}

.middle-yellow-400, .middle-yellow-400-hover:hover {
    --x-gradient: var(--x-start-color),#ffc933,var(--x-end-color,rgba(255,201,51,0))
}

.middle-yellow-500, .middle-yellow-500-hover:hover {
    --x-gradient: var(--x-start-color),#fb0,var(--x-end-color,rgba(255,187,0,0))
}

.middle-yellow-600, .middle-yellow-600-hover:hover {
    --x-gradient: var(--x-start-color),#cc9600,var(--x-end-color,rgba(204,150,0,0))
}

.middle-yellow-700, .middle-yellow-700-hover:hover {
    --x-gradient: var(--x-start-color),#997000,var(--x-end-color,rgba(153,112,0,0))
}

.middle-yellow-800, .middle-yellow-800-hover:hover {
    --x-gradient: var(--x-start-color),#664b00,var(--x-end-color,rgba(102,75,0,0))
}

.middle-yellow-900, .middle-yellow-900-hover:hover {
    --x-gradient: var(--x-start-color),#332500,var(--x-end-color,rgba(51,37,0,0))
}

.middle-green-100, .middle-green-100-hover:hover {
    --x-gradient: var(--x-start-color),#ccf5e7,var(--x-end-color,rgba(204,245,231,0))
}

.middle-green-200, .middle-green-200-hover:hover {
    --x-gradient: var(--x-start-color),#99ebcf,var(--x-end-color,rgba(153,235,207,0))
}

.middle-green-300, .middle-green-300-hover:hover {
    --x-gradient: var(--x-start-color),#66e0b8,var(--x-end-color,rgba(102,224,184,0))
}

.middle-green-400, .middle-green-400-hover:hover {
    --x-gradient: var(--x-start-color),#33d6a0,var(--x-end-color,rgba(51,214,160,0))
}

.middle-green-500, .middle-green-500-hover:hover {
    --x-gradient: var(--x-start-color),#0c8,var(--x-end-color,rgba(0,204,136,0))
}

.middle-green-600, .middle-green-600-hover:hover {
    --x-gradient: var(--x-start-color),#00a36d,var(--x-end-color,rgba(0,163,109,0))
}

.middle-green-700, .middle-green-700-hover:hover {
    --x-gradient: var(--x-start-color),#007a52,var(--x-end-color,rgba(0,122,82,0))
}

.middle-green-800, .middle-green-800-hover:hover {
    --x-gradient: var(--x-start-color),#005236,var(--x-end-color,rgba(0,82,54,0))
}

.middle-green-900, .middle-green-900-hover:hover {
    --x-gradient: var(--x-start-color),#00291b,var(--x-end-color,rgba(0,41,27,0))
}

.middle-teal-100, .middle-teal-100-hover:hover {
    --x-gradient: var(--x-start-color),#d3f8f8,var(--x-end-color,rgba(211,248,248,0))
}

.middle-teal-200, .middle-teal-200-hover:hover {
    --x-gradient: var(--x-start-color),#a7f1f1,var(--x-end-color,rgba(167,241,241,0))
}

.middle-teal-300, .middle-teal-300-hover:hover {
    --x-gradient: var(--x-start-color),#7aebeb,var(--x-end-color,rgba(122,235,235,0))
}

.middle-teal-400, .middle-teal-400-hover:hover {
    --x-gradient: var(--x-start-color),#4ee4e4,var(--x-end-color,rgba(78,228,228,0))
}

.middle-teal-500, .middle-teal-500-hover:hover {
    --x-gradient: var(--x-start-color),#2dd,var(--x-end-color,rgba(34,221,221,0))
}

.middle-teal-600, .middle-teal-600-hover:hover {
    --x-gradient: var(--x-start-color),#1bb1b1,var(--x-end-color,rgba(27,177,177,0))
}

.middle-teal-700, .middle-teal-700-hover:hover {
    --x-gradient: var(--x-start-color),#148585,var(--x-end-color,rgba(20,133,133,0))
}

.middle-teal-800, .middle-teal-800-hover:hover {
    --x-gradient: var(--x-start-color),#0e5858,var(--x-end-color,rgba(14,88,88,0))
}

.middle-teal-900, .middle-teal-900-hover:hover {
    --x-gradient: var(--x-start-color),#072c2c,var(--x-end-color,rgba(7,44,44,0))
}

.middle-cyan-100, .middle-cyan-100-hover:hover {
    --x-gradient: var(--x-start-color),#ccf6ff,var(--x-end-color,rgba(204,246,255,0))
}

.middle-cyan-200, .middle-cyan-200-hover:hover {
    --x-gradient: var(--x-start-color),#9ef,var(--x-end-color,rgba(153,238,255,0))
}

.middle-cyan-300, .middle-cyan-300-hover:hover {
    --x-gradient: var(--x-start-color),#66e5ff,var(--x-end-color,rgba(102,229,255,0))
}

.middle-cyan-400, .middle-cyan-400-hover:hover {
    --x-gradient: var(--x-start-color),#3df,var(--x-end-color,rgba(51,221,255,0))
}

.middle-cyan-500, .middle-cyan-500-hover:hover {
    --x-gradient: var(--x-start-color),#00d4ff,var(--x-end-color,rgba(0,212,255,0))
}

.middle-cyan-600, .middle-cyan-600-hover:hover {
    --x-gradient: var(--x-start-color),#0ac,var(--x-end-color,rgba(0,170,204,0))
}

.middle-cyan-700, .middle-cyan-700-hover:hover {
    --x-gradient: var(--x-start-color),#007f99,var(--x-end-color,rgba(0,127,153,0))
}

.middle-cyan-800, .middle-cyan-800-hover:hover {
    --x-gradient: var(--x-start-color),#056,var(--x-end-color,rgba(0,85,102,0))
}

.middle-cyan-900, .middle-cyan-900-hover:hover {
    --x-gradient: var(--x-start-color),#002a33,var(--x-end-color,rgba(0,42,51,0))
}

.end-white, .end-white-hover:hover {
    --x-end-color: #fff
}

.end-gray-50, .end-gray-50-hover:hover {
    --x-end-color: #fafafa
}

.end-gray-100, .end-gray-100-hover:hover {
    --x-end-color: #f5f9fc
}

.end-gray-200, .end-gray-200-hover:hover {
    --x-end-color: #e7eaf0
}

.end-gray-300, .end-gray-300-hover:hover {
    --x-end-color: #cfd6df
}

.end-gray-400, .end-gray-400-hover:hover {
    --x-end-color: #abb6c5
}

.end-gray-500, .end-gray-500-hover:hover {
    --x-end-color: #8898a9
}

.end-gray-600, .end-gray-600-hover:hover {
    --x-end-color: #6b7b93
}

.end-gray-700, .end-gray-700-hover:hover {
    --x-end-color: #525f7f
}

.end-gray-800, .end-gray-800-hover:hover {
    --x-end-color: #2d3748
}

.end-gray-900, .end-gray-900-hover:hover {
    --x-end-color: #16192c
}

.end-black, .end-black-hover:hover {
    --x-end-color: #000
}

.end-blue-100, .end-blue-100-hover:hover {
    --x-end-color: #ccebff
}

.end-blue-200, .end-blue-200-hover:hover {
    --x-end-color: #99d6ff
}

.end-blue-300, .end-blue-300-hover:hover {
    --x-end-color: #66c2ff
}

.end-blue-400, .end-blue-400-hover:hover {
    --x-end-color: #33adff
}

.end-blue-500, .end-blue-500-hover:hover {
    --x-end-color: #09f
}

.end-blue-600, .end-blue-600-hover:hover {
    --x-end-color: #007acc
}

.end-blue-700, .end-blue-700-hover:hover {
    --x-end-color: #005c99
}

.end-blue-800, .end-blue-800-hover:hover {
    --x-end-color: #003d66
}

.end-blue-900, .end-blue-900-hover:hover {
    --x-end-color: #001f33
}

.end-indigo-100, .end-indigo-100-hover:hover {
    --x-end-color: #dedffd
}

.end-indigo-200, .end-indigo-200-hover:hover {
    --x-end-color: #bebffb
}

.end-indigo-300, .end-indigo-300-hover:hover {
    --x-end-color: #9da0f9
}

.end-indigo-400, .end-indigo-400-hover:hover {
    --x-end-color: #7d80f7
}

.end-indigo-500, .end-indigo-500-hover:hover {
    --x-end-color: #5c60f5
}

.end-indigo-600, .end-indigo-600-hover:hover {
    --x-end-color: #4a4dc4
}

.end-indigo-700, .end-indigo-700-hover:hover {
    --x-end-color: #373a93
}

.end-indigo-800, .end-indigo-800-hover:hover {
    --x-end-color: #252662
}

.end-indigo-900, .end-indigo-900-hover:hover {
    --x-end-color: #121331
}

.end-purple-100, .end-purple-100-hover:hover {
    --x-end-color: #e7ddff
}

.end-purple-200, .end-purple-200-hover:hover {
    --x-end-color: #d0bcff
}

.end-purple-300, .end-purple-300-hover:hover {
    --x-end-color: #b89aff
}

.end-purple-400, .end-purple-400-hover:hover {
    --x-end-color: #a179ff
}

.end-purple-500, .end-purple-500-hover:hover {
    --x-end-color: #8957ff
}

.end-purple-600, .end-purple-600-hover:hover {
    --x-end-color: #6e46cc
}

.end-purple-700, .end-purple-700-hover:hover {
    --x-end-color: #523499
}

.end-purple-800, .end-purple-800-hover:hover {
    --x-end-color: #372366
}

.end-purple-900, .end-purple-900-hover:hover {
    --x-end-color: #1b1133
}

.end-pink-100, .end-pink-100-hover:hover {
    --x-end-color: #ffddeb
}

.end-pink-200, .end-pink-200-hover:hover {
    --x-end-color: #ffbcd7
}

.end-pink-300, .end-pink-300-hover:hover {
    --x-end-color: #ff9ac2
}

.end-pink-400, .end-pink-400-hover:hover {
    --x-end-color: #ff79ae
}

.end-pink-500, .end-pink-500-hover:hover {
    --x-end-color: #ff579a
}

.end-pink-600, .end-pink-600-hover:hover {
    --x-end-color: #cc467b
}

.end-pink-700, .end-pink-700-hover:hover {
    --x-end-color: #99345c
}

.end-pink-800, .end-pink-800-hover:hover {
    --x-end-color: #66233e
}

.end-pink-900, .end-pink-900-hover:hover {
    --x-end-color: #33111f
}

.end-magenta-100, .end-magenta-100-hover:hover {
    --x-end-color: #fcd6ff
}

.end-magenta-200, .end-magenta-200-hover:hover {
    --x-end-color: #f8adff
}

.end-magenta-300, .end-magenta-300-hover:hover {
    --x-end-color: #f585ff
}

.end-magenta-400, .end-magenta-400-hover:hover {
    --x-end-color: #f15cff
}

.end-magenta-500, .end-magenta-500-hover:hover {
    --x-end-color: #e3f
}

.end-magenta-600, .end-magenta-600-hover:hover {
    --x-end-color: #be29cc
}

.end-magenta-700, .end-magenta-700-hover:hover {
    --x-end-color: #8f1f99
}

.end-magenta-800, .end-magenta-800-hover:hover {
    --x-end-color: #5f1466
}

.end-magenta-900, .end-magenta-900-hover:hover {
    --x-end-color: #300a33
}

.end-red-100, .end-red-100-hover:hover {
    --x-end-color: #ffd6e0
}

.end-red-200, .end-red-200-hover:hover {
    --x-end-color: #ffadc2
}

.end-red-300, .end-red-300-hover:hover {
    --x-end-color: #ff85a3
}

.end-red-400, .end-red-400-hover:hover {
    --x-end-color: #ff5c85
}

.end-red-500, .end-red-500-hover:hover {
    --x-end-color: #f36
}

.end-red-600, .end-red-600-hover:hover {
    --x-end-color: #cc2952
}

.end-red-700, .end-red-700-hover:hover {
    --x-end-color: #991f3d
}

.end-red-800, .end-red-800-hover:hover {
    --x-end-color: #661429
}

.end-red-900, .end-red-900-hover:hover {
    --x-end-color: #330a14
}

.end-orange-100, .end-orange-100-hover:hover {
    --x-end-color: #ffe8cc
}

.end-orange-200, .end-orange-200-hover:hover {
    --x-end-color: #ffd199
}

.end-orange-300, .end-orange-300-hover:hover {
    --x-end-color: #ffba66
}

.end-orange-400, .end-orange-400-hover:hover {
    --x-end-color: #ffa333
}

.end-orange-500, .end-orange-500-hover:hover {
    --x-end-color: #ff8c00
}

.end-orange-600, .end-orange-600-hover:hover {
    --x-end-color: #cc7000
}

.end-orange-700, .end-orange-700-hover:hover {
    --x-end-color: #995400
}

.end-orange-800, .end-orange-800-hover:hover {
    --x-end-color: #663800
}

.end-orange-900, .end-orange-900-hover:hover {
    --x-end-color: #331c00
}

.end-yellow-100, .end-yellow-100-hover:hover {
    --x-end-color: #fff1cc
}

.end-yellow-200, .end-yellow-200-hover:hover {
    --x-end-color: #ffe499
}

.end-yellow-300, .end-yellow-300-hover:hover {
    --x-end-color: #ffd666
}

.end-yellow-400, .end-yellow-400-hover:hover {
    --x-end-color: #ffc933
}

.end-yellow-500, .end-yellow-500-hover:hover {
    --x-end-color: #fb0
}

.end-yellow-600, .end-yellow-600-hover:hover {
    --x-end-color: #cc9600
}

.end-yellow-700, .end-yellow-700-hover:hover {
    --x-end-color: #997000
}

.end-yellow-800, .end-yellow-800-hover:hover {
    --x-end-color: #664b00
}

.end-yellow-900, .end-yellow-900-hover:hover {
    --x-end-color: #332500
}

.end-green-100, .end-green-100-hover:hover {
    --x-end-color: #ccf5e7
}

.end-green-200, .end-green-200-hover:hover {
    --x-end-color: #99ebcf
}

.end-green-300, .end-green-300-hover:hover {
    --x-end-color: #66e0b8
}

.end-green-400, .end-green-400-hover:hover {
    --x-end-color: #33d6a0
}

.end-green-500, .end-green-500-hover:hover {
    --x-end-color: #0c8
}

.end-green-600, .end-green-600-hover:hover {
    --x-end-color: #00a36d
}

.end-green-700, .end-green-700-hover:hover {
    --x-end-color: #007a52
}

.end-green-800, .end-green-800-hover:hover {
    --x-end-color: #005236
}

.end-green-900, .end-green-900-hover:hover {
    --x-end-color: #00291b
}

.end-teal-100, .end-teal-100-hover:hover {
    --x-end-color: #d3f8f8
}

.end-teal-200, .end-teal-200-hover:hover {
    --x-end-color: #a7f1f1
}

.end-teal-300, .end-teal-300-hover:hover {
    --x-end-color: #7aebeb
}

.end-teal-400, .end-teal-400-hover:hover {
    --x-end-color: #4ee4e4
}

.end-teal-500, .end-teal-500-hover:hover {
    --x-end-color: #2dd
}

.end-teal-600, .end-teal-600-hover:hover {
    --x-end-color: #1bb1b1
}

.end-teal-700, .end-teal-700-hover:hover {
    --x-end-color: #148585
}

.end-teal-800, .end-teal-800-hover:hover {
    --x-end-color: #0e5858
}

.end-teal-900, .end-teal-900-hover:hover {
    --x-end-color: #072c2c
}

.end-cyan-100, .end-cyan-100-hover:hover {
    --x-end-color: #ccf6ff
}

.end-cyan-200, .end-cyan-200-hover:hover {
    --x-end-color: #9ef
}

.end-cyan-300, .end-cyan-300-hover:hover {
    --x-end-color: #66e5ff
}

.end-cyan-400, .end-cyan-400-hover:hover {
    --x-end-color: #3df
}

.end-cyan-500, .end-cyan-500-hover:hover {
    --x-end-color: #00d4ff
}

.end-cyan-600, .end-cyan-600-hover:hover {
    --x-end-color: #0ac
}

.end-cyan-700, .end-cyan-700-hover:hover {
    --x-end-color: #007f99
}

.end-cyan-800, .end-cyan-800-hover:hover {
    --x-end-color: #056
}

.end-cyan-900, .end-cyan-900-hover:hover {
    --x-end-color: #002a33
}

.filter {
    --x-blur: 0;
    --x-contrast: 0;
    --x-grayscale: 0;
    --x-hue-rotate: 0;
    --x-drop-shadow: 0;
    filter: blur(var(--x-blur))
}

.fade {
    transition: opacity .2s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .2s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    height: auto;
    transition: width .35s ease;
    width: 0
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.transform {
    --x-translate-x: 0;
    --x-translate-y: 0;
    --x-translate-z: 0;
    --x-perspective: 0;
    --x-rotate: 0;
    --x-rotate-x: 0;
    --x-rotate-y: 0;
    --x-skew-x: 0;
    --x-skew-y: 0;
    --x-scale-x: 1;
    --x-scale-y: 1;
    transform: translateX(var(--x-translate-x)) translateY(var(--x-translate-y)) perspective(var(--x-perspective)) rotate(var(--x-rotate)) rotateX(var(--x-rotate-x)) rotateY(var(--x-rotate-y)) skewX(var(--x-skew-x)) skewY(var(--x-skew-y)) scaleX(var(--x-scale-x)) scaleY(var(--x-scale-y))
}

@-webkit-keyframes ping {
    75%,to {
        opacity: 0;
        transform: scale(2)
    }
}

@keyframes ping {
    75%,to {
        opacity: 0;
        transform: scale(2)
    }
}

@-webkit-keyframes pulse {
    50% {
        opacity: .5
    }
}

@keyframes pulse {
    50% {
        opacity: .5
    }
}

@-webkit-keyframes bounce {
    0%,to {
        -webkit-animation-timing-function: cubic-bezier(.8,0,1,1);
        animation-timing-function: cubic-bezier(.8,0,1,1);
        transform: translateY(-25%)
    }

    50% {
        -webkit-animation-timing-function: cubic-bezier(0,0,.2,1);
        animation-timing-function: cubic-bezier(0,0,.2,1);
        transform: none
    }
}

@keyframes bounce {
    0%,to {
        -webkit-animation-timing-function: cubic-bezier(.8,0,1,1);
        animation-timing-function: cubic-bezier(.8,0,1,1);
        transform: translateY(-25%)
    }

    50% {
        -webkit-animation-timing-function: cubic-bezier(0,0,.2,1);
        animation-timing-function: cubic-bezier(0,0,.2,1);
        transform: none
    }
}

@-webkit-keyframes slideLeft {
    0% {
        transform: translateX(0)
    }

    to {
        transform: translateX(-100%)
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(0)
    }

    to {
        transform: translateX(-100%)
    }
}

@-webkit-keyframes slideRight {
    0% {
        transform: translateX(-100%)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(-100%)
    }

    to {
        transform: translateX(0)
    }
}

.h-calc {
    --x-h: 0px;
    height: calc(100vh - var(--x-h))
}

.w-calc {
    --x-w: 0px;
    width: calc(100vw - var(--x-w))
}

@media (min-width:576px) {
    .h-sm-calc {
        --x-h-sm: 0px;
        height: calc(100vh - var(--x-h-sm))
    }

    .w-sm-calc {
        --x-w-sm: 0px;
        width: calc(100vw - var(--x-w-sm))
    }
}

@media (min-width:768px) {
    .h-md-calc {
        --x-h-md: 0px;
        height: calc(100vh - var(--x-h-md))
    }

    .w-md-calc {
        --x-w-md: 0px;
        width: calc(100vw - var(--x-w-md))
    }
}

@media (min-width:992px) {
    .h-lg-calc {
        --x-h-lg: 0px;
        height: calc(100vh - var(--x-h-lg))
    }

    .w-lg-calc {
        --x-w-lg: 0px;
        width: calc(100vw - var(--x-w-lg))
    }
}

@media (min-width:1200px) {
    .h-xl-calc {
        --x-h-xl: 0px;
        height: calc(100vh - var(--x-h-xl))
    }

    .w-xl-calc {
        --x-w-xl: 0px;
        width: calc(100vw - var(--x-w-xl))
    }
}

@media (min-width:1400px) {
    .h-xxl-calc {
        --x-h-xxl: 0px;
        height: calc(100vh - var(--x-h-xxl))
    }

    .w-xxl-calc {
        --x-w-xxl: 0px;
        width: calc(100vw - var(--x-w-xxl))
    }
}

.clearfix:after {
    clear: both;
    content: "";
    display: block
}

.link-primary {
    color: #5c60f5
}

    .link-primary:focus, .link-primary:hover {
        color: #4a4dc4
    }

.link-secondary {
    color: #cfd6df
}

    .link-secondary:focus, .link-secondary:hover {
        color: #d9dee5
    }

.link-tertiary {
    color: #ff579a
}

    .link-tertiary:focus, .link-tertiary:hover {
        color: #cc467b
    }

.link-success {
    color: #0c8
}

    .link-success:focus, .link-success:hover {
        color: #00a36d
    }

.link-info {
    color: #00d4ff
}

    .link-info:focus, .link-info:hover {
        color: #3df
    }

.link-warning {
    color: #ff8c00
}

    .link-warning:focus, .link-warning:hover {
        color: #cc7000
    }

.link-danger {
    color: #f36
}

    .link-danger:focus, .link-danger:hover {
        color: #cc2952
    }

.link-light, .link-light:focus, .link-light:hover, .link-white, .link-white:focus, .link-white:hover {
    color: #fff
}

.link-dark {
    color: #16192c
}

    .link-dark:focus, .link-dark:hover {
        color: #121423
    }

.ratio {
    position: relative;
    width: 100%
}

    .ratio:before {
        content: "";
        display: block;
        padding-top: var(--x-aspect-ratio)
    }

    .ratio > * {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%
    }

.ratio-1x1 {
    --x-aspect-ratio: 100%
}

.ratio-4x3 {
    --x-aspect-ratio: 75%
}

.ratio-16x9 {
    --x-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --x-aspect-ratio: 42.8571428571%
}

.fixed-top {
    top: 0
}

.fixed-bottom, .fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.hstack {
    align-items: center;
    flex-direction: row
}

.hstack, .vstack {
    align-self: stretch;
    display: flex
}

.vstack {
    flex: 1 1 auto;
    flex-direction: column
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    clip: rect(0,0,0,0) !important;
    border: 0 !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important
}

.stretched-link:after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    align-self: stretch;
    background-color: currentColor;
    display: inline-block;
    min-height: 1em;
    opacity: .9;
    width: 1px
}

.floatfix:after, .floatfix:before {
    content: "";
    display: table
}

.floatfix:after {
    clear: both
}

.scrollable-x {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap
}

    .scrollable-x::-webkit-scrollbar {
        display: none
    }

.scrollable-y {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: nowrap
}

    .scrollable-y::-webkit-scrollbar {
        display: none
    }

[class*=blured], [class*=faded] {
    position: relative
}

    [class*=blured]:after, [class*=faded]:after {
        content: "";
        display: block;
        height: 3.9375rem;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%
    }

.faded-top:after {
    background: linear-gradient(0deg,transparent 0,rgba(0,0,0,.6));
    top: 0
}

.faded-bottom:after, .faded-top:after {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr= "#00000000",endColorstr="#a6000000",GradientType=0)
}

.faded-bottom:after {
    background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.6));
    bottom: 0
}

.blured-top:after {
    top: 0
}

.blured-bottom:after, .blured-top:after {
    -webkit-backdrop-filter: blur(10px) saturate(200%);
    backdrop-filter: blur(10px) saturate(200%);
    background: hsla(0,0%,100%,.75)
}

.blured-bottom:after {
    bottom: 0
}

[class*=hover-] {
    transition: all .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    [class*=hover-] {
        transition: none
    }
}

[class*=shadow] {
    transition: all .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    [class*=shadow] {
        transition: none
    }
}

a.text-current:hover, a.text-heading:hover, a.text-muted:hover {
    color: #4e52d0
}

.text-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.svg-fluid svg {
    height: 100%;
    max-width: 100%
}

.svg-current svg [fill]:not([fill=none]) {
    fill: currentColor !important
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.img-grayscale {
    filter: grayscale(100%)
}

.img-saturate {
    filter: saturate(150%)
}

.svg-icon {
    align-self: center;
    display: inline-flex
}

    .svg-icon svg {
        height: 1em;
        position: relative;
        width: 1em
    }

    .svg-icon[class*=text-] > svg [fill]:not([fill=none]) {
        fill: currentColor !important
    }

.svg-align-baseline svg {
    top: .125em
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.subpixel-antialised {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto
}

.line-clamp-1 {
    -webkit-line-clamp: 1
}

.line-clamp-1, .line-clamp-2 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.line-clamp-2 {
    -webkit-line-clamp: 2
}

.line-clamp-3 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-10 {
    opacity: .1 !important
}

.opacity-20 {
    opacity: .2 !important
}

.opacity-30 {
    opacity: .3 !important
}

.opacity-40 {
    opacity: .4 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-60 {
    opacity: .6 !important
}

.opacity-70 {
    opacity: .7 !important
}

.opacity-80 {
    opacity: .8 !important
}

.opacity-90 {
    opacity: .9 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow, .shadow-focus:focus, .shadow-hover:hover {
    box-shadow: 0 3px 3px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
}

.shadow-1, .shadow-1-focus:focus, .shadow-1-hover:hover {
    box-shadow: 0 1px 1px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
}

.shadow-2, .shadow-2-focus:focus, .shadow-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
}

.shadow-3, .shadow-3-focus:focus, .shadow-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
}

.shadow-4, .shadow-4-focus:focus, .shadow-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
}

.shadow-5, .shadow-5-focus:focus, .shadow-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
}

.shadow-6, .shadow-6-focus:focus, .shadow-6-hover:hover {
    box-shadow: 0 36px 50px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
}

.shadow-inset, .shadow-inset-focus:focus, .shadow-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0,0,0,.075) !important
}

.shadow-focus, .shadow-focus-focus:focus, .shadow-focus-hover:hover, .shadow-outline, .shadow-outline-focus:focus, .shadow-outline-hover:hover {
    box-shadow: 0 0 0 3px rgba(66,153,225,.5) !important
}

.shadow-none, .shadow-none-focus:focus, .shadow-none-hover:hover {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-1 {
    top: .25rem !important
}

.top-2 {
    top: .5rem !important
}

.top-3 {
    top: .75rem !important
}

.top-4 {
    top: 1rem !important
}

.top-5 {
    top: 1.25rem !important
}

.top-6 {
    top: 1.5rem !important
}

.top-7 {
    top: 1.75rem !important
}

.top-8 {
    top: 2rem !important
}

.top-10 {
    top: 2.5rem !important
}

.top-12 {
    top: 3rem !important
}

.top-14 {
    top: 3.5rem !important
}

.top-16 {
    top: 4rem !important
}

.top-18 {
    top: 4.5rem !important
}

.top-20 {
    top: 5rem !important
}

.top-24 {
    top: 6rem !important
}

.top-32 {
    top: 8rem !important
}

.top-40 {
    top: 10rem !important
}

.top-48 {
    top: 12rem !important
}

.top-56 {
    top: 14rem !important
}

.top-64 {
    top: 16rem !important
}

.top-72 {
    top: 18rem !important
}

.top-80 {
    top: 20rem !important
}

.top-88 {
    top: 22rem !important
}

.top-96 {
    top: 24rem !important
}

.top-px {
    top: 1px !important
}

.top-1\/2 {
    top: 50% !important
}

.top-full {
    top: 100% !important
}

.top-auto {
    top: auto !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-1 {
    bottom: .25rem !important
}

.bottom-2 {
    bottom: .5rem !important
}

.bottom-3 {
    bottom: .75rem !important
}

.bottom-4 {
    bottom: 1rem !important
}

.bottom-5 {
    bottom: 1.25rem !important
}

.bottom-6 {
    bottom: 1.5rem !important
}

.bottom-7 {
    bottom: 1.75rem !important
}

.bottom-8 {
    bottom: 2rem !important
}

.bottom-10 {
    bottom: 2.5rem !important
}

.bottom-12 {
    bottom: 3rem !important
}

.bottom-14 {
    bottom: 3.5rem !important
}

.bottom-16 {
    bottom: 4rem !important
}

.bottom-18 {
    bottom: 4.5rem !important
}

.bottom-20 {
    bottom: 5rem !important
}

.bottom-24 {
    bottom: 6rem !important
}

.bottom-32 {
    bottom: 8rem !important
}

.bottom-40 {
    bottom: 10rem !important
}

.bottom-48 {
    bottom: 12rem !important
}

.bottom-56 {
    bottom: 14rem !important
}

.bottom-64 {
    bottom: 16rem !important
}

.bottom-72 {
    bottom: 18rem !important
}

.bottom-80 {
    bottom: 20rem !important
}

.bottom-88 {
    bottom: 22rem !important
}

.bottom-96 {
    bottom: 24rem !important
}

.bottom-px {
    bottom: 1px !important
}

.bottom-1\/2 {
    bottom: 50% !important
}

.bottom-full {
    bottom: 100% !important
}

.bottom-auto {
    bottom: auto !important
}

.start-0 {
    left: 0 !important
}

.start-1 {
    left: .25rem !important
}

.start-2 {
    left: .5rem !important
}

.start-3 {
    left: .75rem !important
}

.start-4 {
    left: 1rem !important
}

.start-5 {
    left: 1.25rem !important
}

.start-6 {
    left: 1.5rem !important
}

.start-7 {
    left: 1.75rem !important
}

.start-8 {
    left: 2rem !important
}

.start-10 {
    left: 2.5rem !important
}

.start-12 {
    left: 3rem !important
}

.start-14 {
    left: 3.5rem !important
}

.start-16 {
    left: 4rem !important
}

.start-18 {
    left: 4.5rem !important
}

.start-20 {
    left: 5rem !important
}

.start-24 {
    left: 6rem !important
}

.start-32 {
    left: 8rem !important
}

.start-40 {
    left: 10rem !important
}

.start-48 {
    left: 12rem !important
}

.start-56 {
    left: 14rem !important
}

.start-64 {
    left: 16rem !important
}

.start-72 {
    left: 18rem !important
}

.start-80 {
    left: 20rem !important
}

.start-88 {
    left: 22rem !important
}

.start-96 {
    left: 24rem !important
}

.start-px {
    left: 1px !important
}

.start-1\/2 {
    left: 50% !important
}

.start-full {
    left: 100% !important
}

.start-auto {
    left: auto !important
}

.end-0 {
    right: 0 !important
}

.end-1 {
    right: .25rem !important
}

.end-2 {
    right: .5rem !important
}

.end-3 {
    right: .75rem !important
}

.end-4 {
    right: 1rem !important
}

.end-5 {
    right: 1.25rem !important
}

.end-6 {
    right: 1.5rem !important
}

.end-7 {
    right: 1.75rem !important
}

.end-8 {
    right: 2rem !important
}

.end-10 {
    right: 2.5rem !important
}

.end-12 {
    right: 3rem !important
}

.end-14 {
    right: 3.5rem !important
}

.end-16 {
    right: 4rem !important
}

.end-18 {
    right: 4.5rem !important
}

.end-20 {
    right: 5rem !important
}

.end-24 {
    right: 6rem !important
}

.end-32 {
    right: 8rem !important
}

.end-40 {
    right: 10rem !important
}

.end-48 {
    right: 12rem !important
}

.end-56 {
    right: 14rem !important
}

.end-64 {
    right: 16rem !important
}

.end-72 {
    right: 18rem !important
}

.end-80 {
    right: 20rem !important
}

.end-88 {
    right: 22rem !important
}

.end-96 {
    right: 24rem !important
}

.end-px {
    right: 1px !important
}

.end-1\/2 {
    right: 50% !important
}

.end-full {
    right: 100% !important
}

.end-auto {
    right: auto !important
}

.translate-middle {
    transform: translate(-50%,-50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border-top-0, .border-top-0-focus:focus, .border-top-0-hover:hover {
    border-top-width: 0 !important
}

.border-top, .border-top-focus:focus, .border-top-hover:hover {
    border-top-width: 1px !important
}

.border-top-2, .border-top-2-focus:focus, .border-top-2-hover:hover {
    border-top-width: 2px !important
}

.border-top-3, .border-top-3-focus:focus, .border-top-3-hover:hover {
    border-top-width: 3px !important
}

.border-top-4, .border-top-4-focus:focus, .border-top-4-hover:hover {
    border-top-width: 4px !important
}

.border-top-5, .border-top-5-focus:focus, .border-top-5-hover:hover {
    border-top-width: 5px !important
}

.border-top-6, .border-top-6-focus:focus, .border-top-6-hover:hover {
    border-top-width: 6px !important
}

.border-top-7, .border-top-7-focus:focus, .border-top-7-hover:hover {
    border-top-width: 7px !important
}

.border-top-8, .border-top-8-focus:focus, .border-top-8-hover:hover {
    border-top-width: 8px !important
}

.border-end-0, .border-end-0-focus:focus, .border-end-0-hover:hover {
    border-right-width: 0 !important
}

.border-end, .border-end-focus:focus, .border-end-hover:hover {
    border-right-width: 1px !important
}

.border-end-2, .border-end-2-focus:focus, .border-end-2-hover:hover {
    border-right-width: 2px !important
}

.border-end-3, .border-end-3-focus:focus, .border-end-3-hover:hover {
    border-right-width: 3px !important
}

.border-end-4, .border-end-4-focus:focus, .border-end-4-hover:hover {
    border-right-width: 4px !important
}

.border-end-5, .border-end-5-focus:focus, .border-end-5-hover:hover {
    border-right-width: 5px !important
}

.border-end-6, .border-end-6-focus:focus, .border-end-6-hover:hover {
    border-right-width: 6px !important
}

.border-end-7, .border-end-7-focus:focus, .border-end-7-hover:hover {
    border-right-width: 7px !important
}

.border-end-8, .border-end-8-focus:focus, .border-end-8-hover:hover {
    border-right-width: 8px !important
}

.border-bottom-0, .border-bottom-0-focus:focus, .border-bottom-0-hover:hover {
    border-bottom-width: 0 !important
}

.border-bottom, .border-bottom-focus:focus, .border-bottom-hover:hover {
    border-bottom-width: 1px !important
}

.border-bottom-2, .border-bottom-2-focus:focus, .border-bottom-2-hover:hover {
    border-bottom-width: 2px !important
}

.border-bottom-3, .border-bottom-3-focus:focus, .border-bottom-3-hover:hover {
    border-bottom-width: 3px !important
}

.border-bottom-4, .border-bottom-4-focus:focus, .border-bottom-4-hover:hover {
    border-bottom-width: 4px !important
}

.border-bottom-5, .border-bottom-5-focus:focus, .border-bottom-5-hover:hover {
    border-bottom-width: 5px !important
}

.border-bottom-6, .border-bottom-6-focus:focus, .border-bottom-6-hover:hover {
    border-bottom-width: 6px !important
}

.border-bottom-7, .border-bottom-7-focus:focus, .border-bottom-7-hover:hover {
    border-bottom-width: 7px !important
}

.border-bottom-8, .border-bottom-8-focus:focus, .border-bottom-8-hover:hover {
    border-bottom-width: 8px !important
}

.border-start-0, .border-start-0-focus:focus, .border-start-0-hover:hover {
    border-left-width: 0 !important
}

.border-start, .border-start-focus:focus, .border-start-hover:hover {
    border-left-width: 1px !important
}

.border-start-2, .border-start-2-focus:focus, .border-start-2-hover:hover {
    border-left-width: 2px !important
}

.border-start-3, .border-start-3-focus:focus, .border-start-3-hover:hover {
    border-left-width: 3px !important
}

.border-start-4, .border-start-4-focus:focus, .border-start-4-hover:hover {
    border-left-width: 4px !important
}

.border-start-5, .border-start-5-focus:focus, .border-start-5-hover:hover {
    border-left-width: 5px !important
}

.border-start-6, .border-start-6-focus:focus, .border-start-6-hover:hover {
    border-left-width: 6px !important
}

.border-start-7, .border-start-7-focus:focus, .border-start-7-hover:hover {
    border-left-width: 7px !important
}

.border-start-8, .border-start-8-focus:focus, .border-start-8-hover:hover {
    border-left-width: 8px !important
}

.border-primary, .border-primary-focus:focus, .border-primary-hover:hover {
    border-color: #5c60f5 !important
}

.border-primary-focus-within:focus-within {
    border-color: #5c60f5 !important
}

.border-secondary, .border-secondary-focus:focus, .border-secondary-hover:hover {
    border-color: #cfd6df !important
}

.border-secondary-focus-within:focus-within {
    border-color: #cfd6df !important
}

.border-tertiary, .border-tertiary-focus:focus, .border-tertiary-hover:hover {
    border-color: #ff579a !important
}

.border-tertiary-focus-within:focus-within {
    border-color: #ff579a !important
}

.border-success, .border-success-focus:focus, .border-success-hover:hover {
    border-color: #0c8 !important
}

.border-success-focus-within:focus-within {
    border-color: #0c8 !important
}

.border-info, .border-info-focus:focus, .border-info-hover:hover {
    border-color: #00d4ff !important
}

.border-info-focus-within:focus-within {
    border-color: #00d4ff !important
}

.border-warning, .border-warning-focus:focus, .border-warning-hover:hover {
    border-color: #ff8c00 !important
}

.border-warning-focus-within:focus-within {
    border-color: #ff8c00 !important
}

.border-danger, .border-danger-focus:focus, .border-danger-hover:hover {
    border-color: #f36 !important
}

.border-danger-focus-within:focus-within {
    border-color: #f36 !important
}

.border-white, .border-white-focus:focus, .border-white-hover:hover {
    border-color: #fff !important
}

.border-white-focus-within:focus-within {
    border-color: #fff !important
}

.border-light, .border-light-focus:focus, .border-light-hover:hover {
    border-color: #fff !important
}

.border-light-focus-within:focus-within {
    border-color: #fff !important
}

.border-dark, .border-dark-focus:focus, .border-dark-hover:hover {
    border-color: #16192c !important
}

.border-dark-focus-within:focus-within {
    border-color: #16192c !important
}

.border-transparent, .border-transparent-focus:focus, .border-transparent-hover:hover {
    border-color: transparent !important
}

.border-transparent-focus-within:focus-within {
    border-color: transparent !important
}

.border-current, .border-current-focus:focus, .border-current-hover:hover {
    border-color: currentColor !important
}

.border-current-focus-within:focus-within {
    border-color: currentColor !important
}

.border-base, .border-base-focus:focus, .border-base-hover:hover {
    border-color: #e7eaf0 !important
}

.border-base-focus-within:focus-within {
    border-color: #e7eaf0 !important
}

.border-body, .border-body-focus:focus, .border-body-hover:hover {
    border-color: #fff !important
}

.border-body-focus-within:focus-within {
    border-color: #fff !important
}

.border-card, .border-card-focus:focus, .border-card-hover:hover {
    border-color: #fff !important
}

.border-card-focus-within:focus-within {
    border-color: #fff !important
}

.border-black, .border-black-focus:focus, .border-black-hover:hover {
    border-color: #000 !important
}

.border-black-focus-within:focus-within {
    border-color: #000 !important
}

.border-0, .border-0-focus:focus, .border-0-hover:hover {
    border-width: 0 !important
}

.border, .border-focus:focus, .border-hover:hover {
    border-width: 1px !important
}

.border-2, .border-2-focus:focus, .border-2-hover:hover {
    border-width: 2px !important
}

.border-3, .border-3-focus:focus, .border-3-hover:hover {
    border-width: 3px !important
}

.border-4, .border-4-focus:focus, .border-4-hover:hover {
    border-width: 4px !important
}

.border-5, .border-5-focus:focus, .border-5-hover:hover {
    border-width: 5px !important
}

.border-6, .border-6-focus:focus, .border-6-hover:hover {
    border-width: 6px !important
}

.border-7, .border-7-focus:focus, .border-7-hover:hover {
    border-width: 7px !important
}

.border-8, .border-8-focus:focus, .border-8-hover:hover {
    border-width: 8px !important
}

.w-0 {
    width: 0 !important
}

.w-1 {
    width: .25rem !important
}

.w-2 {
    width: .5rem !important
}

.w-3 {
    width: .75rem !important
}

.w-4 {
    width: 1rem !important
}

.w-5 {
    width: 1.25rem !important
}

.w-6 {
    width: 1.5rem !important
}

.w-7 {
    width: 1.75rem !important
}

.w-8 {
    width: 2rem !important
}

.w-10 {
    width: 2.5rem !important
}

.w-12 {
    width: 3rem !important
}

.w-14 {
    width: 3.5rem !important
}

.w-16 {
    width: 4rem !important
}

.w-18 {
    width: 4.5rem !important
}

.w-20 {
    width: 5rem !important
}

.w-24 {
    width: 6rem !important
}

.w-32 {
    width: 8rem !important
}

.w-40 {
    width: 10rem !important
}

.w-48 {
    width: 12rem !important
}

.w-56 {
    width: 14rem !important
}

.w-64 {
    width: 16rem !important
}

.w-72 {
    width: 18rem !important
}

.w-80 {
    width: 20rem !important
}

.w-88 {
    width: 22rem !important
}

.w-96 {
    width: 24rem !important
}

.w-px {
    width: 1px !important
}

.w-1\/2 {
    width: 50% !important
}

.w-1\/3 {
    width: 33.3333333333% !important
}

.w-2\/3 {
    width: 66.6666666667% !important
}

.w-1\/4 {
    width: 25% !important
}

.w-2\/4 {
    width: 50% !important
}

.w-3\/4 {
    width: 75% !important
}

.w-1\/5 {
    width: 20% !important
}

.w-2\/5 {
    width: 40% !important
}

.w-3\/5 {
    width: 60% !important
}

.w-4\/5 {
    width: 80% !important
}

.w-1\/6 {
    width: 16.6666666667% !important
}

.w-2\/6 {
    width: 33.3333333333% !important
}

.w-3\/6 {
    width: 50% !important
}

.w-4\/6 {
    width: 66.6666666667% !important
}

.w-5\/6 {
    width: 83.3333333333% !important
}

.w-11\/10 {
    width: 110% !important
}

.w-12\/10 {
    width: 120% !important
}

.w-13\/10 {
    width: 130% !important
}

.w-14\/10 {
    width: 140% !important
}

.w-15\/10 {
    width: 150% !important
}

.w-screen-sm {
    width: 640px !important
}

.w-screen-md {
    width: 768px !important
}

.w-screen-lg {
    width: 1024px !important
}

.w-screen-xl {
    width: 1280px !important
}

.w-screen-xxl {
    width: 1536px !important
}

.w-auto {
    width: auto !important
}

.w-full {
    width: 100% !important
}

.w-screen {
    width: 100vw !important
}

.w-min {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important
}

.w-max {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important
}

.max-w-screen-sm {
    max-width: 640px !important
}

.max-w-screen-md {
    max-width: 768px !important
}

.max-w-screen-lg {
    max-width: 1024px !important
}

.max-w-screen-xl {
    max-width: 1280px !important
}

.max-w-screen-xxl {
    max-width: 1536px !important
}

.max-w-0 {
    max-width: 0 !important
}

.max-w-full {
    max-width: 100% !important
}

.max-w-read {
    max-width: 65ch !important
}

.max-w-min {
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important
}

.max-w-max {
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important
}

.h-0 {
    height: 0 !important
}

.h-1 {
    height: .25rem !important
}

.h-2 {
    height: .5rem !important
}

.h-3 {
    height: .75rem !important
}

.h-4 {
    height: 1rem !important
}

.h-5 {
    height: 1.25rem !important
}

.h-6 {
    height: 1.5rem !important
}

.h-7 {
    height: 1.75rem !important
}

.h-8 {
    height: 2rem !important
}

.h-10 {
    height: 2.5rem !important
}

.h-12 {
    height: 3rem !important
}

.h-14 {
    height: 3.5rem !important
}

.h-16 {
    height: 4rem !important
}

.h-18 {
    height: 4.5rem !important
}

.h-20 {
    height: 5rem !important
}

.h-24 {
    height: 6rem !important
}

.h-32 {
    height: 8rem !important
}

.h-40 {
    height: 10rem !important
}

.h-48 {
    height: 12rem !important
}

.h-56 {
    height: 14rem !important
}

.h-64 {
    height: 16rem !important
}

.h-72 {
    height: 18rem !important
}

.h-80 {
    height: 20rem !important
}

.h-88 {
    height: 22rem !important
}

.h-96 {
    height: 24rem !important
}

.h-px {
    height: 1px !important
}

.h-1\/2 {
    height: 50% !important
}

.h-1\/3 {
    height: 33.3333333333% !important
}

.h-2\/3 {
    height: 66.6666666667% !important
}

.h-1\/4 {
    height: 25% !important
}

.h-2\/4 {
    height: 50% !important
}

.h-3\/4 {
    height: 75% !important
}

.h-1\/5 {
    height: 20% !important
}

.h-2\/5 {
    height: 40% !important
}

.h-3\/5 {
    height: 60% !important
}

.h-4\/5 {
    height: 80% !important
}

.h-1\/6 {
    height: 16.6666666667% !important
}

.h-2\/6 {
    height: 33.3333333333% !important
}

.h-3\/6 {
    height: 50% !important
}

.h-4\/6 {
    height: 66.6666666667% !important
}

.h-5\/6 {
    height: 83.3333333333% !important
}

.h-11\/10 {
    height: 110% !important
}

.h-12\/10 {
    height: 120% !important
}

.h-13\/10 {
    height: 130% !important
}

.h-14\/10 {
    height: 140% !important
}

.h-15\/10 {
    height: 150% !important
}

.h-auto {
    height: auto !important
}

.h-full {
    height: 100% !important
}

.h-screen {
    height: 100vh !important
}

.max-h-0 {
    max-height: 0 !important
}

.max-h-1 {
    max-height: .25rem !important
}

.max-h-2 {
    max-height: .5rem !important
}

.max-h-3 {
    max-height: .75rem !important
}

.max-h-4 {
    max-height: 1rem !important
}

.max-h-5 {
    max-height: 1.25rem !important
}

.max-h-6 {
    max-height: 1.5rem !important
}

.max-h-7 {
    max-height: 1.75rem !important
}

.max-h-8 {
    max-height: 2rem !important
}

.max-h-10 {
    max-height: 2.5rem !important
}

.max-h-12 {
    max-height: 3rem !important
}

.max-h-14 {
    max-height: 3.5rem !important
}

.max-h-16 {
    max-height: 4rem !important
}

.max-h-18 {
    max-height: 4.5rem !important
}

.max-h-20 {
    max-height: 5rem !important
}

.max-h-24 {
    max-height: 6rem !important
}

.max-h-32 {
    max-height: 8rem !important
}

.max-h-40 {
    max-height: 10rem !important
}

.max-h-48 {
    max-height: 12rem !important
}

.max-h-56 {
    max-height: 14rem !important
}

.max-h-64 {
    max-height: 16rem !important
}

.max-h-72 {
    max-height: 18rem !important
}

.max-h-80 {
    max-height: 20rem !important
}

.max-h-88 {
    max-height: 22rem !important
}

.max-h-96 {
    max-height: 24rem !important
}

.max-h-px {
    max-height: 1px !important
}

.max-h-full {
    max-height: 100% !important
}

.max-h-screen {
    max-height: 100vh !important
}

.flex-1 {
    flex: 1 1 0% !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-initial {
    flex: 0 1 auto !important
}

.flex-none {
    flex: none !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: .75rem !important
}

.gap-4 {
    gap: 1rem !important
}

.gap-5 {
    gap: 1.25rem !important
}

.gap-6 {
    gap: 1.5rem !important
}

.gap-7 {
    gap: 1.75rem !important
}

.gap-8 {
    gap: 2rem !important
}

.gap-10 {
    gap: 2.5rem !important
}

.gap-12 {
    gap: 3rem !important
}

.gap-14 {
    gap: 3.5rem !important
}

.gap-16 {
    gap: 4rem !important
}

.gap-18 {
    gap: 4.5rem !important
}

.gap-20 {
    gap: 5rem !important
}

.gap-24 {
    gap: 6rem !important
}

.gap-32 {
    gap: 8rem !important
}

.gap-40 {
    gap: 10rem !important
}

.gap-48 {
    gap: 12rem !important
}

.gap-56 {
    gap: 14rem !important
}

.gap-64 {
    gap: 16rem !important
}

.gap-72 {
    gap: 18rem !important
}

.gap-80 {
    gap: 20rem !important
}

.gap-88 {
    gap: 22rem !important
}

.gap-96 {
    gap: 24rem !important
}

.gap-px {
    gap: 1px !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-evenly {
    align-content: space-evenly !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: .75rem !important
}

.m-4 {
    margin: 1rem !important
}

.m-5 {
    margin: 1.25rem !important
}

.m-6 {
    margin: 1.5rem !important
}

.m-7 {
    margin: 1.75rem !important
}

.m-8 {
    margin: 2rem !important
}

.m-10 {
    margin: 2.5rem !important
}

.m-12 {
    margin: 3rem !important
}

.m-14 {
    margin: 3.5rem !important
}

.m-16 {
    margin: 4rem !important
}

.m-18 {
    margin: 4.5rem !important
}

.m-20 {
    margin: 5rem !important
}

.m-24 {
    margin: 6rem !important
}

.m-32 {
    margin: 8rem !important
}

.m-40 {
    margin: 10rem !important
}

.m-48 {
    margin: 12rem !important
}

.m-56 {
    margin: 14rem !important
}

.m-64 {
    margin: 16rem !important
}

.m-72 {
    margin: 18rem !important
}

.m-80 {
    margin: 20rem !important
}

.m-88 {
    margin: 22rem !important
}

.m-96 {
    margin: 24rem !important
}

.m-px {
    margin: 1px !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
}

.mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
}

.mx-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important
}

.mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
}

.mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important
}

.mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
}

.mx-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important
}

.mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
}

.mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
}

.mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
}

.mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important
}

.mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
}

.mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important
}

.mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
}

.mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
}

.mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important
}

.mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important
}

.mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important
}

.mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important
}

.mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important
}

.mx-72 {
    margin-left: 18rem !important;
    margin-right: 18rem !important
}

.mx-80 {
    margin-left: 20rem !important;
    margin-right: 20rem !important
}

.mx-88 {
    margin-left: 22rem !important;
    margin-right: 22rem !important
}

.mx-96 {
    margin-left: 24rem !important;
    margin-right: 24rem !important
}

.mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.my-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
}

.my-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
}

.my-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
}

.my-3 {
    margin-bottom: .75rem !important;
    margin-top: .75rem !important
}

.my-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
}

.my-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important
}

.my-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
}

.my-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important
}

.my-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important
}

.my-10 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important
}

.my-12 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
}

.my-14 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important
}

.my-16 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important
}

.my-18 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important
}

.my-20 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important
}

.my-24 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
}

.my-32 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important
}

.my-40 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important
}

.my-48 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important
}

.my-56 {
    margin-bottom: 14rem !important;
    margin-top: 14rem !important
}

.my-64 {
    margin-bottom: 16rem !important;
    margin-top: 16rem !important
}

.my-72 {
    margin-bottom: 18rem !important;
    margin-top: 18rem !important
}

.my-80 {
    margin-bottom: 20rem !important;
    margin-top: 20rem !important
}

.my-88 {
    margin-bottom: 22rem !important;
    margin-top: 22rem !important
}

.my-96 {
    margin-bottom: 24rem !important;
    margin-top: 24rem !important
}

.my-px {
    margin-bottom: 1px !important;
    margin-top: 1px !important
}

.my-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: .75rem !important
}

.mt-4 {
    margin-top: 1rem !important
}

.mt-5 {
    margin-top: 1.25rem !important
}

.mt-6 {
    margin-top: 1.5rem !important
}

.mt-7 {
    margin-top: 1.75rem !important
}

.mt-8 {
    margin-top: 2rem !important
}

.mt-10 {
    margin-top: 2.5rem !important
}

.mt-12 {
    margin-top: 3rem !important
}

.mt-14 {
    margin-top: 3.5rem !important
}

.mt-16 {
    margin-top: 4rem !important
}

.mt-18 {
    margin-top: 4.5rem !important
}

.mt-20 {
    margin-top: 5rem !important
}

.mt-24 {
    margin-top: 6rem !important
}

.mt-32 {
    margin-top: 8rem !important
}

.mt-40 {
    margin-top: 10rem !important
}

.mt-48 {
    margin-top: 12rem !important
}

.mt-56 {
    margin-top: 14rem !important
}

.mt-64 {
    margin-top: 16rem !important
}

.mt-72 {
    margin-top: 18rem !important
}

.mt-80 {
    margin-top: 20rem !important
}

.mt-88 {
    margin-top: 22rem !important
}

.mt-96 {
    margin-top: 24rem !important
}

.mt-px {
    margin-top: 1px !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: .75rem !important
}

.me-4 {
    margin-right: 1rem !important
}

.me-5 {
    margin-right: 1.25rem !important
}

.me-6 {
    margin-right: 1.5rem !important
}

.me-7 {
    margin-right: 1.75rem !important
}

.me-8 {
    margin-right: 2rem !important
}

.me-10 {
    margin-right: 2.5rem !important
}

.me-12 {
    margin-right: 3rem !important
}

.me-14 {
    margin-right: 3.5rem !important
}

.me-16 {
    margin-right: 4rem !important
}

.me-18 {
    margin-right: 4.5rem !important
}

.me-20 {
    margin-right: 5rem !important
}

.me-24 {
    margin-right: 6rem !important
}

.me-32 {
    margin-right: 8rem !important
}

.me-40 {
    margin-right: 10rem !important
}

.me-48 {
    margin-right: 12rem !important
}

.me-56 {
    margin-right: 14rem !important
}

.me-64 {
    margin-right: 16rem !important
}

.me-72 {
    margin-right: 18rem !important
}

.me-80 {
    margin-right: 20rem !important
}

.me-88 {
    margin-right: 22rem !important
}

.me-96 {
    margin-right: 24rem !important
}

.me-px {
    margin-right: 1px !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: .75rem !important
}

.mb-4 {
    margin-bottom: 1rem !important
}

.mb-5 {
    margin-bottom: 1.25rem !important
}

.mb-6 {
    margin-bottom: 1.5rem !important
}

.mb-7 {
    margin-bottom: 1.75rem !important
}

.mb-8 {
    margin-bottom: 2rem !important
}

.mb-10 {
    margin-bottom: 2.5rem !important
}

.mb-12 {
    margin-bottom: 3rem !important
}

.mb-14 {
    margin-bottom: 3.5rem !important
}

.mb-16 {
    margin-bottom: 4rem !important
}

.mb-18 {
    margin-bottom: 4.5rem !important
}

.mb-20 {
    margin-bottom: 5rem !important
}

.mb-24 {
    margin-bottom: 6rem !important
}

.mb-32 {
    margin-bottom: 8rem !important
}

.mb-40 {
    margin-bottom: 10rem !important
}

.mb-48 {
    margin-bottom: 12rem !important
}

.mb-56 {
    margin-bottom: 14rem !important
}

.mb-64 {
    margin-bottom: 16rem !important
}

.mb-72 {
    margin-bottom: 18rem !important
}

.mb-80 {
    margin-bottom: 20rem !important
}

.mb-88 {
    margin-bottom: 22rem !important
}

.mb-96 {
    margin-bottom: 24rem !important
}

.mb-px {
    margin-bottom: 1px !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: .75rem !important
}

.ms-4 {
    margin-left: 1rem !important
}

.ms-5 {
    margin-left: 1.25rem !important
}

.ms-6 {
    margin-left: 1.5rem !important
}

.ms-7 {
    margin-left: 1.75rem !important
}

.ms-8 {
    margin-left: 2rem !important
}

.ms-10 {
    margin-left: 2.5rem !important
}

.ms-12 {
    margin-left: 3rem !important
}

.ms-14 {
    margin-left: 3.5rem !important
}

.ms-16 {
    margin-left: 4rem !important
}

.ms-18 {
    margin-left: 4.5rem !important
}

.ms-20 {
    margin-left: 5rem !important
}

.ms-24 {
    margin-left: 6rem !important
}

.ms-32 {
    margin-left: 8rem !important
}

.ms-40 {
    margin-left: 10rem !important
}

.ms-48 {
    margin-left: 12rem !important
}

.ms-56 {
    margin-left: 14rem !important
}

.ms-64 {
    margin-left: 16rem !important
}

.ms-72 {
    margin-left: 18rem !important
}

.ms-80 {
    margin-left: 20rem !important
}

.ms-88 {
    margin-left: 22rem !important
}

.ms-96 {
    margin-left: 24rem !important
}

.ms-px {
    margin-left: 1px !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n1 {
    margin: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.m-n3 {
    margin: -.75rem !important
}

.m-n4 {
    margin: -1rem !important
}

.m-n5 {
    margin: -1.25rem !important
}

.m-n6 {
    margin: -1.5rem !important
}

.m-n7 {
    margin: -1.75rem !important
}

.m-n8 {
    margin: -2rem !important
}

.m-n10 {
    margin: -2.5rem !important
}

.m-n12 {
    margin: -3rem !important
}

.m-n14 {
    margin: -3.5rem !important
}

.m-n16 {
    margin: -4rem !important
}

.m-n18 {
    margin: -4.5rem !important
}

.m-n20 {
    margin: -5rem !important
}

.m-n24 {
    margin: -6rem !important
}

.m-n32 {
    margin: -8rem !important
}

.m-n40 {
    margin: -10rem !important
}

.m-n48 {
    margin: -12rem !important
}

.m-n56 {
    margin: -14rem !important
}

.m-n64 {
    margin: -16rem !important
}

.m-n72 {
    margin: -18rem !important
}

.m-n80 {
    margin: -20rem !important
}

.m-n88 {
    margin: -22rem !important
}

.m-n96 {
    margin: -24rem !important
}

.m-npx {
    margin: -1px !important
}

.mx-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
}

.mx-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
}

.mx-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important
}

.mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
}

.mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important
}

.mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
}

.mx-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important
}

.mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
}

.mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important
}

.mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
}

.mx-n14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important
}

.mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
}

.mx-n18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important
}

.mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
}

.mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
}

.mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important
}

.mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important
}

.mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important
}

.mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important
}

.mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important
}

.mx-n72 {
    margin-left: -18rem !important;
    margin-right: -18rem !important
}

.mx-n80 {
    margin-left: -20rem !important;
    margin-right: -20rem !important
}

.mx-n88 {
    margin-left: -22rem !important;
    margin-right: -22rem !important
}

.mx-n96 {
    margin-left: -24rem !important;
    margin-right: -24rem !important
}

.mx-npx {
    margin-left: -1px !important;
    margin-right: -1px !important
}

.my-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
}

.my-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
}

.my-n3 {
    margin-bottom: -.75rem !important;
    margin-top: -.75rem !important
}

.my-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
}

.my-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important
}

.my-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
}

.my-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important
}

.my-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important
}

.my-n10 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important
}

.my-n12 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
}

.my-n14 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important
}

.my-n16 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important
}

.my-n18 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important
}

.my-n20 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important
}

.my-n24 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
}

.my-n32 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important
}

.my-n40 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important
}

.my-n48 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important
}

.my-n56 {
    margin-bottom: -14rem !important;
    margin-top: -14rem !important
}

.my-n64 {
    margin-bottom: -16rem !important;
    margin-top: -16rem !important
}

.my-n72 {
    margin-bottom: -18rem !important;
    margin-top: -18rem !important
}

.my-n80 {
    margin-bottom: -20rem !important;
    margin-top: -20rem !important
}

.my-n88 {
    margin-bottom: -22rem !important;
    margin-top: -22rem !important
}

.my-n96 {
    margin-bottom: -24rem !important;
    margin-top: -24rem !important
}

.my-npx {
    margin-bottom: -1px !important;
    margin-top: -1px !important
}

.mt-n1 {
    margin-top: -.25rem !important
}

.mt-n2 {
    margin-top: -.5rem !important
}

.mt-n3 {
    margin-top: -.75rem !important
}

.mt-n4 {
    margin-top: -1rem !important
}

.mt-n5 {
    margin-top: -1.25rem !important
}

.mt-n6 {
    margin-top: -1.5rem !important
}

.mt-n7 {
    margin-top: -1.75rem !important
}

.mt-n8 {
    margin-top: -2rem !important
}

.mt-n10 {
    margin-top: -2.5rem !important
}

.mt-n12 {
    margin-top: -3rem !important
}

.mt-n14 {
    margin-top: -3.5rem !important
}

.mt-n16 {
    margin-top: -4rem !important
}

.mt-n18 {
    margin-top: -4.5rem !important
}

.mt-n20 {
    margin-top: -5rem !important
}

.mt-n24 {
    margin-top: -6rem !important
}

.mt-n32 {
    margin-top: -8rem !important
}

.mt-n40 {
    margin-top: -10rem !important
}

.mt-n48 {
    margin-top: -12rem !important
}

.mt-n56 {
    margin-top: -14rem !important
}

.mt-n64 {
    margin-top: -16rem !important
}

.mt-n72 {
    margin-top: -18rem !important
}

.mt-n80 {
    margin-top: -20rem !important
}

.mt-n88 {
    margin-top: -22rem !important
}

.mt-n96 {
    margin-top: -24rem !important
}

.mt-npx {
    margin-top: -1px !important
}

.me-n1 {
    margin-right: -.25rem !important
}

.me-n2 {
    margin-right: -.5rem !important
}

.me-n3 {
    margin-right: -.75rem !important
}

.me-n4 {
    margin-right: -1rem !important
}

.me-n5 {
    margin-right: -1.25rem !important
}

.me-n6 {
    margin-right: -1.5rem !important
}

.me-n7 {
    margin-right: -1.75rem !important
}

.me-n8 {
    margin-right: -2rem !important
}

.me-n10 {
    margin-right: -2.5rem !important
}

.me-n12 {
    margin-right: -3rem !important
}

.me-n14 {
    margin-right: -3.5rem !important
}

.me-n16 {
    margin-right: -4rem !important
}

.me-n18 {
    margin-right: -4.5rem !important
}

.me-n20 {
    margin-right: -5rem !important
}

.me-n24 {
    margin-right: -6rem !important
}

.me-n32 {
    margin-right: -8rem !important
}

.me-n40 {
    margin-right: -10rem !important
}

.me-n48 {
    margin-right: -12rem !important
}

.me-n56 {
    margin-right: -14rem !important
}

.me-n64 {
    margin-right: -16rem !important
}

.me-n72 {
    margin-right: -18rem !important
}

.me-n80 {
    margin-right: -20rem !important
}

.me-n88 {
    margin-right: -22rem !important
}

.me-n96 {
    margin-right: -24rem !important
}

.me-npx {
    margin-right: -1px !important
}

.mb-n1 {
    margin-bottom: -.25rem !important
}

.mb-n2 {
    margin-bottom: -.5rem !important
}

.mb-n3 {
    margin-bottom: -.75rem !important
}

.mb-n4 {
    margin-bottom: -1rem !important
}

.mb-n5 {
    margin-bottom: -1.25rem !important
}

.mb-n6 {
    margin-bottom: -1.5rem !important
}

.mb-n7 {
    margin-bottom: -1.75rem !important
}

.mb-n8 {
    margin-bottom: -2rem !important
}

.mb-n10 {
    margin-bottom: -2.5rem !important
}

.mb-n12 {
    margin-bottom: -3rem !important
}

.mb-n14 {
    margin-bottom: -3.5rem !important
}

.mb-n16 {
    margin-bottom: -4rem !important
}

.mb-n18 {
    margin-bottom: -4.5rem !important
}

.mb-n20 {
    margin-bottom: -5rem !important
}

.mb-n24 {
    margin-bottom: -6rem !important
}

.mb-n32 {
    margin-bottom: -8rem !important
}

.mb-n40 {
    margin-bottom: -10rem !important
}

.mb-n48 {
    margin-bottom: -12rem !important
}

.mb-n56 {
    margin-bottom: -14rem !important
}

.mb-n64 {
    margin-bottom: -16rem !important
}

.mb-n72 {
    margin-bottom: -18rem !important
}

.mb-n80 {
    margin-bottom: -20rem !important
}

.mb-n88 {
    margin-bottom: -22rem !important
}

.mb-n96 {
    margin-bottom: -24rem !important
}

.mb-npx {
    margin-bottom: -1px !important
}

.ms-n1 {
    margin-left: -.25rem !important
}

.ms-n2 {
    margin-left: -.5rem !important
}

.ms-n3 {
    margin-left: -.75rem !important
}

.ms-n4 {
    margin-left: -1rem !important
}

.ms-n5 {
    margin-left: -1.25rem !important
}

.ms-n6 {
    margin-left: -1.5rem !important
}

.ms-n7 {
    margin-left: -1.75rem !important
}

.ms-n8 {
    margin-left: -2rem !important
}

.ms-n10 {
    margin-left: -2.5rem !important
}

.ms-n12 {
    margin-left: -3rem !important
}

.ms-n14 {
    margin-left: -3.5rem !important
}

.ms-n16 {
    margin-left: -4rem !important
}

.ms-n18 {
    margin-left: -4.5rem !important
}

.ms-n20 {
    margin-left: -5rem !important
}

.ms-n24 {
    margin-left: -6rem !important
}

.ms-n32 {
    margin-left: -8rem !important
}

.ms-n40 {
    margin-left: -10rem !important
}

.ms-n48 {
    margin-left: -12rem !important
}

.ms-n56 {
    margin-left: -14rem !important
}

.ms-n64 {
    margin-left: -16rem !important
}

.ms-n72 {
    margin-left: -18rem !important
}

.ms-n80 {
    margin-left: -20rem !important
}

.ms-n88 {
    margin-left: -22rem !important
}

.ms-n96 {
    margin-left: -24rem !important
}

.ms-npx {
    margin-left: -1px !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: .75rem !important
}

.p-4 {
    padding: 1rem !important
}

.p-5 {
    padding: 1.25rem !important
}

.p-6 {
    padding: 1.5rem !important
}

.p-7 {
    padding: 1.75rem !important
}

.p-8 {
    padding: 2rem !important
}

.p-10 {
    padding: 2.5rem !important
}

.p-12 {
    padding: 3rem !important
}

.p-14 {
    padding: 3.5rem !important
}

.p-16 {
    padding: 4rem !important
}

.p-18 {
    padding: 4.5rem !important
}

.p-20 {
    padding: 5rem !important
}

.p-24 {
    padding: 6rem !important
}

.p-32 {
    padding: 8rem !important
}

.p-40 {
    padding: 10rem !important
}

.p-48 {
    padding: 12rem !important
}

.p-56 {
    padding: 14rem !important
}

.p-64 {
    padding: 16rem !important
}

.p-72 {
    padding: 18rem !important
}

.p-80 {
    padding: 20rem !important
}

.p-88 {
    padding: 22rem !important
}

.p-96 {
    padding: 24rem !important
}

.p-px {
    padding: 1px !important
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
}

.px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
}

.px-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important
}

.px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
}

.px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important
}

.px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
}

.px-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important
}

.px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
}

.px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
}

.px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
}

.px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important
}

.px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
}

.px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important
}

.px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
}

.px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
}

.px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important
}

.px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important
}

.px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important
}

.px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important
}

.px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important
}

.px-72 {
    padding-left: 18rem !important;
    padding-right: 18rem !important
}

.px-80 {
    padding-left: 20rem !important;
    padding-right: 20rem !important
}

.px-88 {
    padding-left: 22rem !important;
    padding-right: 22rem !important
}

.px-96 {
    padding-left: 24rem !important;
    padding-right: 24rem !important
}

.px-px {
    padding-left: 1px !important;
    padding-right: 1px !important
}

.py-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
}

.py-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
}

.py-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
}

.py-3 {
    padding-bottom: .75rem !important;
    padding-top: .75rem !important
}

.py-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
}

.py-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important
}

.py-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
}

.py-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important
}

.py-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important
}

.py-10 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important
}

.py-12 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
}

.py-14 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important
}

.py-16 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important
}

.py-18 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important
}

.py-20 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important
}

.py-24 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
}

.py-32 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important
}

.py-40 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important
}

.py-48 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important
}

.py-56 {
    padding-bottom: 14rem !important;
    padding-top: 14rem !important
}

.py-64 {
    padding-bottom: 16rem !important;
    padding-top: 16rem !important
}

.py-72 {
    padding-bottom: 18rem !important;
    padding-top: 18rem !important
}

.py-80 {
    padding-bottom: 20rem !important;
    padding-top: 20rem !important
}

.py-88 {
    padding-bottom: 22rem !important;
    padding-top: 22rem !important
}

.py-96 {
    padding-bottom: 24rem !important;
    padding-top: 24rem !important
}

.py-px {
    padding-bottom: 1px !important;
    padding-top: 1px !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: .75rem !important
}

.pt-4 {
    padding-top: 1rem !important
}

.pt-5 {
    padding-top: 1.25rem !important
}

.pt-6 {
    padding-top: 1.5rem !important
}

.pt-7 {
    padding-top: 1.75rem !important
}

.pt-8 {
    padding-top: 2rem !important
}

.pt-10 {
    padding-top: 2.5rem !important
}

.pt-12 {
    padding-top: 3rem !important
}

.pt-14 {
    padding-top: 3.5rem !important
}

.pt-16 {
    padding-top: 4rem !important
}

.pt-18 {
    padding-top: 4.5rem !important
}

.pt-20 {
    padding-top: 5rem !important
}

.pt-24 {
    padding-top: 6rem !important
}

.pt-32 {
    padding-top: 8rem !important
}

.pt-40 {
    padding-top: 10rem !important
}

.pt-48 {
    padding-top: 12rem !important
}

.pt-56 {
    padding-top: 14rem !important
}

.pt-64 {
    padding-top: 16rem !important
}

.pt-72 {
    padding-top: 18rem !important
}

.pt-80 {
    padding-top: 20rem !important
}

.pt-88 {
    padding-top: 22rem !important
}

.pt-96 {
    padding-top: 24rem !important
}

.pt-px {
    padding-top: 1px !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: .75rem !important
}

.pe-4 {
    padding-right: 1rem !important
}

.pe-5 {
    padding-right: 1.25rem !important
}

.pe-6 {
    padding-right: 1.5rem !important
}

.pe-7 {
    padding-right: 1.75rem !important
}

.pe-8 {
    padding-right: 2rem !important
}

.pe-10 {
    padding-right: 2.5rem !important
}

.pe-12 {
    padding-right: 3rem !important
}

.pe-14 {
    padding-right: 3.5rem !important
}

.pe-16 {
    padding-right: 4rem !important
}

.pe-18 {
    padding-right: 4.5rem !important
}

.pe-20 {
    padding-right: 5rem !important
}

.pe-24 {
    padding-right: 6rem !important
}

.pe-32 {
    padding-right: 8rem !important
}

.pe-40 {
    padding-right: 10rem !important
}

.pe-48 {
    padding-right: 12rem !important
}

.pe-56 {
    padding-right: 14rem !important
}

.pe-64 {
    padding-right: 16rem !important
}

.pe-72 {
    padding-right: 18rem !important
}

.pe-80 {
    padding-right: 20rem !important
}

.pe-88 {
    padding-right: 22rem !important
}

.pe-96 {
    padding-right: 24rem !important
}

.pe-px {
    padding-right: 1px !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: .75rem !important
}

.pb-4 {
    padding-bottom: 1rem !important
}

.pb-5 {
    padding-bottom: 1.25rem !important
}

.pb-6 {
    padding-bottom: 1.5rem !important
}

.pb-7 {
    padding-bottom: 1.75rem !important
}

.pb-8 {
    padding-bottom: 2rem !important
}

.pb-10 {
    padding-bottom: 2.5rem !important
}

.pb-12 {
    padding-bottom: 3rem !important
}

.pb-14 {
    padding-bottom: 3.5rem !important
}

.pb-16 {
    padding-bottom: 4rem !important
}

.pb-18 {
    padding-bottom: 4.5rem !important
}

.pb-20 {
    padding-bottom: 5rem !important
}

.pb-24 {
    padding-bottom: 6rem !important
}

.pb-32 {
    padding-bottom: 8rem !important
}

.pb-40 {
    padding-bottom: 10rem !important
}

.pb-48 {
    padding-bottom: 12rem !important
}

.pb-56 {
    padding-bottom: 14rem !important
}

.pb-64 {
    padding-bottom: 16rem !important
}

.pb-72 {
    padding-bottom: 18rem !important
}

.pb-80 {
    padding-bottom: 20rem !important
}

.pb-88 {
    padding-bottom: 22rem !important
}

.pb-96 {
    padding-bottom: 24rem !important
}

.pb-px {
    padding-bottom: 1px !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: .75rem !important
}

.ps-4 {
    padding-left: 1rem !important
}

.ps-5 {
    padding-left: 1.25rem !important
}

.ps-6 {
    padding-left: 1.5rem !important
}

.ps-7 {
    padding-left: 1.75rem !important
}

.ps-8 {
    padding-left: 2rem !important
}

.ps-10 {
    padding-left: 2.5rem !important
}

.ps-12 {
    padding-left: 3rem !important
}

.ps-14 {
    padding-left: 3.5rem !important
}

.ps-16 {
    padding-left: 4rem !important
}

.ps-18 {
    padding-left: 4.5rem !important
}

.ps-20 {
    padding-left: 5rem !important
}

.ps-24 {
    padding-left: 6rem !important
}

.ps-32 {
    padding-left: 8rem !important
}

.ps-40 {
    padding-left: 10rem !important
}

.ps-48 {
    padding-left: 12rem !important
}

.ps-56 {
    padding-left: 14rem !important
}

.ps-64 {
    padding-left: 16rem !important
}

.ps-72 {
    padding-left: 18rem !important
}

.ps-80 {
    padding-left: 20rem !important
}

.ps-88 {
    padding-left: 22rem !important
}

.ps-96 {
    padding-left: 24rem !important
}

.ps-px {
    padding-left: 1px !important
}

.font-base {
    font-family: var(--x-font-sans-serif) !important
}

.font-display {
    font-family: var(--x-font-display) !important
}

.font-serif {
    font-family: var(--x-font-serif) !important
}

.font-code {
    font-family: var(--x-font-monospace) !important
}

.text-xs {
    font-size: .75rem !important
}

.text-sm {
    font-size: .875rem !important
}

.text-base {
    font-size: 1rem !important
}

.text-md {
    font-size: 1.125rem !important
}

.text-lg {
    font-size: 1.25rem !important
}

.text-xl {
    font-size: calc(1.275rem + .3vw) !important
}

.text-2xl {
    font-size: calc(1.325rem + .9vw) !important
}

.font-italic {
    font-style: italic !important
}

.font-normal {
    font-style: normal !important
}

.font-light {
    font-weight: 300 !important
}

.font-lighter {
    font-weight: lighter !important
}

.font-regular {
    font-weight: 400 !important
}

.font-semibold {
    font-weight: 500 !important
}

.font-bold {
    font-weight: 600 !important
}

.font-bolder {
    font-weight: bolder !important
}

.lh-none {
    line-height: 1 !important
}

.lh-tighter {
    line-height: 1.125 !important
}

.lh-tight {
    line-height: 1.2 !important
}

.lh-snug {
    line-height: 1.375 !important
}

.lh-normal {
    line-height: 1.5 !important
}

.lh-relaxed {
    line-height: 1.625 !important
}

.lh-loose {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-no-decoration {
    text-decoration: none !important
}

.text-underline {
    text-decoration: underline !important
}

.text-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --x-text-opacity: 1
}

.text-primary, .text-primary-focus:focus, .text-primary-hover:hover {
    color: rgba(var(--x-primary-rgb),var(--x-text-opacity)) !important
}

.text-secondary {
    --x-text-opacity: 1
}

.text-secondary, .text-secondary-focus:focus, .text-secondary-hover:hover {
    color: rgba(var(--x-secondary-rgb),var(--x-text-opacity)) !important
}

.text-tertiary {
    --x-text-opacity: 1
}

.text-tertiary, .text-tertiary-focus:focus, .text-tertiary-hover:hover {
    color: rgba(var(--x-tertiary-rgb),var(--x-text-opacity)) !important
}

.text-success {
    --x-text-opacity: 1
}

.text-success, .text-success-focus:focus, .text-success-hover:hover {
    color: #fff !important;
    font-weight: 600;
}

.text-info {
    --x-text-opacity: 1
}

.text-info, .text-info-focus:focus, .text-info-hover:hover {
    color: black !important
}

.text-warning {
    --x-text-opacity: 1
}

.text-warning, .text-warning-focus:focus, .text-warning-hover:hover {
    color: rgba(var(--x-warning-rgb),var(--x-text-opacity)) !important
}

.text-danger {
    --x-text-opacity: 1
}

.text-danger, .text-danger-focus:focus, .text-danger-hover:hover {
    color: rgba(var(--x-danger-rgb),var(--x-text-opacity)) !important
}

.text-white {
    --x-text-opacity: 1
}

.text-white, .text-white-focus:focus, .text-white-hover:hover {
    color: rgba(var(--x-white-rgb),var(--x-text-opacity)) !important
}

.text-light {
    --x-text-opacity: 1
}

.text-light, .text-light-focus:focus, .text-light-hover:hover {
    color: rgba(var(--x-light-rgb),var(--x-text-opacity)) !important
}

.text-dark {
    --x-text-opacity: 1
}

.text-dark, .text-dark-focus:focus, .text-dark-hover:hover {
    color: rgba(var(--x-dark-rgb),var(--x-text-opacity)) !important
}

.text-black {
    --x-text-opacity: 1
}

.text-black, .text-black-focus:focus, .text-black-hover:hover {
    color: rgba(var(--x-black-rgb),var(--x-text-opacity)) !important
}

.text-body {
    --x-text-opacity: 1
}

.text-body, .text-body-focus:focus, .text-body-hover:hover {
    color: rgba(var(--x-body-color-rgb),var(--x-text-opacity)) !important
}

.text-muted {
    --x-text-opacity: 1
}

.text-muted, .text-muted-focus:focus, .text-muted-hover:hover {
    color: rgba(var(--x-muted-rgb),var(--x-text-opacity)) !important
}

.text-heading {
    --x-text-opacity: 1
}

.text-heading, .text-heading-focus:focus, .text-heading-hover:hover {
    color: rgba(var(--x-heading-rgb),var(--x-text-opacity)) !important
}

.text-opacity-10, .text-opacity-10-focus:focus, .text-opacity-10-hover:hover {
    --x-text-opacity: 0.1
}

.text-opacity-20, .text-opacity-20-focus:focus, .text-opacity-20-hover:hover {
    --x-text-opacity: 0.2
}

.text-opacity-30, .text-opacity-30-focus:focus, .text-opacity-30-hover:hover {
    --x-text-opacity: 0.3
}

.text-opacity-40, .text-opacity-40-focus:focus, .text-opacity-40-hover:hover {
    --x-text-opacity: 0.4
}

.text-opacity-50, .text-opacity-50-focus:focus, .text-opacity-50-hover:hover {
    --x-text-opacity: 0.5
}

.text-opacity-60, .text-opacity-60-focus:focus, .text-opacity-60-hover:hover {
    --x-text-opacity: 0.6
}

.text-opacity-70, .text-opacity-70-focus:focus, .text-opacity-70-hover:hover {
    --x-text-opacity: 0.7
}

.text-opacity-80, .text-opacity-80-focus:focus, .text-opacity-80-hover:hover {
    --x-text-opacity: 0.8
}

.text-opacity-90, .text-opacity-90-focus:focus, .text-opacity-90-hover:hover {
    --x-text-opacity: 0.9
}

.text-opacity-100, .text-opacity-100-focus:focus, .text-opacity-100-hover:hover {
    --x-text-opacity: 1
}

.bg-primary {
    --x-bg-opacity: 1
}

.bg-primary, .bg-primary-focus:focus, .bg-primary-hover:hover {
    background-color: rgba(var(--x-primary-rgb),var(--x-bg-opacity)) !important
}

.bg-primary-focus-within:focus-within {
    background-color: rgba(var(--x-primary-rgb),var(--x-bg-opacity)) !important
}

.bg-secondary {
    --x-bg-opacity: 1
}

.bg-secondary, .bg-secondary-focus:focus, .bg-secondary-hover:hover {
    background-color: rgba(var(--x-secondary-rgb),var(--x-bg-opacity)) !important
}

.bg-secondary-focus-within:focus-within {
    background-color: rgba(var(--x-secondary-rgb),var(--x-bg-opacity)) !important
}

.bg-tertiary {
    --x-bg-opacity: 1
}

.bg-tertiary, .bg-tertiary-focus:focus, .bg-tertiary-hover:hover {
    background-color: rgba(var(--x-tertiary-rgb),var(--x-bg-opacity)) !important
}

.bg-tertiary-focus-within:focus-within {
    background-color: rgba(var(--x-tertiary-rgb),var(--x-bg-opacity)) !important
}

.bg-success {
    --x-bg-opacity: 1
}

.bg-success, .bg-success-focus:focus, .bg-success-hover:hover {
    background-color: rgba(var(--x-success-rgb),var(--x-bg-opacity)) !important
}

.bg-success-focus-within:focus-within {
    background-color: rgba(var(--x-success-rgb),var(--x-bg-opacity)) !important
}

.bg-info {
    --x-bg-opacity: 1
}

.bg-info, .bg-info-focus:focus, .bg-info-hover:hover {
    background-color: rgba(var(--x-info-rgb),var(--x-bg-opacity)) !important
}

.bg-info-focus-within:focus-within {
    background-color: rgba(var(--x-info-rgb),var(--x-bg-opacity)) !important
}

.bg-warning {
    --x-bg-opacity: 1
}

.bg-warning, .bg-warning-focus:focus, .bg-warning-hover:hover {
    background-color: rgba(var(--x-warning-rgb),var(--x-bg-opacity)) !important
}

.bg-warning-focus-within:focus-within {
    background-color: rgba(var(--x-warning-rgb),var(--x-bg-opacity)) !important
}

.bg-danger {
    --x-bg-opacity: 1
}

.bg-danger, .bg-danger-focus:focus, .bg-danger-hover:hover {
    background-color: rgba(var(--x-danger-rgb),var(--x-bg-opacity)) !important
}

.bg-danger-focus-within:focus-within {
    background-color: rgba(var(--x-danger-rgb),var(--x-bg-opacity)) !important
}

.bg-white {
    --x-bg-opacity: 1
}

.bg-white, .bg-white-focus:focus, .bg-white-hover:hover {
    background-color: rgba(var(--x-white-rgb),var(--x-bg-opacity)) !important
}

.bg-white-focus-within:focus-within {
    background-color: rgba(var(--x-white-rgb),var(--x-bg-opacity)) !important
}

.bg-light {
    --x-bg-opacity: 1
}

.bg-light, .bg-light-focus:focus, .bg-light-hover:hover {
    background-color: rgba(var(--x-light-rgb),var(--x-bg-opacity)) !important
}

.bg-light-focus-within:focus-within {
    background-color: rgba(var(--x-light-rgb),var(--x-bg-opacity)) !important
}

.bg-dark {
    --x-bg-opacity: 1
}

.bg-dark, .bg-dark-focus:focus, .bg-dark-hover:hover {
    background-color: rgba(var(--x-dark-rgb),var(--x-bg-opacity)) !important
}

.bg-dark-focus-within:focus-within {
    background-color: rgba(var(--x-dark-rgb),var(--x-bg-opacity)) !important
}

.bg-body {
    --x-bg-opacity: 1
}

.bg-body, .bg-body-focus:focus, .bg-body-hover:hover {
    background-color: rgba(var(--x-body-bg-rgb),var(--x-bg-opacity)) !important
}

.bg-body-focus-within:focus-within {
    background-color: rgba(var(--x-body-bg-rgb),var(--x-bg-opacity)) !important
}

.bg-black {
    --x-bg-opacity: 1
}

.bg-black, .bg-black-focus:focus, .bg-black-hover:hover {
    background-color: rgba(var(--x-black-rgb),var(--x-bg-opacity)) !important
}

.bg-black-focus-within:focus-within {
    background-color: rgba(var(--x-black-rgb),var(--x-bg-opacity)) !important
}

.bg-card {
    --x-bg-opacity: 1
}

.bg-card, .bg-card-focus:focus, .bg-card-hover:hover {
    background-color: rgba(var(--x-card-rgb),var(--x-bg-opacity)) !important
}

.bg-card-focus-within:focus-within {
    background-color: rgba(var(--x-card-rgb),var(--x-bg-opacity)) !important
}

.bg-opacity-10, .bg-opacity-10-focus:focus, .bg-opacity-10-hover:hover {
    --x-bg-opacity: 0.1
}

.bg-opacity-20, .bg-opacity-20-focus:focus, .bg-opacity-20-hover:hover {
    --x-bg-opacity: 0.2
}

.bg-opacity-30, .bg-opacity-30-focus:focus, .bg-opacity-30-hover:hover {
    --x-bg-opacity: 0.3
}

.bg-opacity-40, .bg-opacity-40-focus:focus, .bg-opacity-40-hover:hover {
    --x-bg-opacity: 0.4
}

.bg-opacity-50, .bg-opacity-50-focus:focus, .bg-opacity-50-hover:hover {
    --x-bg-opacity: 0.5
}

.bg-opacity-60, .bg-opacity-60-focus:focus, .bg-opacity-60-hover:hover {
    --x-bg-opacity: 0.6
}

.bg-opacity-70, .bg-opacity-70-focus:focus, .bg-opacity-70-hover:hover {
    --x-bg-opacity: 0.7
}

.bg-opacity-80, .bg-opacity-80-focus:focus, .bg-opacity-80-hover:hover {
    --x-bg-opacity: 0.8
}

.bg-opacity-90, .bg-opacity-90-focus:focus, .bg-opacity-90-hover:hover {
    --x-bg-opacity: 0.9
}

.bg-opacity-100, .bg-opacity-100-focus:focus, .bg-opacity-100-hover:hover {
    --x-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--x-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: .375rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: .25rem !important
}

.rounded-2 {
    border-radius: .5rem !important
}

.rounded-3 {
    border-radius: .75rem !important
}

.rounded-4 {
    border-radius: 1rem !important
}

.rounded-5 {
    border-radius: 1.5rem !important
}

.rounded-6 {
    border-radius: 3rem !important
}

.rounded-7 {
    border-radius: 4rem !important
}

.rounded-8 {
    border-radius: 5rem !important
}

.rounded-9 {
    border-radius: 6rem !important
}

.rounded-10 {
    border-radius: 7rem !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-card {
    border-radius: .75rem !important
}

.rounded-top {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-top-1 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-top-2 {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important
}

.rounded-top-3 {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important
}

.rounded-top-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important
}

.rounded-top-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important
}

.rounded-top-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important
}

.rounded-top-7 {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important
}

.rounded-top-8 {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important
}

.rounded-top-9 {
    border-top-left-radius: 6rem !important;
    border-top-right-radius: 6rem !important
}

.rounded-top-10 {
    border-top-left-radius: 7rem !important;
    border-top-right-radius: 7rem !important
}

.rounded-top-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important
}

.rounded-top-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important
}

.rounded-top-card {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important
}

.rounded-end {
    border-bottom-right-radius: .375rem !important;
    border-top-right-radius: .375rem !important
}

.rounded-end-0 {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-end-1 {
    border-bottom-right-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-end-2 {
    border-bottom-right-radius: .5rem !important;
    border-top-right-radius: .5rem !important
}

.rounded-end-3 {
    border-bottom-right-radius: .75rem !important;
    border-top-right-radius: .75rem !important
}

.rounded-end-4 {
    border-bottom-right-radius: 1rem !important;
    border-top-right-radius: 1rem !important
}

.rounded-end-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important
}

.rounded-end-6 {
    border-bottom-right-radius: 3rem !important;
    border-top-right-radius: 3rem !important
}

.rounded-end-7 {
    border-bottom-right-radius: 4rem !important;
    border-top-right-radius: 4rem !important
}

.rounded-end-8 {
    border-bottom-right-radius: 5rem !important;
    border-top-right-radius: 5rem !important
}

.rounded-end-9 {
    border-bottom-right-radius: 6rem !important;
    border-top-right-radius: 6rem !important
}

.rounded-end-10 {
    border-bottom-right-radius: 7rem !important;
    border-top-right-radius: 7rem !important
}

.rounded-end-pill {
    border-bottom-right-radius: 50rem !important;
    border-top-right-radius: 50rem !important
}

.rounded-end-circle {
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important
}

.rounded-end-card {
    border-bottom-right-radius: .75rem !important;
    border-top-right-radius: .75rem !important
}

.rounded-bottom {
    border-bottom-left-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-bottom-1 {
    border-bottom-left-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom-2 {
    border-bottom-left-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important
}

.rounded-bottom-3 {
    border-bottom-left-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important
}

.rounded-bottom-4 {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important
}

.rounded-bottom-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important
}

.rounded-bottom-6 {
    border-bottom-left-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important
}

.rounded-bottom-7 {
    border-bottom-left-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important
}

.rounded-bottom-8 {
    border-bottom-left-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important
}

.rounded-bottom-9 {
    border-bottom-left-radius: 6rem !important;
    border-bottom-right-radius: 6rem !important
}

.rounded-bottom-10 {
    border-bottom-left-radius: 7rem !important;
    border-bottom-right-radius: 7rem !important
}

.rounded-bottom-pill {
    border-bottom-left-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important
}

.rounded-bottom-circle {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important
}

.rounded-bottom-card {
    border-bottom-left-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important
}

.rounded-start {
    border-bottom-left-radius: .375rem !important;
    border-top-left-radius: .375rem !important
}

.rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important
}

.rounded-start-1 {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important
}

.rounded-start-2 {
    border-bottom-left-radius: .5rem !important;
    border-top-left-radius: .5rem !important
}

.rounded-start-3 {
    border-bottom-left-radius: .75rem !important;
    border-top-left-radius: .75rem !important
}

.rounded-start-4 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important
}

.rounded-start-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important
}

.rounded-start-6 {
    border-bottom-left-radius: 3rem !important;
    border-top-left-radius: 3rem !important
}

.rounded-start-7 {
    border-bottom-left-radius: 4rem !important;
    border-top-left-radius: 4rem !important
}

.rounded-start-8 {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important
}

.rounded-start-9 {
    border-bottom-left-radius: 6rem !important;
    border-top-left-radius: 6rem !important
}

.rounded-start-10 {
    border-bottom-left-radius: 7rem !important;
    border-top-left-radius: 7rem !important
}

.rounded-start-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important
}

.rounded-start-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important
}

.rounded-start-card {
    border-bottom-left-radius: .75rem !important;
    border-top-left-radius: .75rem !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

.overlap-10 {
    z-index: 10 !important
}

.overlap-20 {
    z-index: 20 !important
}

.overlap-30 {
    z-index: 30 !important
}

.overlap-40 {
    z-index: 40 !important
}

.overlap-50 {
    z-index: 50 !important
}

.overlap-100 {
    z-index: 100 !important
}

.overlap-200 {
    z-index: 200 !important
}

.overlap-300 {
    z-index: 300 !important
}

.overlap-400 {
    z-index: 400 !important
}

.overlap-500 {
    z-index: 500 !important
}

.overlap-1000 {
    z-index: 1000 !important
}

.overlap-auto {
    z-index: auto !important
}

.rounded-top-start {
    border-top-left-radius: .375rem !important
}

.rounded-top-start-0 {
    border-top-left-radius: 0 !important
}

.rounded-top-start-1 {
    border-top-left-radius: .25rem !important
}

.rounded-top-start-2 {
    border-top-left-radius: .5rem !important
}

.rounded-top-start-3 {
    border-top-left-radius: .75rem !important
}

.rounded-top-start-4 {
    border-top-left-radius: 1rem !important
}

.rounded-top-start-5 {
    border-top-left-radius: 1.5rem !important
}

.rounded-top-start-6 {
    border-top-left-radius: 3rem !important
}

.rounded-top-start-7 {
    border-top-left-radius: 4rem !important
}

.rounded-top-start-8 {
    border-top-left-radius: 5rem !important
}

.rounded-top-start-9 {
    border-top-left-radius: 6rem !important
}

.rounded-top-start-10 {
    border-top-left-radius: 7rem !important
}

.rounded-top-start-pill {
    border-top-left-radius: 50rem !important
}

.rounded-top-start-circle {
    border-top-left-radius: 50% !important
}

.rounded-top-start-card {
    border-top-left-radius: .75rem !important
}

.rounded-top-end {
    border-top-right-radius: .375rem !important
}

.rounded-top-end-0 {
    border-top-right-radius: 0 !important
}

.rounded-top-end-1 {
    border-top-right-radius: .25rem !important
}

.rounded-top-end-2 {
    border-top-right-radius: .5rem !important
}

.rounded-top-end-3 {
    border-top-right-radius: .75rem !important
}

.rounded-top-end-4 {
    border-top-right-radius: 1rem !important
}

.rounded-top-end-5 {
    border-top-right-radius: 1.5rem !important
}

.rounded-top-end-6 {
    border-top-right-radius: 3rem !important
}

.rounded-top-end-7 {
    border-top-right-radius: 4rem !important
}

.rounded-top-end-8 {
    border-top-right-radius: 5rem !important
}

.rounded-top-end-9 {
    border-top-right-radius: 6rem !important
}

.rounded-top-end-10 {
    border-top-right-radius: 7rem !important
}

.rounded-top-end-pill {
    border-top-right-radius: 50rem !important
}

.rounded-top-end-circle {
    border-top-right-radius: 50% !important
}

.rounded-top-end-card {
    border-top-right-radius: .75rem !important
}

.rounded-bottom-end {
    border-bottom-right-radius: .375rem !important
}

.rounded-bottom-end-0 {
    border-bottom-right-radius: 0 !important
}

.rounded-bottom-end-1 {
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom-end-2 {
    border-bottom-right-radius: .5rem !important
}

.rounded-bottom-end-3 {
    border-bottom-right-radius: .75rem !important
}

.rounded-bottom-end-4 {
    border-bottom-right-radius: 1rem !important
}

.rounded-bottom-end-5 {
    border-bottom-right-radius: 1.5rem !important
}

.rounded-bottom-end-6 {
    border-bottom-right-radius: 3rem !important
}

.rounded-bottom-end-7 {
    border-bottom-right-radius: 4rem !important
}

.rounded-bottom-end-8 {
    border-bottom-right-radius: 5rem !important
}

.rounded-bottom-end-9 {
    border-bottom-right-radius: 6rem !important
}

.rounded-bottom-end-10 {
    border-bottom-right-radius: 7rem !important
}

.rounded-bottom-end-pill {
    border-bottom-right-radius: 50rem !important
}

.rounded-bottom-end-circle {
    border-bottom-right-radius: 50% !important
}

.rounded-bottom-end-card {
    border-bottom-right-radius: .75rem !important
}

.rounded-bottom-start {
    border-bottom-left-radius: .375rem !important
}

.rounded-bottom-start-0 {
    border-bottom-left-radius: 0 !important
}

.rounded-bottom-start-1 {
    border-bottom-left-radius: .25rem !important
}

.rounded-bottom-start-2 {
    border-bottom-left-radius: .5rem !important
}

.rounded-bottom-start-3 {
    border-bottom-left-radius: .75rem !important
}

.rounded-bottom-start-4 {
    border-bottom-left-radius: 1rem !important
}

.rounded-bottom-start-5 {
    border-bottom-left-radius: 1.5rem !important
}

.rounded-bottom-start-6 {
    border-bottom-left-radius: 3rem !important
}

.rounded-bottom-start-7 {
    border-bottom-left-radius: 4rem !important
}

.rounded-bottom-start-8 {
    border-bottom-left-radius: 5rem !important
}

.rounded-bottom-start-9 {
    border-bottom-left-radius: 6rem !important
}

.rounded-bottom-start-10 {
    border-bottom-left-radius: 7rem !important
}

.rounded-bottom-start-pill {
    border-bottom-left-radius: 50rem !important
}

.rounded-bottom-start-circle {
    border-bottom-left-radius: 50% !important
}

.rounded-bottom-start-card {
    border-bottom-left-radius: .75rem !important
}

.border-solid {
    border-style: solid !important
}

.border-dashed {
    border-style: dashed !important
}

.border-dotted {
    border-style: dotted !important
}

.border-double {
    border-style: double !important
}

.border-groove {
    border-style: groove !important
}

.border-none {
    border-style: none !important
}

.border-auto {
    border-style: auto !important
}

.border-pointer {
    border-style: pointer !important
}

.border-wait {
    border-style: wait !important
}

.border-text {
    border-style: text !important
}

.ls-tighter {
    letter-spacing: -.05em !important
}

.ls-tight {
    letter-spacing: -.025em !important
}

.ls-normal {
    letter-spacing: 0 !important
}

.ls-wide {
    letter-spacing: .025em !important
}

.ls-wider {
    letter-spacing: .05em !important
}

.ls-widest {
    letter-spacing: .1em !important
}

.text-transparent, .text-transparent-focus:focus, .text-transparent-hover:hover {
    color: transparent !important
}

.text-current, .text-current-focus:focus, .text-current-hover:hover {
    color: currentColor !important
}

.text-reset, .text-reset-focus:focus, .text-reset-hover:hover {
    color: inherit !important
}

.bg-transparent, .bg-transparent-focus:focus, .bg-transparent-hover:hover {
    background-color: transparent !important
}

.bg-transparent-focus-within:focus-within {
    background-color: transparent !important
}

.bg-current, .bg-current-focus:focus, .bg-current-hover:hover {
    background-color: currentColor !important
}

.bg-current-focus-within:focus-within {
    background-color: currentColor !important
}

.bg-auto {
    background-size: auto !important
}

.bg-cover {
    background-size: cover !important
}

.bg-contain {
    background-size: contain !important
}

.bg-clip-border {
    background-clip: border-box !important
}

.bg-clip-padding {
    background-clip: padding-box !important
}

.bg-clip-content {
    background-clip: content-box !important
}

.bg-clip-text {
    -webkit-background-clip: text !important;
    background-clip: text !important
}

.shadow-soft-1, .shadow-soft-1-focus:focus, .shadow-soft-1-hover:hover {
    box-shadow: 0 1px 1px 0 rgba(10,22,70,.04) !important
}

.shadow-soft-2, .shadow-soft-2-focus:focus, .shadow-soft-2-hover:hover {
    box-shadow: 0 6px 6px -1px rgba(10,22,70,.04) !important
}

.shadow-soft-3, .shadow-soft-3-focus:focus, .shadow-soft-3-hover:hover {
    box-shadow: 0 9px 9px -1px rgba(10,22,70,.04) !important
}

.shadow-soft-4, .shadow-soft-4-focus:focus, .shadow-soft-4-hover:hover {
    box-shadow: 0 16px 16px -1px rgba(10,22,70,.04) !important
}

.shadow-soft-5, .shadow-soft-5-focus:focus, .shadow-soft-5-hover:hover {
    box-shadow: 0 32px 40px -2px rgba(10,22,70,.04) !important
}

.shadow-soft-6, .shadow-soft-6-focus:focus, .shadow-soft-6-hover:hover {
    box-shadow: 0 36px 50px 0 rgba(10,22,70,.04) !important
}

.shadow-soft-none, .shadow-soft-none-focus:focus, .shadow-soft-none-hover:hover {
    box-shadow: none !important
}

.min-w-0 {
    min-width: 0 !important
}

.min-w-full {
    min-width: 100% !important
}

.min-w-min {
    min-width: -webkit-min-content !important;
    min-width: -moz-min-content !important;
    min-width: min-content !important
}

.min-w-max {
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important
}

.min-h-0 {
    min-height: 0 !important
}

.min-h-full {
    min-height: 100% !important
}

.min-h-screen {
    min-height: 100vh !important
}

.place-content-start {
    place-content: flex-start !important
}

.place-content-end {
    place-content: flex-end !important
}

.place-content-center {
    place-content: center !important
}

.place-content-between {
    place-content: space-between !important
}

.place-content-around {
    place-content: space-around !important
}

.place-content-evenly {
    place-content: space-evenly !important
}

.place-content-stretch {
    place-content: stretch !important
}

.overflow-x-auto {
    overflow-x: auto !important
}

.overflow-x-hidden {
    overflow-x: hidden !important
}

.overflow-x-visible {
    overflow-x: visible !important
}

.overflow-x-scroll {
    overflow-x: scroll !important
}

.overflow-y-auto {
    overflow-y: auto !important
}

.overflow-y-hidden {
    overflow-y: hidden !important
}

.overflow-y-visible {
    overflow-y: visible !important
}

.overflow-y-scroll {
    overflow-y: scroll !important
}

.transition {
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform !important
}

.transition-none {
    transition-property: none !important
}

.transition-all {
    transition-property: all !important
}

.transition-colors {
    transition-property: background-color,border-color,color,fill,stroke !important
}

.transition-opacity {
    transition-property: opacity !important
}

.transition-shadow {
    transition-property: box-shadow !important
}

.transition-transform {
    transition-property: transform !important
}

.ease-base {
    transition-timing-function: cubic-bezier(.4,0,.2,1) !important
}

.ease-linear {
    transition-timing-function: linear !important
}

.ease-in {
    transition-timing-function: cubic-bezier(.4,0,1,1) !important
}

.ease-out {
    transition-timing-function: cubic-bezier(0,0,.2,1) !important
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4,0,.2,1) !important
}

.duration-base {
    transition-duration: .15s !important
}

.duration-75 {
    transition-duration: 75ms !important
}

.duration-100 {
    transition-duration: .1s !important
}

.duration-150 {
    transition-duration: .15s !important
}

.duration-200 {
    transition-duration: .2s !important
}

.duration-300 {
    transition-duration: .3s !important
}

.duration-500 {
    transition-duration: .5s !important
}

.duration-700 {
    transition-duration: .7s !important
}

.duration-1000 {
    transition-duration: 1s !important
}

.delay-75 {
    transition-delay: 75ms !important
}

.delay-100 {
    transition-delay: .1s !important
}

.delay-150 {
    transition-delay: .15s !important
}

.delay-200 {
    transition-delay: .2s !important
}

.delay-300 {
    transition-delay: .3s !important
}

.delay-500 {
    transition-delay: .5s !important
}

.delay-700 {
    transition-delay: .7s !important
}

.delay-1000 {
    transition-delay: 1s !important
}

.animation-none {
    -webkit-animation: none !important;
    animation: none !important
}

.animation-spin {
    -webkit-animation: spin 1s linear infinite !important;
    animation: spin 1s linear infinite !important
}

.animation-ping {
    -webkit-animation: ping 1s cubic-bezier(0,0,.2,1) infinite !important;
    animation: ping 1s cubic-bezier(0,0,.2,1) infinite !important
}

.animation-pulse {
    -webkit-animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite !important;
    animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite !important
}

.animation-bounce {
    -webkit-animation: bounce 1s infinite !important;
    animation: bounce 1s infinite !important
}

.animation-slide-left {
    -webkit-animation: slideLeft 3s infinite !important;
    animation: slideLeft 3s infinite !important
}

.animation-slide-right {
    -webkit-animation: slideRight 3s infinite !important;
    animation: slideRight 3s infinite !important
}

.animation-move-slow-left {
    -webkit-animation: slideLeft 15s linear infinite !important;
    animation: slideLeft 15s linear infinite !important
}

.animation-move-slow-right {
    -webkit-animation: slideRight 15s linear infinite !important;
    animation: slideRight 15s linear infinite !important
}

.blur-5 {
    --x-blur: 5px !important
}

.blur-10 {
    --x-blur: 10px !important
}

.blur-25 {
    --x-blur: 25px !important
}

.blur-50 {
    --x-blur: 50px !important
}

.blur-75 {
    --x-blur: 75px !important
}

.blur-100 {
    --x-blur: 100px !important
}

.blur-125 {
    --x-blur: 125px !important
}

.blur-150 {
    --x-blur: 150px !important
}

.blur-200 {
    --x-blur: 200px !important
}

.transform-none {
    transform: none !important
}

.origin-center {
    transform-origin: center !important
}

.origin-top {
    transform-origin: top !important
}

.origin-top-right {
    transform-origin: top right !important
}

.origin-right {
    transform-origin: right !important
}

.origin-bottom-right {
    transform-origin: bottom right !important
}

.origin-bottom {
    transform-origin: bottom !important
}

.origin-bottom-left {
    transform-origin: bottom left !important
}

.origin-left {
    transform-origin: left !important
}

.origin-top-left {
    transform-origin: top left !important
}

.scale-0, .scale-0-focus:focus, .scale-0-hover:hover {
    --x-scale-x: 0 !important;
    --x-scale-y: 0 !important
}

.scale-25, .scale-25-focus:focus, .scale-25-hover:hover {
    --x-scale-x: 0.25 !important;
    --x-scale-y: 0.25 !important
}

.scale-50, .scale-50-focus:focus, .scale-50-hover:hover {
    --x-scale-x: 0.5 !important;
    --x-scale-y: 0.5 !important
}

.scale-75, .scale-75-focus:focus, .scale-75-hover:hover {
    --x-scale-x: 0.75 !important;
    --x-scale-y: 0.75 !important
}

.scale-90, .scale-90-focus:focus, .scale-90-hover:hover {
    --x-scale-x: 0.9 !important;
    --x-scale-y: 0.9 !important
}

.scale-95, .scale-95-focus:focus, .scale-95-hover:hover {
    --x-scale-x: 0.95 !important;
    --x-scale-y: 0.95 !important
}

.scale-100, .scale-100-focus:focus, .scale-100-hover:hover {
    --x-scale-x: 1 !important;
    --x-scale-y: 1 !important
}

.scale-105, .scale-105-focus:focus, .scale-105-hover:hover {
    --x-scale-x: 1.05 !important;
    --x-scale-y: 1.05 !important
}

.scale-110, .scale-110-focus:focus, .scale-110-hover:hover {
    --x-scale-x: 1.1 !important;
    --x-scale-y: 1.1 !important
}

.scale-125, .scale-125-focus:focus, .scale-125-hover:hover {
    --x-scale-x: 1.25 !important;
    --x-scale-y: 1.25 !important
}

.scale-150, .scale-150-focus:focus, .scale-150-hover:hover {
    --x-scale-x: 1.5 !important;
    --x-scale-y: 1.5 !important
}

.scale-200, .scale-200-focus:focus, .scale-200-hover:hover {
    --x-scale-x: 2 !important;
    --x-scale-y: 2 !important
}

.scale-y-0, .scale-y-0-focus:focus, .scale-y-0-hover:hover {
    --x--scale-y: 0 !important
}

.scale-y-25, .scale-y-25-focus:focus, .scale-y-25-hover:hover {
    --x--scale-y: 0.25 !important
}

.scale-y-50, .scale-y-50-focus:focus, .scale-y-50-hover:hover {
    --x--scale-y: 0.5 !important
}

.scale-y-75, .scale-y-75-focus:focus, .scale-y-75-hover:hover {
    --x--scale-y: 0.75 !important
}

.scale-y-90, .scale-y-90-focus:focus, .scale-y-90-hover:hover {
    --x--scale-y: 0.9 !important
}

.scale-y-95, .scale-y-95-focus:focus, .scale-y-95-hover:hover {
    --x--scale-y: 0.95 !important
}

.scale-y-100, .scale-y-100-focus:focus, .scale-y-100-hover:hover {
    --x--scale-y: 1 !important
}

.scale-y-105, .scale-y-105-focus:focus, .scale-y-105-hover:hover {
    --x--scale-y: 1.05 !important
}

.scale-y-110, .scale-y-110-focus:focus, .scale-y-110-hover:hover {
    --x--scale-y: 1.1 !important
}

.scale-y-125, .scale-y-125-focus:focus, .scale-y-125-hover:hover {
    --x--scale-y: 1.25 !important
}

.scale-y-150, .scale-y-150-focus:focus, .scale-y-150-hover:hover {
    --x--scale-y: 1.5 !important
}

.scale-y-200, .scale-y-200-focus:focus, .scale-y-200-hover:hover {
    --x--scale-y: 2 !important
}

.scale-x-0, .scale-x-0-focus:focus, .scale-x-0-hover:hover {
    --x--scale-x: 0 !important
}

.scale-x-25, .scale-x-25-focus:focus, .scale-x-25-hover:hover {
    --x--scale-x: 0.25 !important
}

.scale-x-50, .scale-x-50-focus:focus, .scale-x-50-hover:hover {
    --x--scale-x: 0.5 !important
}

.scale-x-75, .scale-x-75-focus:focus, .scale-x-75-hover:hover {
    --x--scale-x: 0.75 !important
}

.scale-x-90, .scale-x-90-focus:focus, .scale-x-90-hover:hover {
    --x--scale-x: 0.9 !important
}

.scale-x-95, .scale-x-95-focus:focus, .scale-x-95-hover:hover {
    --x--scale-x: 0.95 !important
}

.scale-x-100, .scale-x-100-focus:focus, .scale-x-100-hover:hover {
    --x--scale-x: 1 !important
}

.scale-x-105, .scale-x-105-focus:focus, .scale-x-105-hover:hover {
    --x--scale-x: 1.05 !important
}

.scale-x-110, .scale-x-110-focus:focus, .scale-x-110-hover:hover {
    --x--scale-x: 1.1 !important
}

.scale-x-125, .scale-x-125-focus:focus, .scale-x-125-hover:hover {
    --x--scale-x: 1.25 !important
}

.scale-x-150, .scale-x-150-focus:focus, .scale-x-150-hover:hover {
    --x--scale-x: 1.5 !important
}

.scale-x-200, .scale-x-200-focus:focus, .scale-x-200-hover:hover {
    --x--scale-x: 2 !important
}

.rotate-0, .rotate-0-focus:focus, .rotate-0-hover:hover {
    --x-rotate: 0deg !important
}

.rotate-1, .rotate-1-focus:focus, .rotate-1-hover:hover {
    --x-rotate: 1deg !important
}

.rotate-2, .rotate-2-focus:focus, .rotate-2-hover:hover {
    --x-rotate: 2deg !important
}

.rotate-3, .rotate-3-focus:focus, .rotate-3-hover:hover {
    --x-rotate: 3deg !important
}

.rotate-6, .rotate-6-focus:focus, .rotate-6-hover:hover {
    --x-rotate: 6deg !important
}

.rotate-12, .rotate-12-focus:focus, .rotate-12-hover:hover {
    --x-rotate: 12deg !important
}

.rotate-30, .rotate-30-focus:focus, .rotate-30-hover:hover {
    --x-rotate: 30deg !important
}

.rotate-45, .rotate-45-focus:focus, .rotate-45-hover:hover {
    --x-rotate: 45deg !important
}

.rotate-90, .rotate-90-focus:focus, .rotate-90-hover:hover {
    --x-rotate: 90deg !important
}

.rotate-180, .rotate-180-focus:focus, .rotate-180-hover:hover {
    --x-rotate: 180deg !important
}

.rotate-n1, .rotate-n1-focus:focus, .rotate-n1-hover:hover {
    --x-rotate: -1deg !important
}

.rotate-n2, .rotate-n2-focus:focus, .rotate-n2-hover:hover {
    --x-rotate: -2deg !important
}

.rotate-n3, .rotate-n3-focus:focus, .rotate-n3-hover:hover {
    --x-rotate: -3deg !important
}

.rotate-n6, .rotate-n6-focus:focus, .rotate-n6-hover:hover {
    --x-rotate: -6deg !important
}

.rotate-n12, .rotate-n12-focus:focus, .rotate-n12-hover:hover {
    --x-rotate: -12deg !important
}

.rotate-n30, .rotate-n30-focus:focus, .rotate-n30-hover:hover {
    --x-rotate: -30deg !important
}

.rotate-n45, .rotate-n45-focus:focus, .rotate-n45-hover:hover {
    --x-rotate: -45deg !important
}

.rotate-n90, .rotate-n90-focus:focus, .rotate-n90-hover:hover {
    --x-rotate: -90deg !important
}

.rotate-n180, .rotate-n180-focus:focus, .rotate-n180-hover:hover {
    --x-rotate: -180deg !important
}

.rotate-y-0, .rotate-y-0-focus:focus, .rotate-y-0-hover:hover {
    --x-rotate-y: 0deg !important
}

.rotate-y-1, .rotate-y-1-focus:focus, .rotate-y-1-hover:hover {
    --x-rotate-y: 1deg !important
}

.rotate-y-2, .rotate-y-2-focus:focus, .rotate-y-2-hover:hover {
    --x-rotate-y: 2deg !important
}

.rotate-y-3, .rotate-y-3-focus:focus, .rotate-y-3-hover:hover {
    --x-rotate-y: 3deg !important
}

.rotate-y-6, .rotate-y-6-focus:focus, .rotate-y-6-hover:hover {
    --x-rotate-y: 6deg !important
}

.rotate-y-12, .rotate-y-12-focus:focus, .rotate-y-12-hover:hover {
    --x-rotate-y: 12deg !important
}

.rotate-y-30, .rotate-y-30-focus:focus, .rotate-y-30-hover:hover {
    --x-rotate-y: 30deg !important
}

.rotate-y-45, .rotate-y-45-focus:focus, .rotate-y-45-hover:hover {
    --x-rotate-y: 45deg !important
}

.rotate-y-90, .rotate-y-90-focus:focus, .rotate-y-90-hover:hover {
    --x-rotate-y: 90deg !important
}

.rotate-y-180, .rotate-y-180-focus:focus, .rotate-y-180-hover:hover {
    --x-rotate-y: 180deg !important
}

.rotate-y-n1, .rotate-y-n1-focus:focus, .rotate-y-n1-hover:hover {
    --x-rotate-y: -1deg !important
}

.rotate-y-n2, .rotate-y-n2-focus:focus, .rotate-y-n2-hover:hover {
    --x-rotate-y: -2deg !important
}

.rotate-y-n3, .rotate-y-n3-focus:focus, .rotate-y-n3-hover:hover {
    --x-rotate-y: -3deg !important
}

.rotate-y-n6, .rotate-y-n6-focus:focus, .rotate-y-n6-hover:hover {
    --x-rotate-y: -6deg !important
}

.rotate-y-n12, .rotate-y-n12-focus:focus, .rotate-y-n12-hover:hover {
    --x-rotate-y: -12deg !important
}

.rotate-y-n30, .rotate-y-n30-focus:focus, .rotate-y-n30-hover:hover {
    --x-rotate-y: -30deg !important
}

.rotate-y-n45, .rotate-y-n45-focus:focus, .rotate-y-n45-hover:hover {
    --x-rotate-y: -45deg !important
}

.rotate-y-n90, .rotate-y-n90-focus:focus, .rotate-y-n90-hover:hover {
    --x-rotate-y: -90deg !important
}

.rotate-y-n180, .rotate-y-n180-focus:focus, .rotate-y-n180-hover:hover {
    --x-rotate-y: -180deg !important
}

.rotate-x-0, .rotate-x-0-focus:focus, .rotate-x-0-hover:hover {
    --x-rotate-x: 0deg !important
}

.rotate-x-1, .rotate-x-1-focus:focus, .rotate-x-1-hover:hover {
    --x-rotate-x: 1deg !important
}

.rotate-x-2, .rotate-x-2-focus:focus, .rotate-x-2-hover:hover {
    --x-rotate-x: 2deg !important
}

.rotate-x-3, .rotate-x-3-focus:focus, .rotate-x-3-hover:hover {
    --x-rotate-x: 3deg !important
}

.rotate-x-6, .rotate-x-6-focus:focus, .rotate-x-6-hover:hover {
    --x-rotate-x: 6deg !important
}

.rotate-x-12, .rotate-x-12-focus:focus, .rotate-x-12-hover:hover {
    --x-rotate-x: 12deg !important
}

.rotate-x-30, .rotate-x-30-focus:focus, .rotate-x-30-hover:hover {
    --x-rotate-x: 30deg !important
}

.rotate-x-45, .rotate-x-45-focus:focus, .rotate-x-45-hover:hover {
    --x-rotate-x: 45deg !important
}

.rotate-x-90, .rotate-x-90-focus:focus, .rotate-x-90-hover:hover {
    --x-rotate-x: 90deg !important
}

.rotate-x-180, .rotate-x-180-focus:focus, .rotate-x-180-hover:hover {
    --x-rotate-x: 180deg !important
}

.rotate-x-n1, .rotate-x-n1-focus:focus, .rotate-x-n1-hover:hover {
    --x-rotate-x: -1deg !important
}

.rotate-x-n2, .rotate-x-n2-focus:focus, .rotate-x-n2-hover:hover {
    --x-rotate-x: -2deg !important
}

.rotate-x-n3, .rotate-x-n3-focus:focus, .rotate-x-n3-hover:hover {
    --x-rotate-x: -3deg !important
}

.rotate-x-n6, .rotate-x-n6-focus:focus, .rotate-x-n6-hover:hover {
    --x-rotate-x: -6deg !important
}

.rotate-x-n12, .rotate-x-n12-focus:focus, .rotate-x-n12-hover:hover {
    --x-rotate-x: -12deg !important
}

.rotate-x-n30, .rotate-x-n30-focus:focus, .rotate-x-n30-hover:hover {
    --x-rotate-x: -30deg !important
}

.rotate-x-n45, .rotate-x-n45-focus:focus, .rotate-x-n45-hover:hover {
    --x-rotate-x: -45deg !important
}

.rotate-x-n90, .rotate-x-n90-focus:focus, .rotate-x-n90-hover:hover {
    --x-rotate-x: -90deg !important
}

.rotate-x-n180, .rotate-x-n180-focus:focus, .rotate-x-n180-hover:hover {
    --x-rotate-x: -180deg !important
}

.perspective-100, .perspective-100-focus:focus, .perspective-100-hover:hover {
    --x-perspective: 100px !important
}

.perspective-200, .perspective-200-focus:focus, .perspective-200-hover:hover {
    --x-perspective: 200px !important
}

.perspective-300, .perspective-300-focus:focus, .perspective-300-hover:hover {
    --x-perspective: 300px !important
}

.perspective-400, .perspective-400-focus:focus, .perspective-400-hover:hover {
    --x-perspective: 400px !important
}

.perspective-500, .perspective-500-focus:focus, .perspective-500-hover:hover {
    --x-perspective: 500px !important
}

.perspective-600, .perspective-600-focus:focus, .perspective-600-hover:hover {
    --x-perspective: 600px !important
}

.perspective-700, .perspective-700-focus:focus, .perspective-700-hover:hover {
    --x-perspective: 700px !important
}

.perspective-800, .perspective-800-focus:focus, .perspective-800-hover:hover {
    --x-perspective: 800px !important
}

.perspective-1000, .perspective-1000-focus:focus, .perspective-1000-hover:hover {
    --x-perspective: 1000px !important
}

.perspective-1250, .perspective-1250-focus:focus, .perspective-1250-hover:hover {
    --x-perspective: 1250px !important
}

.perspective-1500, .perspective-1500-focus:focus, .perspective-1500-hover:hover {
    --x-perspective: 1500px !important
}

.translate-y-0, .translate-y-0-focus:focus, .translate-y-0-hover:hover {
    --x-translate-y: 0 !important
}

.translate-y-1, .translate-y-1-focus:focus, .translate-y-1-hover:hover {
    --x-translate-y: 0.25rem !important
}

.translate-y-2, .translate-y-2-focus:focus, .translate-y-2-hover:hover {
    --x-translate-y: 0.5rem !important
}

.translate-y-3, .translate-y-3-focus:focus, .translate-y-3-hover:hover {
    --x-translate-y: 0.75rem !important
}

.translate-y-4, .translate-y-4-focus:focus, .translate-y-4-hover:hover {
    --x-translate-y: 1rem !important
}

.translate-y-5, .translate-y-5-focus:focus, .translate-y-5-hover:hover {
    --x-translate-y: 1.25rem !important
}

.translate-y-6, .translate-y-6-focus:focus, .translate-y-6-hover:hover {
    --x-translate-y: 1.5rem !important
}

.translate-y-7, .translate-y-7-focus:focus, .translate-y-7-hover:hover {
    --x-translate-y: 1.75rem !important
}

.translate-y-8, .translate-y-8-focus:focus, .translate-y-8-hover:hover {
    --x-translate-y: 2rem !important
}

.translate-y-10, .translate-y-10-focus:focus, .translate-y-10-hover:hover {
    --x-translate-y: 2.5rem !important
}

.translate-y-12, .translate-y-12-focus:focus, .translate-y-12-hover:hover {
    --x-translate-y: 3rem !important
}

.translate-y-14, .translate-y-14-focus:focus, .translate-y-14-hover:hover {
    --x-translate-y: 3.5rem !important
}

.translate-y-16, .translate-y-16-focus:focus, .translate-y-16-hover:hover {
    --x-translate-y: 4rem !important
}

.translate-y-18, .translate-y-18-focus:focus, .translate-y-18-hover:hover {
    --x-translate-y: 4.5rem !important
}

.translate-y-20, .translate-y-20-focus:focus, .translate-y-20-hover:hover {
    --x-translate-y: 5rem !important
}

.translate-y-24, .translate-y-24-focus:focus, .translate-y-24-hover:hover {
    --x-translate-y: 6rem !important
}

.translate-y-32, .translate-y-32-focus:focus, .translate-y-32-hover:hover {
    --x-translate-y: 8rem !important
}

.translate-y-40, .translate-y-40-focus:focus, .translate-y-40-hover:hover {
    --x-translate-y: 10rem !important
}

.translate-y-48, .translate-y-48-focus:focus, .translate-y-48-hover:hover {
    --x-translate-y: 12rem !important
}

.translate-y-56, .translate-y-56-focus:focus, .translate-y-56-hover:hover {
    --x-translate-y: 14rem !important
}

.translate-y-64, .translate-y-64-focus:focus, .translate-y-64-hover:hover {
    --x-translate-y: 16rem !important
}

.translate-y-72, .translate-y-72-focus:focus, .translate-y-72-hover:hover {
    --x-translate-y: 18rem !important
}

.translate-y-80, .translate-y-80-focus:focus, .translate-y-80-hover:hover {
    --x-translate-y: 20rem !important
}

.translate-y-88, .translate-y-88-focus:focus, .translate-y-88-hover:hover {
    --x-translate-y: 22rem !important
}

.translate-y-96, .translate-y-96-focus:focus, .translate-y-96-hover:hover {
    --x-translate-y: 24rem !important
}

.translate-y-px, .translate-y-px-focus:focus, .translate-y-px-hover:hover {
    --x-translate-y: 1px !important
}

.translate-y-1\/2, .translate-y-1\/2-focus:focus, .translate-y-1\/2-hover:hover {
    --x-translate-y: 50% !important
}

.translate-y-1\/3, .translate-y-1\/3-focus:focus, .translate-y-1\/3-hover:hover {
    --x-translate-y: 33.333333% !important
}

.translate-y-1\/4, .translate-y-1\/4-focus:focus, .translate-y-1\/4-hover:hover {
    --x-translate-y: 25% !important
}

.translate-y-1\/5, .translate-y-1\/5-focus:focus, .translate-y-1\/5-hover:hover {
    --x-translate-y: 20% !important
}

.translate-y-2\/3, .translate-y-2\/3-focus:focus, .translate-y-2\/3-hover:hover {
    --x-translate-y: 66.666667% !important
}

.translate-y-2\/5, .translate-y-2\/5-focus:focus, .translate-y-2\/5-hover:hover {
    --x-translate-y: 50% !important
}

.translate-y-3\/4, .translate-y-3\/4-focus:focus, .translate-y-3\/4-hover:hover {
    --x-translate-y: 75% !important
}

.translate-y-full, .translate-y-full-focus:focus, .translate-y-full-hover:hover {
    --x-translate-y: 100% !important
}

.translate-y-n1, .translate-y-n1-focus:focus, .translate-y-n1-hover:hover {
    --x-translate-y: -0.25rem !important
}

.translate-y-n2, .translate-y-n2-focus:focus, .translate-y-n2-hover:hover {
    --x-translate-y: -0.5rem !important
}

.translate-y-n3, .translate-y-n3-focus:focus, .translate-y-n3-hover:hover {
    --x-translate-y: -0.75rem !important
}

.translate-y-n4, .translate-y-n4-focus:focus, .translate-y-n4-hover:hover {
    --x-translate-y: -1rem !important
}

.translate-y-n5, .translate-y-n5-focus:focus, .translate-y-n5-hover:hover {
    --x-translate-y: -1.25rem !important
}

.translate-y-n6, .translate-y-n6-focus:focus, .translate-y-n6-hover:hover {
    --x-translate-y: -1.5rem !important
}

.translate-y-n7, .translate-y-n7-focus:focus, .translate-y-n7-hover:hover {
    --x-translate-y: -1.75rem !important
}

.translate-y-n8, .translate-y-n8-focus:focus, .translate-y-n8-hover:hover {
    --x-translate-y: -2rem !important
}

.translate-y-n10, .translate-y-n10-focus:focus, .translate-y-n10-hover:hover {
    --x-translate-y: -2.5rem !important
}

.translate-y-n12, .translate-y-n12-focus:focus, .translate-y-n12-hover:hover {
    --x-translate-y: -3rem !important
}

.translate-y-n14, .translate-y-n14-focus:focus, .translate-y-n14-hover:hover {
    --x-translate-y: -3.5rem !important
}

.translate-y-n16, .translate-y-n16-focus:focus, .translate-y-n16-hover:hover {
    --x-translate-y: -4rem !important
}

.translate-y-n18, .translate-y-n18-focus:focus, .translate-y-n18-hover:hover {
    --x-translate-y: -4.5rem !important
}

.translate-y-n20, .translate-y-n20-focus:focus, .translate-y-n20-hover:hover {
    --x-translate-y: -5rem !important
}

.translate-y-n24, .translate-y-n24-focus:focus, .translate-y-n24-hover:hover {
    --x-translate-y: -6rem !important
}

.translate-y-n32, .translate-y-n32-focus:focus, .translate-y-n32-hover:hover {
    --x-translate-y: -8rem !important
}

.translate-y-n40, .translate-y-n40-focus:focus, .translate-y-n40-hover:hover {
    --x-translate-y: -10rem !important
}

.translate-y-n48, .translate-y-n48-focus:focus, .translate-y-n48-hover:hover {
    --x-translate-y: -12rem !important
}

.translate-y-n56, .translate-y-n56-focus:focus, .translate-y-n56-hover:hover {
    --x-translate-y: -14rem !important
}

.translate-y-n64, .translate-y-n64-focus:focus, .translate-y-n64-hover:hover {
    --x-translate-y: -16rem !important
}

.translate-y-n72, .translate-y-n72-focus:focus, .translate-y-n72-hover:hover {
    --x-translate-y: -18rem !important
}

.translate-y-n80, .translate-y-n80-focus:focus, .translate-y-n80-hover:hover {
    --x-translate-y: -20rem !important
}

.translate-y-n88, .translate-y-n88-focus:focus, .translate-y-n88-hover:hover {
    --x-translate-y: -22rem !important
}

.translate-y-n96, .translate-y-n96-focus:focus, .translate-y-n96-hover:hover {
    --x-translate-y: -24rem !important
}

.translate-y-npx, .translate-y-npx-focus:focus, .translate-y-npx-hover:hover {
    --x-translate-y: -1px !important
}

.translate-y-n1\/2, .translate-y-n1\/2-focus:focus, .translate-y-n1\/2-hover:hover {
    --x-translate-y: -50% !important
}

.translate-y-n1\/3, .translate-y-n1\/3-focus:focus, .translate-y-n1\/3-hover:hover {
    --x-translate-y: -33.333333% !important
}

.translate-y-n1\/4, .translate-y-n1\/4-focus:focus, .translate-y-n1\/4-hover:hover {
    --x-translate-y: -25% !important
}

.translate-y-n1\/5, .translate-y-n1\/5-focus:focus, .translate-y-n1\/5-hover:hover {
    --x-translate-y: -20% !important
}

.translate-y-n2\/3, .translate-y-n2\/3-focus:focus, .translate-y-n2\/3-hover:hover {
    --x-translate-y: -66.666667% !important
}

.translate-y-n2\/5, .translate-y-n2\/5-focus:focus, .translate-y-n2\/5-hover:hover {
    --x-translate-y: -50% !important
}

.translate-y-n3\/4, .translate-y-n3\/4-focus:focus, .translate-y-n3\/4-hover:hover {
    --x-translate-y: -75% !important
}

.translate-y-nfull, .translate-y-nfull-focus:focus, .translate-y-nfull-hover:hover {
    --x-translate-y: -100% !important
}

.translate-x-0, .translate-x-0-focus:focus, .translate-x-0-hover:hover {
    --x-translate-x: 0 !important
}

.translate-x-1, .translate-x-1-focus:focus, .translate-x-1-hover:hover {
    --x-translate-x: 0.25rem !important
}

.translate-x-2, .translate-x-2-focus:focus, .translate-x-2-hover:hover {
    --x-translate-x: 0.5rem !important
}

.translate-x-3, .translate-x-3-focus:focus, .translate-x-3-hover:hover {
    --x-translate-x: 0.75rem !important
}

.translate-x-4, .translate-x-4-focus:focus, .translate-x-4-hover:hover {
    --x-translate-x: 1rem !important
}

.translate-x-5, .translate-x-5-focus:focus, .translate-x-5-hover:hover {
    --x-translate-x: 1.25rem !important
}

.translate-x-6, .translate-x-6-focus:focus, .translate-x-6-hover:hover {
    --x-translate-x: 1.5rem !important
}

.translate-x-7, .translate-x-7-focus:focus, .translate-x-7-hover:hover {
    --x-translate-x: 1.75rem !important
}

.translate-x-8, .translate-x-8-focus:focus, .translate-x-8-hover:hover {
    --x-translate-x: 2rem !important
}

.translate-x-10, .translate-x-10-focus:focus, .translate-x-10-hover:hover {
    --x-translate-x: 2.5rem !important
}

.translate-x-12, .translate-x-12-focus:focus, .translate-x-12-hover:hover {
    --x-translate-x: 3rem !important
}

.translate-x-14, .translate-x-14-focus:focus, .translate-x-14-hover:hover {
    --x-translate-x: 3.5rem !important
}

.translate-x-16, .translate-x-16-focus:focus, .translate-x-16-hover:hover {
    --x-translate-x: 4rem !important
}

.translate-x-18, .translate-x-18-focus:focus, .translate-x-18-hover:hover {
    --x-translate-x: 4.5rem !important
}

.translate-x-20, .translate-x-20-focus:focus, .translate-x-20-hover:hover {
    --x-translate-x: 5rem !important
}

.translate-x-24, .translate-x-24-focus:focus, .translate-x-24-hover:hover {
    --x-translate-x: 6rem !important
}

.translate-x-32, .translate-x-32-focus:focus, .translate-x-32-hover:hover {
    --x-translate-x: 8rem !important
}

.translate-x-40, .translate-x-40-focus:focus, .translate-x-40-hover:hover {
    --x-translate-x: 10rem !important
}

.translate-x-48, .translate-x-48-focus:focus, .translate-x-48-hover:hover {
    --x-translate-x: 12rem !important
}

.translate-x-56, .translate-x-56-focus:focus, .translate-x-56-hover:hover {
    --x-translate-x: 14rem !important
}

.translate-x-64, .translate-x-64-focus:focus, .translate-x-64-hover:hover {
    --x-translate-x: 16rem !important
}

.translate-x-72, .translate-x-72-focus:focus, .translate-x-72-hover:hover {
    --x-translate-x: 18rem !important
}

.translate-x-80, .translate-x-80-focus:focus, .translate-x-80-hover:hover {
    --x-translate-x: 20rem !important
}

.translate-x-88, .translate-x-88-focus:focus, .translate-x-88-hover:hover {
    --x-translate-x: 22rem !important
}

.translate-x-96, .translate-x-96-focus:focus, .translate-x-96-hover:hover {
    --x-translate-x: 24rem !important
}

.translate-x-px, .translate-x-px-focus:focus, .translate-x-px-hover:hover {
    --x-translate-x: 1px !important
}

.translate-x-1\/2, .translate-x-1\/2-focus:focus, .translate-x-1\/2-hover:hover {
    --x-translate-x: 50% !important
}

.translate-x-1\/3, .translate-x-1\/3-focus:focus, .translate-x-1\/3-hover:hover {
    --x-translate-x: 33.333333% !important
}

.translate-x-1\/4, .translate-x-1\/4-focus:focus, .translate-x-1\/4-hover:hover {
    --x-translate-x: 25% !important
}

.translate-x-1\/5, .translate-x-1\/5-focus:focus, .translate-x-1\/5-hover:hover {
    --x-translate-x: 20% !important
}

.translate-x-2\/3, .translate-x-2\/3-focus:focus, .translate-x-2\/3-hover:hover {
    --x-translate-x: 66.666667% !important
}

.translate-x-2\/5, .translate-x-2\/5-focus:focus, .translate-x-2\/5-hover:hover {
    --x-translate-x: 50% !important
}

.translate-x-3\/4, .translate-x-3\/4-focus:focus, .translate-x-3\/4-hover:hover {
    --x-translate-x: 75% !important
}

.translate-x-full, .translate-x-full-focus:focus, .translate-x-full-hover:hover {
    --x-translate-x: 100% !important
}

.translate-x-n1, .translate-x-n1-focus:focus, .translate-x-n1-hover:hover {
    --x-translate-x: -0.25rem !important
}

.translate-x-n2, .translate-x-n2-focus:focus, .translate-x-n2-hover:hover {
    --x-translate-x: -0.5rem !important
}

.translate-x-n3, .translate-x-n3-focus:focus, .translate-x-n3-hover:hover {
    --x-translate-x: -0.75rem !important
}

.translate-x-n4, .translate-x-n4-focus:focus, .translate-x-n4-hover:hover {
    --x-translate-x: -1rem !important
}

.translate-x-n5, .translate-x-n5-focus:focus, .translate-x-n5-hover:hover {
    --x-translate-x: -1.25rem !important
}

.translate-x-n6, .translate-x-n6-focus:focus, .translate-x-n6-hover:hover {
    --x-translate-x: -1.5rem !important
}

.translate-x-n7, .translate-x-n7-focus:focus, .translate-x-n7-hover:hover {
    --x-translate-x: -1.75rem !important
}

.translate-x-n8, .translate-x-n8-focus:focus, .translate-x-n8-hover:hover {
    --x-translate-x: -2rem !important
}

.translate-x-n10, .translate-x-n10-focus:focus, .translate-x-n10-hover:hover {
    --x-translate-x: -2.5rem !important
}

.translate-x-n12, .translate-x-n12-focus:focus, .translate-x-n12-hover:hover {
    --x-translate-x: -3rem !important
}

.translate-x-n14, .translate-x-n14-focus:focus, .translate-x-n14-hover:hover {
    --x-translate-x: -3.5rem !important
}

.translate-x-n16, .translate-x-n16-focus:focus, .translate-x-n16-hover:hover {
    --x-translate-x: -4rem !important
}

.translate-x-n18, .translate-x-n18-focus:focus, .translate-x-n18-hover:hover {
    --x-translate-x: -4.5rem !important
}

.translate-x-n20, .translate-x-n20-focus:focus, .translate-x-n20-hover:hover {
    --x-translate-x: -5rem !important
}

.translate-x-n24, .translate-x-n24-focus:focus, .translate-x-n24-hover:hover {
    --x-translate-x: -6rem !important
}

.translate-x-n32, .translate-x-n32-focus:focus, .translate-x-n32-hover:hover {
    --x-translate-x: -8rem !important
}

.translate-x-n40, .translate-x-n40-focus:focus, .translate-x-n40-hover:hover {
    --x-translate-x: -10rem !important
}

.translate-x-n48, .translate-x-n48-focus:focus, .translate-x-n48-hover:hover {
    --x-translate-x: -12rem !important
}

.translate-x-n56, .translate-x-n56-focus:focus, .translate-x-n56-hover:hover {
    --x-translate-x: -14rem !important
}

.translate-x-n64, .translate-x-n64-focus:focus, .translate-x-n64-hover:hover {
    --x-translate-x: -16rem !important
}

.translate-x-n72, .translate-x-n72-focus:focus, .translate-x-n72-hover:hover {
    --x-translate-x: -18rem !important
}

.translate-x-n80, .translate-x-n80-focus:focus, .translate-x-n80-hover:hover {
    --x-translate-x: -20rem !important
}

.translate-x-n88, .translate-x-n88-focus:focus, .translate-x-n88-hover:hover {
    --x-translate-x: -22rem !important
}

.translate-x-n96, .translate-x-n96-focus:focus, .translate-x-n96-hover:hover {
    --x-translate-x: -24rem !important
}

.translate-x-npx, .translate-x-npx-focus:focus, .translate-x-npx-hover:hover {
    --x-translate-x: -1px !important
}

.translate-x-n1\/2, .translate-x-n1\/2-focus:focus, .translate-x-n1\/2-hover:hover {
    --x-translate-x: -50% !important
}

.translate-x-n1\/3, .translate-x-n1\/3-focus:focus, .translate-x-n1\/3-hover:hover {
    --x-translate-x: -33.333333% !important
}

.translate-x-n1\/4, .translate-x-n1\/4-focus:focus, .translate-x-n1\/4-hover:hover {
    --x-translate-x: -25% !important
}

.translate-x-n1\/5, .translate-x-n1\/5-focus:focus, .translate-x-n1\/5-hover:hover {
    --x-translate-x: -20% !important
}

.translate-x-n2\/3, .translate-x-n2\/3-focus:focus, .translate-x-n2\/3-hover:hover {
    --x-translate-x: -66.666667% !important
}

.translate-x-n2\/5, .translate-x-n2\/5-focus:focus, .translate-x-n2\/5-hover:hover {
    --x-translate-x: -50% !important
}

.translate-x-n3\/4, .translate-x-n3\/4-focus:focus, .translate-x-n3\/4-hover:hover {
    --x-translate-x: -75% !important
}

.translate-x-nfull, .translate-x-nfull-focus:focus, .translate-x-nfull-hover:hover {
    --x-translate-x: -100% !important
}

.skew-y-0, .skew-y-0-focus:focus, .skew-y-0-hover:hover {
    --x-skew-y: 0deg !important
}

.skew-y-1, .skew-y-1-focus:focus, .skew-y-1-hover:hover {
    --x-skew-y: 1deg !important
}

.skew-y-2, .skew-y-2-focus:focus, .skew-y-2-hover:hover {
    --x-skew-y: 2deg !important
}

.skew-y-3, .skew-y-3-focus:focus, .skew-y-3-hover:hover {
    --x-skew-y: 3deg !important
}

.skew-y-6, .skew-y-6-focus:focus, .skew-y-6-hover:hover {
    --x-skew-y: 6deg !important
}

.skew-y-12, .skew-y-12-focus:focus, .skew-y-12-hover:hover {
    --x-skew-y: 12deg !important
}

.skew-y-n1, .skew-y-n1-focus:focus, .skew-y-n1-hover:hover {
    --x-skew-y: -1deg !important
}

.skew-y-n2, .skew-y-n2-focus:focus, .skew-y-n2-hover:hover {
    --x-skew-y: -2deg !important
}

.skew-y-n3, .skew-y-n3-focus:focus, .skew-y-n3-hover:hover {
    --x-skew-y: -3deg !important
}

.skew-y-n6, .skew-y-n6-focus:focus, .skew-y-n6-hover:hover {
    --x-skew-y: -6deg !important
}

.skew-y-n12, .skew-y-n12-focus:focus, .skew-y-n12-hover:hover {
    --x-skew-y: -12deg !important
}

.skew-x-0, .skew-x-0-focus:focus, .skew-x-0-hover:hover {
    --x-skew-x: 0deg !important
}

.skew-x-1, .skew-x-1-focus:focus, .skew-x-1-hover:hover {
    --x-skew-x: 1deg !important
}

.skew-x-2, .skew-x-2-focus:focus, .skew-x-2-hover:hover {
    --x-skew-x: 2deg !important
}

.skew-x-3, .skew-x-3-focus:focus, .skew-x-3-hover:hover {
    --x-skew-x: 3deg !important
}

.skew-x-6, .skew-x-6-focus:focus, .skew-x-6-hover:hover {
    --x-skew-x: 6deg !important
}

.skew-x-12, .skew-x-12-focus:focus, .skew-x-12-hover:hover {
    --x-skew-x: 12deg !important
}

.skew-x-n1, .skew-x-n1-focus:focus, .skew-x-n1-hover:hover {
    --x-skew-x: -1deg !important
}

.skew-x-n2, .skew-x-n2-focus:focus, .skew-x-n2-hover:hover {
    --x-skew-x: -2deg !important
}

.skew-x-n3, .skew-x-n3-focus:focus, .skew-x-n3-hover:hover {
    --x-skew-x: -3deg !important
}

.skew-x-n6, .skew-x-n6-focus:focus, .skew-x-n6-hover:hover {
    --x-skew-x: -6deg !important
}

.skew-x-n12, .skew-x-n12-focus:focus, .skew-x-n12-hover:hover {
    --x-skew-x: -12deg !important
}

@media (min-width:576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .overflow-sm-auto {
        overflow: auto !important
    }

    .overflow-sm-hidden {
        overflow: hidden !important
    }

    .overflow-sm-visible {
        overflow: visible !important
    }

    .overflow-sm-scroll {
        overflow: scroll !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .shadow-sm, .shadow-sm-focus:focus, .shadow-sm-hover:hover {
        box-shadow: 0 3px 3px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-sm-1, .shadow-sm-1-focus:focus, .shadow-sm-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-sm-2, .shadow-sm-2-focus:focus, .shadow-sm-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-sm-3, .shadow-sm-3-focus:focus, .shadow-sm-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-sm-4, .shadow-sm-4-focus:focus, .shadow-sm-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-sm-5, .shadow-sm-5-focus:focus, .shadow-sm-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-sm-6, .shadow-sm-6-focus:focus, .shadow-sm-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-sm-inset, .shadow-sm-inset-focus:focus, .shadow-sm-inset-hover:hover {
        box-shadow: inset 0 1px 2px rgba(0,0,0,.075) !important
    }

    .shadow-sm-focus, .shadow-sm-focus-focus:focus, .shadow-sm-focus-hover:hover, .shadow-sm-outline, .shadow-sm-outline-focus:focus, .shadow-sm-outline-hover:hover {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5) !important
    }

    .shadow-sm-none, .shadow-sm-none-focus:focus, .shadow-sm-none-hover:hover {
        box-shadow: none !important
    }

    .position-sm-static {
        position: static !important
    }

    .position-sm-relative {
        position: relative !important
    }

    .position-sm-absolute {
        position: absolute !important
    }

    .position-sm-fixed {
        position: fixed !important
    }

    .position-sm-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-sm-0 {
        top: 0 !important
    }

    .top-sm-1 {
        top: .25rem !important
    }

    .top-sm-2 {
        top: .5rem !important
    }

    .top-sm-3 {
        top: .75rem !important
    }

    .top-sm-4 {
        top: 1rem !important
    }

    .top-sm-5 {
        top: 1.25rem !important
    }

    .top-sm-6 {
        top: 1.5rem !important
    }

    .top-sm-7 {
        top: 1.75rem !important
    }

    .top-sm-8 {
        top: 2rem !important
    }

    .top-sm-10 {
        top: 2.5rem !important
    }

    .top-sm-12 {
        top: 3rem !important
    }

    .top-sm-14 {
        top: 3.5rem !important
    }

    .top-sm-16 {
        top: 4rem !important
    }

    .top-sm-18 {
        top: 4.5rem !important
    }

    .top-sm-20 {
        top: 5rem !important
    }

    .top-sm-24 {
        top: 6rem !important
    }

    .top-sm-32 {
        top: 8rem !important
    }

    .top-sm-40 {
        top: 10rem !important
    }

    .top-sm-48 {
        top: 12rem !important
    }

    .top-sm-56 {
        top: 14rem !important
    }

    .top-sm-64 {
        top: 16rem !important
    }

    .top-sm-72 {
        top: 18rem !important
    }

    .top-sm-80 {
        top: 20rem !important
    }

    .top-sm-88 {
        top: 22rem !important
    }

    .top-sm-96 {
        top: 24rem !important
    }

    .top-sm-px {
        top: 1px !important
    }

    .top-sm-1\/2 {
        top: 50% !important
    }

    .top-sm-full {
        top: 100% !important
    }

    .top-sm-auto {
        top: auto !important
    }

    .bottom-sm-0 {
        bottom: 0 !important
    }

    .bottom-sm-1 {
        bottom: .25rem !important
    }

    .bottom-sm-2 {
        bottom: .5rem !important
    }

    .bottom-sm-3 {
        bottom: .75rem !important
    }

    .bottom-sm-4 {
        bottom: 1rem !important
    }

    .bottom-sm-5 {
        bottom: 1.25rem !important
    }

    .bottom-sm-6 {
        bottom: 1.5rem !important
    }

    .bottom-sm-7 {
        bottom: 1.75rem !important
    }

    .bottom-sm-8 {
        bottom: 2rem !important
    }

    .bottom-sm-10 {
        bottom: 2.5rem !important
    }

    .bottom-sm-12 {
        bottom: 3rem !important
    }

    .bottom-sm-14 {
        bottom: 3.5rem !important
    }

    .bottom-sm-16 {
        bottom: 4rem !important
    }

    .bottom-sm-18 {
        bottom: 4.5rem !important
    }

    .bottom-sm-20 {
        bottom: 5rem !important
    }

    .bottom-sm-24 {
        bottom: 6rem !important
    }

    .bottom-sm-32 {
        bottom: 8rem !important
    }

    .bottom-sm-40 {
        bottom: 10rem !important
    }

    .bottom-sm-48 {
        bottom: 12rem !important
    }

    .bottom-sm-56 {
        bottom: 14rem !important
    }

    .bottom-sm-64 {
        bottom: 16rem !important
    }

    .bottom-sm-72 {
        bottom: 18rem !important
    }

    .bottom-sm-80 {
        bottom: 20rem !important
    }

    .bottom-sm-88 {
        bottom: 22rem !important
    }

    .bottom-sm-96 {
        bottom: 24rem !important
    }

    .bottom-sm-px {
        bottom: 1px !important
    }

    .bottom-sm-1\/2 {
        bottom: 50% !important
    }

    .bottom-sm-full {
        bottom: 100% !important
    }

    .bottom-sm-auto {
        bottom: auto !important
    }

    .start-sm-0 {
        left: 0 !important
    }

    .start-sm-1 {
        left: .25rem !important
    }

    .start-sm-2 {
        left: .5rem !important
    }

    .start-sm-3 {
        left: .75rem !important
    }

    .start-sm-4 {
        left: 1rem !important
    }

    .start-sm-5 {
        left: 1.25rem !important
    }

    .start-sm-6 {
        left: 1.5rem !important
    }

    .start-sm-7 {
        left: 1.75rem !important
    }

    .start-sm-8 {
        left: 2rem !important
    }

    .start-sm-10 {
        left: 2.5rem !important
    }

    .start-sm-12 {
        left: 3rem !important
    }

    .start-sm-14 {
        left: 3.5rem !important
    }

    .start-sm-16 {
        left: 4rem !important
    }

    .start-sm-18 {
        left: 4.5rem !important
    }

    .start-sm-20 {
        left: 5rem !important
    }

    .start-sm-24 {
        left: 6rem !important
    }

    .start-sm-32 {
        left: 8rem !important
    }

    .start-sm-40 {
        left: 10rem !important
    }

    .start-sm-48 {
        left: 12rem !important
    }

    .start-sm-56 {
        left: 14rem !important
    }

    .start-sm-64 {
        left: 16rem !important
    }

    .start-sm-72 {
        left: 18rem !important
    }

    .start-sm-80 {
        left: 20rem !important
    }

    .start-sm-88 {
        left: 22rem !important
    }

    .start-sm-96 {
        left: 24rem !important
    }

    .start-sm-px {
        left: 1px !important
    }

    .start-sm-1\/2 {
        left: 50% !important
    }

    .start-sm-full {
        left: 100% !important
    }

    .start-sm-auto {
        left: auto !important
    }

    .end-sm-0 {
        right: 0 !important
    }

    .end-sm-1 {
        right: .25rem !important
    }

    .end-sm-2 {
        right: .5rem !important
    }

    .end-sm-3 {
        right: .75rem !important
    }

    .end-sm-4 {
        right: 1rem !important
    }

    .end-sm-5 {
        right: 1.25rem !important
    }

    .end-sm-6 {
        right: 1.5rem !important
    }

    .end-sm-7 {
        right: 1.75rem !important
    }

    .end-sm-8 {
        right: 2rem !important
    }

    .end-sm-10 {
        right: 2.5rem !important
    }

    .end-sm-12 {
        right: 3rem !important
    }

    .end-sm-14 {
        right: 3.5rem !important
    }

    .end-sm-16 {
        right: 4rem !important
    }

    .end-sm-18 {
        right: 4.5rem !important
    }

    .end-sm-20 {
        right: 5rem !important
    }

    .end-sm-24 {
        right: 6rem !important
    }

    .end-sm-32 {
        right: 8rem !important
    }

    .end-sm-40 {
        right: 10rem !important
    }

    .end-sm-48 {
        right: 12rem !important
    }

    .end-sm-56 {
        right: 14rem !important
    }

    .end-sm-64 {
        right: 16rem !important
    }

    .end-sm-72 {
        right: 18rem !important
    }

    .end-sm-80 {
        right: 20rem !important
    }

    .end-sm-88 {
        right: 22rem !important
    }

    .end-sm-96 {
        right: 24rem !important
    }

    .end-sm-px {
        right: 1px !important
    }

    .end-sm-1\/2 {
        right: 50% !important
    }

    .end-sm-full {
        right: 100% !important
    }

    .end-sm-auto {
        right: auto !important
    }

    .border-top-sm-0, .border-top-sm-0-focus:focus, .border-top-sm-0-hover:hover {
        border-top-width: 0 !important
    }

    .border-top-sm, .border-top-sm-focus:focus, .border-top-sm-hover:hover {
        border-top-width: 1px !important
    }

    .border-top-sm-2, .border-top-sm-2-focus:focus, .border-top-sm-2-hover:hover {
        border-top-width: 2px !important
    }

    .border-top-sm-3, .border-top-sm-3-focus:focus, .border-top-sm-3-hover:hover {
        border-top-width: 3px !important
    }

    .border-top-sm-4, .border-top-sm-4-focus:focus, .border-top-sm-4-hover:hover {
        border-top-width: 4px !important
    }

    .border-top-sm-5, .border-top-sm-5-focus:focus, .border-top-sm-5-hover:hover {
        border-top-width: 5px !important
    }

    .border-top-sm-6, .border-top-sm-6-focus:focus, .border-top-sm-6-hover:hover {
        border-top-width: 6px !important
    }

    .border-top-sm-7, .border-top-sm-7-focus:focus, .border-top-sm-7-hover:hover {
        border-top-width: 7px !important
    }

    .border-top-sm-8, .border-top-sm-8-focus:focus, .border-top-sm-8-hover:hover {
        border-top-width: 8px !important
    }

    .border-end-sm-0, .border-end-sm-0-focus:focus, .border-end-sm-0-hover:hover {
        border-right-width: 0 !important
    }

    .border-end-sm, .border-end-sm-focus:focus, .border-end-sm-hover:hover {
        border-right-width: 1px !important
    }

    .border-end-sm-2, .border-end-sm-2-focus:focus, .border-end-sm-2-hover:hover {
        border-right-width: 2px !important
    }

    .border-end-sm-3, .border-end-sm-3-focus:focus, .border-end-sm-3-hover:hover {
        border-right-width: 3px !important
    }

    .border-end-sm-4, .border-end-sm-4-focus:focus, .border-end-sm-4-hover:hover {
        border-right-width: 4px !important
    }

    .border-end-sm-5, .border-end-sm-5-focus:focus, .border-end-sm-5-hover:hover {
        border-right-width: 5px !important
    }

    .border-end-sm-6, .border-end-sm-6-focus:focus, .border-end-sm-6-hover:hover {
        border-right-width: 6px !important
    }

    .border-end-sm-7, .border-end-sm-7-focus:focus, .border-end-sm-7-hover:hover {
        border-right-width: 7px !important
    }

    .border-end-sm-8, .border-end-sm-8-focus:focus, .border-end-sm-8-hover:hover {
        border-right-width: 8px !important
    }

    .border-bottom-sm-0, .border-bottom-sm-0-focus:focus, .border-bottom-sm-0-hover:hover {
        border-bottom-width: 0 !important
    }

    .border-bottom-sm, .border-bottom-sm-focus:focus, .border-bottom-sm-hover:hover {
        border-bottom-width: 1px !important
    }

    .border-bottom-sm-2, .border-bottom-sm-2-focus:focus, .border-bottom-sm-2-hover:hover {
        border-bottom-width: 2px !important
    }

    .border-bottom-sm-3, .border-bottom-sm-3-focus:focus, .border-bottom-sm-3-hover:hover {
        border-bottom-width: 3px !important
    }

    .border-bottom-sm-4, .border-bottom-sm-4-focus:focus, .border-bottom-sm-4-hover:hover {
        border-bottom-width: 4px !important
    }

    .border-bottom-sm-5, .border-bottom-sm-5-focus:focus, .border-bottom-sm-5-hover:hover {
        border-bottom-width: 5px !important
    }

    .border-bottom-sm-6, .border-bottom-sm-6-focus:focus, .border-bottom-sm-6-hover:hover {
        border-bottom-width: 6px !important
    }

    .border-bottom-sm-7, .border-bottom-sm-7-focus:focus, .border-bottom-sm-7-hover:hover {
        border-bottom-width: 7px !important
    }

    .border-bottom-sm-8, .border-bottom-sm-8-focus:focus, .border-bottom-sm-8-hover:hover {
        border-bottom-width: 8px !important
    }

    .border-start-sm-0, .border-start-sm-0-focus:focus, .border-start-sm-0-hover:hover {
        border-left-width: 0 !important
    }

    .border-start-sm, .border-start-sm-focus:focus, .border-start-sm-hover:hover {
        border-left-width: 1px !important
    }

    .border-start-sm-2, .border-start-sm-2-focus:focus, .border-start-sm-2-hover:hover {
        border-left-width: 2px !important
    }

    .border-start-sm-3, .border-start-sm-3-focus:focus, .border-start-sm-3-hover:hover {
        border-left-width: 3px !important
    }

    .border-start-sm-4, .border-start-sm-4-focus:focus, .border-start-sm-4-hover:hover {
        border-left-width: 4px !important
    }

    .border-start-sm-5, .border-start-sm-5-focus:focus, .border-start-sm-5-hover:hover {
        border-left-width: 5px !important
    }

    .border-start-sm-6, .border-start-sm-6-focus:focus, .border-start-sm-6-hover:hover {
        border-left-width: 6px !important
    }

    .border-start-sm-7, .border-start-sm-7-focus:focus, .border-start-sm-7-hover:hover {
        border-left-width: 7px !important
    }

    .border-start-sm-8, .border-start-sm-8-focus:focus, .border-start-sm-8-hover:hover {
        border-left-width: 8px !important
    }

    .border-sm-primary, .border-sm-primary-focus:focus, .border-sm-primary-hover:hover {
        border-color: #5c60f5 !important
    }

    .border-sm-primary-focus-within:focus-within {
        border-color: #5c60f5 !important
    }

    .border-sm-secondary, .border-sm-secondary-focus:focus, .border-sm-secondary-hover:hover {
        border-color: #cfd6df !important
    }

    .border-sm-secondary-focus-within:focus-within {
        border-color: #cfd6df !important
    }

    .border-sm-tertiary, .border-sm-tertiary-focus:focus, .border-sm-tertiary-hover:hover {
        border-color: #ff579a !important
    }

    .border-sm-tertiary-focus-within:focus-within {
        border-color: #ff579a !important
    }

    .border-sm-success, .border-sm-success-focus:focus, .border-sm-success-hover:hover {
        border-color: #0c8 !important
    }

    .border-sm-success-focus-within:focus-within {
        border-color: #0c8 !important
    }

    .border-sm-info, .border-sm-info-focus:focus, .border-sm-info-hover:hover {
        border-color: #00d4ff !important
    }

    .border-sm-info-focus-within:focus-within {
        border-color: #00d4ff !important
    }

    .border-sm-warning, .border-sm-warning-focus:focus, .border-sm-warning-hover:hover {
        border-color: #ff8c00 !important
    }

    .border-sm-warning-focus-within:focus-within {
        border-color: #ff8c00 !important
    }

    .border-sm-danger, .border-sm-danger-focus:focus, .border-sm-danger-hover:hover {
        border-color: #f36 !important
    }

    .border-sm-danger-focus-within:focus-within {
        border-color: #f36 !important
    }

    .border-sm-white, .border-sm-white-focus:focus, .border-sm-white-hover:hover {
        border-color: #fff !important
    }

    .border-sm-white-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-sm-light, .border-sm-light-focus:focus, .border-sm-light-hover:hover {
        border-color: #fff !important
    }

    .border-sm-light-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-sm-dark, .border-sm-dark-focus:focus, .border-sm-dark-hover:hover {
        border-color: #16192c !important
    }

    .border-sm-dark-focus-within:focus-within {
        border-color: #16192c !important
    }

    .border-sm-transparent, .border-sm-transparent-focus:focus, .border-sm-transparent-hover:hover {
        border-color: transparent !important
    }

    .border-sm-transparent-focus-within:focus-within {
        border-color: transparent !important
    }

    .border-sm-current, .border-sm-current-focus:focus, .border-sm-current-hover:hover {
        border-color: currentColor !important
    }

    .border-sm-current-focus-within:focus-within {
        border-color: currentColor !important
    }

    .border-sm-base, .border-sm-base-focus:focus, .border-sm-base-hover:hover {
        border-color: #e7eaf0 !important
    }

    .border-sm-base-focus-within:focus-within {
        border-color: #e7eaf0 !important
    }

    .border-sm-body, .border-sm-body-focus:focus, .border-sm-body-hover:hover {
        border-color: #fff !important
    }

    .border-sm-body-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-sm-card, .border-sm-card-focus:focus, .border-sm-card-hover:hover {
        border-color: #fff !important
    }

    .border-sm-card-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-sm-black, .border-sm-black-focus:focus, .border-sm-black-hover:hover {
        border-color: #000 !important
    }

    .border-sm-black-focus-within:focus-within {
        border-color: #000 !important
    }

    .border-sm-0, .border-sm-0-focus:focus, .border-sm-0-hover:hover {
        border-width: 0 !important
    }

    .border-sm, .border-sm-focus:focus, .border-sm-hover:hover {
        border-width: 1px !important
    }

    .border-sm-2, .border-sm-2-focus:focus, .border-sm-2-hover:hover {
        border-width: 2px !important
    }

    .border-sm-3, .border-sm-3-focus:focus, .border-sm-3-hover:hover {
        border-width: 3px !important
    }

    .border-sm-4, .border-sm-4-focus:focus, .border-sm-4-hover:hover {
        border-width: 4px !important
    }

    .border-sm-5, .border-sm-5-focus:focus, .border-sm-5-hover:hover {
        border-width: 5px !important
    }

    .border-sm-6, .border-sm-6-focus:focus, .border-sm-6-hover:hover {
        border-width: 6px !important
    }

    .border-sm-7, .border-sm-7-focus:focus, .border-sm-7-hover:hover {
        border-width: 7px !important
    }

    .border-sm-8, .border-sm-8-focus:focus, .border-sm-8-hover:hover {
        border-width: 8px !important
    }

    .w-sm-0 {
        width: 0 !important
    }

    .w-sm-1 {
        width: .25rem !important
    }

    .w-sm-2 {
        width: .5rem !important
    }

    .w-sm-3 {
        width: .75rem !important
    }

    .w-sm-4 {
        width: 1rem !important
    }

    .w-sm-5 {
        width: 1.25rem !important
    }

    .w-sm-6 {
        width: 1.5rem !important
    }

    .w-sm-7 {
        width: 1.75rem !important
    }

    .w-sm-8 {
        width: 2rem !important
    }

    .w-sm-10 {
        width: 2.5rem !important
    }

    .w-sm-12 {
        width: 3rem !important
    }

    .w-sm-14 {
        width: 3.5rem !important
    }

    .w-sm-16 {
        width: 4rem !important
    }

    .w-sm-18 {
        width: 4.5rem !important
    }

    .w-sm-20 {
        width: 5rem !important
    }

    .w-sm-24 {
        width: 6rem !important
    }

    .w-sm-32 {
        width: 8rem !important
    }

    .w-sm-40 {
        width: 10rem !important
    }

    .w-sm-48 {
        width: 12rem !important
    }

    .w-sm-56 {
        width: 14rem !important
    }

    .w-sm-64 {
        width: 16rem !important
    }

    .w-sm-72 {
        width: 18rem !important
    }

    .w-sm-80 {
        width: 20rem !important
    }

    .w-sm-88 {
        width: 22rem !important
    }

    .w-sm-96 {
        width: 24rem !important
    }

    .w-sm-px {
        width: 1px !important
    }

    .w-sm-1\/2 {
        width: 50% !important
    }

    .w-sm-1\/3 {
        width: 33.3333333333% !important
    }

    .w-sm-2\/3 {
        width: 66.6666666667% !important
    }

    .w-sm-1\/4 {
        width: 25% !important
    }

    .w-sm-2\/4 {
        width: 50% !important
    }

    .w-sm-3\/4 {
        width: 75% !important
    }

    .w-sm-1\/5 {
        width: 20% !important
    }

    .w-sm-2\/5 {
        width: 40% !important
    }

    .w-sm-3\/5 {
        width: 60% !important
    }

    .w-sm-4\/5 {
        width: 80% !important
    }

    .w-sm-1\/6 {
        width: 16.6666666667% !important
    }

    .w-sm-2\/6 {
        width: 33.3333333333% !important
    }

    .w-sm-3\/6 {
        width: 50% !important
    }

    .w-sm-4\/6 {
        width: 66.6666666667% !important
    }

    .w-sm-5\/6 {
        width: 83.3333333333% !important
    }

    .w-sm-11\/10 {
        width: 110% !important
    }

    .w-sm-12\/10 {
        width: 120% !important
    }

    .w-sm-13\/10 {
        width: 130% !important
    }

    .w-sm-14\/10 {
        width: 140% !important
    }

    .w-sm-15\/10 {
        width: 150% !important
    }

    .w-sm-screen-sm {
        width: 640px !important
    }

    .w-sm-screen-md {
        width: 768px !important
    }

    .w-sm-screen-lg {
        width: 1024px !important
    }

    .w-sm-screen-xl {
        width: 1280px !important
    }

    .w-sm-screen-xxl {
        width: 1536px !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .w-sm-full {
        width: 100% !important
    }

    .w-sm-screen {
        width: 100vw !important
    }

    .w-sm-min {
        width: -webkit-min-content !important;
        width: -moz-min-content !important;
        width: min-content !important
    }

    .w-sm-max {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important
    }

    .max-w-sm-screen-sm {
        max-width: 640px !important
    }

    .max-w-sm-screen-md {
        max-width: 768px !important
    }

    .max-w-sm-screen-lg {
        max-width: 1024px !important
    }

    .max-w-sm-screen-xl {
        max-width: 1280px !important
    }

    .max-w-sm-screen-xxl {
        max-width: 1536px !important
    }

    .max-w-sm-0 {
        max-width: 0 !important
    }

    .max-w-sm-full {
        max-width: 100% !important
    }

    .max-w-sm-read {
        max-width: 65ch !important
    }

    .max-w-sm-min {
        max-width: -webkit-min-content !important;
        max-width: -moz-min-content !important;
        max-width: min-content !important
    }

    .max-w-sm-max {
        max-width: -webkit-max-content !important;
        max-width: -moz-max-content !important;
        max-width: max-content !important
    }

    .h-sm-0 {
        height: 0 !important
    }

    .h-sm-1 {
        height: .25rem !important
    }

    .h-sm-2 {
        height: .5rem !important
    }

    .h-sm-3 {
        height: .75rem !important
    }

    .h-sm-4 {
        height: 1rem !important
    }

    .h-sm-5 {
        height: 1.25rem !important
    }

    .h-sm-6 {
        height: 1.5rem !important
    }

    .h-sm-7 {
        height: 1.75rem !important
    }

    .h-sm-8 {
        height: 2rem !important
    }

    .h-sm-10 {
        height: 2.5rem !important
    }

    .h-sm-12 {
        height: 3rem !important
    }

    .h-sm-14 {
        height: 3.5rem !important
    }

    .h-sm-16 {
        height: 4rem !important
    }

    .h-sm-18 {
        height: 4.5rem !important
    }

    .h-sm-20 {
        height: 5rem !important
    }

    .h-sm-24 {
        height: 6rem !important
    }

    .h-sm-32 {
        height: 8rem !important
    }

    .h-sm-40 {
        height: 10rem !important
    }

    .h-sm-48 {
        height: 12rem !important
    }

    .h-sm-56 {
        height: 14rem !important
    }

    .h-sm-64 {
        height: 16rem !important
    }

    .h-sm-72 {
        height: 18rem !important
    }

    .h-sm-80 {
        height: 20rem !important
    }

    .h-sm-88 {
        height: 22rem !important
    }

    .h-sm-96 {
        height: 24rem !important
    }

    .h-sm-px {
        height: 1px !important
    }

    .h-sm-1\/2 {
        height: 50% !important
    }

    .h-sm-1\/3 {
        height: 33.3333333333% !important
    }

    .h-sm-2\/3 {
        height: 66.6666666667% !important
    }

    .h-sm-1\/4 {
        height: 25% !important
    }

    .h-sm-2\/4 {
        height: 50% !important
    }

    .h-sm-3\/4 {
        height: 75% !important
    }

    .h-sm-1\/5 {
        height: 20% !important
    }

    .h-sm-2\/5 {
        height: 40% !important
    }

    .h-sm-3\/5 {
        height: 60% !important
    }

    .h-sm-4\/5 {
        height: 80% !important
    }

    .h-sm-1\/6 {
        height: 16.6666666667% !important
    }

    .h-sm-2\/6 {
        height: 33.3333333333% !important
    }

    .h-sm-3\/6 {
        height: 50% !important
    }

    .h-sm-4\/6 {
        height: 66.6666666667% !important
    }

    .h-sm-5\/6 {
        height: 83.3333333333% !important
    }

    .h-sm-11\/10 {
        height: 110% !important
    }

    .h-sm-12\/10 {
        height: 120% !important
    }

    .h-sm-13\/10 {
        height: 130% !important
    }

    .h-sm-14\/10 {
        height: 140% !important
    }

    .h-sm-15\/10 {
        height: 150% !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .h-sm-full {
        height: 100% !important
    }

    .h-sm-screen {
        height: 100vh !important
    }

    .max-h-sm-0 {
        max-height: 0 !important
    }

    .max-h-sm-1 {
        max-height: .25rem !important
    }

    .max-h-sm-2 {
        max-height: .5rem !important
    }

    .max-h-sm-3 {
        max-height: .75rem !important
    }

    .max-h-sm-4 {
        max-height: 1rem !important
    }

    .max-h-sm-5 {
        max-height: 1.25rem !important
    }

    .max-h-sm-6 {
        max-height: 1.5rem !important
    }

    .max-h-sm-7 {
        max-height: 1.75rem !important
    }

    .max-h-sm-8 {
        max-height: 2rem !important
    }

    .max-h-sm-10 {
        max-height: 2.5rem !important
    }

    .max-h-sm-12 {
        max-height: 3rem !important
    }

    .max-h-sm-14 {
        max-height: 3.5rem !important
    }

    .max-h-sm-16 {
        max-height: 4rem !important
    }

    .max-h-sm-18 {
        max-height: 4.5rem !important
    }

    .max-h-sm-20 {
        max-height: 5rem !important
    }

    .max-h-sm-24 {
        max-height: 6rem !important
    }

    .max-h-sm-32 {
        max-height: 8rem !important
    }

    .max-h-sm-40 {
        max-height: 10rem !important
    }

    .max-h-sm-48 {
        max-height: 12rem !important
    }

    .max-h-sm-56 {
        max-height: 14rem !important
    }

    .max-h-sm-64 {
        max-height: 16rem !important
    }

    .max-h-sm-72 {
        max-height: 18rem !important
    }

    .max-h-sm-80 {
        max-height: 20rem !important
    }

    .max-h-sm-88 {
        max-height: 22rem !important
    }

    .max-h-sm-96 {
        max-height: 24rem !important
    }

    .max-h-sm-px {
        max-height: 1px !important
    }

    .max-h-sm-full {
        max-height: 100% !important
    }

    .max-h-sm-screen {
        max-height: 100vh !important
    }

    .flex-sm-1 {
        flex: 1 1 0% !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-initial {
        flex: 0 1 auto !important
    }

    .flex-sm-none {
        flex: none !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: .75rem !important
    }

    .gap-sm-4 {
        gap: 1rem !important
    }

    .gap-sm-5 {
        gap: 1.25rem !important
    }

    .gap-sm-6 {
        gap: 1.5rem !important
    }

    .gap-sm-7 {
        gap: 1.75rem !important
    }

    .gap-sm-8 {
        gap: 2rem !important
    }

    .gap-sm-10 {
        gap: 2.5rem !important
    }

    .gap-sm-12 {
        gap: 3rem !important
    }

    .gap-sm-14 {
        gap: 3.5rem !important
    }

    .gap-sm-16 {
        gap: 4rem !important
    }

    .gap-sm-18 {
        gap: 4.5rem !important
    }

    .gap-sm-20 {
        gap: 5rem !important
    }

    .gap-sm-24 {
        gap: 6rem !important
    }

    .gap-sm-32 {
        gap: 8rem !important
    }

    .gap-sm-40 {
        gap: 10rem !important
    }

    .gap-sm-48 {
        gap: 12rem !important
    }

    .gap-sm-56 {
        gap: 14rem !important
    }

    .gap-sm-64 {
        gap: 16rem !important
    }

    .gap-sm-72 {
        gap: 18rem !important
    }

    .gap-sm-80 {
        gap: 20rem !important
    }

    .gap-sm-88 {
        gap: 22rem !important
    }

    .gap-sm-96 {
        gap: 24rem !important
    }

    .gap-sm-px {
        gap: 1px !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-evenly {
        align-content: space-evenly !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: .75rem !important
    }

    .m-sm-4 {
        margin: 1rem !important
    }

    .m-sm-5 {
        margin: 1.25rem !important
    }

    .m-sm-6 {
        margin: 1.5rem !important
    }

    .m-sm-7 {
        margin: 1.75rem !important
    }

    .m-sm-8 {
        margin: 2rem !important
    }

    .m-sm-10 {
        margin: 2.5rem !important
    }

    .m-sm-12 {
        margin: 3rem !important
    }

    .m-sm-14 {
        margin: 3.5rem !important
    }

    .m-sm-16 {
        margin: 4rem !important
    }

    .m-sm-18 {
        margin: 4.5rem !important
    }

    .m-sm-20 {
        margin: 5rem !important
    }

    .m-sm-24 {
        margin: 6rem !important
    }

    .m-sm-32 {
        margin: 8rem !important
    }

    .m-sm-40 {
        margin: 10rem !important
    }

    .m-sm-48 {
        margin: 12rem !important
    }

    .m-sm-56 {
        margin: 14rem !important
    }

    .m-sm-64 {
        margin: 16rem !important
    }

    .m-sm-72 {
        margin: 18rem !important
    }

    .m-sm-80 {
        margin: 20rem !important
    }

    .m-sm-88 {
        margin: 22rem !important
    }

    .m-sm-96 {
        margin: 24rem !important
    }

    .m-sm-px {
        margin: 1px !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-sm-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-sm-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-sm-3 {
        margin-left: .75rem !important;
        margin-right: .75rem !important
    }

    .mx-sm-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-sm-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important
    }

    .mx-sm-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-sm-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important
    }

    .mx-sm-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-sm-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-sm-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-sm-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important
    }

    .mx-sm-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-sm-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }

    .mx-sm-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-sm-24 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-sm-32 {
        margin-left: 8rem !important;
        margin-right: 8rem !important
    }

    .mx-sm-40 {
        margin-left: 10rem !important;
        margin-right: 10rem !important
    }

    .mx-sm-48 {
        margin-left: 12rem !important;
        margin-right: 12rem !important
    }

    .mx-sm-56 {
        margin-left: 14rem !important;
        margin-right: 14rem !important
    }

    .mx-sm-64 {
        margin-left: 16rem !important;
        margin-right: 16rem !important
    }

    .mx-sm-72 {
        margin-left: 18rem !important;
        margin-right: 18rem !important
    }

    .mx-sm-80 {
        margin-left: 20rem !important;
        margin-right: 20rem !important
    }

    .mx-sm-88 {
        margin-left: 22rem !important;
        margin-right: 22rem !important
    }

    .mx-sm-96 {
        margin-left: 24rem !important;
        margin-right: 24rem !important
    }

    .mx-sm-px {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mx-sm-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-sm-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-sm-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-sm-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-sm-3 {
        margin-bottom: .75rem !important;
        margin-top: .75rem !important
    }

    .my-sm-4 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-sm-5 {
        margin-bottom: 1.25rem !important;
        margin-top: 1.25rem !important
    }

    .my-sm-6 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-sm-7 {
        margin-bottom: 1.75rem !important;
        margin-top: 1.75rem !important
    }

    .my-sm-8 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-sm-10 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-sm-12 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-sm-14 {
        margin-bottom: 3.5rem !important;
        margin-top: 3.5rem !important
    }

    .my-sm-16 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-sm-18 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }

    .my-sm-20 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-sm-24 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-sm-32 {
        margin-bottom: 8rem !important;
        margin-top: 8rem !important
    }

    .my-sm-40 {
        margin-bottom: 10rem !important;
        margin-top: 10rem !important
    }

    .my-sm-48 {
        margin-bottom: 12rem !important;
        margin-top: 12rem !important
    }

    .my-sm-56 {
        margin-bottom: 14rem !important;
        margin-top: 14rem !important
    }

    .my-sm-64 {
        margin-bottom: 16rem !important;
        margin-top: 16rem !important
    }

    .my-sm-72 {
        margin-bottom: 18rem !important;
        margin-top: 18rem !important
    }

    .my-sm-80 {
        margin-bottom: 20rem !important;
        margin-top: 20rem !important
    }

    .my-sm-88 {
        margin-bottom: 22rem !important;
        margin-top: 22rem !important
    }

    .my-sm-96 {
        margin-bottom: 24rem !important;
        margin-top: 24rem !important
    }

    .my-sm-px {
        margin-bottom: 1px !important;
        margin-top: 1px !important
    }

    .my-sm-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: .75rem !important
    }

    .mt-sm-4 {
        margin-top: 1rem !important
    }

    .mt-sm-5 {
        margin-top: 1.25rem !important
    }

    .mt-sm-6 {
        margin-top: 1.5rem !important
    }

    .mt-sm-7 {
        margin-top: 1.75rem !important
    }

    .mt-sm-8 {
        margin-top: 2rem !important
    }

    .mt-sm-10 {
        margin-top: 2.5rem !important
    }

    .mt-sm-12 {
        margin-top: 3rem !important
    }

    .mt-sm-14 {
        margin-top: 3.5rem !important
    }

    .mt-sm-16 {
        margin-top: 4rem !important
    }

    .mt-sm-18 {
        margin-top: 4.5rem !important
    }

    .mt-sm-20 {
        margin-top: 5rem !important
    }

    .mt-sm-24 {
        margin-top: 6rem !important
    }

    .mt-sm-32 {
        margin-top: 8rem !important
    }

    .mt-sm-40 {
        margin-top: 10rem !important
    }

    .mt-sm-48 {
        margin-top: 12rem !important
    }

    .mt-sm-56 {
        margin-top: 14rem !important
    }

    .mt-sm-64 {
        margin-top: 16rem !important
    }

    .mt-sm-72 {
        margin-top: 18rem !important
    }

    .mt-sm-80 {
        margin-top: 20rem !important
    }

    .mt-sm-88 {
        margin-top: 22rem !important
    }

    .mt-sm-96 {
        margin-top: 24rem !important
    }

    .mt-sm-px {
        margin-top: 1px !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: .75rem !important
    }

    .me-sm-4 {
        margin-right: 1rem !important
    }

    .me-sm-5 {
        margin-right: 1.25rem !important
    }

    .me-sm-6 {
        margin-right: 1.5rem !important
    }

    .me-sm-7 {
        margin-right: 1.75rem !important
    }

    .me-sm-8 {
        margin-right: 2rem !important
    }

    .me-sm-10 {
        margin-right: 2.5rem !important
    }

    .me-sm-12 {
        margin-right: 3rem !important
    }

    .me-sm-14 {
        margin-right: 3.5rem !important
    }

    .me-sm-16 {
        margin-right: 4rem !important
    }

    .me-sm-18 {
        margin-right: 4.5rem !important
    }

    .me-sm-20 {
        margin-right: 5rem !important
    }

    .me-sm-24 {
        margin-right: 6rem !important
    }

    .me-sm-32 {
        margin-right: 8rem !important
    }

    .me-sm-40 {
        margin-right: 10rem !important
    }

    .me-sm-48 {
        margin-right: 12rem !important
    }

    .me-sm-56 {
        margin-right: 14rem !important
    }

    .me-sm-64 {
        margin-right: 16rem !important
    }

    .me-sm-72 {
        margin-right: 18rem !important
    }

    .me-sm-80 {
        margin-right: 20rem !important
    }

    .me-sm-88 {
        margin-right: 22rem !important
    }

    .me-sm-96 {
        margin-right: 24rem !important
    }

    .me-sm-px {
        margin-right: 1px !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: .75rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1rem !important
    }

    .mb-sm-5 {
        margin-bottom: 1.25rem !important
    }

    .mb-sm-6 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-7 {
        margin-bottom: 1.75rem !important
    }

    .mb-sm-8 {
        margin-bottom: 2rem !important
    }

    .mb-sm-10 {
        margin-bottom: 2.5rem !important
    }

    .mb-sm-12 {
        margin-bottom: 3rem !important
    }

    .mb-sm-14 {
        margin-bottom: 3.5rem !important
    }

    .mb-sm-16 {
        margin-bottom: 4rem !important
    }

    .mb-sm-18 {
        margin-bottom: 4.5rem !important
    }

    .mb-sm-20 {
        margin-bottom: 5rem !important
    }

    .mb-sm-24 {
        margin-bottom: 6rem !important
    }

    .mb-sm-32 {
        margin-bottom: 8rem !important
    }

    .mb-sm-40 {
        margin-bottom: 10rem !important
    }

    .mb-sm-48 {
        margin-bottom: 12rem !important
    }

    .mb-sm-56 {
        margin-bottom: 14rem !important
    }

    .mb-sm-64 {
        margin-bottom: 16rem !important
    }

    .mb-sm-72 {
        margin-bottom: 18rem !important
    }

    .mb-sm-80 {
        margin-bottom: 20rem !important
    }

    .mb-sm-88 {
        margin-bottom: 22rem !important
    }

    .mb-sm-96 {
        margin-bottom: 24rem !important
    }

    .mb-sm-px {
        margin-bottom: 1px !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: .75rem !important
    }

    .ms-sm-4 {
        margin-left: 1rem !important
    }

    .ms-sm-5 {
        margin-left: 1.25rem !important
    }

    .ms-sm-6 {
        margin-left: 1.5rem !important
    }

    .ms-sm-7 {
        margin-left: 1.75rem !important
    }

    .ms-sm-8 {
        margin-left: 2rem !important
    }

    .ms-sm-10 {
        margin-left: 2.5rem !important
    }

    .ms-sm-12 {
        margin-left: 3rem !important
    }

    .ms-sm-14 {
        margin-left: 3.5rem !important
    }

    .ms-sm-16 {
        margin-left: 4rem !important
    }

    .ms-sm-18 {
        margin-left: 4.5rem !important
    }

    .ms-sm-20 {
        margin-left: 5rem !important
    }

    .ms-sm-24 {
        margin-left: 6rem !important
    }

    .ms-sm-32 {
        margin-left: 8rem !important
    }

    .ms-sm-40 {
        margin-left: 10rem !important
    }

    .ms-sm-48 {
        margin-left: 12rem !important
    }

    .ms-sm-56 {
        margin-left: 14rem !important
    }

    .ms-sm-64 {
        margin-left: 16rem !important
    }

    .ms-sm-72 {
        margin-left: 18rem !important
    }

    .ms-sm-80 {
        margin-left: 20rem !important
    }

    .ms-sm-88 {
        margin-left: 22rem !important
    }

    .ms-sm-96 {
        margin-left: 24rem !important
    }

    .ms-sm-px {
        margin-left: 1px !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .m-sm-n3 {
        margin: -.75rem !important
    }

    .m-sm-n4 {
        margin: -1rem !important
    }

    .m-sm-n5 {
        margin: -1.25rem !important
    }

    .m-sm-n6 {
        margin: -1.5rem !important
    }

    .m-sm-n7 {
        margin: -1.75rem !important
    }

    .m-sm-n8 {
        margin: -2rem !important
    }

    .m-sm-n10 {
        margin: -2.5rem !important
    }

    .m-sm-n12 {
        margin: -3rem !important
    }

    .m-sm-n14 {
        margin: -3.5rem !important
    }

    .m-sm-n16 {
        margin: -4rem !important
    }

    .m-sm-n18 {
        margin: -4.5rem !important
    }

    .m-sm-n20 {
        margin: -5rem !important
    }

    .m-sm-n24 {
        margin: -6rem !important
    }

    .m-sm-n32 {
        margin: -8rem !important
    }

    .m-sm-n40 {
        margin: -10rem !important
    }

    .m-sm-n48 {
        margin: -12rem !important
    }

    .m-sm-n56 {
        margin: -14rem !important
    }

    .m-sm-n64 {
        margin: -16rem !important
    }

    .m-sm-n72 {
        margin: -18rem !important
    }

    .m-sm-n80 {
        margin: -20rem !important
    }

    .m-sm-n88 {
        margin: -22rem !important
    }

    .m-sm-n96 {
        margin: -24rem !important
    }

    .m-sm-npx {
        margin: -1px !important
    }

    .mx-sm-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-sm-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-sm-n3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important
    }

    .mx-sm-n4 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-sm-n5 {
        margin-left: -1.25rem !important;
        margin-right: -1.25rem !important
    }

    .mx-sm-n6 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-sm-n7 {
        margin-left: -1.75rem !important;
        margin-right: -1.75rem !important
    }

    .mx-sm-n8 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-sm-n10 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-sm-n12 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-sm-n14 {
        margin-left: -3.5rem !important;
        margin-right: -3.5rem !important
    }

    .mx-sm-n16 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-sm-n18 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }

    .mx-sm-n20 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-sm-n24 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .mx-sm-n32 {
        margin-left: -8rem !important;
        margin-right: -8rem !important
    }

    .mx-sm-n40 {
        margin-left: -10rem !important;
        margin-right: -10rem !important
    }

    .mx-sm-n48 {
        margin-left: -12rem !important;
        margin-right: -12rem !important
    }

    .mx-sm-n56 {
        margin-left: -14rem !important;
        margin-right: -14rem !important
    }

    .mx-sm-n64 {
        margin-left: -16rem !important;
        margin-right: -16rem !important
    }

    .mx-sm-n72 {
        margin-left: -18rem !important;
        margin-right: -18rem !important
    }

    .mx-sm-n80 {
        margin-left: -20rem !important;
        margin-right: -20rem !important
    }

    .mx-sm-n88 {
        margin-left: -22rem !important;
        margin-right: -22rem !important
    }

    .mx-sm-n96 {
        margin-left: -24rem !important;
        margin-right: -24rem !important
    }

    .mx-sm-npx {
        margin-left: -1px !important;
        margin-right: -1px !important
    }

    .my-sm-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-sm-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-sm-n3 {
        margin-bottom: -.75rem !important;
        margin-top: -.75rem !important
    }

    .my-sm-n4 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-sm-n5 {
        margin-bottom: -1.25rem !important;
        margin-top: -1.25rem !important
    }

    .my-sm-n6 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-sm-n7 {
        margin-bottom: -1.75rem !important;
        margin-top: -1.75rem !important
    }

    .my-sm-n8 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-sm-n10 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-sm-n12 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-sm-n14 {
        margin-bottom: -3.5rem !important;
        margin-top: -3.5rem !important
    }

    .my-sm-n16 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-sm-n18 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }

    .my-sm-n20 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-sm-n24 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .my-sm-n32 {
        margin-bottom: -8rem !important;
        margin-top: -8rem !important
    }

    .my-sm-n40 {
        margin-bottom: -10rem !important;
        margin-top: -10rem !important
    }

    .my-sm-n48 {
        margin-bottom: -12rem !important;
        margin-top: -12rem !important
    }

    .my-sm-n56 {
        margin-bottom: -14rem !important;
        margin-top: -14rem !important
    }

    .my-sm-n64 {
        margin-bottom: -16rem !important;
        margin-top: -16rem !important
    }

    .my-sm-n72 {
        margin-bottom: -18rem !important;
        margin-top: -18rem !important
    }

    .my-sm-n80 {
        margin-bottom: -20rem !important;
        margin-top: -20rem !important
    }

    .my-sm-n88 {
        margin-bottom: -22rem !important;
        margin-top: -22rem !important
    }

    .my-sm-n96 {
        margin-bottom: -24rem !important;
        margin-top: -24rem !important
    }

    .my-sm-npx {
        margin-bottom: -1px !important;
        margin-top: -1px !important
    }

    .mt-sm-n1 {
        margin-top: -.25rem !important
    }

    .mt-sm-n2 {
        margin-top: -.5rem !important
    }

    .mt-sm-n3 {
        margin-top: -.75rem !important
    }

    .mt-sm-n4 {
        margin-top: -1rem !important
    }

    .mt-sm-n5 {
        margin-top: -1.25rem !important
    }

    .mt-sm-n6 {
        margin-top: -1.5rem !important
    }

    .mt-sm-n7 {
        margin-top: -1.75rem !important
    }

    .mt-sm-n8 {
        margin-top: -2rem !important
    }

    .mt-sm-n10 {
        margin-top: -2.5rem !important
    }

    .mt-sm-n12 {
        margin-top: -3rem !important
    }

    .mt-sm-n14 {
        margin-top: -3.5rem !important
    }

    .mt-sm-n16 {
        margin-top: -4rem !important
    }

    .mt-sm-n18 {
        margin-top: -4.5rem !important
    }

    .mt-sm-n20 {
        margin-top: -5rem !important
    }

    .mt-sm-n24 {
        margin-top: -6rem !important
    }

    .mt-sm-n32 {
        margin-top: -8rem !important
    }

    .mt-sm-n40 {
        margin-top: -10rem !important
    }

    .mt-sm-n48 {
        margin-top: -12rem !important
    }

    .mt-sm-n56 {
        margin-top: -14rem !important
    }

    .mt-sm-n64 {
        margin-top: -16rem !important
    }

    .mt-sm-n72 {
        margin-top: -18rem !important
    }

    .mt-sm-n80 {
        margin-top: -20rem !important
    }

    .mt-sm-n88 {
        margin-top: -22rem !important
    }

    .mt-sm-n96 {
        margin-top: -24rem !important
    }

    .mt-sm-npx {
        margin-top: -1px !important
    }

    .me-sm-n1 {
        margin-right: -.25rem !important
    }

    .me-sm-n2 {
        margin-right: -.5rem !important
    }

    .me-sm-n3 {
        margin-right: -.75rem !important
    }

    .me-sm-n4 {
        margin-right: -1rem !important
    }

    .me-sm-n5 {
        margin-right: -1.25rem !important
    }

    .me-sm-n6 {
        margin-right: -1.5rem !important
    }

    .me-sm-n7 {
        margin-right: -1.75rem !important
    }

    .me-sm-n8 {
        margin-right: -2rem !important
    }

    .me-sm-n10 {
        margin-right: -2.5rem !important
    }

    .me-sm-n12 {
        margin-right: -3rem !important
    }

    .me-sm-n14 {
        margin-right: -3.5rem !important
    }

    .me-sm-n16 {
        margin-right: -4rem !important
    }

    .me-sm-n18 {
        margin-right: -4.5rem !important
    }

    .me-sm-n20 {
        margin-right: -5rem !important
    }

    .me-sm-n24 {
        margin-right: -6rem !important
    }

    .me-sm-n32 {
        margin-right: -8rem !important
    }

    .me-sm-n40 {
        margin-right: -10rem !important
    }

    .me-sm-n48 {
        margin-right: -12rem !important
    }

    .me-sm-n56 {
        margin-right: -14rem !important
    }

    .me-sm-n64 {
        margin-right: -16rem !important
    }

    .me-sm-n72 {
        margin-right: -18rem !important
    }

    .me-sm-n80 {
        margin-right: -20rem !important
    }

    .me-sm-n88 {
        margin-right: -22rem !important
    }

    .me-sm-n96 {
        margin-right: -24rem !important
    }

    .me-sm-npx {
        margin-right: -1px !important
    }

    .mb-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-sm-n3 {
        margin-bottom: -.75rem !important
    }

    .mb-sm-n4 {
        margin-bottom: -1rem !important
    }

    .mb-sm-n5 {
        margin-bottom: -1.25rem !important
    }

    .mb-sm-n6 {
        margin-bottom: -1.5rem !important
    }

    .mb-sm-n7 {
        margin-bottom: -1.75rem !important
    }

    .mb-sm-n8 {
        margin-bottom: -2rem !important
    }

    .mb-sm-n10 {
        margin-bottom: -2.5rem !important
    }

    .mb-sm-n12 {
        margin-bottom: -3rem !important
    }

    .mb-sm-n14 {
        margin-bottom: -3.5rem !important
    }

    .mb-sm-n16 {
        margin-bottom: -4rem !important
    }

    .mb-sm-n18 {
        margin-bottom: -4.5rem !important
    }

    .mb-sm-n20 {
        margin-bottom: -5rem !important
    }

    .mb-sm-n24 {
        margin-bottom: -6rem !important
    }

    .mb-sm-n32 {
        margin-bottom: -8rem !important
    }

    .mb-sm-n40 {
        margin-bottom: -10rem !important
    }

    .mb-sm-n48 {
        margin-bottom: -12rem !important
    }

    .mb-sm-n56 {
        margin-bottom: -14rem !important
    }

    .mb-sm-n64 {
        margin-bottom: -16rem !important
    }

    .mb-sm-n72 {
        margin-bottom: -18rem !important
    }

    .mb-sm-n80 {
        margin-bottom: -20rem !important
    }

    .mb-sm-n88 {
        margin-bottom: -22rem !important
    }

    .mb-sm-n96 {
        margin-bottom: -24rem !important
    }

    .mb-sm-npx {
        margin-bottom: -1px !important
    }

    .ms-sm-n1 {
        margin-left: -.25rem !important
    }

    .ms-sm-n2 {
        margin-left: -.5rem !important
    }

    .ms-sm-n3 {
        margin-left: -.75rem !important
    }

    .ms-sm-n4 {
        margin-left: -1rem !important
    }

    .ms-sm-n5 {
        margin-left: -1.25rem !important
    }

    .ms-sm-n6 {
        margin-left: -1.5rem !important
    }

    .ms-sm-n7 {
        margin-left: -1.75rem !important
    }

    .ms-sm-n8 {
        margin-left: -2rem !important
    }

    .ms-sm-n10 {
        margin-left: -2.5rem !important
    }

    .ms-sm-n12 {
        margin-left: -3rem !important
    }

    .ms-sm-n14 {
        margin-left: -3.5rem !important
    }

    .ms-sm-n16 {
        margin-left: -4rem !important
    }

    .ms-sm-n18 {
        margin-left: -4.5rem !important
    }

    .ms-sm-n20 {
        margin-left: -5rem !important
    }

    .ms-sm-n24 {
        margin-left: -6rem !important
    }

    .ms-sm-n32 {
        margin-left: -8rem !important
    }

    .ms-sm-n40 {
        margin-left: -10rem !important
    }

    .ms-sm-n48 {
        margin-left: -12rem !important
    }

    .ms-sm-n56 {
        margin-left: -14rem !important
    }

    .ms-sm-n64 {
        margin-left: -16rem !important
    }

    .ms-sm-n72 {
        margin-left: -18rem !important
    }

    .ms-sm-n80 {
        margin-left: -20rem !important
    }

    .ms-sm-n88 {
        margin-left: -22rem !important
    }

    .ms-sm-n96 {
        margin-left: -24rem !important
    }

    .ms-sm-npx {
        margin-left: -1px !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: .75rem !important
    }

    .p-sm-4 {
        padding: 1rem !important
    }

    .p-sm-5 {
        padding: 1.25rem !important
    }

    .p-sm-6 {
        padding: 1.5rem !important
    }

    .p-sm-7 {
        padding: 1.75rem !important
    }

    .p-sm-8 {
        padding: 2rem !important
    }

    .p-sm-10 {
        padding: 2.5rem !important
    }

    .p-sm-12 {
        padding: 3rem !important
    }

    .p-sm-14 {
        padding: 3.5rem !important
    }

    .p-sm-16 {
        padding: 4rem !important
    }

    .p-sm-18 {
        padding: 4.5rem !important
    }

    .p-sm-20 {
        padding: 5rem !important
    }

    .p-sm-24 {
        padding: 6rem !important
    }

    .p-sm-32 {
        padding: 8rem !important
    }

    .p-sm-40 {
        padding: 10rem !important
    }

    .p-sm-48 {
        padding: 12rem !important
    }

    .p-sm-56 {
        padding: 14rem !important
    }

    .p-sm-64 {
        padding: 16rem !important
    }

    .p-sm-72 {
        padding: 18rem !important
    }

    .p-sm-80 {
        padding: 20rem !important
    }

    .p-sm-88 {
        padding: 22rem !important
    }

    .p-sm-96 {
        padding: 24rem !important
    }

    .p-sm-px {
        padding: 1px !important
    }

    .px-sm-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-sm-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-sm-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-sm-3 {
        padding-left: .75rem !important;
        padding-right: .75rem !important
    }

    .px-sm-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-sm-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important
    }

    .px-sm-6 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-sm-7 {
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important
    }

    .px-sm-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-sm-10 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-sm-12 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-sm-14 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important
    }

    .px-sm-16 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-sm-18 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }

    .px-sm-20 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-sm-24 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .px-sm-32 {
        padding-left: 8rem !important;
        padding-right: 8rem !important
    }

    .px-sm-40 {
        padding-left: 10rem !important;
        padding-right: 10rem !important
    }

    .px-sm-48 {
        padding-left: 12rem !important;
        padding-right: 12rem !important
    }

    .px-sm-56 {
        padding-left: 14rem !important;
        padding-right: 14rem !important
    }

    .px-sm-64 {
        padding-left: 16rem !important;
        padding-right: 16rem !important
    }

    .px-sm-72 {
        padding-left: 18rem !important;
        padding-right: 18rem !important
    }

    .px-sm-80 {
        padding-left: 20rem !important;
        padding-right: 20rem !important
    }

    .px-sm-88 {
        padding-left: 22rem !important;
        padding-right: 22rem !important
    }

    .px-sm-96 {
        padding-left: 24rem !important;
        padding-right: 24rem !important
    }

    .px-sm-px {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .py-sm-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-sm-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-sm-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-sm-3 {
        padding-bottom: .75rem !important;
        padding-top: .75rem !important
    }

    .py-sm-4 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-sm-5 {
        padding-bottom: 1.25rem !important;
        padding-top: 1.25rem !important
    }

    .py-sm-6 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-sm-7 {
        padding-bottom: 1.75rem !important;
        padding-top: 1.75rem !important
    }

    .py-sm-8 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-sm-10 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-sm-12 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-sm-14 {
        padding-bottom: 3.5rem !important;
        padding-top: 3.5rem !important
    }

    .py-sm-16 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-sm-18 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }

    .py-sm-20 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-sm-24 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .py-sm-32 {
        padding-bottom: 8rem !important;
        padding-top: 8rem !important
    }

    .py-sm-40 {
        padding-bottom: 10rem !important;
        padding-top: 10rem !important
    }

    .py-sm-48 {
        padding-bottom: 12rem !important;
        padding-top: 12rem !important
    }

    .py-sm-56 {
        padding-bottom: 14rem !important;
        padding-top: 14rem !important
    }

    .py-sm-64 {
        padding-bottom: 16rem !important;
        padding-top: 16rem !important
    }

    .py-sm-72 {
        padding-bottom: 18rem !important;
        padding-top: 18rem !important
    }

    .py-sm-80 {
        padding-bottom: 20rem !important;
        padding-top: 20rem !important
    }

    .py-sm-88 {
        padding-bottom: 22rem !important;
        padding-top: 22rem !important
    }

    .py-sm-96 {
        padding-bottom: 24rem !important;
        padding-top: 24rem !important
    }

    .py-sm-px {
        padding-bottom: 1px !important;
        padding-top: 1px !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: .75rem !important
    }

    .pt-sm-4 {
        padding-top: 1rem !important
    }

    .pt-sm-5 {
        padding-top: 1.25rem !important
    }

    .pt-sm-6 {
        padding-top: 1.5rem !important
    }

    .pt-sm-7 {
        padding-top: 1.75rem !important
    }

    .pt-sm-8 {
        padding-top: 2rem !important
    }

    .pt-sm-10 {
        padding-top: 2.5rem !important
    }

    .pt-sm-12 {
        padding-top: 3rem !important
    }

    .pt-sm-14 {
        padding-top: 3.5rem !important
    }

    .pt-sm-16 {
        padding-top: 4rem !important
    }

    .pt-sm-18 {
        padding-top: 4.5rem !important
    }

    .pt-sm-20 {
        padding-top: 5rem !important
    }

    .pt-sm-24 {
        padding-top: 6rem !important
    }

    .pt-sm-32 {
        padding-top: 8rem !important
    }

    .pt-sm-40 {
        padding-top: 10rem !important
    }

    .pt-sm-48 {
        padding-top: 12rem !important
    }

    .pt-sm-56 {
        padding-top: 14rem !important
    }

    .pt-sm-64 {
        padding-top: 16rem !important
    }

    .pt-sm-72 {
        padding-top: 18rem !important
    }

    .pt-sm-80 {
        padding-top: 20rem !important
    }

    .pt-sm-88 {
        padding-top: 22rem !important
    }

    .pt-sm-96 {
        padding-top: 24rem !important
    }

    .pt-sm-px {
        padding-top: 1px !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: .75rem !important
    }

    .pe-sm-4 {
        padding-right: 1rem !important
    }

    .pe-sm-5 {
        padding-right: 1.25rem !important
    }

    .pe-sm-6 {
        padding-right: 1.5rem !important
    }

    .pe-sm-7 {
        padding-right: 1.75rem !important
    }

    .pe-sm-8 {
        padding-right: 2rem !important
    }

    .pe-sm-10 {
        padding-right: 2.5rem !important
    }

    .pe-sm-12 {
        padding-right: 3rem !important
    }

    .pe-sm-14 {
        padding-right: 3.5rem !important
    }

    .pe-sm-16 {
        padding-right: 4rem !important
    }

    .pe-sm-18 {
        padding-right: 4.5rem !important
    }

    .pe-sm-20 {
        padding-right: 5rem !important
    }

    .pe-sm-24 {
        padding-right: 6rem !important
    }

    .pe-sm-32 {
        padding-right: 8rem !important
    }

    .pe-sm-40 {
        padding-right: 10rem !important
    }

    .pe-sm-48 {
        padding-right: 12rem !important
    }

    .pe-sm-56 {
        padding-right: 14rem !important
    }

    .pe-sm-64 {
        padding-right: 16rem !important
    }

    .pe-sm-72 {
        padding-right: 18rem !important
    }

    .pe-sm-80 {
        padding-right: 20rem !important
    }

    .pe-sm-88 {
        padding-right: 22rem !important
    }

    .pe-sm-96 {
        padding-right: 24rem !important
    }

    .pe-sm-px {
        padding-right: 1px !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: .75rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1rem !important
    }

    .pb-sm-5 {
        padding-bottom: 1.25rem !important
    }

    .pb-sm-6 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-7 {
        padding-bottom: 1.75rem !important
    }

    .pb-sm-8 {
        padding-bottom: 2rem !important
    }

    .pb-sm-10 {
        padding-bottom: 2.5rem !important
    }

    .pb-sm-12 {
        padding-bottom: 3rem !important
    }

    .pb-sm-14 {
        padding-bottom: 3.5rem !important
    }

    .pb-sm-16 {
        padding-bottom: 4rem !important
    }

    .pb-sm-18 {
        padding-bottom: 4.5rem !important
    }

    .pb-sm-20 {
        padding-bottom: 5rem !important
    }

    .pb-sm-24 {
        padding-bottom: 6rem !important
    }

    .pb-sm-32 {
        padding-bottom: 8rem !important
    }

    .pb-sm-40 {
        padding-bottom: 10rem !important
    }

    .pb-sm-48 {
        padding-bottom: 12rem !important
    }

    .pb-sm-56 {
        padding-bottom: 14rem !important
    }

    .pb-sm-64 {
        padding-bottom: 16rem !important
    }

    .pb-sm-72 {
        padding-bottom: 18rem !important
    }

    .pb-sm-80 {
        padding-bottom: 20rem !important
    }

    .pb-sm-88 {
        padding-bottom: 22rem !important
    }

    .pb-sm-96 {
        padding-bottom: 24rem !important
    }

    .pb-sm-px {
        padding-bottom: 1px !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: .75rem !important
    }

    .ps-sm-4 {
        padding-left: 1rem !important
    }

    .ps-sm-5 {
        padding-left: 1.25rem !important
    }

    .ps-sm-6 {
        padding-left: 1.5rem !important
    }

    .ps-sm-7 {
        padding-left: 1.75rem !important
    }

    .ps-sm-8 {
        padding-left: 2rem !important
    }

    .ps-sm-10 {
        padding-left: 2.5rem !important
    }

    .ps-sm-12 {
        padding-left: 3rem !important
    }

    .ps-sm-14 {
        padding-left: 3.5rem !important
    }

    .ps-sm-16 {
        padding-left: 4rem !important
    }

    .ps-sm-18 {
        padding-left: 4.5rem !important
    }

    .ps-sm-20 {
        padding-left: 5rem !important
    }

    .ps-sm-24 {
        padding-left: 6rem !important
    }

    .ps-sm-32 {
        padding-left: 8rem !important
    }

    .ps-sm-40 {
        padding-left: 10rem !important
    }

    .ps-sm-48 {
        padding-left: 12rem !important
    }

    .ps-sm-56 {
        padding-left: 14rem !important
    }

    .ps-sm-64 {
        padding-left: 16rem !important
    }

    .ps-sm-72 {
        padding-left: 18rem !important
    }

    .ps-sm-80 {
        padding-left: 20rem !important
    }

    .ps-sm-88 {
        padding-left: 22rem !important
    }

    .ps-sm-96 {
        padding-left: 24rem !important
    }

    .ps-sm-px {
        padding-left: 1px !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }

    .text-sm-primary {
        --x-text-opacity: 1
    }

    .text-sm-primary, .text-sm-primary-focus:focus, .text-sm-primary-hover:hover {
        color: rgba(var(--x-primary-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-secondary {
        --x-text-opacity: 1
    }

    .text-sm-secondary, .text-sm-secondary-focus:focus, .text-sm-secondary-hover:hover {
        color: rgba(var(--x-secondary-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-tertiary {
        --x-text-opacity: 1
    }

    .text-sm-tertiary, .text-sm-tertiary-focus:focus, .text-sm-tertiary-hover:hover {
        color: rgba(var(--x-tertiary-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-success {
        --x-text-opacity: 1
    }

    .text-sm-success, .text-sm-success-focus:focus, .text-sm-success-hover:hover {
        color: rgba(var(--x-success-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-info {
        --x-text-opacity: 1
    }

    .text-sm-info, .text-sm-info-focus:focus, .text-sm-info-hover:hover {
        color: rgba(var(--x-info-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-warning {
        --x-text-opacity: 1
    }

    .text-sm-warning, .text-sm-warning-focus:focus, .text-sm-warning-hover:hover {
        color: rgba(var(--x-warning-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-danger {
        --x-text-opacity: 1
    }

    .text-sm-danger, .text-sm-danger-focus:focus, .text-sm-danger-hover:hover {
        color: rgba(var(--x-danger-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-white {
        --x-text-opacity: 1
    }

    .text-sm-white, .text-sm-white-focus:focus, .text-sm-white-hover:hover {
        color: rgba(var(--x-white-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-light {
        --x-text-opacity: 1
    }

    .text-sm-light, .text-sm-light-focus:focus, .text-sm-light-hover:hover {
        color: rgba(var(--x-light-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-dark {
        --x-text-opacity: 1
    }

    .text-sm-dark, .text-sm-dark-focus:focus, .text-sm-dark-hover:hover {
        color: rgba(var(--x-dark-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-black {
        --x-text-opacity: 1
    }

    .text-sm-black, .text-sm-black-focus:focus, .text-sm-black-hover:hover {
        color: rgba(var(--x-black-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-body {
        --x-text-opacity: 1
    }

    .text-sm-body, .text-sm-body-focus:focus, .text-sm-body-hover:hover {
        color: rgba(var(--x-body-color-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-muted {
        --x-text-opacity: 1
    }

    .text-sm-muted, .text-sm-muted-focus:focus, .text-sm-muted-hover:hover {
        color: rgba(var(--x-muted-rgb),var(--x-text-opacity)) !important
    }

    .text-sm-heading {
        --x-text-opacity: 1
    }

    .text-sm-heading, .text-sm-heading-focus:focus, .text-sm-heading-hover:hover {
        color: rgba(var(--x-heading-rgb),var(--x-text-opacity)) !important
    }

    .rounded-sm {
        border-radius: .375rem !important
    }

    .rounded-sm-0 {
        border-radius: 0 !important
    }

    .rounded-sm-1 {
        border-radius: .25rem !important
    }

    .rounded-sm-2 {
        border-radius: .5rem !important
    }

    .rounded-sm-3 {
        border-radius: .75rem !important
    }

    .rounded-sm-4 {
        border-radius: 1rem !important
    }

    .rounded-sm-5 {
        border-radius: 1.5rem !important
    }

    .rounded-sm-6 {
        border-radius: 3rem !important
    }

    .rounded-sm-7 {
        border-radius: 4rem !important
    }

    .rounded-sm-8 {
        border-radius: 5rem !important
    }

    .rounded-sm-9 {
        border-radius: 6rem !important
    }

    .rounded-sm-10 {
        border-radius: 7rem !important
    }

    .rounded-sm-pill {
        border-radius: 50rem !important
    }

    .rounded-sm-circle {
        border-radius: 50% !important
    }

    .rounded-sm-card {
        border-radius: .75rem !important
    }

    .rounded-top-sm {
        border-top-left-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-top-sm-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-sm-1 {
        border-top-left-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-top-sm-2 {
        border-top-left-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-top-sm-3 {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-top-sm-4 {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-top-sm-5 {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-sm-6 {
        border-top-left-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-top-sm-7 {
        border-top-left-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-top-sm-8 {
        border-top-left-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-top-sm-9 {
        border-top-left-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-top-sm-10 {
        border-top-left-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-top-sm-pill {
        border-top-left-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-top-sm-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-sm-card {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-sm {
        border-bottom-right-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-end-sm-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-sm-1 {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-end-sm-2 {
        border-bottom-right-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-end-sm-3 {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-sm-4 {
        border-bottom-right-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-end-sm-5 {
        border-bottom-right-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-end-sm-6 {
        border-bottom-right-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-end-sm-7 {
        border-bottom-right-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-end-sm-8 {
        border-bottom-right-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-end-sm-9 {
        border-bottom-right-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-end-sm-10 {
        border-bottom-right-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-end-sm-pill {
        border-bottom-right-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-end-sm-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-sm-card {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-sm {
        border-bottom-left-radius: .375rem !important;
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-sm-1 {
        border-bottom-left-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-sm-2 {
        border-bottom-left-radius: .5rem !important;
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-sm-3 {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-sm-4 {
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-sm-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-sm-6 {
        border-bottom-left-radius: 3rem !important;
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-sm-7 {
        border-bottom-left-radius: 4rem !important;
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-sm-8 {
        border-bottom-left-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-sm-9 {
        border-bottom-left-radius: 6rem !important;
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-sm-10 {
        border-bottom-left-radius: 7rem !important;
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-sm-pill {
        border-bottom-left-radius: 50rem !important;
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-sm-card {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-start-sm {
        border-bottom-left-radius: .375rem !important;
        border-top-left-radius: .375rem !important
    }

    .rounded-start-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-sm-1 {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important
    }

    .rounded-start-sm-2 {
        border-bottom-left-radius: .5rem !important;
        border-top-left-radius: .5rem !important
    }

    .rounded-start-sm-3 {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-start-sm-4 {
        border-bottom-left-radius: 1rem !important;
        border-top-left-radius: 1rem !important
    }

    .rounded-start-sm-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important
    }

    .rounded-start-sm-6 {
        border-bottom-left-radius: 3rem !important;
        border-top-left-radius: 3rem !important
    }

    .rounded-start-sm-7 {
        border-bottom-left-radius: 4rem !important;
        border-top-left-radius: 4rem !important
    }

    .rounded-start-sm-8 {
        border-bottom-left-radius: 5rem !important;
        border-top-left-radius: 5rem !important
    }

    .rounded-start-sm-9 {
        border-bottom-left-radius: 6rem !important;
        border-top-left-radius: 6rem !important
    }

    .rounded-start-sm-10 {
        border-bottom-left-radius: 7rem !important;
        border-top-left-radius: 7rem !important
    }

    .rounded-start-sm-pill {
        border-bottom-left-radius: 50rem !important;
        border-top-left-radius: 50rem !important
    }

    .rounded-start-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-sm-card {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-sm {
        border-top-left-radius: .375rem !important
    }

    .rounded-top-start-sm-0 {
        border-top-left-radius: 0 !important
    }

    .rounded-top-start-sm-1 {
        border-top-left-radius: .25rem !important
    }

    .rounded-top-start-sm-2 {
        border-top-left-radius: .5rem !important
    }

    .rounded-top-start-sm-3 {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-sm-4 {
        border-top-left-radius: 1rem !important
    }

    .rounded-top-start-sm-5 {
        border-top-left-radius: 1.5rem !important
    }

    .rounded-top-start-sm-6 {
        border-top-left-radius: 3rem !important
    }

    .rounded-top-start-sm-7 {
        border-top-left-radius: 4rem !important
    }

    .rounded-top-start-sm-8 {
        border-top-left-radius: 5rem !important
    }

    .rounded-top-start-sm-9 {
        border-top-left-radius: 6rem !important
    }

    .rounded-top-start-sm-10 {
        border-top-left-radius: 7rem !important
    }

    .rounded-top-start-sm-pill {
        border-top-left-radius: 50rem !important
    }

    .rounded-top-start-sm-circle {
        border-top-left-radius: 50% !important
    }

    .rounded-top-start-sm-card {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-end-sm {
        border-top-right-radius: .375rem !important
    }

    .rounded-top-end-sm-0 {
        border-top-right-radius: 0 !important
    }

    .rounded-top-end-sm-1 {
        border-top-right-radius: .25rem !important
    }

    .rounded-top-end-sm-2 {
        border-top-right-radius: .5rem !important
    }

    .rounded-top-end-sm-3 {
        border-top-right-radius: .75rem !important
    }

    .rounded-top-end-sm-4 {
        border-top-right-radius: 1rem !important
    }

    .rounded-top-end-sm-5 {
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-end-sm-6 {
        border-top-right-radius: 3rem !important
    }

    .rounded-top-end-sm-7 {
        border-top-right-radius: 4rem !important
    }

    .rounded-top-end-sm-8 {
        border-top-right-radius: 5rem !important
    }

    .rounded-top-end-sm-9 {
        border-top-right-radius: 6rem !important
    }

    .rounded-top-end-sm-10 {
        border-top-right-radius: 7rem !important
    }

    .rounded-top-end-sm-pill {
        border-top-right-radius: 50rem !important
    }

    .rounded-top-end-sm-circle {
        border-top-right-radius: 50% !important
    }

    .rounded-top-end-sm-card {
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-end-sm {
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-end-sm-0 {
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-end-sm-1 {
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-end-sm-2 {
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-end-sm-3 {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-end-sm-4 {
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-end-sm-5 {
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-end-sm-6 {
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-end-sm-7 {
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-end-sm-8 {
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-end-sm-9 {
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-end-sm-10 {
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-end-sm-pill {
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-end-sm-circle {
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-end-sm-card {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-start-sm {
        border-bottom-left-radius: .375rem !important
    }

    .rounded-bottom-start-sm-0 {
        border-bottom-left-radius: 0 !important
    }

    .rounded-bottom-start-sm-1 {
        border-bottom-left-radius: .25rem !important
    }

    .rounded-bottom-start-sm-2 {
        border-bottom-left-radius: .5rem !important
    }

    .rounded-bottom-start-sm-3 {
        border-bottom-left-radius: .75rem !important
    }

    .rounded-bottom-start-sm-4 {
        border-bottom-left-radius: 1rem !important
    }

    .rounded-bottom-start-sm-5 {
        border-bottom-left-radius: 1.5rem !important
    }

    .rounded-bottom-start-sm-6 {
        border-bottom-left-radius: 3rem !important
    }

    .rounded-bottom-start-sm-7 {
        border-bottom-left-radius: 4rem !important
    }

    .rounded-bottom-start-sm-8 {
        border-bottom-left-radius: 5rem !important
    }

    .rounded-bottom-start-sm-9 {
        border-bottom-left-radius: 6rem !important
    }

    .rounded-bottom-start-sm-10 {
        border-bottom-left-radius: 7rem !important
    }

    .rounded-bottom-start-sm-pill {
        border-bottom-left-radius: 50rem !important
    }

    .rounded-bottom-start-sm-circle {
        border-bottom-left-radius: 50% !important
    }

    .rounded-bottom-start-sm-card {
        border-bottom-left-radius: .75rem !important
    }

    .border-sm-solid {
        border-style: solid !important
    }

    .border-sm-dashed {
        border-style: dashed !important
    }

    .border-sm-dotted {
        border-style: dotted !important
    }

    .border-sm-double {
        border-style: double !important
    }

    .border-sm-groove {
        border-style: groove !important
    }

    .border-sm-none {
        border-style: none !important
    }

    .text-sm-transparent, .text-sm-transparent-focus:focus, .text-sm-transparent-hover:hover {
        color: transparent !important
    }

    .text-sm-current, .text-sm-current-focus:focus, .text-sm-current-hover:hover {
        color: currentColor !important
    }

    .text-sm-reset, .text-sm-reset-focus:focus, .text-sm-reset-hover:hover {
        color: inherit !important
    }

    .shadow-soft-sm-1, .shadow-soft-sm-1-focus:focus, .shadow-soft-sm-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-sm-2, .shadow-soft-sm-2-focus:focus, .shadow-soft-sm-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-sm-3, .shadow-soft-sm-3-focus:focus, .shadow-soft-sm-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-sm-4, .shadow-soft-sm-4-focus:focus, .shadow-soft-sm-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-sm-5, .shadow-soft-sm-5-focus:focus, .shadow-soft-sm-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.04) !important
    }

    .shadow-soft-sm-6, .shadow-soft-sm-6-focus:focus, .shadow-soft-sm-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-sm-none, .shadow-soft-sm-none-focus:focus, .shadow-soft-sm-none-hover:hover {
        box-shadow: none !important
    }

    .min-w-sm-0 {
        min-width: 0 !important
    }

    .min-w-sm-full {
        min-width: 100% !important
    }

    .min-w-sm-min {
        min-width: -webkit-min-content !important;
        min-width: -moz-min-content !important;
        min-width: min-content !important
    }

    .min-w-sm-max {
        min-width: -webkit-max-content !important;
        min-width: -moz-max-content !important;
        min-width: max-content !important
    }

    .min-h-sm-0 {
        min-height: 0 !important
    }

    .min-h-sm-full {
        min-height: 100% !important
    }

    .min-h-sm-screen {
        min-height: 100vh !important
    }

    .place-content-sm-start {
        place-content: flex-start !important
    }

    .place-content-sm-end {
        place-content: flex-end !important
    }

    .place-content-sm-center {
        place-content: center !important
    }

    .place-content-sm-between {
        place-content: space-between !important
    }

    .place-content-sm-around {
        place-content: space-around !important
    }

    .place-content-sm-evenly {
        place-content: space-evenly !important
    }

    .place-content-sm-stretch {
        place-content: stretch !important
    }

    .overflow-x-sm-auto {
        overflow-x: auto !important
    }

    .overflow-x-sm-hidden {
        overflow-x: hidden !important
    }

    .overflow-x-sm-visible {
        overflow-x: visible !important
    }

    .overflow-x-sm-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-sm-auto {
        overflow-y: auto !important
    }

    .overflow-y-sm-hidden {
        overflow-y: hidden !important
    }

    .overflow-y-sm-visible {
        overflow-y: visible !important
    }

    .overflow-y-sm-scroll {
        overflow-y: scroll !important
    }

    .transition-sm {
        transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform !important
    }

    .transition-sm-none {
        transition-property: none !important
    }

    .transition-sm-all {
        transition-property: all !important
    }

    .transition-sm-colors {
        transition-property: background-color,border-color,color,fill,stroke !important
    }

    .transition-sm-opacity {
        transition-property: opacity !important
    }

    .transition-sm-shadow {
        transition-property: box-shadow !important
    }

    .transition-sm-transform {
        transition-property: transform !important
    }

    .ease-sm-base {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .ease-sm-linear {
        transition-timing-function: linear !important
    }

    .ease-sm-in {
        transition-timing-function: cubic-bezier(.4,0,1,1) !important
    }

    .ease-sm-out {
        transition-timing-function: cubic-bezier(0,0,.2,1) !important
    }

    .ease-sm-in-out {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .transform-sm-none {
        transform: none !important
    }

    .origin-sm-center {
        transform-origin: center !important
    }

    .origin-sm-top {
        transform-origin: top !important
    }

    .origin-sm-top-right {
        transform-origin: top right !important
    }

    .origin-sm-right {
        transform-origin: right !important
    }

    .origin-sm-bottom-right {
        transform-origin: bottom right !important
    }

    .origin-sm-bottom {
        transform-origin: bottom !important
    }

    .origin-sm-bottom-left {
        transform-origin: bottom left !important
    }

    .origin-sm-left {
        transform-origin: left !important
    }

    .origin-sm-top-left {
        transform-origin: top left !important
    }

    .scale-sm-0, .scale-sm-0-focus:focus, .scale-sm-0-hover:hover {
        --x-scale-x: 0 !important;
        --x-scale-y: 0 !important
    }

    .scale-sm-25, .scale-sm-25-focus:focus, .scale-sm-25-hover:hover {
        --x-scale-x: 0.25 !important;
        --x-scale-y: 0.25 !important
    }

    .scale-sm-50, .scale-sm-50-focus:focus, .scale-sm-50-hover:hover {
        --x-scale-x: 0.5 !important;
        --x-scale-y: 0.5 !important
    }

    .scale-sm-75, .scale-sm-75-focus:focus, .scale-sm-75-hover:hover {
        --x-scale-x: 0.75 !important;
        --x-scale-y: 0.75 !important
    }

    .scale-sm-90, .scale-sm-90-focus:focus, .scale-sm-90-hover:hover {
        --x-scale-x: 0.9 !important;
        --x-scale-y: 0.9 !important
    }

    .scale-sm-95, .scale-sm-95-focus:focus, .scale-sm-95-hover:hover {
        --x-scale-x: 0.95 !important;
        --x-scale-y: 0.95 !important
    }

    .scale-sm-100, .scale-sm-100-focus:focus, .scale-sm-100-hover:hover {
        --x-scale-x: 1 !important;
        --x-scale-y: 1 !important
    }

    .scale-sm-105, .scale-sm-105-focus:focus, .scale-sm-105-hover:hover {
        --x-scale-x: 1.05 !important;
        --x-scale-y: 1.05 !important
    }

    .scale-sm-110, .scale-sm-110-focus:focus, .scale-sm-110-hover:hover {
        --x-scale-x: 1.1 !important;
        --x-scale-y: 1.1 !important
    }

    .scale-sm-125, .scale-sm-125-focus:focus, .scale-sm-125-hover:hover {
        --x-scale-x: 1.25 !important;
        --x-scale-y: 1.25 !important
    }

    .scale-sm-150, .scale-sm-150-focus:focus, .scale-sm-150-hover:hover {
        --x-scale-x: 1.5 !important;
        --x-scale-y: 1.5 !important
    }

    .scale-sm-200, .scale-sm-200-focus:focus, .scale-sm-200-hover:hover {
        --x-scale-x: 2 !important;
        --x-scale-y: 2 !important
    }

    .scale-y-sm-0, .scale-y-sm-0-focus:focus, .scale-y-sm-0-hover:hover {
        --x--scale-y: 0 !important
    }

    .scale-y-sm-25, .scale-y-sm-25-focus:focus, .scale-y-sm-25-hover:hover {
        --x--scale-y: 0.25 !important
    }

    .scale-y-sm-50, .scale-y-sm-50-focus:focus, .scale-y-sm-50-hover:hover {
        --x--scale-y: 0.5 !important
    }

    .scale-y-sm-75, .scale-y-sm-75-focus:focus, .scale-y-sm-75-hover:hover {
        --x--scale-y: 0.75 !important
    }

    .scale-y-sm-90, .scale-y-sm-90-focus:focus, .scale-y-sm-90-hover:hover {
        --x--scale-y: 0.9 !important
    }

    .scale-y-sm-95, .scale-y-sm-95-focus:focus, .scale-y-sm-95-hover:hover {
        --x--scale-y: 0.95 !important
    }

    .scale-y-sm-100, .scale-y-sm-100-focus:focus, .scale-y-sm-100-hover:hover {
        --x--scale-y: 1 !important
    }

    .scale-y-sm-105, .scale-y-sm-105-focus:focus, .scale-y-sm-105-hover:hover {
        --x--scale-y: 1.05 !important
    }

    .scale-y-sm-110, .scale-y-sm-110-focus:focus, .scale-y-sm-110-hover:hover {
        --x--scale-y: 1.1 !important
    }

    .scale-y-sm-125, .scale-y-sm-125-focus:focus, .scale-y-sm-125-hover:hover {
        --x--scale-y: 1.25 !important
    }

    .scale-y-sm-150, .scale-y-sm-150-focus:focus, .scale-y-sm-150-hover:hover {
        --x--scale-y: 1.5 !important
    }

    .scale-y-sm-200, .scale-y-sm-200-focus:focus, .scale-y-sm-200-hover:hover {
        --x--scale-y: 2 !important
    }

    .scale-x-sm-0, .scale-x-sm-0-focus:focus, .scale-x-sm-0-hover:hover {
        --x--scale-x: 0 !important
    }

    .scale-x-sm-25, .scale-x-sm-25-focus:focus, .scale-x-sm-25-hover:hover {
        --x--scale-x: 0.25 !important
    }

    .scale-x-sm-50, .scale-x-sm-50-focus:focus, .scale-x-sm-50-hover:hover {
        --x--scale-x: 0.5 !important
    }

    .scale-x-sm-75, .scale-x-sm-75-focus:focus, .scale-x-sm-75-hover:hover {
        --x--scale-x: 0.75 !important
    }

    .scale-x-sm-90, .scale-x-sm-90-focus:focus, .scale-x-sm-90-hover:hover {
        --x--scale-x: 0.9 !important
    }

    .scale-x-sm-95, .scale-x-sm-95-focus:focus, .scale-x-sm-95-hover:hover {
        --x--scale-x: 0.95 !important
    }

    .scale-x-sm-100, .scale-x-sm-100-focus:focus, .scale-x-sm-100-hover:hover {
        --x--scale-x: 1 !important
    }

    .scale-x-sm-105, .scale-x-sm-105-focus:focus, .scale-x-sm-105-hover:hover {
        --x--scale-x: 1.05 !important
    }

    .scale-x-sm-110, .scale-x-sm-110-focus:focus, .scale-x-sm-110-hover:hover {
        --x--scale-x: 1.1 !important
    }

    .scale-x-sm-125, .scale-x-sm-125-focus:focus, .scale-x-sm-125-hover:hover {
        --x--scale-x: 1.25 !important
    }

    .scale-x-sm-150, .scale-x-sm-150-focus:focus, .scale-x-sm-150-hover:hover {
        --x--scale-x: 1.5 !important
    }

    .scale-x-sm-200, .scale-x-sm-200-focus:focus, .scale-x-sm-200-hover:hover {
        --x--scale-x: 2 !important
    }

    .rotate-sm-0, .rotate-sm-0-focus:focus, .rotate-sm-0-hover:hover {
        --x-rotate: 0deg !important
    }

    .rotate-sm-1, .rotate-sm-1-focus:focus, .rotate-sm-1-hover:hover {
        --x-rotate: 1deg !important
    }

    .rotate-sm-2, .rotate-sm-2-focus:focus, .rotate-sm-2-hover:hover {
        --x-rotate: 2deg !important
    }

    .rotate-sm-3, .rotate-sm-3-focus:focus, .rotate-sm-3-hover:hover {
        --x-rotate: 3deg !important
    }

    .rotate-sm-6, .rotate-sm-6-focus:focus, .rotate-sm-6-hover:hover {
        --x-rotate: 6deg !important
    }

    .rotate-sm-12, .rotate-sm-12-focus:focus, .rotate-sm-12-hover:hover {
        --x-rotate: 12deg !important
    }

    .rotate-sm-30, .rotate-sm-30-focus:focus, .rotate-sm-30-hover:hover {
        --x-rotate: 30deg !important
    }

    .rotate-sm-45, .rotate-sm-45-focus:focus, .rotate-sm-45-hover:hover {
        --x-rotate: 45deg !important
    }

    .rotate-sm-90, .rotate-sm-90-focus:focus, .rotate-sm-90-hover:hover {
        --x-rotate: 90deg !important
    }

    .rotate-sm-180, .rotate-sm-180-focus:focus, .rotate-sm-180-hover:hover {
        --x-rotate: 180deg !important
    }

    .rotate-sm-n1, .rotate-sm-n1-focus:focus, .rotate-sm-n1-hover:hover {
        --x-rotate: -1deg !important
    }

    .rotate-sm-n2, .rotate-sm-n2-focus:focus, .rotate-sm-n2-hover:hover {
        --x-rotate: -2deg !important
    }

    .rotate-sm-n3, .rotate-sm-n3-focus:focus, .rotate-sm-n3-hover:hover {
        --x-rotate: -3deg !important
    }

    .rotate-sm-n6, .rotate-sm-n6-focus:focus, .rotate-sm-n6-hover:hover {
        --x-rotate: -6deg !important
    }

    .rotate-sm-n12, .rotate-sm-n12-focus:focus, .rotate-sm-n12-hover:hover {
        --x-rotate: -12deg !important
    }

    .rotate-sm-n30, .rotate-sm-n30-focus:focus, .rotate-sm-n30-hover:hover {
        --x-rotate: -30deg !important
    }

    .rotate-sm-n45, .rotate-sm-n45-focus:focus, .rotate-sm-n45-hover:hover {
        --x-rotate: -45deg !important
    }

    .rotate-sm-n90, .rotate-sm-n90-focus:focus, .rotate-sm-n90-hover:hover {
        --x-rotate: -90deg !important
    }

    .rotate-sm-n180, .rotate-sm-n180-focus:focus, .rotate-sm-n180-hover:hover {
        --x-rotate: -180deg !important
    }

    .rotate-y-sm-0, .rotate-y-sm-0-focus:focus, .rotate-y-sm-0-hover:hover {
        --x-rotate-y: 0deg !important
    }

    .rotate-y-sm-1, .rotate-y-sm-1-focus:focus, .rotate-y-sm-1-hover:hover {
        --x-rotate-y: 1deg !important
    }

    .rotate-y-sm-2, .rotate-y-sm-2-focus:focus, .rotate-y-sm-2-hover:hover {
        --x-rotate-y: 2deg !important
    }

    .rotate-y-sm-3, .rotate-y-sm-3-focus:focus, .rotate-y-sm-3-hover:hover {
        --x-rotate-y: 3deg !important
    }

    .rotate-y-sm-6, .rotate-y-sm-6-focus:focus, .rotate-y-sm-6-hover:hover {
        --x-rotate-y: 6deg !important
    }

    .rotate-y-sm-12, .rotate-y-sm-12-focus:focus, .rotate-y-sm-12-hover:hover {
        --x-rotate-y: 12deg !important
    }

    .rotate-y-sm-30, .rotate-y-sm-30-focus:focus, .rotate-y-sm-30-hover:hover {
        --x-rotate-y: 30deg !important
    }

    .rotate-y-sm-45, .rotate-y-sm-45-focus:focus, .rotate-y-sm-45-hover:hover {
        --x-rotate-y: 45deg !important
    }

    .rotate-y-sm-90, .rotate-y-sm-90-focus:focus, .rotate-y-sm-90-hover:hover {
        --x-rotate-y: 90deg !important
    }

    .rotate-y-sm-180, .rotate-y-sm-180-focus:focus, .rotate-y-sm-180-hover:hover {
        --x-rotate-y: 180deg !important
    }

    .rotate-y-sm-n1, .rotate-y-sm-n1-focus:focus, .rotate-y-sm-n1-hover:hover {
        --x-rotate-y: -1deg !important
    }

    .rotate-y-sm-n2, .rotate-y-sm-n2-focus:focus, .rotate-y-sm-n2-hover:hover {
        --x-rotate-y: -2deg !important
    }

    .rotate-y-sm-n3, .rotate-y-sm-n3-focus:focus, .rotate-y-sm-n3-hover:hover {
        --x-rotate-y: -3deg !important
    }

    .rotate-y-sm-n6, .rotate-y-sm-n6-focus:focus, .rotate-y-sm-n6-hover:hover {
        --x-rotate-y: -6deg !important
    }

    .rotate-y-sm-n12, .rotate-y-sm-n12-focus:focus, .rotate-y-sm-n12-hover:hover {
        --x-rotate-y: -12deg !important
    }

    .rotate-y-sm-n30, .rotate-y-sm-n30-focus:focus, .rotate-y-sm-n30-hover:hover {
        --x-rotate-y: -30deg !important
    }

    .rotate-y-sm-n45, .rotate-y-sm-n45-focus:focus, .rotate-y-sm-n45-hover:hover {
        --x-rotate-y: -45deg !important
    }

    .rotate-y-sm-n90, .rotate-y-sm-n90-focus:focus, .rotate-y-sm-n90-hover:hover {
        --x-rotate-y: -90deg !important
    }

    .rotate-y-sm-n180, .rotate-y-sm-n180-focus:focus, .rotate-y-sm-n180-hover:hover {
        --x-rotate-y: -180deg !important
    }

    .rotate-x-sm-0, .rotate-x-sm-0-focus:focus, .rotate-x-sm-0-hover:hover {
        --x-rotate-x: 0deg !important
    }

    .rotate-x-sm-1, .rotate-x-sm-1-focus:focus, .rotate-x-sm-1-hover:hover {
        --x-rotate-x: 1deg !important
    }

    .rotate-x-sm-2, .rotate-x-sm-2-focus:focus, .rotate-x-sm-2-hover:hover {
        --x-rotate-x: 2deg !important
    }

    .rotate-x-sm-3, .rotate-x-sm-3-focus:focus, .rotate-x-sm-3-hover:hover {
        --x-rotate-x: 3deg !important
    }

    .rotate-x-sm-6, .rotate-x-sm-6-focus:focus, .rotate-x-sm-6-hover:hover {
        --x-rotate-x: 6deg !important
    }

    .rotate-x-sm-12, .rotate-x-sm-12-focus:focus, .rotate-x-sm-12-hover:hover {
        --x-rotate-x: 12deg !important
    }

    .rotate-x-sm-30, .rotate-x-sm-30-focus:focus, .rotate-x-sm-30-hover:hover {
        --x-rotate-x: 30deg !important
    }

    .rotate-x-sm-45, .rotate-x-sm-45-focus:focus, .rotate-x-sm-45-hover:hover {
        --x-rotate-x: 45deg !important
    }

    .rotate-x-sm-90, .rotate-x-sm-90-focus:focus, .rotate-x-sm-90-hover:hover {
        --x-rotate-x: 90deg !important
    }

    .rotate-x-sm-180, .rotate-x-sm-180-focus:focus, .rotate-x-sm-180-hover:hover {
        --x-rotate-x: 180deg !important
    }

    .rotate-x-sm-n1, .rotate-x-sm-n1-focus:focus, .rotate-x-sm-n1-hover:hover {
        --x-rotate-x: -1deg !important
    }

    .rotate-x-sm-n2, .rotate-x-sm-n2-focus:focus, .rotate-x-sm-n2-hover:hover {
        --x-rotate-x: -2deg !important
    }

    .rotate-x-sm-n3, .rotate-x-sm-n3-focus:focus, .rotate-x-sm-n3-hover:hover {
        --x-rotate-x: -3deg !important
    }

    .rotate-x-sm-n6, .rotate-x-sm-n6-focus:focus, .rotate-x-sm-n6-hover:hover {
        --x-rotate-x: -6deg !important
    }

    .rotate-x-sm-n12, .rotate-x-sm-n12-focus:focus, .rotate-x-sm-n12-hover:hover {
        --x-rotate-x: -12deg !important
    }

    .rotate-x-sm-n30, .rotate-x-sm-n30-focus:focus, .rotate-x-sm-n30-hover:hover {
        --x-rotate-x: -30deg !important
    }

    .rotate-x-sm-n45, .rotate-x-sm-n45-focus:focus, .rotate-x-sm-n45-hover:hover {
        --x-rotate-x: -45deg !important
    }

    .rotate-x-sm-n90, .rotate-x-sm-n90-focus:focus, .rotate-x-sm-n90-hover:hover {
        --x-rotate-x: -90deg !important
    }

    .rotate-x-sm-n180, .rotate-x-sm-n180-focus:focus, .rotate-x-sm-n180-hover:hover {
        --x-rotate-x: -180deg !important
    }

    .perspective-sm-100, .perspective-sm-100-focus:focus, .perspective-sm-100-hover:hover {
        --x-perspective: 100px !important
    }

    .perspective-sm-200, .perspective-sm-200-focus:focus, .perspective-sm-200-hover:hover {
        --x-perspective: 200px !important
    }

    .perspective-sm-300, .perspective-sm-300-focus:focus, .perspective-sm-300-hover:hover {
        --x-perspective: 300px !important
    }

    .perspective-sm-400, .perspective-sm-400-focus:focus, .perspective-sm-400-hover:hover {
        --x-perspective: 400px !important
    }

    .perspective-sm-500, .perspective-sm-500-focus:focus, .perspective-sm-500-hover:hover {
        --x-perspective: 500px !important
    }

    .perspective-sm-600, .perspective-sm-600-focus:focus, .perspective-sm-600-hover:hover {
        --x-perspective: 600px !important
    }

    .perspective-sm-700, .perspective-sm-700-focus:focus, .perspective-sm-700-hover:hover {
        --x-perspective: 700px !important
    }

    .perspective-sm-800, .perspective-sm-800-focus:focus, .perspective-sm-800-hover:hover {
        --x-perspective: 800px !important
    }

    .perspective-sm-1000, .perspective-sm-1000-focus:focus, .perspective-sm-1000-hover:hover {
        --x-perspective: 1000px !important
    }

    .perspective-sm-1250, .perspective-sm-1250-focus:focus, .perspective-sm-1250-hover:hover {
        --x-perspective: 1250px !important
    }

    .perspective-sm-1500, .perspective-sm-1500-focus:focus, .perspective-sm-1500-hover:hover {
        --x-perspective: 1500px !important
    }

    .translate-y-sm-0, .translate-y-sm-0-focus:focus, .translate-y-sm-0-hover:hover {
        --x-translate-y: 0 !important
    }

    .translate-y-sm-1, .translate-y-sm-1-focus:focus, .translate-y-sm-1-hover:hover {
        --x-translate-y: 0.25rem !important
    }

    .translate-y-sm-2, .translate-y-sm-2-focus:focus, .translate-y-sm-2-hover:hover {
        --x-translate-y: 0.5rem !important
    }

    .translate-y-sm-3, .translate-y-sm-3-focus:focus, .translate-y-sm-3-hover:hover {
        --x-translate-y: 0.75rem !important
    }

    .translate-y-sm-4, .translate-y-sm-4-focus:focus, .translate-y-sm-4-hover:hover {
        --x-translate-y: 1rem !important
    }

    .translate-y-sm-5, .translate-y-sm-5-focus:focus, .translate-y-sm-5-hover:hover {
        --x-translate-y: 1.25rem !important
    }

    .translate-y-sm-6, .translate-y-sm-6-focus:focus, .translate-y-sm-6-hover:hover {
        --x-translate-y: 1.5rem !important
    }

    .translate-y-sm-7, .translate-y-sm-7-focus:focus, .translate-y-sm-7-hover:hover {
        --x-translate-y: 1.75rem !important
    }

    .translate-y-sm-8, .translate-y-sm-8-focus:focus, .translate-y-sm-8-hover:hover {
        --x-translate-y: 2rem !important
    }

    .translate-y-sm-10, .translate-y-sm-10-focus:focus, .translate-y-sm-10-hover:hover {
        --x-translate-y: 2.5rem !important
    }

    .translate-y-sm-12, .translate-y-sm-12-focus:focus, .translate-y-sm-12-hover:hover {
        --x-translate-y: 3rem !important
    }

    .translate-y-sm-14, .translate-y-sm-14-focus:focus, .translate-y-sm-14-hover:hover {
        --x-translate-y: 3.5rem !important
    }

    .translate-y-sm-16, .translate-y-sm-16-focus:focus, .translate-y-sm-16-hover:hover {
        --x-translate-y: 4rem !important
    }

    .translate-y-sm-18, .translate-y-sm-18-focus:focus, .translate-y-sm-18-hover:hover {
        --x-translate-y: 4.5rem !important
    }

    .translate-y-sm-20, .translate-y-sm-20-focus:focus, .translate-y-sm-20-hover:hover {
        --x-translate-y: 5rem !important
    }

    .translate-y-sm-24, .translate-y-sm-24-focus:focus, .translate-y-sm-24-hover:hover {
        --x-translate-y: 6rem !important
    }

    .translate-y-sm-32, .translate-y-sm-32-focus:focus, .translate-y-sm-32-hover:hover {
        --x-translate-y: 8rem !important
    }

    .translate-y-sm-40, .translate-y-sm-40-focus:focus, .translate-y-sm-40-hover:hover {
        --x-translate-y: 10rem !important
    }

    .translate-y-sm-48, .translate-y-sm-48-focus:focus, .translate-y-sm-48-hover:hover {
        --x-translate-y: 12rem !important
    }

    .translate-y-sm-56, .translate-y-sm-56-focus:focus, .translate-y-sm-56-hover:hover {
        --x-translate-y: 14rem !important
    }

    .translate-y-sm-64, .translate-y-sm-64-focus:focus, .translate-y-sm-64-hover:hover {
        --x-translate-y: 16rem !important
    }

    .translate-y-sm-72, .translate-y-sm-72-focus:focus, .translate-y-sm-72-hover:hover {
        --x-translate-y: 18rem !important
    }

    .translate-y-sm-80, .translate-y-sm-80-focus:focus, .translate-y-sm-80-hover:hover {
        --x-translate-y: 20rem !important
    }

    .translate-y-sm-88, .translate-y-sm-88-focus:focus, .translate-y-sm-88-hover:hover {
        --x-translate-y: 22rem !important
    }

    .translate-y-sm-96, .translate-y-sm-96-focus:focus, .translate-y-sm-96-hover:hover {
        --x-translate-y: 24rem !important
    }

    .translate-y-sm-px, .translate-y-sm-px-focus:focus, .translate-y-sm-px-hover:hover {
        --x-translate-y: 1px !important
    }

    .translate-y-sm-1\/2, .translate-y-sm-1\/2-focus:focus, .translate-y-sm-1\/2-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-sm-1\/3, .translate-y-sm-1\/3-focus:focus, .translate-y-sm-1\/3-hover:hover {
        --x-translate-y: 33.333333% !important
    }

    .translate-y-sm-1\/4, .translate-y-sm-1\/4-focus:focus, .translate-y-sm-1\/4-hover:hover {
        --x-translate-y: 25% !important
    }

    .translate-y-sm-1\/5, .translate-y-sm-1\/5-focus:focus, .translate-y-sm-1\/5-hover:hover {
        --x-translate-y: 20% !important
    }

    .translate-y-sm-2\/3, .translate-y-sm-2\/3-focus:focus, .translate-y-sm-2\/3-hover:hover {
        --x-translate-y: 66.666667% !important
    }

    .translate-y-sm-2\/5, .translate-y-sm-2\/5-focus:focus, .translate-y-sm-2\/5-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-sm-3\/4, .translate-y-sm-3\/4-focus:focus, .translate-y-sm-3\/4-hover:hover {
        --x-translate-y: 75% !important
    }

    .translate-y-sm-full, .translate-y-sm-full-focus:focus, .translate-y-sm-full-hover:hover {
        --x-translate-y: 100% !important
    }

    .translate-y-sm-n1, .translate-y-sm-n1-focus:focus, .translate-y-sm-n1-hover:hover {
        --x-translate-y: -0.25rem !important
    }

    .translate-y-sm-n2, .translate-y-sm-n2-focus:focus, .translate-y-sm-n2-hover:hover {
        --x-translate-y: -0.5rem !important
    }

    .translate-y-sm-n3, .translate-y-sm-n3-focus:focus, .translate-y-sm-n3-hover:hover {
        --x-translate-y: -0.75rem !important
    }

    .translate-y-sm-n4, .translate-y-sm-n4-focus:focus, .translate-y-sm-n4-hover:hover {
        --x-translate-y: -1rem !important
    }

    .translate-y-sm-n5, .translate-y-sm-n5-focus:focus, .translate-y-sm-n5-hover:hover {
        --x-translate-y: -1.25rem !important
    }

    .translate-y-sm-n6, .translate-y-sm-n6-focus:focus, .translate-y-sm-n6-hover:hover {
        --x-translate-y: -1.5rem !important
    }

    .translate-y-sm-n7, .translate-y-sm-n7-focus:focus, .translate-y-sm-n7-hover:hover {
        --x-translate-y: -1.75rem !important
    }

    .translate-y-sm-n8, .translate-y-sm-n8-focus:focus, .translate-y-sm-n8-hover:hover {
        --x-translate-y: -2rem !important
    }

    .translate-y-sm-n10, .translate-y-sm-n10-focus:focus, .translate-y-sm-n10-hover:hover {
        --x-translate-y: -2.5rem !important
    }

    .translate-y-sm-n12, .translate-y-sm-n12-focus:focus, .translate-y-sm-n12-hover:hover {
        --x-translate-y: -3rem !important
    }

    .translate-y-sm-n14, .translate-y-sm-n14-focus:focus, .translate-y-sm-n14-hover:hover {
        --x-translate-y: -3.5rem !important
    }

    .translate-y-sm-n16, .translate-y-sm-n16-focus:focus, .translate-y-sm-n16-hover:hover {
        --x-translate-y: -4rem !important
    }

    .translate-y-sm-n18, .translate-y-sm-n18-focus:focus, .translate-y-sm-n18-hover:hover {
        --x-translate-y: -4.5rem !important
    }

    .translate-y-sm-n20, .translate-y-sm-n20-focus:focus, .translate-y-sm-n20-hover:hover {
        --x-translate-y: -5rem !important
    }

    .translate-y-sm-n24, .translate-y-sm-n24-focus:focus, .translate-y-sm-n24-hover:hover {
        --x-translate-y: -6rem !important
    }

    .translate-y-sm-n32, .translate-y-sm-n32-focus:focus, .translate-y-sm-n32-hover:hover {
        --x-translate-y: -8rem !important
    }

    .translate-y-sm-n40, .translate-y-sm-n40-focus:focus, .translate-y-sm-n40-hover:hover {
        --x-translate-y: -10rem !important
    }

    .translate-y-sm-n48, .translate-y-sm-n48-focus:focus, .translate-y-sm-n48-hover:hover {
        --x-translate-y: -12rem !important
    }

    .translate-y-sm-n56, .translate-y-sm-n56-focus:focus, .translate-y-sm-n56-hover:hover {
        --x-translate-y: -14rem !important
    }

    .translate-y-sm-n64, .translate-y-sm-n64-focus:focus, .translate-y-sm-n64-hover:hover {
        --x-translate-y: -16rem !important
    }

    .translate-y-sm-n72, .translate-y-sm-n72-focus:focus, .translate-y-sm-n72-hover:hover {
        --x-translate-y: -18rem !important
    }

    .translate-y-sm-n80, .translate-y-sm-n80-focus:focus, .translate-y-sm-n80-hover:hover {
        --x-translate-y: -20rem !important
    }

    .translate-y-sm-n88, .translate-y-sm-n88-focus:focus, .translate-y-sm-n88-hover:hover {
        --x-translate-y: -22rem !important
    }

    .translate-y-sm-n96, .translate-y-sm-n96-focus:focus, .translate-y-sm-n96-hover:hover {
        --x-translate-y: -24rem !important
    }

    .translate-y-sm-npx, .translate-y-sm-npx-focus:focus, .translate-y-sm-npx-hover:hover {
        --x-translate-y: -1px !important
    }

    .translate-y-sm-n1\/2, .translate-y-sm-n1\/2-focus:focus, .translate-y-sm-n1\/2-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-sm-n1\/3, .translate-y-sm-n1\/3-focus:focus, .translate-y-sm-n1\/3-hover:hover {
        --x-translate-y: -33.333333% !important
    }

    .translate-y-sm-n1\/4, .translate-y-sm-n1\/4-focus:focus, .translate-y-sm-n1\/4-hover:hover {
        --x-translate-y: -25% !important
    }

    .translate-y-sm-n1\/5, .translate-y-sm-n1\/5-focus:focus, .translate-y-sm-n1\/5-hover:hover {
        --x-translate-y: -20% !important
    }

    .translate-y-sm-n2\/3, .translate-y-sm-n2\/3-focus:focus, .translate-y-sm-n2\/3-hover:hover {
        --x-translate-y: -66.666667% !important
    }

    .translate-y-sm-n2\/5, .translate-y-sm-n2\/5-focus:focus, .translate-y-sm-n2\/5-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-sm-n3\/4, .translate-y-sm-n3\/4-focus:focus, .translate-y-sm-n3\/4-hover:hover {
        --x-translate-y: -75% !important
    }

    .translate-y-sm-nfull, .translate-y-sm-nfull-focus:focus, .translate-y-sm-nfull-hover:hover {
        --x-translate-y: -100% !important
    }

    .translate-x-sm-0, .translate-x-sm-0-focus:focus, .translate-x-sm-0-hover:hover {
        --x-translate-x: 0 !important
    }

    .translate-x-sm-1, .translate-x-sm-1-focus:focus, .translate-x-sm-1-hover:hover {
        --x-translate-x: 0.25rem !important
    }

    .translate-x-sm-2, .translate-x-sm-2-focus:focus, .translate-x-sm-2-hover:hover {
        --x-translate-x: 0.5rem !important
    }

    .translate-x-sm-3, .translate-x-sm-3-focus:focus, .translate-x-sm-3-hover:hover {
        --x-translate-x: 0.75rem !important
    }

    .translate-x-sm-4, .translate-x-sm-4-focus:focus, .translate-x-sm-4-hover:hover {
        --x-translate-x: 1rem !important
    }

    .translate-x-sm-5, .translate-x-sm-5-focus:focus, .translate-x-sm-5-hover:hover {
        --x-translate-x: 1.25rem !important
    }

    .translate-x-sm-6, .translate-x-sm-6-focus:focus, .translate-x-sm-6-hover:hover {
        --x-translate-x: 1.5rem !important
    }

    .translate-x-sm-7, .translate-x-sm-7-focus:focus, .translate-x-sm-7-hover:hover {
        --x-translate-x: 1.75rem !important
    }

    .translate-x-sm-8, .translate-x-sm-8-focus:focus, .translate-x-sm-8-hover:hover {
        --x-translate-x: 2rem !important
    }

    .translate-x-sm-10, .translate-x-sm-10-focus:focus, .translate-x-sm-10-hover:hover {
        --x-translate-x: 2.5rem !important
    }

    .translate-x-sm-12, .translate-x-sm-12-focus:focus, .translate-x-sm-12-hover:hover {
        --x-translate-x: 3rem !important
    }

    .translate-x-sm-14, .translate-x-sm-14-focus:focus, .translate-x-sm-14-hover:hover {
        --x-translate-x: 3.5rem !important
    }

    .translate-x-sm-16, .translate-x-sm-16-focus:focus, .translate-x-sm-16-hover:hover {
        --x-translate-x: 4rem !important
    }

    .translate-x-sm-18, .translate-x-sm-18-focus:focus, .translate-x-sm-18-hover:hover {
        --x-translate-x: 4.5rem !important
    }

    .translate-x-sm-20, .translate-x-sm-20-focus:focus, .translate-x-sm-20-hover:hover {
        --x-translate-x: 5rem !important
    }

    .translate-x-sm-24, .translate-x-sm-24-focus:focus, .translate-x-sm-24-hover:hover {
        --x-translate-x: 6rem !important
    }

    .translate-x-sm-32, .translate-x-sm-32-focus:focus, .translate-x-sm-32-hover:hover {
        --x-translate-x: 8rem !important
    }

    .translate-x-sm-40, .translate-x-sm-40-focus:focus, .translate-x-sm-40-hover:hover {
        --x-translate-x: 10rem !important
    }

    .translate-x-sm-48, .translate-x-sm-48-focus:focus, .translate-x-sm-48-hover:hover {
        --x-translate-x: 12rem !important
    }

    .translate-x-sm-56, .translate-x-sm-56-focus:focus, .translate-x-sm-56-hover:hover {
        --x-translate-x: 14rem !important
    }

    .translate-x-sm-64, .translate-x-sm-64-focus:focus, .translate-x-sm-64-hover:hover {
        --x-translate-x: 16rem !important
    }

    .translate-x-sm-72, .translate-x-sm-72-focus:focus, .translate-x-sm-72-hover:hover {
        --x-translate-x: 18rem !important
    }

    .translate-x-sm-80, .translate-x-sm-80-focus:focus, .translate-x-sm-80-hover:hover {
        --x-translate-x: 20rem !important
    }

    .translate-x-sm-88, .translate-x-sm-88-focus:focus, .translate-x-sm-88-hover:hover {
        --x-translate-x: 22rem !important
    }

    .translate-x-sm-96, .translate-x-sm-96-focus:focus, .translate-x-sm-96-hover:hover {
        --x-translate-x: 24rem !important
    }

    .translate-x-sm-px, .translate-x-sm-px-focus:focus, .translate-x-sm-px-hover:hover {
        --x-translate-x: 1px !important
    }

    .translate-x-sm-1\/2, .translate-x-sm-1\/2-focus:focus, .translate-x-sm-1\/2-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-sm-1\/3, .translate-x-sm-1\/3-focus:focus, .translate-x-sm-1\/3-hover:hover {
        --x-translate-x: 33.333333% !important
    }

    .translate-x-sm-1\/4, .translate-x-sm-1\/4-focus:focus, .translate-x-sm-1\/4-hover:hover {
        --x-translate-x: 25% !important
    }

    .translate-x-sm-1\/5, .translate-x-sm-1\/5-focus:focus, .translate-x-sm-1\/5-hover:hover {
        --x-translate-x: 20% !important
    }

    .translate-x-sm-2\/3, .translate-x-sm-2\/3-focus:focus, .translate-x-sm-2\/3-hover:hover {
        --x-translate-x: 66.666667% !important
    }

    .translate-x-sm-2\/5, .translate-x-sm-2\/5-focus:focus, .translate-x-sm-2\/5-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-sm-3\/4, .translate-x-sm-3\/4-focus:focus, .translate-x-sm-3\/4-hover:hover {
        --x-translate-x: 75% !important
    }

    .translate-x-sm-full, .translate-x-sm-full-focus:focus, .translate-x-sm-full-hover:hover {
        --x-translate-x: 100% !important
    }

    .translate-x-sm-n1, .translate-x-sm-n1-focus:focus, .translate-x-sm-n1-hover:hover {
        --x-translate-x: -0.25rem !important
    }

    .translate-x-sm-n2, .translate-x-sm-n2-focus:focus, .translate-x-sm-n2-hover:hover {
        --x-translate-x: -0.5rem !important
    }

    .translate-x-sm-n3, .translate-x-sm-n3-focus:focus, .translate-x-sm-n3-hover:hover {
        --x-translate-x: -0.75rem !important
    }

    .translate-x-sm-n4, .translate-x-sm-n4-focus:focus, .translate-x-sm-n4-hover:hover {
        --x-translate-x: -1rem !important
    }

    .translate-x-sm-n5, .translate-x-sm-n5-focus:focus, .translate-x-sm-n5-hover:hover {
        --x-translate-x: -1.25rem !important
    }

    .translate-x-sm-n6, .translate-x-sm-n6-focus:focus, .translate-x-sm-n6-hover:hover {
        --x-translate-x: -1.5rem !important
    }

    .translate-x-sm-n7, .translate-x-sm-n7-focus:focus, .translate-x-sm-n7-hover:hover {
        --x-translate-x: -1.75rem !important
    }

    .translate-x-sm-n8, .translate-x-sm-n8-focus:focus, .translate-x-sm-n8-hover:hover {
        --x-translate-x: -2rem !important
    }

    .translate-x-sm-n10, .translate-x-sm-n10-focus:focus, .translate-x-sm-n10-hover:hover {
        --x-translate-x: -2.5rem !important
    }

    .translate-x-sm-n12, .translate-x-sm-n12-focus:focus, .translate-x-sm-n12-hover:hover {
        --x-translate-x: -3rem !important
    }

    .translate-x-sm-n14, .translate-x-sm-n14-focus:focus, .translate-x-sm-n14-hover:hover {
        --x-translate-x: -3.5rem !important
    }

    .translate-x-sm-n16, .translate-x-sm-n16-focus:focus, .translate-x-sm-n16-hover:hover {
        --x-translate-x: -4rem !important
    }

    .translate-x-sm-n18, .translate-x-sm-n18-focus:focus, .translate-x-sm-n18-hover:hover {
        --x-translate-x: -4.5rem !important
    }

    .translate-x-sm-n20, .translate-x-sm-n20-focus:focus, .translate-x-sm-n20-hover:hover {
        --x-translate-x: -5rem !important
    }

    .translate-x-sm-n24, .translate-x-sm-n24-focus:focus, .translate-x-sm-n24-hover:hover {
        --x-translate-x: -6rem !important
    }

    .translate-x-sm-n32, .translate-x-sm-n32-focus:focus, .translate-x-sm-n32-hover:hover {
        --x-translate-x: -8rem !important
    }

    .translate-x-sm-n40, .translate-x-sm-n40-focus:focus, .translate-x-sm-n40-hover:hover {
        --x-translate-x: -10rem !important
    }

    .translate-x-sm-n48, .translate-x-sm-n48-focus:focus, .translate-x-sm-n48-hover:hover {
        --x-translate-x: -12rem !important
    }

    .translate-x-sm-n56, .translate-x-sm-n56-focus:focus, .translate-x-sm-n56-hover:hover {
        --x-translate-x: -14rem !important
    }

    .translate-x-sm-n64, .translate-x-sm-n64-focus:focus, .translate-x-sm-n64-hover:hover {
        --x-translate-x: -16rem !important
    }

    .translate-x-sm-n72, .translate-x-sm-n72-focus:focus, .translate-x-sm-n72-hover:hover {
        --x-translate-x: -18rem !important
    }

    .translate-x-sm-n80, .translate-x-sm-n80-focus:focus, .translate-x-sm-n80-hover:hover {
        --x-translate-x: -20rem !important
    }

    .translate-x-sm-n88, .translate-x-sm-n88-focus:focus, .translate-x-sm-n88-hover:hover {
        --x-translate-x: -22rem !important
    }

    .translate-x-sm-n96, .translate-x-sm-n96-focus:focus, .translate-x-sm-n96-hover:hover {
        --x-translate-x: -24rem !important
    }

    .translate-x-sm-npx, .translate-x-sm-npx-focus:focus, .translate-x-sm-npx-hover:hover {
        --x-translate-x: -1px !important
    }

    .translate-x-sm-n1\/2, .translate-x-sm-n1\/2-focus:focus, .translate-x-sm-n1\/2-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-sm-n1\/3, .translate-x-sm-n1\/3-focus:focus, .translate-x-sm-n1\/3-hover:hover {
        --x-translate-x: -33.333333% !important
    }

    .translate-x-sm-n1\/4, .translate-x-sm-n1\/4-focus:focus, .translate-x-sm-n1\/4-hover:hover {
        --x-translate-x: -25% !important
    }

    .translate-x-sm-n1\/5, .translate-x-sm-n1\/5-focus:focus, .translate-x-sm-n1\/5-hover:hover {
        --x-translate-x: -20% !important
    }

    .translate-x-sm-n2\/3, .translate-x-sm-n2\/3-focus:focus, .translate-x-sm-n2\/3-hover:hover {
        --x-translate-x: -66.666667% !important
    }

    .translate-x-sm-n2\/5, .translate-x-sm-n2\/5-focus:focus, .translate-x-sm-n2\/5-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-sm-n3\/4, .translate-x-sm-n3\/4-focus:focus, .translate-x-sm-n3\/4-hover:hover {
        --x-translate-x: -75% !important
    }

    .translate-x-sm-nfull, .translate-x-sm-nfull-focus:focus, .translate-x-sm-nfull-hover:hover {
        --x-translate-x: -100% !important
    }
}

@media (min-width:768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .overflow-md-auto {
        overflow: auto !important
    }

    .overflow-md-hidden {
        overflow: hidden !important
    }

    .overflow-md-visible {
        overflow: visible !important
    }

    .overflow-md-scroll {
        overflow: scroll !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .shadow-md, .shadow-md-focus:focus, .shadow-md-hover:hover {
        box-shadow: 0 3px 3px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-md-1, .shadow-md-1-focus:focus, .shadow-md-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-md-2, .shadow-md-2-focus:focus, .shadow-md-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-md-3, .shadow-md-3-focus:focus, .shadow-md-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-md-4, .shadow-md-4-focus:focus, .shadow-md-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-md-5, .shadow-md-5-focus:focus, .shadow-md-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-md-6, .shadow-md-6-focus:focus, .shadow-md-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-md-inset, .shadow-md-inset-focus:focus, .shadow-md-inset-hover:hover {
        box-shadow: inset 0 1px 2px rgba(0,0,0,.075) !important
    }

    .shadow-md-focus, .shadow-md-focus-focus:focus, .shadow-md-focus-hover:hover, .shadow-md-outline, .shadow-md-outline-focus:focus, .shadow-md-outline-hover:hover {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5) !important
    }

    .shadow-md-none, .shadow-md-none-focus:focus, .shadow-md-none-hover:hover {
        box-shadow: none !important
    }

    .position-md-static {
        position: static !important
    }

    .position-md-relative {
        position: relative !important
    }

    .position-md-absolute {
        position: absolute !important
    }

    .position-md-fixed {
        position: fixed !important
    }

    .position-md-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-md-0 {
        top: 0 !important
    }

    .top-md-1 {
        top: .25rem !important
    }

    .top-md-2 {
        top: .5rem !important
    }

    .top-md-3 {
        top: .75rem !important
    }

    .top-md-4 {
        top: 1rem !important
    }

    .top-md-5 {
        top: 1.25rem !important
    }

    .top-md-6 {
        top: 1.5rem !important
    }

    .top-md-7 {
        top: 1.75rem !important
    }

    .top-md-8 {
        top: 2rem !important
    }

    .top-md-10 {
        top: 2.5rem !important
    }

    .top-md-12 {
        top: 3rem !important
    }

    .top-md-14 {
        top: 3.5rem !important
    }

    .top-md-16 {
        top: 4rem !important
    }

    .top-md-18 {
        top: 4.5rem !important
    }

    .top-md-20 {
        top: 5rem !important
    }

    .top-md-24 {
        top: 6rem !important
    }

    .top-md-32 {
        top: 8rem !important
    }

    .top-md-40 {
        top: 10rem !important
    }

    .top-md-48 {
        top: 12rem !important
    }

    .top-md-56 {
        top: 14rem !important
    }

    .top-md-64 {
        top: 16rem !important
    }

    .top-md-72 {
        top: 18rem !important
    }

    .top-md-80 {
        top: 20rem !important
    }

    .top-md-88 {
        top: 22rem !important
    }

    .top-md-96 {
        top: 24rem !important
    }

    .top-md-px {
        top: 1px !important
    }

    .top-md-1\/2 {
        top: 50% !important
    }

    .top-md-full {
        top: 100% !important
    }

    .top-md-auto {
        top: auto !important
    }

    .bottom-md-0 {
        bottom: 0 !important
    }

    .bottom-md-1 {
        bottom: .25rem !important
    }

    .bottom-md-2 {
        bottom: .5rem !important
    }

    .bottom-md-3 {
        bottom: .75rem !important
    }

    .bottom-md-4 {
        bottom: 1rem !important
    }

    .bottom-md-5 {
        bottom: 1.25rem !important
    }

    .bottom-md-6 {
        bottom: 1.5rem !important
    }

    .bottom-md-7 {
        bottom: 1.75rem !important
    }

    .bottom-md-8 {
        bottom: 2rem !important
    }

    .bottom-md-10 {
        bottom: 2.5rem !important
    }

    .bottom-md-12 {
        bottom: 3rem !important
    }

    .bottom-md-14 {
        bottom: 3.5rem !important
    }

    .bottom-md-16 {
        bottom: 4rem !important
    }

    .bottom-md-18 {
        bottom: 4.5rem !important
    }

    .bottom-md-20 {
        bottom: 5rem !important
    }

    .bottom-md-24 {
        bottom: 6rem !important
    }

    .bottom-md-32 {
        bottom: 8rem !important
    }

    .bottom-md-40 {
        bottom: 10rem !important
    }

    .bottom-md-48 {
        bottom: 12rem !important
    }

    .bottom-md-56 {
        bottom: 14rem !important
    }

    .bottom-md-64 {
        bottom: 16rem !important
    }

    .bottom-md-72 {
        bottom: 18rem !important
    }

    .bottom-md-80 {
        bottom: 20rem !important
    }

    .bottom-md-88 {
        bottom: 22rem !important
    }

    .bottom-md-96 {
        bottom: 24rem !important
    }

    .bottom-md-px {
        bottom: 1px !important
    }

    .bottom-md-1\/2 {
        bottom: 50% !important
    }

    .bottom-md-full {
        bottom: 100% !important
    }

    .bottom-md-auto {
        bottom: auto !important
    }

    .start-md-0 {
        left: 0 !important
    }

    .start-md-1 {
        left: .25rem !important
    }

    .start-md-2 {
        left: .5rem !important
    }

    .start-md-3 {
        left: .75rem !important
    }

    .start-md-4 {
        left: 1rem !important
    }

    .start-md-5 {
        left: 1.25rem !important
    }

    .start-md-6 {
        left: 1.5rem !important
    }

    .start-md-7 {
        left: 1.75rem !important
    }

    .start-md-8 {
        left: 2rem !important
    }

    .start-md-10 {
        left: 2.5rem !important
    }

    .start-md-12 {
        left: 3rem !important
    }

    .start-md-14 {
        left: 3.5rem !important
    }

    .start-md-16 {
        left: 4rem !important
    }

    .start-md-18 {
        left: 4.5rem !important
    }

    .start-md-20 {
        left: 5rem !important
    }

    .start-md-24 {
        left: 6rem !important
    }

    .start-md-32 {
        left: 8rem !important
    }

    .start-md-40 {
        left: 10rem !important
    }

    .start-md-48 {
        left: 12rem !important
    }

    .start-md-56 {
        left: 14rem !important
    }

    .start-md-64 {
        left: 16rem !important
    }

    .start-md-72 {
        left: 18rem !important
    }

    .start-md-80 {
        left: 20rem !important
    }

    .start-md-88 {
        left: 22rem !important
    }

    .start-md-96 {
        left: 24rem !important
    }

    .start-md-px {
        left: 1px !important
    }

    .start-md-1\/2 {
        left: 50% !important
    }

    .start-md-full {
        left: 100% !important
    }

    .start-md-auto {
        left: auto !important
    }

    .end-md-0 {
        right: 0 !important
    }

    .end-md-1 {
        right: .25rem !important
    }

    .end-md-2 {
        right: .5rem !important
    }

    .end-md-3 {
        right: .75rem !important
    }

    .end-md-4 {
        right: 1rem !important
    }

    .end-md-5 {
        right: 1.25rem !important
    }

    .end-md-6 {
        right: 1.5rem !important
    }

    .end-md-7 {
        right: 1.75rem !important
    }

    .end-md-8 {
        right: 2rem !important
    }

    .end-md-10 {
        right: 2.5rem !important
    }

    .end-md-12 {
        right: 3rem !important
    }

    .end-md-14 {
        right: 3.5rem !important
    }

    .end-md-16 {
        right: 4rem !important
    }

    .end-md-18 {
        right: 4.5rem !important
    }

    .end-md-20 {
        right: 5rem !important
    }

    .end-md-24 {
        right: 6rem !important
    }

    .end-md-32 {
        right: 8rem !important
    }

    .end-md-40 {
        right: 10rem !important
    }

    .end-md-48 {
        right: 12rem !important
    }

    .end-md-56 {
        right: 14rem !important
    }

    .end-md-64 {
        right: 16rem !important
    }

    .end-md-72 {
        right: 18rem !important
    }

    .end-md-80 {
        right: 20rem !important
    }

    .end-md-88 {
        right: 22rem !important
    }

    .end-md-96 {
        right: 24rem !important
    }

    .end-md-px {
        right: 1px !important
    }

    .end-md-1\/2 {
        right: 50% !important
    }

    .end-md-full {
        right: 100% !important
    }

    .end-md-auto {
        right: auto !important
    }

    .border-top-md-0, .border-top-md-0-focus:focus, .border-top-md-0-hover:hover {
        border-top-width: 0 !important
    }

    .border-top-md, .border-top-md-focus:focus, .border-top-md-hover:hover {
        border-top-width: 1px !important
    }

    .border-top-md-2, .border-top-md-2-focus:focus, .border-top-md-2-hover:hover {
        border-top-width: 2px !important
    }

    .border-top-md-3, .border-top-md-3-focus:focus, .border-top-md-3-hover:hover {
        border-top-width: 3px !important
    }

    .border-top-md-4, .border-top-md-4-focus:focus, .border-top-md-4-hover:hover {
        border-top-width: 4px !important
    }

    .border-top-md-5, .border-top-md-5-focus:focus, .border-top-md-5-hover:hover {
        border-top-width: 5px !important
    }

    .border-top-md-6, .border-top-md-6-focus:focus, .border-top-md-6-hover:hover {
        border-top-width: 6px !important
    }

    .border-top-md-7, .border-top-md-7-focus:focus, .border-top-md-7-hover:hover {
        border-top-width: 7px !important
    }

    .border-top-md-8, .border-top-md-8-focus:focus, .border-top-md-8-hover:hover {
        border-top-width: 8px !important
    }

    .border-end-md-0, .border-end-md-0-focus:focus, .border-end-md-0-hover:hover {
        border-right-width: 0 !important
    }

    .border-end-md, .border-end-md-focus:focus, .border-end-md-hover:hover {
        border-right-width: 1px !important
    }

    .border-end-md-2, .border-end-md-2-focus:focus, .border-end-md-2-hover:hover {
        border-right-width: 2px !important
    }

    .border-end-md-3, .border-end-md-3-focus:focus, .border-end-md-3-hover:hover {
        border-right-width: 3px !important
    }

    .border-end-md-4, .border-end-md-4-focus:focus, .border-end-md-4-hover:hover {
        border-right-width: 4px !important
    }

    .border-end-md-5, .border-end-md-5-focus:focus, .border-end-md-5-hover:hover {
        border-right-width: 5px !important
    }

    .border-end-md-6, .border-end-md-6-focus:focus, .border-end-md-6-hover:hover {
        border-right-width: 6px !important
    }

    .border-end-md-7, .border-end-md-7-focus:focus, .border-end-md-7-hover:hover {
        border-right-width: 7px !important
    }

    .border-end-md-8, .border-end-md-8-focus:focus, .border-end-md-8-hover:hover {
        border-right-width: 8px !important
    }

    .border-bottom-md-0, .border-bottom-md-0-focus:focus, .border-bottom-md-0-hover:hover {
        border-bottom-width: 0 !important
    }

    .border-bottom-md, .border-bottom-md-focus:focus, .border-bottom-md-hover:hover {
        border-bottom-width: 1px !important
    }

    .border-bottom-md-2, .border-bottom-md-2-focus:focus, .border-bottom-md-2-hover:hover {
        border-bottom-width: 2px !important
    }

    .border-bottom-md-3, .border-bottom-md-3-focus:focus, .border-bottom-md-3-hover:hover {
        border-bottom-width: 3px !important
    }

    .border-bottom-md-4, .border-bottom-md-4-focus:focus, .border-bottom-md-4-hover:hover {
        border-bottom-width: 4px !important
    }

    .border-bottom-md-5, .border-bottom-md-5-focus:focus, .border-bottom-md-5-hover:hover {
        border-bottom-width: 5px !important
    }

    .border-bottom-md-6, .border-bottom-md-6-focus:focus, .border-bottom-md-6-hover:hover {
        border-bottom-width: 6px !important
    }

    .border-bottom-md-7, .border-bottom-md-7-focus:focus, .border-bottom-md-7-hover:hover {
        border-bottom-width: 7px !important
    }

    .border-bottom-md-8, .border-bottom-md-8-focus:focus, .border-bottom-md-8-hover:hover {
        border-bottom-width: 8px !important
    }

    .border-start-md-0, .border-start-md-0-focus:focus, .border-start-md-0-hover:hover {
        border-left-width: 0 !important
    }

    .border-start-md, .border-start-md-focus:focus, .border-start-md-hover:hover {
        border-left-width: 1px !important
    }

    .border-start-md-2, .border-start-md-2-focus:focus, .border-start-md-2-hover:hover {
        border-left-width: 2px !important
    }

    .border-start-md-3, .border-start-md-3-focus:focus, .border-start-md-3-hover:hover {
        border-left-width: 3px !important
    }

    .border-start-md-4, .border-start-md-4-focus:focus, .border-start-md-4-hover:hover {
        border-left-width: 4px !important
    }

    .border-start-md-5, .border-start-md-5-focus:focus, .border-start-md-5-hover:hover {
        border-left-width: 5px !important
    }

    .border-start-md-6, .border-start-md-6-focus:focus, .border-start-md-6-hover:hover {
        border-left-width: 6px !important
    }

    .border-start-md-7, .border-start-md-7-focus:focus, .border-start-md-7-hover:hover {
        border-left-width: 7px !important
    }

    .border-start-md-8, .border-start-md-8-focus:focus, .border-start-md-8-hover:hover {
        border-left-width: 8px !important
    }

    .border-md-primary, .border-md-primary-focus:focus, .border-md-primary-hover:hover {
        border-color: #5c60f5 !important
    }

    .border-md-primary-focus-within:focus-within {
        border-color: #5c60f5 !important
    }

    .border-md-secondary, .border-md-secondary-focus:focus, .border-md-secondary-hover:hover {
        border-color: #cfd6df !important
    }

    .border-md-secondary-focus-within:focus-within {
        border-color: #cfd6df !important
    }

    .border-md-tertiary, .border-md-tertiary-focus:focus, .border-md-tertiary-hover:hover {
        border-color: #ff579a !important
    }

    .border-md-tertiary-focus-within:focus-within {
        border-color: #ff579a !important
    }

    .border-md-success, .border-md-success-focus:focus, .border-md-success-hover:hover {
        border-color: #0c8 !important
    }

    .border-md-success-focus-within:focus-within {
        border-color: #0c8 !important
    }

    .border-md-info, .border-md-info-focus:focus, .border-md-info-hover:hover {
        border-color: #00d4ff !important
    }

    .border-md-info-focus-within:focus-within {
        border-color: #00d4ff !important
    }

    .border-md-warning, .border-md-warning-focus:focus, .border-md-warning-hover:hover {
        border-color: #ff8c00 !important
    }

    .border-md-warning-focus-within:focus-within {
        border-color: #ff8c00 !important
    }

    .border-md-danger, .border-md-danger-focus:focus, .border-md-danger-hover:hover {
        border-color: #f36 !important
    }

    .border-md-danger-focus-within:focus-within {
        border-color: #f36 !important
    }

    .border-md-white, .border-md-white-focus:focus, .border-md-white-hover:hover {
        border-color: #fff !important
    }

    .border-md-white-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-md-light, .border-md-light-focus:focus, .border-md-light-hover:hover {
        border-color: #fff !important
    }

    .border-md-light-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-md-dark, .border-md-dark-focus:focus, .border-md-dark-hover:hover {
        border-color: #16192c !important
    }

    .border-md-dark-focus-within:focus-within {
        border-color: #16192c !important
    }

    .border-md-transparent, .border-md-transparent-focus:focus, .border-md-transparent-hover:hover {
        border-color: transparent !important
    }

    .border-md-transparent-focus-within:focus-within {
        border-color: transparent !important
    }

    .border-md-current, .border-md-current-focus:focus, .border-md-current-hover:hover {
        border-color: currentColor !important
    }

    .border-md-current-focus-within:focus-within {
        border-color: currentColor !important
    }

    .border-md-base, .border-md-base-focus:focus, .border-md-base-hover:hover {
        border-color: #e7eaf0 !important
    }

    .border-md-base-focus-within:focus-within {
        border-color: #e7eaf0 !important
    }

    .border-md-body, .border-md-body-focus:focus, .border-md-body-hover:hover {
        border-color: #fff !important
    }

    .border-md-body-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-md-card, .border-md-card-focus:focus, .border-md-card-hover:hover {
        border-color: #fff !important
    }

    .border-md-card-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-md-black, .border-md-black-focus:focus, .border-md-black-hover:hover {
        border-color: #000 !important
    }

    .border-md-black-focus-within:focus-within {
        border-color: #000 !important
    }

    .border-md-0, .border-md-0-focus:focus, .border-md-0-hover:hover {
        border-width: 0 !important
    }

    .border-md, .border-md-focus:focus, .border-md-hover:hover {
        border-width: 1px !important
    }

    .border-md-2, .border-md-2-focus:focus, .border-md-2-hover:hover {
        border-width: 2px !important
    }

    .border-md-3, .border-md-3-focus:focus, .border-md-3-hover:hover {
        border-width: 3px !important
    }

    .border-md-4, .border-md-4-focus:focus, .border-md-4-hover:hover {
        border-width: 4px !important
    }

    .border-md-5, .border-md-5-focus:focus, .border-md-5-hover:hover {
        border-width: 5px !important
    }

    .border-md-6, .border-md-6-focus:focus, .border-md-6-hover:hover {
        border-width: 6px !important
    }

    .border-md-7, .border-md-7-focus:focus, .border-md-7-hover:hover {
        border-width: 7px !important
    }

    .border-md-8, .border-md-8-focus:focus, .border-md-8-hover:hover {
        border-width: 8px !important
    }

    .w-md-0 {
        width: 0 !important
    }

    .w-md-1 {
        width: .25rem !important
    }

    .w-md-2 {
        width: .5rem !important
    }

    .w-md-3 {
        width: .75rem !important
    }

    .w-md-4 {
        width: 1rem !important
    }

    .w-md-5 {
        width: 1.25rem !important
    }

    .w-md-6 {
        width: 1.5rem !important
    }

    .w-md-7 {
        width: 1.75rem !important
    }

    .w-md-8 {
        width: 2rem !important
    }

    .w-md-10 {
        width: 2.5rem !important
    }

    .w-md-12 {
        width: 3rem !important
    }

    .w-md-14 {
        width: 3.5rem !important
    }

    .w-md-16 {
        width: 4rem !important
    }

    .w-md-18 {
        width: 4.5rem !important
    }

    .w-md-20 {
        width: 5rem !important
    }

    .w-md-24 {
        width: 6rem !important
    }

    .w-md-32 {
        width: 8rem !important
    }

    .w-md-40 {
        width: 10rem !important
    }

    .w-md-48 {
        width: 12rem !important
    }

    .w-md-56 {
        width: 14rem !important
    }

    .w-md-64 {
        width: 16rem !important
    }

    .w-md-72 {
        width: 18rem !important
    }

    .w-md-80 {
        width: 20rem !important
    }

    .w-md-88 {
        width: 22rem !important
    }

    .w-md-96 {
        width: 24rem !important
    }

    .w-md-px {
        width: 1px !important
    }

    .w-md-1\/2 {
        width: 50% !important
    }

    .w-md-1\/3 {
        width: 33.3333333333% !important
    }

    .w-md-2\/3 {
        width: 66.6666666667% !important
    }

    .w-md-1\/4 {
        width: 25% !important
    }

    .w-md-2\/4 {
        width: 50% !important
    }

    .w-md-3\/4 {
        width: 75% !important
    }

    .w-md-1\/5 {
        width: 20% !important
    }

    .w-md-2\/5 {
        width: 40% !important
    }

    .w-md-3\/5 {
        width: 60% !important
    }

    .w-md-4\/5 {
        width: 80% !important
    }

    .w-md-1\/6 {
        width: 16.6666666667% !important
    }

    .w-md-2\/6 {
        width: 33.3333333333% !important
    }

    .w-md-3\/6 {
        width: 50% !important
    }

    .w-md-4\/6 {
        width: 66.6666666667% !important
    }

    .w-md-5\/6 {
        width: 83.3333333333% !important
    }

    .w-md-11\/10 {
        width: 110% !important
    }

    .w-md-12\/10 {
        width: 120% !important
    }

    .w-md-13\/10 {
        width: 130% !important
    }

    .w-md-14\/10 {
        width: 140% !important
    }

    .w-md-15\/10 {
        width: 150% !important
    }

    .w-md-screen-sm {
        width: 640px !important
    }

    .w-md-screen-md {
        width: 768px !important
    }

    .w-md-screen-lg {
        width: 1024px !important
    }

    .w-md-screen-xl {
        width: 1280px !important
    }

    .w-md-screen-xxl {
        width: 1536px !important
    }

    .w-md-auto {
        width: auto !important
    }

    .w-md-full {
        width: 100% !important
    }

    .w-md-screen {
        width: 100vw !important
    }

    .w-md-min {
        width: -webkit-min-content !important;
        width: -moz-min-content !important;
        width: min-content !important
    }

    .w-md-max {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important
    }

    .max-w-md-screen-sm {
        max-width: 640px !important
    }

    .max-w-md-screen-md {
        max-width: 768px !important
    }

    .max-w-md-screen-lg {
        max-width: 1024px !important
    }

    .max-w-md-screen-xl {
        max-width: 1280px !important
    }

    .max-w-md-screen-xxl {
        max-width: 1536px !important
    }

    .max-w-md-0 {
        max-width: 0 !important
    }

    .max-w-md-full {
        max-width: 100% !important
    }

    .max-w-md-read {
        max-width: 65ch !important
    }

    .max-w-md-min {
        max-width: -webkit-min-content !important;
        max-width: -moz-min-content !important;
        max-width: min-content !important
    }

    .max-w-md-max {
        max-width: -webkit-max-content !important;
        max-width: -moz-max-content !important;
        max-width: max-content !important
    }

    .h-md-0 {
        height: 0 !important
    }

    .h-md-1 {
        height: .25rem !important
    }

    .h-md-2 {
        height: .5rem !important
    }

    .h-md-3 {
        height: .75rem !important
    }

    .h-md-4 {
        height: 1rem !important
    }

    .h-md-5 {
        height: 1.25rem !important
    }

    .h-md-6 {
        height: 1.5rem !important
    }

    .h-md-7 {
        height: 1.75rem !important
    }

    .h-md-8 {
        height: 2rem !important
    }

    .h-md-10 {
        height: 2.5rem !important
    }

    .h-md-12 {
        height: 3rem !important
    }

    .h-md-14 {
        height: 3.5rem !important
    }

    .h-md-16 {
        height: 4rem !important
    }

    .h-md-18 {
        height: 4.5rem !important
    }

    .h-md-20 {
        height: 5rem !important
    }

    .h-md-24 {
        height: 6rem !important
    }

    .h-md-32 {
        height: 8rem !important
    }

    .h-md-40 {
        height: 10rem !important
    }

    .h-md-48 {
        height: 12rem !important
    }

    .h-md-56 {
        height: 14rem !important
    }

    .h-md-64 {
        height: 16rem !important
    }

    .h-md-72 {
        height: 18rem !important
    }

    .h-md-80 {
        height: 20rem !important
    }

    .h-md-88 {
        height: 22rem !important
    }

    .h-md-96 {
        height: 24rem !important
    }

    .h-md-px {
        height: 1px !important
    }

    .h-md-1\/2 {
        height: 50% !important
    }

    .h-md-1\/3 {
        height: 33.3333333333% !important
    }

    .h-md-2\/3 {
        height: 66.6666666667% !important
    }

    .h-md-1\/4 {
        height: 25% !important
    }

    .h-md-2\/4 {
        height: 50% !important
    }

    .h-md-3\/4 {
        height: 75% !important
    }

    .h-md-1\/5 {
        height: 20% !important
    }

    .h-md-2\/5 {
        height: 40% !important
    }

    .h-md-3\/5 {
        height: 60% !important
    }

    .h-md-4\/5 {
        height: 80% !important
    }

    .h-md-1\/6 {
        height: 16.6666666667% !important
    }

    .h-md-2\/6 {
        height: 33.3333333333% !important
    }

    .h-md-3\/6 {
        height: 50% !important
    }

    .h-md-4\/6 {
        height: 66.6666666667% !important
    }

    .h-md-5\/6 {
        height: 83.3333333333% !important
    }

    .h-md-11\/10 {
        height: 110% !important
    }

    .h-md-12\/10 {
        height: 120% !important
    }

    .h-md-13\/10 {
        height: 130% !important
    }

    .h-md-14\/10 {
        height: 140% !important
    }

    .h-md-15\/10 {
        height: 150% !important
    }

    .h-md-auto {
        height: auto !important
    }

    .h-md-full {
        height: 100% !important
    }

    .h-md-screen {
        height: 100vh !important
    }

    .max-h-md-0 {
        max-height: 0 !important
    }

    .max-h-md-1 {
        max-height: .25rem !important
    }

    .max-h-md-2 {
        max-height: .5rem !important
    }

    .max-h-md-3 {
        max-height: .75rem !important
    }

    .max-h-md-4 {
        max-height: 1rem !important
    }

    .max-h-md-5 {
        max-height: 1.25rem !important
    }

    .max-h-md-6 {
        max-height: 1.5rem !important
    }

    .max-h-md-7 {
        max-height: 1.75rem !important
    }

    .max-h-md-8 {
        max-height: 2rem !important
    }

    .max-h-md-10 {
        max-height: 2.5rem !important
    }

    .max-h-md-12 {
        max-height: 3rem !important
    }

    .max-h-md-14 {
        max-height: 3.5rem !important
    }

    .max-h-md-16 {
        max-height: 4rem !important
    }

    .max-h-md-18 {
        max-height: 4.5rem !important
    }

    .max-h-md-20 {
        max-height: 5rem !important
    }

    .max-h-md-24 {
        max-height: 6rem !important
    }

    .max-h-md-32 {
        max-height: 8rem !important
    }

    .max-h-md-40 {
        max-height: 10rem !important
    }

    .max-h-md-48 {
        max-height: 12rem !important
    }

    .max-h-md-56 {
        max-height: 14rem !important
    }

    .max-h-md-64 {
        max-height: 16rem !important
    }

    .max-h-md-72 {
        max-height: 18rem !important
    }

    .max-h-md-80 {
        max-height: 20rem !important
    }

    .max-h-md-88 {
        max-height: 22rem !important
    }

    .max-h-md-96 {
        max-height: 24rem !important
    }

    .max-h-md-px {
        max-height: 1px !important
    }

    .max-h-md-full {
        max-height: 100% !important
    }

    .max-h-md-screen {
        max-height: 100vh !important
    }

    .flex-md-1 {
        flex: 1 1 0% !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-initial {
        flex: 0 1 auto !important
    }

    .flex-md-none {
        flex: none !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: .75rem !important
    }

    .gap-md-4 {
        gap: 1rem !important
    }

    .gap-md-5 {
        gap: 1.25rem !important
    }

    .gap-md-6 {
        gap: 1.5rem !important
    }

    .gap-md-7 {
        gap: 1.75rem !important
    }

    .gap-md-8 {
        gap: 2rem !important
    }

    .gap-md-10 {
        gap: 2.5rem !important
    }

    .gap-md-12 {
        gap: 3rem !important
    }

    .gap-md-14 {
        gap: 3.5rem !important
    }

    .gap-md-16 {
        gap: 4rem !important
    }

    .gap-md-18 {
        gap: 4.5rem !important
    }

    .gap-md-20 {
        gap: 5rem !important
    }

    .gap-md-24 {
        gap: 6rem !important
    }

    .gap-md-32 {
        gap: 8rem !important
    }

    .gap-md-40 {
        gap: 10rem !important
    }

    .gap-md-48 {
        gap: 12rem !important
    }

    .gap-md-56 {
        gap: 14rem !important
    }

    .gap-md-64 {
        gap: 16rem !important
    }

    .gap-md-72 {
        gap: 18rem !important
    }

    .gap-md-80 {
        gap: 20rem !important
    }

    .gap-md-88 {
        gap: 22rem !important
    }

    .gap-md-96 {
        gap: 24rem !important
    }

    .gap-md-px {
        gap: 1px !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-evenly {
        align-content: space-evenly !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: .75rem !important
    }

    .m-md-4 {
        margin: 1rem !important
    }

    .m-md-5 {
        margin: 1.25rem !important
    }

    .m-md-6 {
        margin: 1.5rem !important
    }

    .m-md-7 {
        margin: 1.75rem !important
    }

    .m-md-8 {
        margin: 2rem !important
    }

    .m-md-10 {
        margin: 2.5rem !important
    }

    .m-md-12 {
        margin: 3rem !important
    }

    .m-md-14 {
        margin: 3.5rem !important
    }

    .m-md-16 {
        margin: 4rem !important
    }

    .m-md-18 {
        margin: 4.5rem !important
    }

    .m-md-20 {
        margin: 5rem !important
    }

    .m-md-24 {
        margin: 6rem !important
    }

    .m-md-32 {
        margin: 8rem !important
    }

    .m-md-40 {
        margin: 10rem !important
    }

    .m-md-48 {
        margin: 12rem !important
    }

    .m-md-56 {
        margin: 14rem !important
    }

    .m-md-64 {
        margin: 16rem !important
    }

    .m-md-72 {
        margin: 18rem !important
    }

    .m-md-80 {
        margin: 20rem !important
    }

    .m-md-88 {
        margin: 22rem !important
    }

    .m-md-96 {
        margin: 24rem !important
    }

    .m-md-px {
        margin: 1px !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-md-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-md-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-md-3 {
        margin-left: .75rem !important;
        margin-right: .75rem !important
    }

    .mx-md-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-md-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important
    }

    .mx-md-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-md-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important
    }

    .mx-md-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-md-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-md-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-md-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important
    }

    .mx-md-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-md-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }

    .mx-md-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-md-24 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-md-32 {
        margin-left: 8rem !important;
        margin-right: 8rem !important
    }

    .mx-md-40 {
        margin-left: 10rem !important;
        margin-right: 10rem !important
    }

    .mx-md-48 {
        margin-left: 12rem !important;
        margin-right: 12rem !important
    }

    .mx-md-56 {
        margin-left: 14rem !important;
        margin-right: 14rem !important
    }

    .mx-md-64 {
        margin-left: 16rem !important;
        margin-right: 16rem !important
    }

    .mx-md-72 {
        margin-left: 18rem !important;
        margin-right: 18rem !important
    }

    .mx-md-80 {
        margin-left: 20rem !important;
        margin-right: 20rem !important
    }

    .mx-md-88 {
        margin-left: 22rem !important;
        margin-right: 22rem !important
    }

    .mx-md-96 {
        margin-left: 24rem !important;
        margin-right: 24rem !important
    }

    .mx-md-px {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mx-md-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-md-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-md-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-md-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-md-3 {
        margin-bottom: .75rem !important;
        margin-top: .75rem !important
    }

    .my-md-4 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-md-5 {
        margin-bottom: 1.25rem !important;
        margin-top: 1.25rem !important
    }

    .my-md-6 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-md-7 {
        margin-bottom: 1.75rem !important;
        margin-top: 1.75rem !important
    }

    .my-md-8 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-md-10 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-md-12 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-md-14 {
        margin-bottom: 3.5rem !important;
        margin-top: 3.5rem !important
    }

    .my-md-16 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-md-18 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }

    .my-md-20 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-md-24 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-md-32 {
        margin-bottom: 8rem !important;
        margin-top: 8rem !important
    }

    .my-md-40 {
        margin-bottom: 10rem !important;
        margin-top: 10rem !important
    }

    .my-md-48 {
        margin-bottom: 12rem !important;
        margin-top: 12rem !important
    }

    .my-md-56 {
        margin-bottom: 14rem !important;
        margin-top: 14rem !important
    }

    .my-md-64 {
        margin-bottom: 16rem !important;
        margin-top: 16rem !important
    }

    .my-md-72 {
        margin-bottom: 18rem !important;
        margin-top: 18rem !important
    }

    .my-md-80 {
        margin-bottom: 20rem !important;
        margin-top: 20rem !important
    }

    .my-md-88 {
        margin-bottom: 22rem !important;
        margin-top: 22rem !important
    }

    .my-md-96 {
        margin-bottom: 24rem !important;
        margin-top: 24rem !important
    }

    .my-md-px {
        margin-bottom: 1px !important;
        margin-top: 1px !important
    }

    .my-md-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: .75rem !important
    }

    .mt-md-4 {
        margin-top: 1rem !important
    }

    .mt-md-5 {
        margin-top: 1.25rem !important
    }

    .mt-md-6 {
        margin-top: 1.5rem !important
    }

    .mt-md-7 {
        margin-top: 1.75rem !important
    }

    .mt-md-8 {
        margin-top: 2rem !important
    }

    .mt-md-10 {
        margin-top: 2.5rem !important
    }

    .mt-md-12 {
        margin-top: 3rem !important
    }

    .mt-md-14 {
        margin-top: 3.5rem !important
    }

    .mt-md-16 {
        margin-top: 4rem !important
    }

    .mt-md-18 {
        margin-top: 4.5rem !important
    }

    .mt-md-20 {
        margin-top: 5rem !important
    }

    .mt-md-24 {
        margin-top: 6rem !important
    }

    .mt-md-32 {
        margin-top: 8rem !important
    }

    .mt-md-40 {
        margin-top: 10rem !important
    }

    .mt-md-48 {
        margin-top: 12rem !important
    }

    .mt-md-56 {
        margin-top: 14rem !important
    }

    .mt-md-64 {
        margin-top: 16rem !important
    }

    .mt-md-72 {
        margin-top: 18rem !important
    }

    .mt-md-80 {
        margin-top: 20rem !important
    }

    .mt-md-88 {
        margin-top: 22rem !important
    }

    .mt-md-96 {
        margin-top: 24rem !important
    }

    .mt-md-px {
        margin-top: 1px !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: .75rem !important
    }

    .me-md-4 {
        margin-right: 1rem !important
    }

    .me-md-5 {
        margin-right: 1.25rem !important
    }

    .me-md-6 {
        margin-right: 1.5rem !important
    }

    .me-md-7 {
        margin-right: 1.75rem !important
    }

    .me-md-8 {
        margin-right: 2rem !important
    }

    .me-md-10 {
        margin-right: 2.5rem !important
    }

    .me-md-12 {
        margin-right: 3rem !important
    }

    .me-md-14 {
        margin-right: 3.5rem !important
    }

    .me-md-16 {
        margin-right: 4rem !important
    }

    .me-md-18 {
        margin-right: 4.5rem !important
    }

    .me-md-20 {
        margin-right: 5rem !important
    }

    .me-md-24 {
        margin-right: 6rem !important
    }

    .me-md-32 {
        margin-right: 8rem !important
    }

    .me-md-40 {
        margin-right: 10rem !important
    }

    .me-md-48 {
        margin-right: 12rem !important
    }

    .me-md-56 {
        margin-right: 14rem !important
    }

    .me-md-64 {
        margin-right: 16rem !important
    }

    .me-md-72 {
        margin-right: 18rem !important
    }

    .me-md-80 {
        margin-right: 20rem !important
    }

    .me-md-88 {
        margin-right: 22rem !important
    }

    .me-md-96 {
        margin-right: 24rem !important
    }

    .me-md-px {
        margin-right: 1px !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: .75rem !important
    }

    .mb-md-4 {
        margin-bottom: 1rem !important
    }

    .mb-md-5 {
        margin-bottom: 1.25rem !important
    }

    .mb-md-6 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-7 {
        margin-bottom: 1.75rem !important
    }

    .mb-md-8 {
        margin-bottom: 2rem !important
    }

    .mb-md-10 {
        margin-bottom: 2.5rem !important
    }

    .mb-md-12 {
        margin-bottom: 3rem !important
    }

    .mb-md-14 {
        margin-bottom: 3.5rem !important
    }

    .mb-md-16 {
        margin-bottom: 4rem !important
    }

    .mb-md-18 {
        margin-bottom: 4.5rem !important
    }

    .mb-md-20 {
        margin-bottom: 5rem !important
    }

    .mb-md-24 {
        margin-bottom: 6rem !important
    }

    .mb-md-32 {
        margin-bottom: 8rem !important
    }

    .mb-md-40 {
        margin-bottom: 10rem !important
    }

    .mb-md-48 {
        margin-bottom: 12rem !important
    }

    .mb-md-56 {
        margin-bottom: 14rem !important
    }

    .mb-md-64 {
        margin-bottom: 16rem !important
    }

    .mb-md-72 {
        margin-bottom: 18rem !important
    }

    .mb-md-80 {
        margin-bottom: 20rem !important
    }

    .mb-md-88 {
        margin-bottom: 22rem !important
    }

    .mb-md-96 {
        margin-bottom: 24rem !important
    }

    .mb-md-px {
        margin-bottom: 1px !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: .75rem !important
    }

    .ms-md-4 {
        margin-left: 1rem !important
    }

    .ms-md-5 {
        margin-left: 1.25rem !important
    }

    .ms-md-6 {
        margin-left: 1.5rem !important
    }

    .ms-md-7 {
        margin-left: 1.75rem !important
    }

    .ms-md-8 {
        margin-left: 2rem !important
    }

    .ms-md-10 {
        margin-left: 2.5rem !important
    }

    .ms-md-12 {
        margin-left: 3rem !important
    }

    .ms-md-14 {
        margin-left: 3.5rem !important
    }

    .ms-md-16 {
        margin-left: 4rem !important
    }

    .ms-md-18 {
        margin-left: 4.5rem !important
    }

    .ms-md-20 {
        margin-left: 5rem !important
    }

    .ms-md-24 {
        margin-left: 6rem !important
    }

    .ms-md-32 {
        margin-left: 8rem !important
    }

    .ms-md-40 {
        margin-left: 10rem !important
    }

    .ms-md-48 {
        margin-left: 12rem !important
    }

    .ms-md-56 {
        margin-left: 14rem !important
    }

    .ms-md-64 {
        margin-left: 16rem !important
    }

    .ms-md-72 {
        margin-left: 18rem !important
    }

    .ms-md-80 {
        margin-left: 20rem !important
    }

    .ms-md-88 {
        margin-left: 22rem !important
    }

    .ms-md-96 {
        margin-left: 24rem !important
    }

    .ms-md-px {
        margin-left: 1px !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .m-md-n3 {
        margin: -.75rem !important
    }

    .m-md-n4 {
        margin: -1rem !important
    }

    .m-md-n5 {
        margin: -1.25rem !important
    }

    .m-md-n6 {
        margin: -1.5rem !important
    }

    .m-md-n7 {
        margin: -1.75rem !important
    }

    .m-md-n8 {
        margin: -2rem !important
    }

    .m-md-n10 {
        margin: -2.5rem !important
    }

    .m-md-n12 {
        margin: -3rem !important
    }

    .m-md-n14 {
        margin: -3.5rem !important
    }

    .m-md-n16 {
        margin: -4rem !important
    }

    .m-md-n18 {
        margin: -4.5rem !important
    }

    .m-md-n20 {
        margin: -5rem !important
    }

    .m-md-n24 {
        margin: -6rem !important
    }

    .m-md-n32 {
        margin: -8rem !important
    }

    .m-md-n40 {
        margin: -10rem !important
    }

    .m-md-n48 {
        margin: -12rem !important
    }

    .m-md-n56 {
        margin: -14rem !important
    }

    .m-md-n64 {
        margin: -16rem !important
    }

    .m-md-n72 {
        margin: -18rem !important
    }

    .m-md-n80 {
        margin: -20rem !important
    }

    .m-md-n88 {
        margin: -22rem !important
    }

    .m-md-n96 {
        margin: -24rem !important
    }

    .m-md-npx {
        margin: -1px !important
    }

    .mx-md-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-md-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-md-n3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important
    }

    .mx-md-n4 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-md-n5 {
        margin-left: -1.25rem !important;
        margin-right: -1.25rem !important
    }

    .mx-md-n6 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-md-n7 {
        margin-left: -1.75rem !important;
        margin-right: -1.75rem !important
    }

    .mx-md-n8 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-md-n10 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-md-n12 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-md-n14 {
        margin-left: -3.5rem !important;
        margin-right: -3.5rem !important
    }

    .mx-md-n16 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-md-n18 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }

    .mx-md-n20 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-md-n24 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .mx-md-n32 {
        margin-left: -8rem !important;
        margin-right: -8rem !important
    }

    .mx-md-n40 {
        margin-left: -10rem !important;
        margin-right: -10rem !important
    }

    .mx-md-n48 {
        margin-left: -12rem !important;
        margin-right: -12rem !important
    }

    .mx-md-n56 {
        margin-left: -14rem !important;
        margin-right: -14rem !important
    }

    .mx-md-n64 {
        margin-left: -16rem !important;
        margin-right: -16rem !important
    }

    .mx-md-n72 {
        margin-left: -18rem !important;
        margin-right: -18rem !important
    }

    .mx-md-n80 {
        margin-left: -20rem !important;
        margin-right: -20rem !important
    }

    .mx-md-n88 {
        margin-left: -22rem !important;
        margin-right: -22rem !important
    }

    .mx-md-n96 {
        margin-left: -24rem !important;
        margin-right: -24rem !important
    }

    .mx-md-npx {
        margin-left: -1px !important;
        margin-right: -1px !important
    }

    .my-md-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-md-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-md-n3 {
        margin-bottom: -.75rem !important;
        margin-top: -.75rem !important
    }

    .my-md-n4 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-md-n5 {
        margin-bottom: -1.25rem !important;
        margin-top: -1.25rem !important
    }

    .my-md-n6 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-md-n7 {
        margin-bottom: -1.75rem !important;
        margin-top: -1.75rem !important
    }

    .my-md-n8 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-md-n10 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-md-n12 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-md-n14 {
        margin-bottom: -3.5rem !important;
        margin-top: -3.5rem !important
    }

    .my-md-n16 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-md-n18 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }

    .my-md-n20 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-md-n24 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .my-md-n32 {
        margin-bottom: -8rem !important;
        margin-top: -8rem !important
    }

    .my-md-n40 {
        margin-bottom: -10rem !important;
        margin-top: -10rem !important
    }

    .my-md-n48 {
        margin-bottom: -12rem !important;
        margin-top: -12rem !important
    }

    .my-md-n56 {
        margin-bottom: -14rem !important;
        margin-top: -14rem !important
    }

    .my-md-n64 {
        margin-bottom: -16rem !important;
        margin-top: -16rem !important
    }

    .my-md-n72 {
        margin-bottom: -18rem !important;
        margin-top: -18rem !important
    }

    .my-md-n80 {
        margin-bottom: -20rem !important;
        margin-top: -20rem !important
    }

    .my-md-n88 {
        margin-bottom: -22rem !important;
        margin-top: -22rem !important
    }

    .my-md-n96 {
        margin-bottom: -24rem !important;
        margin-top: -24rem !important
    }

    .my-md-npx {
        margin-bottom: -1px !important;
        margin-top: -1px !important
    }

    .mt-md-n1 {
        margin-top: -.25rem !important
    }

    .mt-md-n2 {
        margin-top: -.5rem !important
    }

    .mt-md-n3 {
        margin-top: -.75rem !important
    }

    .mt-md-n4 {
        margin-top: -1rem !important
    }

    .mt-md-n5 {
        margin-top: -1.25rem !important
    }

    .mt-md-n6 {
        margin-top: -1.5rem !important
    }

    .mt-md-n7 {
        margin-top: -1.75rem !important
    }

    .mt-md-n8 {
        margin-top: -2rem !important
    }

    .mt-md-n10 {
        margin-top: -2.5rem !important
    }

    .mt-md-n12 {
        margin-top: -3rem !important
    }

    .mt-md-n14 {
        margin-top: -3.5rem !important
    }

    .mt-md-n16 {
        margin-top: -4rem !important
    }

    .mt-md-n18 {
        margin-top: -4.5rem !important
    }

    .mt-md-n20 {
        margin-top: -5rem !important
    }

    .mt-md-n24 {
        margin-top: -6rem !important
    }

    .mt-md-n32 {
        margin-top: -8rem !important
    }

    .mt-md-n40 {
        margin-top: -10rem !important
    }

    .mt-md-n48 {
        margin-top: -12rem !important
    }

    .mt-md-n56 {
        margin-top: -14rem !important
    }

    .mt-md-n64 {
        margin-top: -16rem !important
    }

    .mt-md-n72 {
        margin-top: -18rem !important
    }

    .mt-md-n80 {
        margin-top: -20rem !important
    }

    .mt-md-n88 {
        margin-top: -22rem !important
    }

    .mt-md-n96 {
        margin-top: -24rem !important
    }

    .mt-md-npx {
        margin-top: -1px !important
    }

    .me-md-n1 {
        margin-right: -.25rem !important
    }

    .me-md-n2 {
        margin-right: -.5rem !important
    }

    .me-md-n3 {
        margin-right: -.75rem !important
    }

    .me-md-n4 {
        margin-right: -1rem !important
    }

    .me-md-n5 {
        margin-right: -1.25rem !important
    }

    .me-md-n6 {
        margin-right: -1.5rem !important
    }

    .me-md-n7 {
        margin-right: -1.75rem !important
    }

    .me-md-n8 {
        margin-right: -2rem !important
    }

    .me-md-n10 {
        margin-right: -2.5rem !important
    }

    .me-md-n12 {
        margin-right: -3rem !important
    }

    .me-md-n14 {
        margin-right: -3.5rem !important
    }

    .me-md-n16 {
        margin-right: -4rem !important
    }

    .me-md-n18 {
        margin-right: -4.5rem !important
    }

    .me-md-n20 {
        margin-right: -5rem !important
    }

    .me-md-n24 {
        margin-right: -6rem !important
    }

    .me-md-n32 {
        margin-right: -8rem !important
    }

    .me-md-n40 {
        margin-right: -10rem !important
    }

    .me-md-n48 {
        margin-right: -12rem !important
    }

    .me-md-n56 {
        margin-right: -14rem !important
    }

    .me-md-n64 {
        margin-right: -16rem !important
    }

    .me-md-n72 {
        margin-right: -18rem !important
    }

    .me-md-n80 {
        margin-right: -20rem !important
    }

    .me-md-n88 {
        margin-right: -22rem !important
    }

    .me-md-n96 {
        margin-right: -24rem !important
    }

    .me-md-npx {
        margin-right: -1px !important
    }

    .mb-md-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-md-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-md-n3 {
        margin-bottom: -.75rem !important
    }

    .mb-md-n4 {
        margin-bottom: -1rem !important
    }

    .mb-md-n5 {
        margin-bottom: -1.25rem !important
    }

    .mb-md-n6 {
        margin-bottom: -1.5rem !important
    }

    .mb-md-n7 {
        margin-bottom: -1.75rem !important
    }

    .mb-md-n8 {
        margin-bottom: -2rem !important
    }

    .mb-md-n10 {
        margin-bottom: -2.5rem !important
    }

    .mb-md-n12 {
        margin-bottom: -3rem !important
    }

    .mb-md-n14 {
        margin-bottom: -3.5rem !important
    }

    .mb-md-n16 {
        margin-bottom: -4rem !important
    }

    .mb-md-n18 {
        margin-bottom: -4.5rem !important
    }

    .mb-md-n20 {
        margin-bottom: -5rem !important
    }

    .mb-md-n24 {
        margin-bottom: -6rem !important
    }

    .mb-md-n32 {
        margin-bottom: -8rem !important
    }

    .mb-md-n40 {
        margin-bottom: -10rem !important
    }

    .mb-md-n48 {
        margin-bottom: -12rem !important
    }

    .mb-md-n56 {
        margin-bottom: -14rem !important
    }

    .mb-md-n64 {
        margin-bottom: -16rem !important
    }

    .mb-md-n72 {
        margin-bottom: -18rem !important
    }

    .mb-md-n80 {
        margin-bottom: -20rem !important
    }

    .mb-md-n88 {
        margin-bottom: -22rem !important
    }

    .mb-md-n96 {
        margin-bottom: -24rem !important
    }

    .mb-md-npx {
        margin-bottom: -1px !important
    }

    .ms-md-n1 {
        margin-left: -.25rem !important
    }

    .ms-md-n2 {
        margin-left: -.5rem !important
    }

    .ms-md-n3 {
        margin-left: -.75rem !important
    }

    .ms-md-n4 {
        margin-left: -1rem !important
    }

    .ms-md-n5 {
        margin-left: -1.25rem !important
    }

    .ms-md-n6 {
        margin-left: -1.5rem !important
    }

    .ms-md-n7 {
        margin-left: -1.75rem !important
    }

    .ms-md-n8 {
        margin-left: -2rem !important
    }

    .ms-md-n10 {
        margin-left: -2.5rem !important
    }

    .ms-md-n12 {
        margin-left: -3rem !important
    }

    .ms-md-n14 {
        margin-left: -3.5rem !important
    }

    .ms-md-n16 {
        margin-left: -4rem !important
    }

    .ms-md-n18 {
        margin-left: -4.5rem !important
    }

    .ms-md-n20 {
        margin-left: -5rem !important
    }

    .ms-md-n24 {
        margin-left: -6rem !important
    }

    .ms-md-n32 {
        margin-left: -8rem !important
    }

    .ms-md-n40 {
        margin-left: -10rem !important
    }

    .ms-md-n48 {
        margin-left: -12rem !important
    }

    .ms-md-n56 {
        margin-left: -14rem !important
    }

    .ms-md-n64 {
        margin-left: -16rem !important
    }

    .ms-md-n72 {
        margin-left: -18rem !important
    }

    .ms-md-n80 {
        margin-left: -20rem !important
    }

    .ms-md-n88 {
        margin-left: -22rem !important
    }

    .ms-md-n96 {
        margin-left: -24rem !important
    }

    .ms-md-npx {
        margin-left: -1px !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: .75rem !important
    }

    .p-md-4 {
        padding: 1rem !important
    }

    .p-md-5 {
        padding: 1.25rem !important
    }

    .p-md-6 {
        padding: 1.5rem !important
    }

    .p-md-7 {
        padding: 1.75rem !important
    }

    .p-md-8 {
        padding: 2rem !important
    }

    .p-md-10 {
        padding: 2.5rem !important
    }

    .p-md-12 {
        padding: 3rem !important
    }

    .p-md-14 {
        padding: 3.5rem !important
    }

    .p-md-16 {
        padding: 4rem !important
    }

    .p-md-18 {
        padding: 4.5rem !important
    }

    .p-md-20 {
        padding: 5rem !important
    }

    .p-md-24 {
        padding: 6rem !important
    }

    .p-md-32 {
        padding: 8rem !important
    }

    .p-md-40 {
        padding: 10rem !important
    }

    .p-md-48 {
        padding: 12rem !important
    }

    .p-md-56 {
        padding: 14rem !important
    }

    .p-md-64 {
        padding: 16rem !important
    }

    .p-md-72 {
        padding: 18rem !important
    }

    .p-md-80 {
        padding: 20rem !important
    }

    .p-md-88 {
        padding: 22rem !important
    }

    .p-md-96 {
        padding: 24rem !important
    }

    .p-md-px {
        padding: 1px !important
    }

    .px-md-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-md-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-md-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-md-3 {
        padding-left: .75rem !important;
        padding-right: .75rem !important
    }

    .px-md-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-md-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important
    }

    .px-md-6 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-md-7 {
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important
    }

    .px-md-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-md-10 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-md-12 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-md-14 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important
    }

    .px-md-16 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-md-18 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }

    .px-md-20 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-md-24 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .px-md-32 {
        padding-left: 8rem !important;
        padding-right: 8rem !important
    }

    .px-md-40 {
        padding-left: 10rem !important;
        padding-right: 10rem !important
    }

    .px-md-48 {
        padding-left: 12rem !important;
        padding-right: 12rem !important
    }

    .px-md-56 {
        padding-left: 14rem !important;
        padding-right: 14rem !important
    }

    .px-md-64 {
        padding-left: 16rem !important;
        padding-right: 16rem !important
    }

    .px-md-72 {
        padding-left: 18rem !important;
        padding-right: 18rem !important
    }

    .px-md-80 {
        padding-left: 20rem !important;
        padding-right: 20rem !important
    }

    .px-md-88 {
        padding-left: 22rem !important;
        padding-right: 22rem !important
    }

    .px-md-96 {
        padding-left: 24rem !important;
        padding-right: 24rem !important
    }

    .px-md-px {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .py-md-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-md-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-md-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-md-3 {
        padding-bottom: .75rem !important;
        padding-top: .75rem !important
    }

    .py-md-4 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-md-5 {
        padding-bottom: 1.25rem !important;
        padding-top: 1.25rem !important
    }

    .py-md-6 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-md-7 {
        padding-bottom: 1.75rem !important;
        padding-top: 1.75rem !important
    }

    .py-md-8 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-md-10 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-md-12 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-md-14 {
        padding-bottom: 3.5rem !important;
        padding-top: 3.5rem !important
    }

    .py-md-16 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-md-18 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }

    .py-md-20 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-md-24 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .py-md-32 {
        padding-bottom: 8rem !important;
        padding-top: 8rem !important
    }

    .py-md-40 {
        padding-bottom: 10rem !important;
        padding-top: 10rem !important
    }

    .py-md-48 {
        padding-bottom: 12rem !important;
        padding-top: 12rem !important
    }

    .py-md-56 {
        padding-bottom: 14rem !important;
        padding-top: 14rem !important
    }

    .py-md-64 {
        padding-bottom: 16rem !important;
        padding-top: 16rem !important
    }

    .py-md-72 {
        padding-bottom: 18rem !important;
        padding-top: 18rem !important
    }

    .py-md-80 {
        padding-bottom: 20rem !important;
        padding-top: 20rem !important
    }

    .py-md-88 {
        padding-bottom: 22rem !important;
        padding-top: 22rem !important
    }

    .py-md-96 {
        padding-bottom: 24rem !important;
        padding-top: 24rem !important
    }

    .py-md-px {
        padding-bottom: 1px !important;
        padding-top: 1px !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: .75rem !important
    }

    .pt-md-4 {
        padding-top: 1rem !important
    }

    .pt-md-5 {
        padding-top: 1.25rem !important
    }

    .pt-md-6 {
        padding-top: 1.5rem !important
    }

    .pt-md-7 {
        padding-top: 1.75rem !important
    }

    .pt-md-8 {
        padding-top: 2rem !important
    }

    .pt-md-10 {
        padding-top: 2.5rem !important
    }

    .pt-md-12 {
        padding-top: 3rem !important
    }

    .pt-md-14 {
        padding-top: 3.5rem !important
    }

    .pt-md-16 {
        padding-top: 4rem !important
    }

    .pt-md-18 {
        padding-top: 4.5rem !important
    }

    .pt-md-20 {
        padding-top: 5rem !important
    }

    .pt-md-24 {
        padding-top: 6rem !important
    }

    .pt-md-32 {
        padding-top: 8rem !important
    }

    .pt-md-40 {
        padding-top: 10rem !important
    }

    .pt-md-48 {
        padding-top: 12rem !important
    }

    .pt-md-56 {
        padding-top: 14rem !important
    }

    .pt-md-64 {
        padding-top: 16rem !important
    }

    .pt-md-72 {
        padding-top: 18rem !important
    }

    .pt-md-80 {
        padding-top: 20rem !important
    }

    .pt-md-88 {
        padding-top: 22rem !important
    }

    .pt-md-96 {
        padding-top: 24rem !important
    }

    .pt-md-px {
        padding-top: 1px !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: .75rem !important
    }

    .pe-md-4 {
        padding-right: 1rem !important
    }

    .pe-md-5 {
        padding-right: 1.25rem !important
    }

    .pe-md-6 {
        padding-right: 1.5rem !important
    }

    .pe-md-7 {
        padding-right: 1.75rem !important
    }

    .pe-md-8 {
        padding-right: 2rem !important
    }

    .pe-md-10 {
        padding-right: 2.5rem !important
    }

    .pe-md-12 {
        padding-right: 3rem !important
    }

    .pe-md-14 {
        padding-right: 3.5rem !important
    }

    .pe-md-16 {
        padding-right: 4rem !important
    }

    .pe-md-18 {
        padding-right: 4.5rem !important
    }

    .pe-md-20 {
        padding-right: 5rem !important
    }

    .pe-md-24 {
        padding-right: 6rem !important
    }

    .pe-md-32 {
        padding-right: 8rem !important
    }

    .pe-md-40 {
        padding-right: 10rem !important
    }

    .pe-md-48 {
        padding-right: 12rem !important
    }

    .pe-md-56 {
        padding-right: 14rem !important
    }

    .pe-md-64 {
        padding-right: 16rem !important
    }

    .pe-md-72 {
        padding-right: 18rem !important
    }

    .pe-md-80 {
        padding-right: 20rem !important
    }

    .pe-md-88 {
        padding-right: 22rem !important
    }

    .pe-md-96 {
        padding-right: 24rem !important
    }

    .pe-md-px {
        padding-right: 1px !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: .75rem !important
    }

    .pb-md-4 {
        padding-bottom: 1rem !important
    }

    .pb-md-5 {
        padding-bottom: 1.25rem !important
    }

    .pb-md-6 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-7 {
        padding-bottom: 1.75rem !important
    }

    .pb-md-8 {
        padding-bottom: 2rem !important
    }

    .pb-md-10 {
        padding-bottom: 2.5rem !important
    }

    .pb-md-12 {
        padding-bottom: 3rem !important
    }

    .pb-md-14 {
        padding-bottom: 3.5rem !important
    }

    .pb-md-16 {
        padding-bottom: 4rem !important
    }

    .pb-md-18 {
        padding-bottom: 4.5rem !important
    }

    .pb-md-20 {
        padding-bottom: 5rem !important
    }

    .pb-md-24 {
        padding-bottom: 6rem !important
    }

    .pb-md-32 {
        padding-bottom: 8rem !important
    }

    .pb-md-40 {
        padding-bottom: 10rem !important
    }

    .pb-md-48 {
        padding-bottom: 12rem !important
    }

    .pb-md-56 {
        padding-bottom: 14rem !important
    }

    .pb-md-64 {
        padding-bottom: 16rem !important
    }

    .pb-md-72 {
        padding-bottom: 18rem !important
    }

    .pb-md-80 {
        padding-bottom: 20rem !important
    }

    .pb-md-88 {
        padding-bottom: 22rem !important
    }

    .pb-md-96 {
        padding-bottom: 24rem !important
    }

    .pb-md-px {
        padding-bottom: 1px !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: .75rem !important
    }

    .ps-md-4 {
        padding-left: 1rem !important
    }

    .ps-md-5 {
        padding-left: 1.25rem !important
    }

    .ps-md-6 {
        padding-left: 1.5rem !important
    }

    .ps-md-7 {
        padding-left: 1.75rem !important
    }

    .ps-md-8 {
        padding-left: 2rem !important
    }

    .ps-md-10 {
        padding-left: 2.5rem !important
    }

    .ps-md-12 {
        padding-left: 3rem !important
    }

    .ps-md-14 {
        padding-left: 3.5rem !important
    }

    .ps-md-16 {
        padding-left: 4rem !important
    }

    .ps-md-18 {
        padding-left: 4.5rem !important
    }

    .ps-md-20 {
        padding-left: 5rem !important
    }

    .ps-md-24 {
        padding-left: 6rem !important
    }

    .ps-md-32 {
        padding-left: 8rem !important
    }

    .ps-md-40 {
        padding-left: 10rem !important
    }

    .ps-md-48 {
        padding-left: 12rem !important
    }

    .ps-md-56 {
        padding-left: 14rem !important
    }

    .ps-md-64 {
        padding-left: 16rem !important
    }

    .ps-md-72 {
        padding-left: 18rem !important
    }

    .ps-md-80 {
        padding-left: 20rem !important
    }

    .ps-md-88 {
        padding-left: 22rem !important
    }

    .ps-md-96 {
        padding-left: 24rem !important
    }

    .ps-md-px {
        padding-left: 1px !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }

    .text-md-primary {
        --x-text-opacity: 1
    }

    .text-md-primary, .text-md-primary-focus:focus, .text-md-primary-hover:hover {
        color: rgba(var(--x-primary-rgb),var(--x-text-opacity)) !important
    }

    .text-md-secondary {
        --x-text-opacity: 1
    }

    .text-md-secondary, .text-md-secondary-focus:focus, .text-md-secondary-hover:hover {
        color: rgba(var(--x-secondary-rgb),var(--x-text-opacity)) !important
    }

    .text-md-tertiary {
        --x-text-opacity: 1
    }

    .text-md-tertiary, .text-md-tertiary-focus:focus, .text-md-tertiary-hover:hover {
        color: rgba(var(--x-tertiary-rgb),var(--x-text-opacity)) !important
    }

    .text-md-success {
        --x-text-opacity: 1
    }

    .text-md-success, .text-md-success-focus:focus, .text-md-success-hover:hover {
        color: rgba(var(--x-success-rgb),var(--x-text-opacity)) !important
    }

    .text-md-info {
        --x-text-opacity: 1
    }

    .text-md-info, .text-md-info-focus:focus, .text-md-info-hover:hover {
        color: rgba(var(--x-info-rgb),var(--x-text-opacity)) !important
    }

    .text-md-warning {
        --x-text-opacity: 1
    }

    .text-md-warning, .text-md-warning-focus:focus, .text-md-warning-hover:hover {
        color: rgba(var(--x-warning-rgb),var(--x-text-opacity)) !important
    }

    .text-md-danger {
        --x-text-opacity: 1
    }

    .text-md-danger, .text-md-danger-focus:focus, .text-md-danger-hover:hover {
        color: rgba(var(--x-danger-rgb),var(--x-text-opacity)) !important
    }

    .text-md-white {
        --x-text-opacity: 1
    }

    .text-md-white, .text-md-white-focus:focus, .text-md-white-hover:hover {
        color: rgba(var(--x-white-rgb),var(--x-text-opacity)) !important
    }

    .text-md-light {
        --x-text-opacity: 1
    }

    .text-md-light, .text-md-light-focus:focus, .text-md-light-hover:hover {
        color: rgba(var(--x-light-rgb),var(--x-text-opacity)) !important
    }

    .text-md-dark {
        --x-text-opacity: 1
    }

    .text-md-dark, .text-md-dark-focus:focus, .text-md-dark-hover:hover {
        color: rgba(var(--x-dark-rgb),var(--x-text-opacity)) !important
    }

    .text-md-black {
        --x-text-opacity: 1
    }

    .text-md-black, .text-md-black-focus:focus, .text-md-black-hover:hover {
        color: rgba(var(--x-black-rgb),var(--x-text-opacity)) !important
    }

    .text-md-body {
        --x-text-opacity: 1
    }

    .text-md-body, .text-md-body-focus:focus, .text-md-body-hover:hover {
        color: rgba(var(--x-body-color-rgb),var(--x-text-opacity)) !important
    }

    .text-md-muted {
        --x-text-opacity: 1
    }

    .text-md-muted, .text-md-muted-focus:focus, .text-md-muted-hover:hover {
        color: rgba(var(--x-muted-rgb),var(--x-text-opacity)) !important
    }

    .text-md-heading {
        --x-text-opacity: 1
    }

    .text-md-heading, .text-md-heading-focus:focus, .text-md-heading-hover:hover {
        color: rgba(var(--x-heading-rgb),var(--x-text-opacity)) !important
    }

    .rounded-md {
        border-radius: .375rem !important
    }

    .rounded-md-0 {
        border-radius: 0 !important
    }

    .rounded-md-1 {
        border-radius: .25rem !important
    }

    .rounded-md-2 {
        border-radius: .5rem !important
    }

    .rounded-md-3 {
        border-radius: .75rem !important
    }

    .rounded-md-4 {
        border-radius: 1rem !important
    }

    .rounded-md-5 {
        border-radius: 1.5rem !important
    }

    .rounded-md-6 {
        border-radius: 3rem !important
    }

    .rounded-md-7 {
        border-radius: 4rem !important
    }

    .rounded-md-8 {
        border-radius: 5rem !important
    }

    .rounded-md-9 {
        border-radius: 6rem !important
    }

    .rounded-md-10 {
        border-radius: 7rem !important
    }

    .rounded-md-pill {
        border-radius: 50rem !important
    }

    .rounded-md-circle {
        border-radius: 50% !important
    }

    .rounded-md-card {
        border-radius: .75rem !important
    }

    .rounded-top-md {
        border-top-left-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-top-md-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-md-1 {
        border-top-left-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-top-md-2 {
        border-top-left-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-top-md-3 {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-top-md-4 {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-top-md-5 {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-md-6 {
        border-top-left-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-top-md-7 {
        border-top-left-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-top-md-8 {
        border-top-left-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-top-md-9 {
        border-top-left-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-top-md-10 {
        border-top-left-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-top-md-pill {
        border-top-left-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-top-md-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-md-card {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-md {
        border-bottom-right-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-end-md-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-md-1 {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-end-md-2 {
        border-bottom-right-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-end-md-3 {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-md-4 {
        border-bottom-right-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-end-md-5 {
        border-bottom-right-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-end-md-6 {
        border-bottom-right-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-end-md-7 {
        border-bottom-right-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-end-md-8 {
        border-bottom-right-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-end-md-9 {
        border-bottom-right-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-end-md-10 {
        border-bottom-right-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-end-md-pill {
        border-bottom-right-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-end-md-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-md-card {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-md {
        border-bottom-left-radius: .375rem !important;
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-md-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-md-1 {
        border-bottom-left-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-md-2 {
        border-bottom-left-radius: .5rem !important;
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-md-3 {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-md-4 {
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-md-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-md-6 {
        border-bottom-left-radius: 3rem !important;
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-md-7 {
        border-bottom-left-radius: 4rem !important;
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-md-8 {
        border-bottom-left-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-md-9 {
        border-bottom-left-radius: 6rem !important;
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-md-10 {
        border-bottom-left-radius: 7rem !important;
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-md-pill {
        border-bottom-left-radius: 50rem !important;
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-md-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-md-card {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-start-md {
        border-bottom-left-radius: .375rem !important;
        border-top-left-radius: .375rem !important
    }

    .rounded-start-md-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-md-1 {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important
    }

    .rounded-start-md-2 {
        border-bottom-left-radius: .5rem !important;
        border-top-left-radius: .5rem !important
    }

    .rounded-start-md-3 {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-start-md-4 {
        border-bottom-left-radius: 1rem !important;
        border-top-left-radius: 1rem !important
    }

    .rounded-start-md-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important
    }

    .rounded-start-md-6 {
        border-bottom-left-radius: 3rem !important;
        border-top-left-radius: 3rem !important
    }

    .rounded-start-md-7 {
        border-bottom-left-radius: 4rem !important;
        border-top-left-radius: 4rem !important
    }

    .rounded-start-md-8 {
        border-bottom-left-radius: 5rem !important;
        border-top-left-radius: 5rem !important
    }

    .rounded-start-md-9 {
        border-bottom-left-radius: 6rem !important;
        border-top-left-radius: 6rem !important
    }

    .rounded-start-md-10 {
        border-bottom-left-radius: 7rem !important;
        border-top-left-radius: 7rem !important
    }

    .rounded-start-md-pill {
        border-bottom-left-radius: 50rem !important;
        border-top-left-radius: 50rem !important
    }

    .rounded-start-md-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-md-card {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-md {
        border-top-left-radius: .375rem !important
    }

    .rounded-top-start-md-0 {
        border-top-left-radius: 0 !important
    }

    .rounded-top-start-md-1 {
        border-top-left-radius: .25rem !important
    }

    .rounded-top-start-md-2 {
        border-top-left-radius: .5rem !important
    }

    .rounded-top-start-md-3 {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-md-4 {
        border-top-left-radius: 1rem !important
    }

    .rounded-top-start-md-5 {
        border-top-left-radius: 1.5rem !important
    }

    .rounded-top-start-md-6 {
        border-top-left-radius: 3rem !important
    }

    .rounded-top-start-md-7 {
        border-top-left-radius: 4rem !important
    }

    .rounded-top-start-md-8 {
        border-top-left-radius: 5rem !important
    }

    .rounded-top-start-md-9 {
        border-top-left-radius: 6rem !important
    }

    .rounded-top-start-md-10 {
        border-top-left-radius: 7rem !important
    }

    .rounded-top-start-md-pill {
        border-top-left-radius: 50rem !important
    }

    .rounded-top-start-md-circle {
        border-top-left-radius: 50% !important
    }

    .rounded-top-start-md-card {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-end-md {
        border-top-right-radius: .375rem !important
    }

    .rounded-top-end-md-0 {
        border-top-right-radius: 0 !important
    }

    .rounded-top-end-md-1 {
        border-top-right-radius: .25rem !important
    }

    .rounded-top-end-md-2 {
        border-top-right-radius: .5rem !important
    }

    .rounded-top-end-md-3 {
        border-top-right-radius: .75rem !important
    }

    .rounded-top-end-md-4 {
        border-top-right-radius: 1rem !important
    }

    .rounded-top-end-md-5 {
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-end-md-6 {
        border-top-right-radius: 3rem !important
    }

    .rounded-top-end-md-7 {
        border-top-right-radius: 4rem !important
    }

    .rounded-top-end-md-8 {
        border-top-right-radius: 5rem !important
    }

    .rounded-top-end-md-9 {
        border-top-right-radius: 6rem !important
    }

    .rounded-top-end-md-10 {
        border-top-right-radius: 7rem !important
    }

    .rounded-top-end-md-pill {
        border-top-right-radius: 50rem !important
    }

    .rounded-top-end-md-circle {
        border-top-right-radius: 50% !important
    }

    .rounded-top-end-md-card {
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-end-md {
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-end-md-0 {
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-end-md-1 {
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-end-md-2 {
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-end-md-3 {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-end-md-4 {
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-end-md-5 {
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-end-md-6 {
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-end-md-7 {
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-end-md-8 {
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-end-md-9 {
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-end-md-10 {
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-end-md-pill {
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-end-md-circle {
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-end-md-card {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-start-md {
        border-bottom-left-radius: .375rem !important
    }

    .rounded-bottom-start-md-0 {
        border-bottom-left-radius: 0 !important
    }

    .rounded-bottom-start-md-1 {
        border-bottom-left-radius: .25rem !important
    }

    .rounded-bottom-start-md-2 {
        border-bottom-left-radius: .5rem !important
    }

    .rounded-bottom-start-md-3 {
        border-bottom-left-radius: .75rem !important
    }

    .rounded-bottom-start-md-4 {
        border-bottom-left-radius: 1rem !important
    }

    .rounded-bottom-start-md-5 {
        border-bottom-left-radius: 1.5rem !important
    }

    .rounded-bottom-start-md-6 {
        border-bottom-left-radius: 3rem !important
    }

    .rounded-bottom-start-md-7 {
        border-bottom-left-radius: 4rem !important
    }

    .rounded-bottom-start-md-8 {
        border-bottom-left-radius: 5rem !important
    }

    .rounded-bottom-start-md-9 {
        border-bottom-left-radius: 6rem !important
    }

    .rounded-bottom-start-md-10 {
        border-bottom-left-radius: 7rem !important
    }

    .rounded-bottom-start-md-pill {
        border-bottom-left-radius: 50rem !important
    }

    .rounded-bottom-start-md-circle {
        border-bottom-left-radius: 50% !important
    }

    .rounded-bottom-start-md-card {
        border-bottom-left-radius: .75rem !important
    }

    .border-md-solid {
        border-style: solid !important
    }

    .border-md-dashed {
        border-style: dashed !important
    }

    .border-md-dotted {
        border-style: dotted !important
    }

    .border-md-double {
        border-style: double !important
    }

    .border-md-groove {
        border-style: groove !important
    }

    .border-md-none {
        border-style: none !important
    }

    .text-md-transparent, .text-md-transparent-focus:focus, .text-md-transparent-hover:hover {
        color: transparent !important
    }

    .text-md-current, .text-md-current-focus:focus, .text-md-current-hover:hover {
        color: currentColor !important
    }

    .text-md-reset, .text-md-reset-focus:focus, .text-md-reset-hover:hover {
        color: inherit !important
    }

    .shadow-soft-md-1, .shadow-soft-md-1-focus:focus, .shadow-soft-md-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-md-2, .shadow-soft-md-2-focus:focus, .shadow-soft-md-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-md-3, .shadow-soft-md-3-focus:focus, .shadow-soft-md-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-md-4, .shadow-soft-md-4-focus:focus, .shadow-soft-md-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-md-5, .shadow-soft-md-5-focus:focus, .shadow-soft-md-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.04) !important
    }

    .shadow-soft-md-6, .shadow-soft-md-6-focus:focus, .shadow-soft-md-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-md-none, .shadow-soft-md-none-focus:focus, .shadow-soft-md-none-hover:hover {
        box-shadow: none !important
    }

    .min-w-md-0 {
        min-width: 0 !important
    }

    .min-w-md-full {
        min-width: 100% !important
    }

    .min-w-md-min {
        min-width: -webkit-min-content !important;
        min-width: -moz-min-content !important;
        min-width: min-content !important
    }

    .min-w-md-max {
        min-width: -webkit-max-content !important;
        min-width: -moz-max-content !important;
        min-width: max-content !important
    }

    .min-h-md-0 {
        min-height: 0 !important
    }

    .min-h-md-full {
        min-height: 100% !important
    }

    .min-h-md-screen {
        min-height: 100vh !important
    }

    .place-content-md-start {
        place-content: flex-start !important
    }

    .place-content-md-end {
        place-content: flex-end !important
    }

    .place-content-md-center {
        place-content: center !important
    }

    .place-content-md-between {
        place-content: space-between !important
    }

    .place-content-md-around {
        place-content: space-around !important
    }

    .place-content-md-evenly {
        place-content: space-evenly !important
    }

    .place-content-md-stretch {
        place-content: stretch !important
    }

    .overflow-x-md-auto {
        overflow-x: auto !important
    }

    .overflow-x-md-hidden {
        overflow-x: hidden !important
    }

    .overflow-x-md-visible {
        overflow-x: visible !important
    }

    .overflow-x-md-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-md-auto {
        overflow-y: auto !important
    }

    .overflow-y-md-hidden {
        overflow-y: hidden !important
    }

    .overflow-y-md-visible {
        overflow-y: visible !important
    }

    .overflow-y-md-scroll {
        overflow-y: scroll !important
    }

    .transition-md {
        transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform !important
    }

    .transition-md-none {
        transition-property: none !important
    }

    .transition-md-all {
        transition-property: all !important
    }

    .transition-md-colors {
        transition-property: background-color,border-color,color,fill,stroke !important
    }

    .transition-md-opacity {
        transition-property: opacity !important
    }

    .transition-md-shadow {
        transition-property: box-shadow !important
    }

    .transition-md-transform {
        transition-property: transform !important
    }

    .ease-md-base {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .ease-md-linear {
        transition-timing-function: linear !important
    }

    .ease-md-in {
        transition-timing-function: cubic-bezier(.4,0,1,1) !important
    }

    .ease-md-out {
        transition-timing-function: cubic-bezier(0,0,.2,1) !important
    }

    .ease-md-in-out {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .transform-md-none {
        transform: none !important
    }

    .origin-md-center {
        transform-origin: center !important
    }

    .origin-md-top {
        transform-origin: top !important
    }

    .origin-md-top-right {
        transform-origin: top right !important
    }

    .origin-md-right {
        transform-origin: right !important
    }

    .origin-md-bottom-right {
        transform-origin: bottom right !important
    }

    .origin-md-bottom {
        transform-origin: bottom !important
    }

    .origin-md-bottom-left {
        transform-origin: bottom left !important
    }

    .origin-md-left {
        transform-origin: left !important
    }

    .origin-md-top-left {
        transform-origin: top left !important
    }

    .scale-md-0, .scale-md-0-focus:focus, .scale-md-0-hover:hover {
        --x-scale-x: 0 !important;
        --x-scale-y: 0 !important
    }

    .scale-md-25, .scale-md-25-focus:focus, .scale-md-25-hover:hover {
        --x-scale-x: 0.25 !important;
        --x-scale-y: 0.25 !important
    }

    .scale-md-50, .scale-md-50-focus:focus, .scale-md-50-hover:hover {
        --x-scale-x: 0.5 !important;
        --x-scale-y: 0.5 !important
    }

    .scale-md-75, .scale-md-75-focus:focus, .scale-md-75-hover:hover {
        --x-scale-x: 0.75 !important;
        --x-scale-y: 0.75 !important
    }

    .scale-md-90, .scale-md-90-focus:focus, .scale-md-90-hover:hover {
        --x-scale-x: 0.9 !important;
        --x-scale-y: 0.9 !important
    }

    .scale-md-95, .scale-md-95-focus:focus, .scale-md-95-hover:hover {
        --x-scale-x: 0.95 !important;
        --x-scale-y: 0.95 !important
    }

    .scale-md-100, .scale-md-100-focus:focus, .scale-md-100-hover:hover {
        --x-scale-x: 1 !important;
        --x-scale-y: 1 !important
    }

    .scale-md-105, .scale-md-105-focus:focus, .scale-md-105-hover:hover {
        --x-scale-x: 1.05 !important;
        --x-scale-y: 1.05 !important
    }

    .scale-md-110, .scale-md-110-focus:focus, .scale-md-110-hover:hover {
        --x-scale-x: 1.1 !important;
        --x-scale-y: 1.1 !important
    }

    .scale-md-125, .scale-md-125-focus:focus, .scale-md-125-hover:hover {
        --x-scale-x: 1.25 !important;
        --x-scale-y: 1.25 !important
    }

    .scale-md-150, .scale-md-150-focus:focus, .scale-md-150-hover:hover {
        --x-scale-x: 1.5 !important;
        --x-scale-y: 1.5 !important
    }

    .scale-md-200, .scale-md-200-focus:focus, .scale-md-200-hover:hover {
        --x-scale-x: 2 !important;
        --x-scale-y: 2 !important
    }

    .scale-y-md-0, .scale-y-md-0-focus:focus, .scale-y-md-0-hover:hover {
        --x--scale-y: 0 !important
    }

    .scale-y-md-25, .scale-y-md-25-focus:focus, .scale-y-md-25-hover:hover {
        --x--scale-y: 0.25 !important
    }

    .scale-y-md-50, .scale-y-md-50-focus:focus, .scale-y-md-50-hover:hover {
        --x--scale-y: 0.5 !important
    }

    .scale-y-md-75, .scale-y-md-75-focus:focus, .scale-y-md-75-hover:hover {
        --x--scale-y: 0.75 !important
    }

    .scale-y-md-90, .scale-y-md-90-focus:focus, .scale-y-md-90-hover:hover {
        --x--scale-y: 0.9 !important
    }

    .scale-y-md-95, .scale-y-md-95-focus:focus, .scale-y-md-95-hover:hover {
        --x--scale-y: 0.95 !important
    }

    .scale-y-md-100, .scale-y-md-100-focus:focus, .scale-y-md-100-hover:hover {
        --x--scale-y: 1 !important
    }

    .scale-y-md-105, .scale-y-md-105-focus:focus, .scale-y-md-105-hover:hover {
        --x--scale-y: 1.05 !important
    }

    .scale-y-md-110, .scale-y-md-110-focus:focus, .scale-y-md-110-hover:hover {
        --x--scale-y: 1.1 !important
    }

    .scale-y-md-125, .scale-y-md-125-focus:focus, .scale-y-md-125-hover:hover {
        --x--scale-y: 1.25 !important
    }

    .scale-y-md-150, .scale-y-md-150-focus:focus, .scale-y-md-150-hover:hover {
        --x--scale-y: 1.5 !important
    }

    .scale-y-md-200, .scale-y-md-200-focus:focus, .scale-y-md-200-hover:hover {
        --x--scale-y: 2 !important
    }

    .scale-x-md-0, .scale-x-md-0-focus:focus, .scale-x-md-0-hover:hover {
        --x--scale-x: 0 !important
    }

    .scale-x-md-25, .scale-x-md-25-focus:focus, .scale-x-md-25-hover:hover {
        --x--scale-x: 0.25 !important
    }

    .scale-x-md-50, .scale-x-md-50-focus:focus, .scale-x-md-50-hover:hover {
        --x--scale-x: 0.5 !important
    }

    .scale-x-md-75, .scale-x-md-75-focus:focus, .scale-x-md-75-hover:hover {
        --x--scale-x: 0.75 !important
    }

    .scale-x-md-90, .scale-x-md-90-focus:focus, .scale-x-md-90-hover:hover {
        --x--scale-x: 0.9 !important
    }

    .scale-x-md-95, .scale-x-md-95-focus:focus, .scale-x-md-95-hover:hover {
        --x--scale-x: 0.95 !important
    }

    .scale-x-md-100, .scale-x-md-100-focus:focus, .scale-x-md-100-hover:hover {
        --x--scale-x: 1 !important
    }

    .scale-x-md-105, .scale-x-md-105-focus:focus, .scale-x-md-105-hover:hover {
        --x--scale-x: 1.05 !important
    }

    .scale-x-md-110, .scale-x-md-110-focus:focus, .scale-x-md-110-hover:hover {
        --x--scale-x: 1.1 !important
    }

    .scale-x-md-125, .scale-x-md-125-focus:focus, .scale-x-md-125-hover:hover {
        --x--scale-x: 1.25 !important
    }

    .scale-x-md-150, .scale-x-md-150-focus:focus, .scale-x-md-150-hover:hover {
        --x--scale-x: 1.5 !important
    }

    .scale-x-md-200, .scale-x-md-200-focus:focus, .scale-x-md-200-hover:hover {
        --x--scale-x: 2 !important
    }

    .rotate-md-0, .rotate-md-0-focus:focus, .rotate-md-0-hover:hover {
        --x-rotate: 0deg !important
    }

    .rotate-md-1, .rotate-md-1-focus:focus, .rotate-md-1-hover:hover {
        --x-rotate: 1deg !important
    }

    .rotate-md-2, .rotate-md-2-focus:focus, .rotate-md-2-hover:hover {
        --x-rotate: 2deg !important
    }

    .rotate-md-3, .rotate-md-3-focus:focus, .rotate-md-3-hover:hover {
        --x-rotate: 3deg !important
    }

    .rotate-md-6, .rotate-md-6-focus:focus, .rotate-md-6-hover:hover {
        --x-rotate: 6deg !important
    }

    .rotate-md-12, .rotate-md-12-focus:focus, .rotate-md-12-hover:hover {
        --x-rotate: 12deg !important
    }

    .rotate-md-30, .rotate-md-30-focus:focus, .rotate-md-30-hover:hover {
        --x-rotate: 30deg !important
    }

    .rotate-md-45, .rotate-md-45-focus:focus, .rotate-md-45-hover:hover {
        --x-rotate: 45deg !important
    }

    .rotate-md-90, .rotate-md-90-focus:focus, .rotate-md-90-hover:hover {
        --x-rotate: 90deg !important
    }

    .rotate-md-180, .rotate-md-180-focus:focus, .rotate-md-180-hover:hover {
        --x-rotate: 180deg !important
    }

    .rotate-md-n1, .rotate-md-n1-focus:focus, .rotate-md-n1-hover:hover {
        --x-rotate: -1deg !important
    }

    .rotate-md-n2, .rotate-md-n2-focus:focus, .rotate-md-n2-hover:hover {
        --x-rotate: -2deg !important
    }

    .rotate-md-n3, .rotate-md-n3-focus:focus, .rotate-md-n3-hover:hover {
        --x-rotate: -3deg !important
    }

    .rotate-md-n6, .rotate-md-n6-focus:focus, .rotate-md-n6-hover:hover {
        --x-rotate: -6deg !important
    }

    .rotate-md-n12, .rotate-md-n12-focus:focus, .rotate-md-n12-hover:hover {
        --x-rotate: -12deg !important
    }

    .rotate-md-n30, .rotate-md-n30-focus:focus, .rotate-md-n30-hover:hover {
        --x-rotate: -30deg !important
    }

    .rotate-md-n45, .rotate-md-n45-focus:focus, .rotate-md-n45-hover:hover {
        --x-rotate: -45deg !important
    }

    .rotate-md-n90, .rotate-md-n90-focus:focus, .rotate-md-n90-hover:hover {
        --x-rotate: -90deg !important
    }

    .rotate-md-n180, .rotate-md-n180-focus:focus, .rotate-md-n180-hover:hover {
        --x-rotate: -180deg !important
    }

    .rotate-y-md-0, .rotate-y-md-0-focus:focus, .rotate-y-md-0-hover:hover {
        --x-rotate-y: 0deg !important
    }

    .rotate-y-md-1, .rotate-y-md-1-focus:focus, .rotate-y-md-1-hover:hover {
        --x-rotate-y: 1deg !important
    }

    .rotate-y-md-2, .rotate-y-md-2-focus:focus, .rotate-y-md-2-hover:hover {
        --x-rotate-y: 2deg !important
    }

    .rotate-y-md-3, .rotate-y-md-3-focus:focus, .rotate-y-md-3-hover:hover {
        --x-rotate-y: 3deg !important
    }

    .rotate-y-md-6, .rotate-y-md-6-focus:focus, .rotate-y-md-6-hover:hover {
        --x-rotate-y: 6deg !important
    }

    .rotate-y-md-12, .rotate-y-md-12-focus:focus, .rotate-y-md-12-hover:hover {
        --x-rotate-y: 12deg !important
    }

    .rotate-y-md-30, .rotate-y-md-30-focus:focus, .rotate-y-md-30-hover:hover {
        --x-rotate-y: 30deg !important
    }

    .rotate-y-md-45, .rotate-y-md-45-focus:focus, .rotate-y-md-45-hover:hover {
        --x-rotate-y: 45deg !important
    }

    .rotate-y-md-90, .rotate-y-md-90-focus:focus, .rotate-y-md-90-hover:hover {
        --x-rotate-y: 90deg !important
    }

    .rotate-y-md-180, .rotate-y-md-180-focus:focus, .rotate-y-md-180-hover:hover {
        --x-rotate-y: 180deg !important
    }

    .rotate-y-md-n1, .rotate-y-md-n1-focus:focus, .rotate-y-md-n1-hover:hover {
        --x-rotate-y: -1deg !important
    }

    .rotate-y-md-n2, .rotate-y-md-n2-focus:focus, .rotate-y-md-n2-hover:hover {
        --x-rotate-y: -2deg !important
    }

    .rotate-y-md-n3, .rotate-y-md-n3-focus:focus, .rotate-y-md-n3-hover:hover {
        --x-rotate-y: -3deg !important
    }

    .rotate-y-md-n6, .rotate-y-md-n6-focus:focus, .rotate-y-md-n6-hover:hover {
        --x-rotate-y: -6deg !important
    }

    .rotate-y-md-n12, .rotate-y-md-n12-focus:focus, .rotate-y-md-n12-hover:hover {
        --x-rotate-y: -12deg !important
    }

    .rotate-y-md-n30, .rotate-y-md-n30-focus:focus, .rotate-y-md-n30-hover:hover {
        --x-rotate-y: -30deg !important
    }

    .rotate-y-md-n45, .rotate-y-md-n45-focus:focus, .rotate-y-md-n45-hover:hover {
        --x-rotate-y: -45deg !important
    }

    .rotate-y-md-n90, .rotate-y-md-n90-focus:focus, .rotate-y-md-n90-hover:hover {
        --x-rotate-y: -90deg !important
    }

    .rotate-y-md-n180, .rotate-y-md-n180-focus:focus, .rotate-y-md-n180-hover:hover {
        --x-rotate-y: -180deg !important
    }

    .rotate-x-md-0, .rotate-x-md-0-focus:focus, .rotate-x-md-0-hover:hover {
        --x-rotate-x: 0deg !important
    }

    .rotate-x-md-1, .rotate-x-md-1-focus:focus, .rotate-x-md-1-hover:hover {
        --x-rotate-x: 1deg !important
    }

    .rotate-x-md-2, .rotate-x-md-2-focus:focus, .rotate-x-md-2-hover:hover {
        --x-rotate-x: 2deg !important
    }

    .rotate-x-md-3, .rotate-x-md-3-focus:focus, .rotate-x-md-3-hover:hover {
        --x-rotate-x: 3deg !important
    }

    .rotate-x-md-6, .rotate-x-md-6-focus:focus, .rotate-x-md-6-hover:hover {
        --x-rotate-x: 6deg !important
    }

    .rotate-x-md-12, .rotate-x-md-12-focus:focus, .rotate-x-md-12-hover:hover {
        --x-rotate-x: 12deg !important
    }

    .rotate-x-md-30, .rotate-x-md-30-focus:focus, .rotate-x-md-30-hover:hover {
        --x-rotate-x: 30deg !important
    }

    .rotate-x-md-45, .rotate-x-md-45-focus:focus, .rotate-x-md-45-hover:hover {
        --x-rotate-x: 45deg !important
    }

    .rotate-x-md-90, .rotate-x-md-90-focus:focus, .rotate-x-md-90-hover:hover {
        --x-rotate-x: 90deg !important
    }

    .rotate-x-md-180, .rotate-x-md-180-focus:focus, .rotate-x-md-180-hover:hover {
        --x-rotate-x: 180deg !important
    }

    .rotate-x-md-n1, .rotate-x-md-n1-focus:focus, .rotate-x-md-n1-hover:hover {
        --x-rotate-x: -1deg !important
    }

    .rotate-x-md-n2, .rotate-x-md-n2-focus:focus, .rotate-x-md-n2-hover:hover {
        --x-rotate-x: -2deg !important
    }

    .rotate-x-md-n3, .rotate-x-md-n3-focus:focus, .rotate-x-md-n3-hover:hover {
        --x-rotate-x: -3deg !important
    }

    .rotate-x-md-n6, .rotate-x-md-n6-focus:focus, .rotate-x-md-n6-hover:hover {
        --x-rotate-x: -6deg !important
    }

    .rotate-x-md-n12, .rotate-x-md-n12-focus:focus, .rotate-x-md-n12-hover:hover {
        --x-rotate-x: -12deg !important
    }

    .rotate-x-md-n30, .rotate-x-md-n30-focus:focus, .rotate-x-md-n30-hover:hover {
        --x-rotate-x: -30deg !important
    }

    .rotate-x-md-n45, .rotate-x-md-n45-focus:focus, .rotate-x-md-n45-hover:hover {
        --x-rotate-x: -45deg !important
    }

    .rotate-x-md-n90, .rotate-x-md-n90-focus:focus, .rotate-x-md-n90-hover:hover {
        --x-rotate-x: -90deg !important
    }

    .rotate-x-md-n180, .rotate-x-md-n180-focus:focus, .rotate-x-md-n180-hover:hover {
        --x-rotate-x: -180deg !important
    }

    .perspective-md-100, .perspective-md-100-focus:focus, .perspective-md-100-hover:hover {
        --x-perspective: 100px !important
    }

    .perspective-md-200, .perspective-md-200-focus:focus, .perspective-md-200-hover:hover {
        --x-perspective: 200px !important
    }

    .perspective-md-300, .perspective-md-300-focus:focus, .perspective-md-300-hover:hover {
        --x-perspective: 300px !important
    }

    .perspective-md-400, .perspective-md-400-focus:focus, .perspective-md-400-hover:hover {
        --x-perspective: 400px !important
    }

    .perspective-md-500, .perspective-md-500-focus:focus, .perspective-md-500-hover:hover {
        --x-perspective: 500px !important
    }

    .perspective-md-600, .perspective-md-600-focus:focus, .perspective-md-600-hover:hover {
        --x-perspective: 600px !important
    }

    .perspective-md-700, .perspective-md-700-focus:focus, .perspective-md-700-hover:hover {
        --x-perspective: 700px !important
    }

    .perspective-md-800, .perspective-md-800-focus:focus, .perspective-md-800-hover:hover {
        --x-perspective: 800px !important
    }

    .perspective-md-1000, .perspective-md-1000-focus:focus, .perspective-md-1000-hover:hover {
        --x-perspective: 1000px !important
    }

    .perspective-md-1250, .perspective-md-1250-focus:focus, .perspective-md-1250-hover:hover {
        --x-perspective: 1250px !important
    }

    .perspective-md-1500, .perspective-md-1500-focus:focus, .perspective-md-1500-hover:hover {
        --x-perspective: 1500px !important
    }

    .translate-y-md-0, .translate-y-md-0-focus:focus, .translate-y-md-0-hover:hover {
        --x-translate-y: 0 !important
    }

    .translate-y-md-1, .translate-y-md-1-focus:focus, .translate-y-md-1-hover:hover {
        --x-translate-y: 0.25rem !important
    }

    .translate-y-md-2, .translate-y-md-2-focus:focus, .translate-y-md-2-hover:hover {
        --x-translate-y: 0.5rem !important
    }

    .translate-y-md-3, .translate-y-md-3-focus:focus, .translate-y-md-3-hover:hover {
        --x-translate-y: 0.75rem !important
    }

    .translate-y-md-4, .translate-y-md-4-focus:focus, .translate-y-md-4-hover:hover {
        --x-translate-y: 1rem !important
    }

    .translate-y-md-5, .translate-y-md-5-focus:focus, .translate-y-md-5-hover:hover {
        --x-translate-y: 1.25rem !important
    }

    .translate-y-md-6, .translate-y-md-6-focus:focus, .translate-y-md-6-hover:hover {
        --x-translate-y: 1.5rem !important
    }

    .translate-y-md-7, .translate-y-md-7-focus:focus, .translate-y-md-7-hover:hover {
        --x-translate-y: 1.75rem !important
    }

    .translate-y-md-8, .translate-y-md-8-focus:focus, .translate-y-md-8-hover:hover {
        --x-translate-y: 2rem !important
    }

    .translate-y-md-10, .translate-y-md-10-focus:focus, .translate-y-md-10-hover:hover {
        --x-translate-y: 2.5rem !important
    }

    .translate-y-md-12, .translate-y-md-12-focus:focus, .translate-y-md-12-hover:hover {
        --x-translate-y: 3rem !important
    }

    .translate-y-md-14, .translate-y-md-14-focus:focus, .translate-y-md-14-hover:hover {
        --x-translate-y: 3.5rem !important
    }

    .translate-y-md-16, .translate-y-md-16-focus:focus, .translate-y-md-16-hover:hover {
        --x-translate-y: 4rem !important
    }

    .translate-y-md-18, .translate-y-md-18-focus:focus, .translate-y-md-18-hover:hover {
        --x-translate-y: 4.5rem !important
    }

    .translate-y-md-20, .translate-y-md-20-focus:focus, .translate-y-md-20-hover:hover {
        --x-translate-y: 5rem !important
    }

    .translate-y-md-24, .translate-y-md-24-focus:focus, .translate-y-md-24-hover:hover {
        --x-translate-y: 6rem !important
    }

    .translate-y-md-32, .translate-y-md-32-focus:focus, .translate-y-md-32-hover:hover {
        --x-translate-y: 8rem !important
    }

    .translate-y-md-40, .translate-y-md-40-focus:focus, .translate-y-md-40-hover:hover {
        --x-translate-y: 10rem !important
    }

    .translate-y-md-48, .translate-y-md-48-focus:focus, .translate-y-md-48-hover:hover {
        --x-translate-y: 12rem !important
    }

    .translate-y-md-56, .translate-y-md-56-focus:focus, .translate-y-md-56-hover:hover {
        --x-translate-y: 14rem !important
    }

    .translate-y-md-64, .translate-y-md-64-focus:focus, .translate-y-md-64-hover:hover {
        --x-translate-y: 16rem !important
    }

    .translate-y-md-72, .translate-y-md-72-focus:focus, .translate-y-md-72-hover:hover {
        --x-translate-y: 18rem !important
    }

    .translate-y-md-80, .translate-y-md-80-focus:focus, .translate-y-md-80-hover:hover {
        --x-translate-y: 20rem !important
    }

    .translate-y-md-88, .translate-y-md-88-focus:focus, .translate-y-md-88-hover:hover {
        --x-translate-y: 22rem !important
    }

    .translate-y-md-96, .translate-y-md-96-focus:focus, .translate-y-md-96-hover:hover {
        --x-translate-y: 24rem !important
    }

    .translate-y-md-px, .translate-y-md-px-focus:focus, .translate-y-md-px-hover:hover {
        --x-translate-y: 1px !important
    }

    .translate-y-md-1\/2, .translate-y-md-1\/2-focus:focus, .translate-y-md-1\/2-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-md-1\/3, .translate-y-md-1\/3-focus:focus, .translate-y-md-1\/3-hover:hover {
        --x-translate-y: 33.333333% !important
    }

    .translate-y-md-1\/4, .translate-y-md-1\/4-focus:focus, .translate-y-md-1\/4-hover:hover {
        --x-translate-y: 25% !important
    }

    .translate-y-md-1\/5, .translate-y-md-1\/5-focus:focus, .translate-y-md-1\/5-hover:hover {
        --x-translate-y: 20% !important
    }

    .translate-y-md-2\/3, .translate-y-md-2\/3-focus:focus, .translate-y-md-2\/3-hover:hover {
        --x-translate-y: 66.666667% !important
    }

    .translate-y-md-2\/5, .translate-y-md-2\/5-focus:focus, .translate-y-md-2\/5-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-md-3\/4, .translate-y-md-3\/4-focus:focus, .translate-y-md-3\/4-hover:hover {
        --x-translate-y: 75% !important
    }

    .translate-y-md-full, .translate-y-md-full-focus:focus, .translate-y-md-full-hover:hover {
        --x-translate-y: 100% !important
    }

    .translate-y-md-n1, .translate-y-md-n1-focus:focus, .translate-y-md-n1-hover:hover {
        --x-translate-y: -0.25rem !important
    }

    .translate-y-md-n2, .translate-y-md-n2-focus:focus, .translate-y-md-n2-hover:hover {
        --x-translate-y: -0.5rem !important
    }

    .translate-y-md-n3, .translate-y-md-n3-focus:focus, .translate-y-md-n3-hover:hover {
        --x-translate-y: -0.75rem !important
    }

    .translate-y-md-n4, .translate-y-md-n4-focus:focus, .translate-y-md-n4-hover:hover {
        --x-translate-y: -1rem !important
    }

    .translate-y-md-n5, .translate-y-md-n5-focus:focus, .translate-y-md-n5-hover:hover {
        --x-translate-y: -1.25rem !important
    }

    .translate-y-md-n6, .translate-y-md-n6-focus:focus, .translate-y-md-n6-hover:hover {
        --x-translate-y: -1.5rem !important
    }

    .translate-y-md-n7, .translate-y-md-n7-focus:focus, .translate-y-md-n7-hover:hover {
        --x-translate-y: -1.75rem !important
    }

    .translate-y-md-n8, .translate-y-md-n8-focus:focus, .translate-y-md-n8-hover:hover {
        --x-translate-y: -2rem !important
    }

    .translate-y-md-n10, .translate-y-md-n10-focus:focus, .translate-y-md-n10-hover:hover {
        --x-translate-y: -2.5rem !important
    }

    .translate-y-md-n12, .translate-y-md-n12-focus:focus, .translate-y-md-n12-hover:hover {
        --x-translate-y: -3rem !important
    }

    .translate-y-md-n14, .translate-y-md-n14-focus:focus, .translate-y-md-n14-hover:hover {
        --x-translate-y: -3.5rem !important
    }

    .translate-y-md-n16, .translate-y-md-n16-focus:focus, .translate-y-md-n16-hover:hover {
        --x-translate-y: -4rem !important
    }

    .translate-y-md-n18, .translate-y-md-n18-focus:focus, .translate-y-md-n18-hover:hover {
        --x-translate-y: -4.5rem !important
    }

    .translate-y-md-n20, .translate-y-md-n20-focus:focus, .translate-y-md-n20-hover:hover {
        --x-translate-y: -5rem !important
    }

    .translate-y-md-n24, .translate-y-md-n24-focus:focus, .translate-y-md-n24-hover:hover {
        --x-translate-y: -6rem !important
    }

    .translate-y-md-n32, .translate-y-md-n32-focus:focus, .translate-y-md-n32-hover:hover {
        --x-translate-y: -8rem !important
    }

    .translate-y-md-n40, .translate-y-md-n40-focus:focus, .translate-y-md-n40-hover:hover {
        --x-translate-y: -10rem !important
    }

    .translate-y-md-n48, .translate-y-md-n48-focus:focus, .translate-y-md-n48-hover:hover {
        --x-translate-y: -12rem !important
    }

    .translate-y-md-n56, .translate-y-md-n56-focus:focus, .translate-y-md-n56-hover:hover {
        --x-translate-y: -14rem !important
    }

    .translate-y-md-n64, .translate-y-md-n64-focus:focus, .translate-y-md-n64-hover:hover {
        --x-translate-y: -16rem !important
    }

    .translate-y-md-n72, .translate-y-md-n72-focus:focus, .translate-y-md-n72-hover:hover {
        --x-translate-y: -18rem !important
    }

    .translate-y-md-n80, .translate-y-md-n80-focus:focus, .translate-y-md-n80-hover:hover {
        --x-translate-y: -20rem !important
    }

    .translate-y-md-n88, .translate-y-md-n88-focus:focus, .translate-y-md-n88-hover:hover {
        --x-translate-y: -22rem !important
    }

    .translate-y-md-n96, .translate-y-md-n96-focus:focus, .translate-y-md-n96-hover:hover {
        --x-translate-y: -24rem !important
    }

    .translate-y-md-npx, .translate-y-md-npx-focus:focus, .translate-y-md-npx-hover:hover {
        --x-translate-y: -1px !important
    }

    .translate-y-md-n1\/2, .translate-y-md-n1\/2-focus:focus, .translate-y-md-n1\/2-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-md-n1\/3, .translate-y-md-n1\/3-focus:focus, .translate-y-md-n1\/3-hover:hover {
        --x-translate-y: -33.333333% !important
    }

    .translate-y-md-n1\/4, .translate-y-md-n1\/4-focus:focus, .translate-y-md-n1\/4-hover:hover {
        --x-translate-y: -25% !important
    }

    .translate-y-md-n1\/5, .translate-y-md-n1\/5-focus:focus, .translate-y-md-n1\/5-hover:hover {
        --x-translate-y: -20% !important
    }

    .translate-y-md-n2\/3, .translate-y-md-n2\/3-focus:focus, .translate-y-md-n2\/3-hover:hover {
        --x-translate-y: -66.666667% !important
    }

    .translate-y-md-n2\/5, .translate-y-md-n2\/5-focus:focus, .translate-y-md-n2\/5-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-md-n3\/4, .translate-y-md-n3\/4-focus:focus, .translate-y-md-n3\/4-hover:hover {
        --x-translate-y: -75% !important
    }

    .translate-y-md-nfull, .translate-y-md-nfull-focus:focus, .translate-y-md-nfull-hover:hover {
        --x-translate-y: -100% !important
    }

    .translate-x-md-0, .translate-x-md-0-focus:focus, .translate-x-md-0-hover:hover {
        --x-translate-x: 0 !important
    }

    .translate-x-md-1, .translate-x-md-1-focus:focus, .translate-x-md-1-hover:hover {
        --x-translate-x: 0.25rem !important
    }

    .translate-x-md-2, .translate-x-md-2-focus:focus, .translate-x-md-2-hover:hover {
        --x-translate-x: 0.5rem !important
    }

    .translate-x-md-3, .translate-x-md-3-focus:focus, .translate-x-md-3-hover:hover {
        --x-translate-x: 0.75rem !important
    }

    .translate-x-md-4, .translate-x-md-4-focus:focus, .translate-x-md-4-hover:hover {
        --x-translate-x: 1rem !important
    }

    .translate-x-md-5, .translate-x-md-5-focus:focus, .translate-x-md-5-hover:hover {
        --x-translate-x: 1.25rem !important
    }

    .translate-x-md-6, .translate-x-md-6-focus:focus, .translate-x-md-6-hover:hover {
        --x-translate-x: 1.5rem !important
    }

    .translate-x-md-7, .translate-x-md-7-focus:focus, .translate-x-md-7-hover:hover {
        --x-translate-x: 1.75rem !important
    }

    .translate-x-md-8, .translate-x-md-8-focus:focus, .translate-x-md-8-hover:hover {
        --x-translate-x: 2rem !important
    }

    .translate-x-md-10, .translate-x-md-10-focus:focus, .translate-x-md-10-hover:hover {
        --x-translate-x: 2.5rem !important
    }

    .translate-x-md-12, .translate-x-md-12-focus:focus, .translate-x-md-12-hover:hover {
        --x-translate-x: 3rem !important
    }

    .translate-x-md-14, .translate-x-md-14-focus:focus, .translate-x-md-14-hover:hover {
        --x-translate-x: 3.5rem !important
    }

    .translate-x-md-16, .translate-x-md-16-focus:focus, .translate-x-md-16-hover:hover {
        --x-translate-x: 4rem !important
    }

    .translate-x-md-18, .translate-x-md-18-focus:focus, .translate-x-md-18-hover:hover {
        --x-translate-x: 4.5rem !important
    }

    .translate-x-md-20, .translate-x-md-20-focus:focus, .translate-x-md-20-hover:hover {
        --x-translate-x: 5rem !important
    }

    .translate-x-md-24, .translate-x-md-24-focus:focus, .translate-x-md-24-hover:hover {
        --x-translate-x: 6rem !important
    }

    .translate-x-md-32, .translate-x-md-32-focus:focus, .translate-x-md-32-hover:hover {
        --x-translate-x: 8rem !important
    }

    .translate-x-md-40, .translate-x-md-40-focus:focus, .translate-x-md-40-hover:hover {
        --x-translate-x: 10rem !important
    }

    .translate-x-md-48, .translate-x-md-48-focus:focus, .translate-x-md-48-hover:hover {
        --x-translate-x: 12rem !important
    }

    .translate-x-md-56, .translate-x-md-56-focus:focus, .translate-x-md-56-hover:hover {
        --x-translate-x: 14rem !important
    }

    .translate-x-md-64, .translate-x-md-64-focus:focus, .translate-x-md-64-hover:hover {
        --x-translate-x: 16rem !important
    }

    .translate-x-md-72, .translate-x-md-72-focus:focus, .translate-x-md-72-hover:hover {
        --x-translate-x: 18rem !important
    }

    .translate-x-md-80, .translate-x-md-80-focus:focus, .translate-x-md-80-hover:hover {
        --x-translate-x: 20rem !important
    }

    .translate-x-md-88, .translate-x-md-88-focus:focus, .translate-x-md-88-hover:hover {
        --x-translate-x: 22rem !important
    }

    .translate-x-md-96, .translate-x-md-96-focus:focus, .translate-x-md-96-hover:hover {
        --x-translate-x: 24rem !important
    }

    .translate-x-md-px, .translate-x-md-px-focus:focus, .translate-x-md-px-hover:hover {
        --x-translate-x: 1px !important
    }

    .translate-x-md-1\/2, .translate-x-md-1\/2-focus:focus, .translate-x-md-1\/2-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-md-1\/3, .translate-x-md-1\/3-focus:focus, .translate-x-md-1\/3-hover:hover {
        --x-translate-x: 33.333333% !important
    }

    .translate-x-md-1\/4, .translate-x-md-1\/4-focus:focus, .translate-x-md-1\/4-hover:hover {
        --x-translate-x: 25% !important
    }

    .translate-x-md-1\/5, .translate-x-md-1\/5-focus:focus, .translate-x-md-1\/5-hover:hover {
        --x-translate-x: 20% !important
    }

    .translate-x-md-2\/3, .translate-x-md-2\/3-focus:focus, .translate-x-md-2\/3-hover:hover {
        --x-translate-x: 66.666667% !important
    }

    .translate-x-md-2\/5, .translate-x-md-2\/5-focus:focus, .translate-x-md-2\/5-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-md-3\/4, .translate-x-md-3\/4-focus:focus, .translate-x-md-3\/4-hover:hover {
        --x-translate-x: 75% !important
    }

    .translate-x-md-full, .translate-x-md-full-focus:focus, .translate-x-md-full-hover:hover {
        --x-translate-x: 100% !important
    }

    .translate-x-md-n1, .translate-x-md-n1-focus:focus, .translate-x-md-n1-hover:hover {
        --x-translate-x: -0.25rem !important
    }

    .translate-x-md-n2, .translate-x-md-n2-focus:focus, .translate-x-md-n2-hover:hover {
        --x-translate-x: -0.5rem !important
    }

    .translate-x-md-n3, .translate-x-md-n3-focus:focus, .translate-x-md-n3-hover:hover {
        --x-translate-x: -0.75rem !important
    }

    .translate-x-md-n4, .translate-x-md-n4-focus:focus, .translate-x-md-n4-hover:hover {
        --x-translate-x: -1rem !important
    }

    .translate-x-md-n5, .translate-x-md-n5-focus:focus, .translate-x-md-n5-hover:hover {
        --x-translate-x: -1.25rem !important
    }

    .translate-x-md-n6, .translate-x-md-n6-focus:focus, .translate-x-md-n6-hover:hover {
        --x-translate-x: -1.5rem !important
    }

    .translate-x-md-n7, .translate-x-md-n7-focus:focus, .translate-x-md-n7-hover:hover {
        --x-translate-x: -1.75rem !important
    }

    .translate-x-md-n8, .translate-x-md-n8-focus:focus, .translate-x-md-n8-hover:hover {
        --x-translate-x: -2rem !important
    }

    .translate-x-md-n10, .translate-x-md-n10-focus:focus, .translate-x-md-n10-hover:hover {
        --x-translate-x: -2.5rem !important
    }

    .translate-x-md-n12, .translate-x-md-n12-focus:focus, .translate-x-md-n12-hover:hover {
        --x-translate-x: -3rem !important
    }

    .translate-x-md-n14, .translate-x-md-n14-focus:focus, .translate-x-md-n14-hover:hover {
        --x-translate-x: -3.5rem !important
    }

    .translate-x-md-n16, .translate-x-md-n16-focus:focus, .translate-x-md-n16-hover:hover {
        --x-translate-x: -4rem !important
    }

    .translate-x-md-n18, .translate-x-md-n18-focus:focus, .translate-x-md-n18-hover:hover {
        --x-translate-x: -4.5rem !important
    }

    .translate-x-md-n20, .translate-x-md-n20-focus:focus, .translate-x-md-n20-hover:hover {
        --x-translate-x: -5rem !important
    }

    .translate-x-md-n24, .translate-x-md-n24-focus:focus, .translate-x-md-n24-hover:hover {
        --x-translate-x: -6rem !important
    }

    .translate-x-md-n32, .translate-x-md-n32-focus:focus, .translate-x-md-n32-hover:hover {
        --x-translate-x: -8rem !important
    }

    .translate-x-md-n40, .translate-x-md-n40-focus:focus, .translate-x-md-n40-hover:hover {
        --x-translate-x: -10rem !important
    }

    .translate-x-md-n48, .translate-x-md-n48-focus:focus, .translate-x-md-n48-hover:hover {
        --x-translate-x: -12rem !important
    }

    .translate-x-md-n56, .translate-x-md-n56-focus:focus, .translate-x-md-n56-hover:hover {
        --x-translate-x: -14rem !important
    }

    .translate-x-md-n64, .translate-x-md-n64-focus:focus, .translate-x-md-n64-hover:hover {
        --x-translate-x: -16rem !important
    }

    .translate-x-md-n72, .translate-x-md-n72-focus:focus, .translate-x-md-n72-hover:hover {
        --x-translate-x: -18rem !important
    }

    .translate-x-md-n80, .translate-x-md-n80-focus:focus, .translate-x-md-n80-hover:hover {
        --x-translate-x: -20rem !important
    }

    .translate-x-md-n88, .translate-x-md-n88-focus:focus, .translate-x-md-n88-hover:hover {
        --x-translate-x: -22rem !important
    }

    .translate-x-md-n96, .translate-x-md-n96-focus:focus, .translate-x-md-n96-hover:hover {
        --x-translate-x: -24rem !important
    }

    .translate-x-md-npx, .translate-x-md-npx-focus:focus, .translate-x-md-npx-hover:hover {
        --x-translate-x: -1px !important
    }

    .translate-x-md-n1\/2, .translate-x-md-n1\/2-focus:focus, .translate-x-md-n1\/2-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-md-n1\/3, .translate-x-md-n1\/3-focus:focus, .translate-x-md-n1\/3-hover:hover {
        --x-translate-x: -33.333333% !important
    }

    .translate-x-md-n1\/4, .translate-x-md-n1\/4-focus:focus, .translate-x-md-n1\/4-hover:hover {
        --x-translate-x: -25% !important
    }

    .translate-x-md-n1\/5, .translate-x-md-n1\/5-focus:focus, .translate-x-md-n1\/5-hover:hover {
        --x-translate-x: -20% !important
    }

    .translate-x-md-n2\/3, .translate-x-md-n2\/3-focus:focus, .translate-x-md-n2\/3-hover:hover {
        --x-translate-x: -66.666667% !important
    }

    .translate-x-md-n2\/5, .translate-x-md-n2\/5-focus:focus, .translate-x-md-n2\/5-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-md-n3\/4, .translate-x-md-n3\/4-focus:focus, .translate-x-md-n3\/4-hover:hover {
        --x-translate-x: -75% !important
    }

    .translate-x-md-nfull, .translate-x-md-nfull-focus:focus, .translate-x-md-nfull-hover:hover {
        --x-translate-x: -100% !important
    }
}

@media (min-width:992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .overflow-lg-auto {
        overflow: auto !important
    }

    .overflow-lg-hidden {
        overflow: hidden !important
    }

    .overflow-lg-visible {
        overflow: visible !important
    }

    .overflow-lg-scroll {
        overflow: scroll !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .shadow-lg, .shadow-lg-focus:focus, .shadow-lg-hover:hover {
        box-shadow: 0 3px 3px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-lg-1, .shadow-lg-1-focus:focus, .shadow-lg-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-lg-2, .shadow-lg-2-focus:focus, .shadow-lg-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-lg-3, .shadow-lg-3-focus:focus, .shadow-lg-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-lg-4, .shadow-lg-4-focus:focus, .shadow-lg-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-lg-5, .shadow-lg-5-focus:focus, .shadow-lg-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-lg-6, .shadow-lg-6-focus:focus, .shadow-lg-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-lg-inset, .shadow-lg-inset-focus:focus, .shadow-lg-inset-hover:hover {
        box-shadow: inset 0 1px 2px rgba(0,0,0,.075) !important
    }

    .shadow-lg-focus, .shadow-lg-focus-focus:focus, .shadow-lg-focus-hover:hover, .shadow-lg-outline, .shadow-lg-outline-focus:focus, .shadow-lg-outline-hover:hover {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5) !important
    }

    .shadow-lg-none, .shadow-lg-none-focus:focus, .shadow-lg-none-hover:hover {
        box-shadow: none !important
    }

    .position-lg-static {
        position: static !important
    }

    .position-lg-relative {
        position: relative !important
    }

    .position-lg-absolute {
        position: absolute !important
    }

    .position-lg-fixed {
        position: fixed !important
    }

    .position-lg-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-lg-0 {
        top: 0 !important
    }

    .top-lg-1 {
        top: .25rem !important
    }

    .top-lg-2 {
        top: .5rem !important
    }

    .top-lg-3 {
        top: .75rem !important
    }

    .top-lg-4 {
        top: 1rem !important
    }

    .top-lg-5 {
        top: 1.25rem !important
    }

    .top-lg-6 {
        top: 1.5rem !important
    }

    .top-lg-7 {
        top: 1.75rem !important
    }

    .top-lg-8 {
        top: 2rem !important
    }

    .top-lg-10 {
        top: 2.5rem !important
    }

    .top-lg-12 {
        top: 3rem !important
    }

    .top-lg-14 {
        top: 3.5rem !important
    }

    .top-lg-16 {
        top: 4rem !important
    }

    .top-lg-18 {
        top: 4.5rem !important
    }

    .top-lg-20 {
        top: 5rem !important
    }

    .top-lg-24 {
        top: 6rem !important
    }

    .top-lg-32 {
        top: 8rem !important
    }

    .top-lg-40 {
        top: 10rem !important
    }

    .top-lg-48 {
        top: 12rem !important
    }

    .top-lg-56 {
        top: 14rem !important
    }

    .top-lg-64 {
        top: 16rem !important
    }

    .top-lg-72 {
        top: 18rem !important
    }

    .top-lg-80 {
        top: 20rem !important
    }

    .top-lg-88 {
        top: 22rem !important
    }

    .top-lg-96 {
        top: 24rem !important
    }

    .top-lg-px {
        top: 1px !important
    }

    .top-lg-1\/2 {
        top: 50% !important
    }

    .top-lg-full {
        top: 100% !important
    }

    .top-lg-auto {
        top: auto !important
    }

    .bottom-lg-0 {
        bottom: 0 !important
    }

    .bottom-lg-1 {
        bottom: .25rem !important
    }

    .bottom-lg-2 {
        bottom: .5rem !important
    }

    .bottom-lg-3 {
        bottom: .75rem !important
    }

    .bottom-lg-4 {
        bottom: 1rem !important
    }

    .bottom-lg-5 {
        bottom: 1.25rem !important
    }

    .bottom-lg-6 {
        bottom: 1.5rem !important
    }

    .bottom-lg-7 {
        bottom: 1.75rem !important
    }

    .bottom-lg-8 {
        bottom: 2rem !important
    }

    .bottom-lg-10 {
        bottom: 2.5rem !important
    }

    .bottom-lg-12 {
        bottom: 3rem !important
    }

    .bottom-lg-14 {
        bottom: 3.5rem !important
    }

    .bottom-lg-16 {
        bottom: 4rem !important
    }

    .bottom-lg-18 {
        bottom: 4.5rem !important
    }

    .bottom-lg-20 {
        bottom: 5rem !important
    }

    .bottom-lg-24 {
        bottom: 6rem !important
    }

    .bottom-lg-32 {
        bottom: 8rem !important
    }

    .bottom-lg-40 {
        bottom: 10rem !important
    }

    .bottom-lg-48 {
        bottom: 12rem !important
    }

    .bottom-lg-56 {
        bottom: 14rem !important
    }

    .bottom-lg-64 {
        bottom: 16rem !important
    }

    .bottom-lg-72 {
        bottom: 18rem !important
    }

    .bottom-lg-80 {
        bottom: 20rem !important
    }

    .bottom-lg-88 {
        bottom: 22rem !important
    }

    .bottom-lg-96 {
        bottom: 24rem !important
    }

    .bottom-lg-px {
        bottom: 1px !important
    }

    .bottom-lg-1\/2 {
        bottom: 50% !important
    }

    .bottom-lg-full {
        bottom: 100% !important
    }

    .bottom-lg-auto {
        bottom: auto !important
    }

    .start-lg-0 {
        left: 0 !important
    }

    .start-lg-1 {
        left: .25rem !important
    }

    .start-lg-2 {
        left: .5rem !important
    }

    .start-lg-3 {
        left: .75rem !important
    }

    .start-lg-4 {
        left: 1rem !important
    }

    .start-lg-5 {
        left: 1.25rem !important
    }

    .start-lg-6 {
        left: 1.5rem !important
    }

    .start-lg-7 {
        left: 1.75rem !important
    }

    .start-lg-8 {
        left: 2rem !important
    }

    .start-lg-10 {
        left: 2.5rem !important
    }

    .start-lg-12 {
        left: 3rem !important
    }

    .start-lg-14 {
        left: 3.5rem !important
    }

    .start-lg-16 {
        left: 4rem !important
    }

    .start-lg-18 {
        left: 4.5rem !important
    }

    .start-lg-20 {
        left: 5rem !important
    }

    .start-lg-24 {
        left: 6rem !important
    }

    .start-lg-32 {
        left: 8rem !important
    }

    .start-lg-40 {
        left: 10rem !important
    }

    .start-lg-48 {
        left: 12rem !important
    }

    .start-lg-56 {
        left: 14rem !important
    }

    .start-lg-64 {
        left: 16rem !important
    }

    .start-lg-72 {
        left: 18rem !important
    }

    .start-lg-80 {
        left: 20rem !important
    }

    .start-lg-88 {
        left: 22rem !important
    }

    .start-lg-96 {
        left: 24rem !important
    }

    .start-lg-px {
        left: 1px !important
    }

    .start-lg-1\/2 {
        left: 50% !important
    }

    .start-lg-full {
        left: 100% !important
    }

    .start-lg-auto {
        left: auto !important
    }

    .end-lg-0 {
        right: 0 !important
    }

    .end-lg-1 {
        right: .25rem !important
    }

    .end-lg-2 {
        right: .5rem !important
    }

    .end-lg-3 {
        right: .75rem !important
    }

    .end-lg-4 {
        right: 1rem !important
    }

    .end-lg-5 {
        right: 1.25rem !important
    }

    .end-lg-6 {
        right: 1.5rem !important
    }

    .end-lg-7 {
        right: 1.75rem !important
    }

    .end-lg-8 {
        right: 2rem !important
    }

    .end-lg-10 {
        right: 2.5rem !important
    }

    .end-lg-12 {
        right: 3rem !important
    }

    .end-lg-14 {
        right: 3.5rem !important
    }

    .end-lg-16 {
        right: 4rem !important
    }

    .end-lg-18 {
        right: 4.5rem !important
    }

    .end-lg-20 {
        right: 5rem !important
    }

    .end-lg-24 {
        right: 6rem !important
    }

    .end-lg-32 {
        right: 8rem !important
    }

    .end-lg-40 {
        right: 10rem !important
    }

    .end-lg-48 {
        right: 12rem !important
    }

    .end-lg-56 {
        right: 14rem !important
    }

    .end-lg-64 {
        right: 16rem !important
    }

    .end-lg-72 {
        right: 18rem !important
    }

    .end-lg-80 {
        right: 20rem !important
    }

    .end-lg-88 {
        right: 22rem !important
    }

    .end-lg-96 {
        right: 24rem !important
    }

    .end-lg-px {
        right: 1px !important
    }

    .end-lg-1\/2 {
        right: 50% !important
    }

    .end-lg-full {
        right: 100% !important
    }

    .end-lg-auto {
        right: auto !important
    }

    .border-top-lg-0, .border-top-lg-0-focus:focus, .border-top-lg-0-hover:hover {
        border-top-width: 0 !important
    }

    .border-top-lg, .border-top-lg-focus:focus, .border-top-lg-hover:hover {
        border-top-width: 1px !important
    }

    .border-top-lg-2, .border-top-lg-2-focus:focus, .border-top-lg-2-hover:hover {
        border-top-width: 2px !important
    }

    .border-top-lg-3, .border-top-lg-3-focus:focus, .border-top-lg-3-hover:hover {
        border-top-width: 3px !important
    }

    .border-top-lg-4, .border-top-lg-4-focus:focus, .border-top-lg-4-hover:hover {
        border-top-width: 4px !important
    }

    .border-top-lg-5, .border-top-lg-5-focus:focus, .border-top-lg-5-hover:hover {
        border-top-width: 5px !important
    }

    .border-top-lg-6, .border-top-lg-6-focus:focus, .border-top-lg-6-hover:hover {
        border-top-width: 6px !important
    }

    .border-top-lg-7, .border-top-lg-7-focus:focus, .border-top-lg-7-hover:hover {
        border-top-width: 7px !important
    }

    .border-top-lg-8, .border-top-lg-8-focus:focus, .border-top-lg-8-hover:hover {
        border-top-width: 8px !important
    }

    .border-end-lg-0, .border-end-lg-0-focus:focus, .border-end-lg-0-hover:hover {
        border-right-width: 0 !important
    }

    .border-end-lg, .border-end-lg-focus:focus, .border-end-lg-hover:hover {
        border-right-width: 1px !important
    }

    .border-end-lg-2, .border-end-lg-2-focus:focus, .border-end-lg-2-hover:hover {
        border-right-width: 2px !important
    }

    .border-end-lg-3, .border-end-lg-3-focus:focus, .border-end-lg-3-hover:hover {
        border-right-width: 3px !important
    }

    .border-end-lg-4, .border-end-lg-4-focus:focus, .border-end-lg-4-hover:hover {
        border-right-width: 4px !important
    }

    .border-end-lg-5, .border-end-lg-5-focus:focus, .border-end-lg-5-hover:hover {
        border-right-width: 5px !important
    }

    .border-end-lg-6, .border-end-lg-6-focus:focus, .border-end-lg-6-hover:hover {
        border-right-width: 6px !important
    }

    .border-end-lg-7, .border-end-lg-7-focus:focus, .border-end-lg-7-hover:hover {
        border-right-width: 7px !important
    }

    .border-end-lg-8, .border-end-lg-8-focus:focus, .border-end-lg-8-hover:hover {
        border-right-width: 8px !important
    }

    .border-bottom-lg-0, .border-bottom-lg-0-focus:focus, .border-bottom-lg-0-hover:hover {
        border-bottom-width: 0 !important
    }

    .border-bottom-lg, .border-bottom-lg-focus:focus, .border-bottom-lg-hover:hover {
        border-bottom-width: 1px !important
    }

    .border-bottom-lg-2, .border-bottom-lg-2-focus:focus, .border-bottom-lg-2-hover:hover {
        border-bottom-width: 2px !important
    }

    .border-bottom-lg-3, .border-bottom-lg-3-focus:focus, .border-bottom-lg-3-hover:hover {
        border-bottom-width: 3px !important
    }

    .border-bottom-lg-4, .border-bottom-lg-4-focus:focus, .border-bottom-lg-4-hover:hover {
        border-bottom-width: 4px !important
    }

    .border-bottom-lg-5, .border-bottom-lg-5-focus:focus, .border-bottom-lg-5-hover:hover {
        border-bottom-width: 5px !important
    }

    .border-bottom-lg-6, .border-bottom-lg-6-focus:focus, .border-bottom-lg-6-hover:hover {
        border-bottom-width: 6px !important
    }

    .border-bottom-lg-7, .border-bottom-lg-7-focus:focus, .border-bottom-lg-7-hover:hover {
        border-bottom-width: 7px !important
    }

    .border-bottom-lg-8, .border-bottom-lg-8-focus:focus, .border-bottom-lg-8-hover:hover {
        border-bottom-width: 8px !important
    }

    .border-start-lg-0, .border-start-lg-0-focus:focus, .border-start-lg-0-hover:hover {
        border-left-width: 0 !important
    }

    .border-start-lg, .border-start-lg-focus:focus, .border-start-lg-hover:hover {
        border-left-width: 1px !important
    }

    .border-start-lg-2, .border-start-lg-2-focus:focus, .border-start-lg-2-hover:hover {
        border-left-width: 2px !important
    }

    .border-start-lg-3, .border-start-lg-3-focus:focus, .border-start-lg-3-hover:hover {
        border-left-width: 3px !important
    }

    .border-start-lg-4, .border-start-lg-4-focus:focus, .border-start-lg-4-hover:hover {
        border-left-width: 4px !important
    }

    .border-start-lg-5, .border-start-lg-5-focus:focus, .border-start-lg-5-hover:hover {
        border-left-width: 5px !important
    }

    .border-start-lg-6, .border-start-lg-6-focus:focus, .border-start-lg-6-hover:hover {
        border-left-width: 6px !important
    }

    .border-start-lg-7, .border-start-lg-7-focus:focus, .border-start-lg-7-hover:hover {
        border-left-width: 7px !important
    }

    .border-start-lg-8, .border-start-lg-8-focus:focus, .border-start-lg-8-hover:hover {
        border-left-width: 8px !important
    }

    .border-lg-primary, .border-lg-primary-focus:focus, .border-lg-primary-hover:hover {
        border-color: #5c60f5 !important
    }

    .border-lg-primary-focus-within:focus-within {
        border-color: #5c60f5 !important
    }

    .border-lg-secondary, .border-lg-secondary-focus:focus, .border-lg-secondary-hover:hover {
        border-color: #cfd6df !important
    }

    .border-lg-secondary-focus-within:focus-within {
        border-color: #cfd6df !important
    }

    .border-lg-tertiary, .border-lg-tertiary-focus:focus, .border-lg-tertiary-hover:hover {
        border-color: #ff579a !important
    }

    .border-lg-tertiary-focus-within:focus-within {
        border-color: #ff579a !important
    }

    .border-lg-success, .border-lg-success-focus:focus, .border-lg-success-hover:hover {
        border-color: #0c8 !important
    }

    .border-lg-success-focus-within:focus-within {
        border-color: #0c8 !important
    }

    .border-lg-info, .border-lg-info-focus:focus, .border-lg-info-hover:hover {
        border-color: #00d4ff !important
    }

    .border-lg-info-focus-within:focus-within {
        border-color: #00d4ff !important
    }

    .border-lg-warning, .border-lg-warning-focus:focus, .border-lg-warning-hover:hover {
        border-color: #ff8c00 !important
    }

    .border-lg-warning-focus-within:focus-within {
        border-color: #ff8c00 !important
    }

    .border-lg-danger, .border-lg-danger-focus:focus, .border-lg-danger-hover:hover {
        border-color: #f36 !important
    }

    .border-lg-danger-focus-within:focus-within {
        border-color: #f36 !important
    }

    .border-lg-white, .border-lg-white-focus:focus, .border-lg-white-hover:hover {
        border-color: #fff !important
    }

    .border-lg-white-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-lg-light, .border-lg-light-focus:focus, .border-lg-light-hover:hover {
        border-color: #fff !important
    }

    .border-lg-light-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-lg-dark, .border-lg-dark-focus:focus, .border-lg-dark-hover:hover {
        border-color: #16192c !important
    }

    .border-lg-dark-focus-within:focus-within {
        border-color: #16192c !important
    }

    .border-lg-transparent, .border-lg-transparent-focus:focus, .border-lg-transparent-hover:hover {
        border-color: transparent !important
    }

    .border-lg-transparent-focus-within:focus-within {
        border-color: transparent !important
    }

    .border-lg-current, .border-lg-current-focus:focus, .border-lg-current-hover:hover {
        border-color: currentColor !important
    }

    .border-lg-current-focus-within:focus-within {
        border-color: currentColor !important
    }

    .border-lg-base, .border-lg-base-focus:focus, .border-lg-base-hover:hover {
        border-color: #e7eaf0 !important
    }

    .border-lg-base-focus-within:focus-within {
        border-color: #e7eaf0 !important
    }

    .border-lg-body, .border-lg-body-focus:focus, .border-lg-body-hover:hover {
        border-color: #fff !important
    }

    .border-lg-body-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-lg-card, .border-lg-card-focus:focus, .border-lg-card-hover:hover {
        border-color: #fff !important
    }

    .border-lg-card-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-lg-black, .border-lg-black-focus:focus, .border-lg-black-hover:hover {
        border-color: #000 !important
    }

    .border-lg-black-focus-within:focus-within {
        border-color: #000 !important
    }

    .border-lg-0, .border-lg-0-focus:focus, .border-lg-0-hover:hover {
        border-width: 0 !important
    }

    .border-lg, .border-lg-focus:focus, .border-lg-hover:hover {
        border-width: 1px !important
    }

    .border-lg-2, .border-lg-2-focus:focus, .border-lg-2-hover:hover {
        border-width: 2px !important
    }

    .border-lg-3, .border-lg-3-focus:focus, .border-lg-3-hover:hover {
        border-width: 3px !important
    }

    .border-lg-4, .border-lg-4-focus:focus, .border-lg-4-hover:hover {
        border-width: 4px !important
    }

    .border-lg-5, .border-lg-5-focus:focus, .border-lg-5-hover:hover {
        border-width: 5px !important
    }

    .border-lg-6, .border-lg-6-focus:focus, .border-lg-6-hover:hover {
        border-width: 6px !important
    }

    .border-lg-7, .border-lg-7-focus:focus, .border-lg-7-hover:hover {
        border-width: 7px !important
    }

    .border-lg-8, .border-lg-8-focus:focus, .border-lg-8-hover:hover {
        border-width: 8px !important
    }

    .w-lg-0 {
        width: 0 !important
    }

    .w-lg-1 {
        width: .25rem !important
    }

    .w-lg-2 {
        width: .5rem !important
    }

    .w-lg-3 {
        width: .75rem !important
    }

    .w-lg-4 {
        width: 1rem !important
    }

    .w-lg-5 {
        width: 1.25rem !important
    }

    .w-lg-6 {
        width: 1.5rem !important
    }

    .w-lg-7 {
        width: 1.75rem !important
    }

    .w-lg-8 {
        width: 2rem !important
    }

    .w-lg-10 {
        width: 2.5rem !important
    }

    .w-lg-12 {
        width: 3rem !important
    }

    .w-lg-14 {
        width: 3.5rem !important
    }

    .w-lg-16 {
        width: 4rem !important
    }

    .w-lg-18 {
        width: 4.5rem !important
    }

    .w-lg-20 {
        width: 5rem !important
    }

    .w-lg-24 {
        width: 6rem !important
    }

    .w-lg-32 {
        width: 8rem !important
    }

    .w-lg-40 {
        width: 10rem !important
    }

    .w-lg-48 {
        width: 12rem !important
    }

    .w-lg-56 {
        width: 14rem !important
    }

    .w-lg-64 {
        width: 16rem !important
    }

    .w-lg-72 {
        width: 18rem !important
    }

    .w-lg-80 {
        width: 20rem !important
    }

    .w-lg-88 {
        width: 22rem !important
    }

    .w-lg-96 {
        width: 24rem !important
    }

    .w-lg-px {
        width: 1px !important
    }

    .w-lg-1\/2 {
        width: 50% !important
    }

    .w-lg-1\/3 {
        width: 33.3333333333% !important
    }

    .w-lg-2\/3 {
        width: 66.6666666667% !important
    }

    .w-lg-1\/4 {
        width: 25% !important
    }

    .w-lg-2\/4 {
        width: 50% !important
    }

    .w-lg-3\/4 {
        width: 75% !important
    }

    .w-lg-1\/5 {
        width: 20% !important
    }

    .w-lg-2\/5 {
        width: 40% !important
    }

    .w-lg-3\/5 {
        width: 60% !important
    }

    .w-lg-4\/5 {
        width: 80% !important
    }

    .w-lg-1\/6 {
        width: 16.6666666667% !important
    }

    .w-lg-2\/6 {
        width: 33.3333333333% !important
    }

    .w-lg-3\/6 {
        width: 50% !important
    }

    .w-lg-4\/6 {
        width: 66.6666666667% !important
    }

    .w-lg-5\/6 {
        width: 83.3333333333% !important
    }

    .w-lg-11\/10 {
        width: 110% !important
    }

    .w-lg-12\/10 {
        width: 120% !important
    }

    .w-lg-13\/10 {
        width: 130% !important
    }

    .w-lg-14\/10 {
        width: 140% !important
    }

    .w-lg-15\/10 {
        width: 150% !important
    }

    .w-lg-screen-sm {
        width: 640px !important
    }

    .w-lg-screen-md {
        width: 768px !important
    }

    .w-lg-screen-lg {
        width: 1024px !important
    }

    .w-lg-screen-xl {
        width: 1280px !important
    }

    .w-lg-screen-xxl {
        width: 1536px !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .w-lg-full {
        width: 100% !important
    }

    .w-lg-screen {
        width: 100vw !important
    }

    .w-lg-min {
        width: -webkit-min-content !important;
        width: -moz-min-content !important;
        width: min-content !important
    }

    .w-lg-max {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important
    }

    .max-w-lg-screen-sm {
        max-width: 640px !important
    }

    .max-w-lg-screen-md {
        max-width: 768px !important
    }

    .max-w-lg-screen-lg {
        max-width: 1024px !important
    }

    .max-w-lg-screen-xl {
        max-width: 1280px !important
    }

    .max-w-lg-screen-xxl {
        max-width: 1536px !important
    }

    .max-w-lg-0 {
        max-width: 0 !important
    }

    .max-w-lg-full {
        max-width: 100% !important
    }

    .max-w-lg-read {
        max-width: 65ch !important
    }

    .max-w-lg-min {
        max-width: -webkit-min-content !important;
        max-width: -moz-min-content !important;
        max-width: min-content !important
    }

    .max-w-lg-max {
        max-width: -webkit-max-content !important;
        max-width: -moz-max-content !important;
        max-width: max-content !important
    }

    .h-lg-0 {
        height: 0 !important
    }

    .h-lg-1 {
        height: .25rem !important
    }

    .h-lg-2 {
        height: .5rem !important
    }

    .h-lg-3 {
        height: .75rem !important
    }

    .h-lg-4 {
        height: 1rem !important
    }

    .h-lg-5 {
        height: 1.25rem !important
    }

    .h-lg-6 {
        height: 1.5rem !important
    }

    .h-lg-7 {
        height: 1.75rem !important
    }

    .h-lg-8 {
        height: 2rem !important
    }

    .h-lg-10 {
        height: 2.5rem !important
    }

    .h-lg-12 {
        height: 3rem !important
    }

    .h-lg-14 {
        height: 3.5rem !important
    }

    .h-lg-16 {
        height: 4rem !important
    }

    .h-lg-18 {
        height: 4.5rem !important
    }

    .h-lg-20 {
        height: 5rem !important
    }

    .h-lg-24 {
        height: 6rem !important
    }

    .h-lg-32 {
        height: 8rem !important
    }

    .h-lg-40 {
        height: 10rem !important
    }

    .h-lg-48 {
        height: 12rem !important
    }

    .h-lg-56 {
        height: 14rem !important
    }

    .h-lg-64 {
        height: 16rem !important
    }

    .h-lg-72 {
        height: 18rem !important
    }

    .h-lg-80 {
        height: 20rem !important
    }

    .h-lg-88 {
        height: 22rem !important
    }

    .h-lg-96 {
        height: 24rem !important
    }

    .h-lg-px {
        height: 1px !important
    }

    .h-lg-1\/2 {
        height: 50% !important
    }

    .h-lg-1\/3 {
        height: 33.3333333333% !important
    }

    .h-lg-2\/3 {
        height: 66.6666666667% !important
    }

    .h-lg-1\/4 {
        height: 25% !important
    }

    .h-lg-2\/4 {
        height: 50% !important
    }

    .h-lg-3\/4 {
        height: 75% !important
    }

    .h-lg-1\/5 {
        height: 20% !important
    }

    .h-lg-2\/5 {
        height: 40% !important
    }

    .h-lg-3\/5 {
        height: 60% !important
    }

    .h-lg-4\/5 {
        height: 80% !important
    }

    .h-lg-1\/6 {
        height: 16.6666666667% !important
    }

    .h-lg-2\/6 {
        height: 33.3333333333% !important
    }

    .h-lg-3\/6 {
        height: 50% !important
    }

    .h-lg-4\/6 {
        height: 66.6666666667% !important
    }

    .h-lg-5\/6 {
        height: 83.3333333333% !important
    }

    .h-lg-11\/10 {
        height: 110% !important
    }

    .h-lg-12\/10 {
        height: 120% !important
    }

    .h-lg-13\/10 {
        height: 130% !important
    }

    .h-lg-14\/10 {
        height: 140% !important
    }

    .h-lg-15\/10 {
        height: 150% !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .h-lg-full {
        height: 100% !important
    }

    .h-lg-screen {
        height: 100vh !important
    }

    .max-h-lg-0 {
        max-height: 0 !important
    }

    .max-h-lg-1 {
        max-height: .25rem !important
    }

    .max-h-lg-2 {
        max-height: .5rem !important
    }

    .max-h-lg-3 {
        max-height: .75rem !important
    }

    .max-h-lg-4 {
        max-height: 1rem !important
    }

    .max-h-lg-5 {
        max-height: 1.25rem !important
    }

    .max-h-lg-6 {
        max-height: 1.5rem !important
    }

    .max-h-lg-7 {
        max-height: 1.75rem !important
    }

    .max-h-lg-8 {
        max-height: 2rem !important
    }

    .max-h-lg-10 {
        max-height: 2.5rem !important
    }

    .max-h-lg-12 {
        max-height: 3rem !important
    }

    .max-h-lg-14 {
        max-height: 3.5rem !important
    }

    .max-h-lg-16 {
        max-height: 4rem !important
    }

    .max-h-lg-18 {
        max-height: 4.5rem !important
    }

    .max-h-lg-20 {
        max-height: 5rem !important
    }

    .max-h-lg-24 {
        max-height: 6rem !important
    }

    .max-h-lg-32 {
        max-height: 8rem !important
    }

    .max-h-lg-40 {
        max-height: 10rem !important
    }

    .max-h-lg-48 {
        max-height: 12rem !important
    }

    .max-h-lg-56 {
        max-height: 14rem !important
    }

    .max-h-lg-64 {
        max-height: 16rem !important
    }

    .max-h-lg-72 {
        max-height: 18rem !important
    }

    .max-h-lg-80 {
        max-height: 20rem !important
    }

    .max-h-lg-88 {
        max-height: 22rem !important
    }

    .max-h-lg-96 {
        max-height: 24rem !important
    }

    .max-h-lg-px {
        max-height: 1px !important
    }

    .max-h-lg-full {
        max-height: 100% !important
    }

    .max-h-lg-screen {
        max-height: 100vh !important
    }

    .flex-lg-1 {
        flex: 1 1 0% !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-initial {
        flex: 0 1 auto !important
    }

    .flex-lg-none {
        flex: none !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: .75rem !important
    }

    .gap-lg-4 {
        gap: 1rem !important
    }

    .gap-lg-5 {
        gap: 1.25rem !important
    }

    .gap-lg-6 {
        gap: 1.5rem !important
    }

    .gap-lg-7 {
        gap: 1.75rem !important
    }

    .gap-lg-8 {
        gap: 2rem !important
    }

    .gap-lg-10 {
        gap: 2.5rem !important
    }

    .gap-lg-12 {
        gap: 3rem !important
    }

    .gap-lg-14 {
        gap: 3.5rem !important
    }

    .gap-lg-16 {
        gap: 4rem !important
    }

    .gap-lg-18 {
        gap: 4.5rem !important
    }

    .gap-lg-20 {
        gap: 5rem !important
    }

    .gap-lg-24 {
        gap: 6rem !important
    }

    .gap-lg-32 {
        gap: 8rem !important
    }

    .gap-lg-40 {
        gap: 10rem !important
    }

    .gap-lg-48 {
        gap: 12rem !important
    }

    .gap-lg-56 {
        gap: 14rem !important
    }

    .gap-lg-64 {
        gap: 16rem !important
    }

    .gap-lg-72 {
        gap: 18rem !important
    }

    .gap-lg-80 {
        gap: 20rem !important
    }

    .gap-lg-88 {
        gap: 22rem !important
    }

    .gap-lg-96 {
        gap: 24rem !important
    }

    .gap-lg-px {
        gap: 1px !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-evenly {
        align-content: space-evenly !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: .75rem !important
    }

    .m-lg-4 {
        margin: 1rem !important
    }

    .m-lg-5 {
        margin: 1.25rem !important
    }

    .m-lg-6 {
        margin: 1.5rem !important
    }

    .m-lg-7 {
        margin: 1.75rem !important
    }

    .m-lg-8 {
        margin: 2rem !important
    }

    .m-lg-10 {
        margin: 2.5rem !important
    }

    .m-lg-12 {
        margin: 3rem !important
    }

    .m-lg-14 {
        margin: 3.5rem !important
    }

    .m-lg-16 {
        margin: 4rem !important
    }

    .m-lg-18 {
        margin: 4.5rem !important
    }

    .m-lg-20 {
        margin: 5rem !important
    }

    .m-lg-24 {
        margin: 6rem !important
    }

    .m-lg-32 {
        margin: 8rem !important
    }

    .m-lg-40 {
        margin: 10rem !important
    }

    .m-lg-48 {
        margin: 12rem !important
    }

    .m-lg-56 {
        margin: 14rem !important
    }

    .m-lg-64 {
        margin: 16rem !important
    }

    .m-lg-72 {
        margin: 18rem !important
    }

    .m-lg-80 {
        margin: 20rem !important
    }

    .m-lg-88 {
        margin: 22rem !important
    }

    .m-lg-96 {
        margin: 24rem !important
    }

    .m-lg-px {
        margin: 1px !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-lg-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-lg-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-lg-3 {
        margin-left: .75rem !important;
        margin-right: .75rem !important
    }

    .mx-lg-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-lg-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important
    }

    .mx-lg-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-lg-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important
    }

    .mx-lg-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-lg-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-lg-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-lg-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important
    }

    .mx-lg-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-lg-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }

    .mx-lg-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-lg-24 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-lg-32 {
        margin-left: 8rem !important;
        margin-right: 8rem !important
    }

    .mx-lg-40 {
        margin-left: 10rem !important;
        margin-right: 10rem !important
    }

    .mx-lg-48 {
        margin-left: 12rem !important;
        margin-right: 12rem !important
    }

    .mx-lg-56 {
        margin-left: 14rem !important;
        margin-right: 14rem !important
    }

    .mx-lg-64 {
        margin-left: 16rem !important;
        margin-right: 16rem !important
    }

    .mx-lg-72 {
        margin-left: 18rem !important;
        margin-right: 18rem !important
    }

    .mx-lg-80 {
        margin-left: 20rem !important;
        margin-right: 20rem !important
    }

    .mx-lg-88 {
        margin-left: 22rem !important;
        margin-right: 22rem !important
    }

    .mx-lg-96 {
        margin-left: 24rem !important;
        margin-right: 24rem !important
    }

    .mx-lg-px {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mx-lg-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-lg-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-lg-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-lg-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-lg-3 {
        margin-bottom: .75rem !important;
        margin-top: .75rem !important
    }

    .my-lg-4 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-lg-5 {
        margin-bottom: 1.25rem !important;
        margin-top: 1.25rem !important
    }

    .my-lg-6 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-lg-7 {
        margin-bottom: 1.75rem !important;
        margin-top: 1.75rem !important
    }

    .my-lg-8 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-lg-10 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-lg-12 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-lg-14 {
        margin-bottom: 3.5rem !important;
        margin-top: 3.5rem !important
    }

    .my-lg-16 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-lg-18 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }

    .my-lg-20 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-lg-24 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-lg-32 {
        margin-bottom: 8rem !important;
        margin-top: 8rem !important
    }

    .my-lg-40 {
        margin-bottom: 10rem !important;
        margin-top: 10rem !important
    }

    .my-lg-48 {
        margin-bottom: 12rem !important;
        margin-top: 12rem !important
    }

    .my-lg-56 {
        margin-bottom: 14rem !important;
        margin-top: 14rem !important
    }

    .my-lg-64 {
        margin-bottom: 16rem !important;
        margin-top: 16rem !important
    }

    .my-lg-72 {
        margin-bottom: 18rem !important;
        margin-top: 18rem !important
    }

    .my-lg-80 {
        margin-bottom: 20rem !important;
        margin-top: 20rem !important
    }

    .my-lg-88 {
        margin-bottom: 22rem !important;
        margin-top: 22rem !important
    }

    .my-lg-96 {
        margin-bottom: 24rem !important;
        margin-top: 24rem !important
    }

    .my-lg-px {
        margin-bottom: 1px !important;
        margin-top: 1px !important
    }

    .my-lg-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: .75rem !important
    }

    .mt-lg-4 {
        margin-top: 1rem !important
    }

    .mt-lg-5 {
        margin-top: 1.25rem !important
    }

    .mt-lg-6 {
        margin-top: 1.5rem !important
    }

    .mt-lg-7 {
        margin-top: 1.75rem !important
    }

    .mt-lg-8 {
        margin-top: 2rem !important
    }

    .mt-lg-10 {
        margin-top: 2.5rem !important
    }

    .mt-lg-12 {
        margin-top: 3rem !important
    }

    .mt-lg-14 {
        margin-top: 3.5rem !important
    }

    .mt-lg-16 {
        margin-top: 4rem !important
    }

    .mt-lg-18 {
        margin-top: 4.5rem !important
    }

    .mt-lg-20 {
        margin-top: 5rem !important
    }

    .mt-lg-24 {
        margin-top: 6rem !important
    }

    .mt-lg-32 {
        margin-top: 8rem !important
    }

    .mt-lg-40 {
        margin-top: 10rem !important
    }

    .mt-lg-48 {
        margin-top: 12rem !important
    }

    .mt-lg-56 {
        margin-top: 14rem !important
    }

    .mt-lg-64 {
        margin-top: 16rem !important
    }

    .mt-lg-72 {
        margin-top: 18rem !important
    }

    .mt-lg-80 {
        margin-top: 20rem !important
    }

    .mt-lg-88 {
        margin-top: 22rem !important
    }

    .mt-lg-96 {
        margin-top: 24rem !important
    }

    .mt-lg-px {
        margin-top: 1px !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: .75rem !important
    }

    .me-lg-4 {
        margin-right: 1rem !important
    }

    .me-lg-5 {
        margin-right: 1.25rem !important
    }

    .me-lg-6 {
        margin-right: 1.5rem !important
    }

    .me-lg-7 {
        margin-right: 1.75rem !important
    }

    .me-lg-8 {
        margin-right: 2rem !important
    }

    .me-lg-10 {
        margin-right: 2.5rem !important
    }

    .me-lg-12 {
        margin-right: 3rem !important
    }

    .me-lg-14 {
        margin-right: 3.5rem !important
    }

    .me-lg-16 {
        margin-right: 4rem !important
    }

    .me-lg-18 {
        margin-right: 4.5rem !important
    }

    .me-lg-20 {
        margin-right: 5rem !important
    }

    .me-lg-24 {
        margin-right: 6rem !important
    }

    .me-lg-32 {
        margin-right: 8rem !important
    }

    .me-lg-40 {
        margin-right: 10rem !important
    }

    .me-lg-48 {
        margin-right: 12rem !important
    }

    .me-lg-56 {
        margin-right: 14rem !important
    }

    .me-lg-64 {
        margin-right: 16rem !important
    }

    .me-lg-72 {
        margin-right: 18rem !important
    }

    .me-lg-80 {
        margin-right: 20rem !important
    }

    .me-lg-88 {
        margin-right: 22rem !important
    }

    .me-lg-96 {
        margin-right: 24rem !important
    }

    .me-lg-px {
        margin-right: 1px !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: .75rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1rem !important
    }

    .mb-lg-5 {
        margin-bottom: 1.25rem !important
    }

    .mb-lg-6 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-7 {
        margin-bottom: 1.75rem !important
    }

    .mb-lg-8 {
        margin-bottom: 2rem !important
    }

    .mb-lg-10 {
        margin-bottom: 2.5rem !important
    }

    .mb-lg-12 {
        margin-bottom: 3rem !important
    }

    .mb-lg-14 {
        margin-bottom: 3.5rem !important
    }

    .mb-lg-16 {
        margin-bottom: 4rem !important
    }

    .mb-lg-18 {
        margin-bottom: 4.5rem !important
    }

    .mb-lg-20 {
        margin-bottom: 5rem !important
    }

    .mb-lg-24 {
        margin-bottom: 6rem !important
    }

    .mb-lg-32 {
        margin-bottom: 8rem !important
    }

    .mb-lg-40 {
        margin-bottom: 10rem !important
    }

    .mb-lg-48 {
        margin-bottom: 12rem !important
    }

    .mb-lg-56 {
        margin-bottom: 14rem !important
    }

    .mb-lg-64 {
        margin-bottom: 16rem !important
    }

    .mb-lg-72 {
        margin-bottom: 18rem !important
    }

    .mb-lg-80 {
        margin-bottom: 20rem !important
    }

    .mb-lg-88 {
        margin-bottom: 22rem !important
    }

    .mb-lg-96 {
        margin-bottom: 24rem !important
    }

    .mb-lg-px {
        margin-bottom: 1px !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: .75rem !important
    }

    .ms-lg-4 {
        margin-left: 1rem !important
    }

    .ms-lg-5 {
        margin-left: 1.25rem !important
    }

    .ms-lg-6 {
        margin-left: 1.5rem !important
    }

    .ms-lg-7 {
        margin-left: 1.75rem !important
    }

    .ms-lg-8 {
        margin-left: 2rem !important
    }

    .ms-lg-10 {
        margin-left: 2.5rem !important
    }

    .ms-lg-12 {
        margin-left: 3rem !important
    }

    .ms-lg-14 {
        margin-left: 3.5rem !important
    }

    .ms-lg-16 {
        margin-left: 4rem !important
    }

    .ms-lg-18 {
        margin-left: 4.5rem !important
    }

    .ms-lg-20 {
        margin-left: 5rem !important
    }

    .ms-lg-24 {
        margin-left: 6rem !important
    }

    .ms-lg-32 {
        margin-left: 8rem !important
    }

    .ms-lg-40 {
        margin-left: 10rem !important
    }

    .ms-lg-48 {
        margin-left: 12rem !important
    }

    .ms-lg-56 {
        margin-left: 14rem !important
    }

    .ms-lg-64 {
        margin-left: 16rem !important
    }

    .ms-lg-72 {
        margin-left: 18rem !important
    }

    .ms-lg-80 {
        margin-left: 20rem !important
    }

    .ms-lg-88 {
        margin-left: 22rem !important
    }

    .ms-lg-96 {
        margin-left: 24rem !important
    }

    .ms-lg-px {
        margin-left: 1px !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .m-lg-n3 {
        margin: -.75rem !important
    }

    .m-lg-n4 {
        margin: -1rem !important
    }

    .m-lg-n5 {
        margin: -1.25rem !important
    }

    .m-lg-n6 {
        margin: -1.5rem !important
    }

    .m-lg-n7 {
        margin: -1.75rem !important
    }

    .m-lg-n8 {
        margin: -2rem !important
    }

    .m-lg-n10 {
        margin: -2.5rem !important
    }

    .m-lg-n12 {
        margin: -3rem !important
    }

    .m-lg-n14 {
        margin: -3.5rem !important
    }

    .m-lg-n16 {
        margin: -4rem !important
    }

    .m-lg-n18 {
        margin: -4.5rem !important
    }

    .m-lg-n20 {
        margin: -5rem !important
    }

    .m-lg-n24 {
        margin: -6rem !important
    }

    .m-lg-n32 {
        margin: -8rem !important
    }

    .m-lg-n40 {
        margin: -10rem !important
    }

    .m-lg-n48 {
        margin: -12rem !important
    }

    .m-lg-n56 {
        margin: -14rem !important
    }

    .m-lg-n64 {
        margin: -16rem !important
    }

    .m-lg-n72 {
        margin: -18rem !important
    }

    .m-lg-n80 {
        margin: -20rem !important
    }

    .m-lg-n88 {
        margin: -22rem !important
    }

    .m-lg-n96 {
        margin: -24rem !important
    }

    .m-lg-npx {
        margin: -1px !important
    }

    .mx-lg-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-lg-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-lg-n3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important
    }

    .mx-lg-n4 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-lg-n5 {
        margin-left: -1.25rem !important;
        margin-right: -1.25rem !important
    }

    .mx-lg-n6 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-lg-n7 {
        margin-left: -1.75rem !important;
        margin-right: -1.75rem !important
    }

    .mx-lg-n8 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-lg-n10 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-lg-n12 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-lg-n14 {
        margin-left: -3.5rem !important;
        margin-right: -3.5rem !important
    }

    .mx-lg-n16 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-lg-n18 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }

    .mx-lg-n20 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-lg-n24 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .mx-lg-n32 {
        margin-left: -8rem !important;
        margin-right: -8rem !important
    }

    .mx-lg-n40 {
        margin-left: -10rem !important;
        margin-right: -10rem !important
    }

    .mx-lg-n48 {
        margin-left: -12rem !important;
        margin-right: -12rem !important
    }

    .mx-lg-n56 {
        margin-left: -14rem !important;
        margin-right: -14rem !important
    }

    .mx-lg-n64 {
        margin-left: -16rem !important;
        margin-right: -16rem !important
    }

    .mx-lg-n72 {
        margin-left: -18rem !important;
        margin-right: -18rem !important
    }

    .mx-lg-n80 {
        margin-left: -20rem !important;
        margin-right: -20rem !important
    }

    .mx-lg-n88 {
        margin-left: -22rem !important;
        margin-right: -22rem !important
    }

    .mx-lg-n96 {
        margin-left: -24rem !important;
        margin-right: -24rem !important
    }

    .mx-lg-npx {
        margin-left: -1px !important;
        margin-right: -1px !important
    }

    .my-lg-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-lg-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-lg-n3 {
        margin-bottom: -.75rem !important;
        margin-top: -.75rem !important
    }

    .my-lg-n4 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-lg-n5 {
        margin-bottom: -1.25rem !important;
        margin-top: -1.25rem !important
    }

    .my-lg-n6 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-lg-n7 {
        margin-bottom: -1.75rem !important;
        margin-top: -1.75rem !important
    }

    .my-lg-n8 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-lg-n10 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-lg-n12 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-lg-n14 {
        margin-bottom: -3.5rem !important;
        margin-top: -3.5rem !important
    }

    .my-lg-n16 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-lg-n18 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }

    .my-lg-n20 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-lg-n24 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .my-lg-n32 {
        margin-bottom: -8rem !important;
        margin-top: -8rem !important
    }

    .my-lg-n40 {
        margin-bottom: -10rem !important;
        margin-top: -10rem !important
    }

    .my-lg-n48 {
        margin-bottom: -12rem !important;
        margin-top: -12rem !important
    }

    .my-lg-n56 {
        margin-bottom: -14rem !important;
        margin-top: -14rem !important
    }

    .my-lg-n64 {
        margin-bottom: -16rem !important;
        margin-top: -16rem !important
    }

    .my-lg-n72 {
        margin-bottom: -18rem !important;
        margin-top: -18rem !important
    }

    .my-lg-n80 {
        margin-bottom: -20rem !important;
        margin-top: -20rem !important
    }

    .my-lg-n88 {
        margin-bottom: -22rem !important;
        margin-top: -22rem !important
    }

    .my-lg-n96 {
        margin-bottom: -24rem !important;
        margin-top: -24rem !important
    }

    .my-lg-npx {
        margin-bottom: -1px !important;
        margin-top: -1px !important
    }

    .mt-lg-n1 {
        margin-top: -.25rem !important
    }

    .mt-lg-n2 {
        margin-top: -.5rem !important
    }

    .mt-lg-n3 {
        margin-top: -.75rem !important
    }

    .mt-lg-n4 {
        margin-top: -1rem !important
    }

    .mt-lg-n5 {
        margin-top: -1.25rem !important
    }

    .mt-lg-n6 {
        margin-top: -1.5rem !important
    }

    .mt-lg-n7 {
        margin-top: -1.75rem !important
    }

    .mt-lg-n8 {
        margin-top: -2rem !important
    }

    .mt-lg-n10 {
        margin-top: -2.5rem !important
    }

    .mt-lg-n12 {
        margin-top: -3rem !important
    }

    .mt-lg-n14 {
        margin-top: -3.5rem !important
    }

    .mt-lg-n16 {
        margin-top: -4rem !important
    }

    .mt-lg-n18 {
        margin-top: -4.5rem !important
    }

    .mt-lg-n20 {
        margin-top: -5rem !important
    }

    .mt-lg-n24 {
        margin-top: -6rem !important
    }

    .mt-lg-n32 {
        margin-top: -8rem !important
    }

    .mt-lg-n40 {
        margin-top: -10rem !important
    }

    .mt-lg-n48 {
        margin-top: -12rem !important
    }

    .mt-lg-n56 {
        margin-top: -14rem !important
    }

    .mt-lg-n64 {
        margin-top: -16rem !important
    }

    .mt-lg-n72 {
        margin-top: -18rem !important
    }

    .mt-lg-n80 {
        margin-top: -20rem !important
    }

    .mt-lg-n88 {
        margin-top: -22rem !important
    }

    .mt-lg-n96 {
        margin-top: -24rem !important
    }

    .mt-lg-npx {
        margin-top: -1px !important
    }

    .me-lg-n1 {
        margin-right: -.25rem !important
    }

    .me-lg-n2 {
        margin-right: -.5rem !important
    }

    .me-lg-n3 {
        margin-right: -.75rem !important
    }

    .me-lg-n4 {
        margin-right: -1rem !important
    }

    .me-lg-n5 {
        margin-right: -1.25rem !important
    }

    .me-lg-n6 {
        margin-right: -1.5rem !important
    }

    .me-lg-n7 {
        margin-right: -1.75rem !important
    }

    .me-lg-n8 {
        margin-right: -2rem !important
    }

    .me-lg-n10 {
        margin-right: -2.5rem !important
    }

    .me-lg-n12 {
        margin-right: -3rem !important
    }

    .me-lg-n14 {
        margin-right: -3.5rem !important
    }

    .me-lg-n16 {
        margin-right: -4rem !important
    }

    .me-lg-n18 {
        margin-right: -4.5rem !important
    }

    .me-lg-n20 {
        margin-right: -5rem !important
    }

    .me-lg-n24 {
        margin-right: -6rem !important
    }

    .me-lg-n32 {
        margin-right: -8rem !important
    }

    .me-lg-n40 {
        margin-right: -10rem !important
    }

    .me-lg-n48 {
        margin-right: -12rem !important
    }

    .me-lg-n56 {
        margin-right: -14rem !important
    }

    .me-lg-n64 {
        margin-right: -16rem !important
    }

    .me-lg-n72 {
        margin-right: -18rem !important
    }

    .me-lg-n80 {
        margin-right: -20rem !important
    }

    .me-lg-n88 {
        margin-right: -22rem !important
    }

    .me-lg-n96 {
        margin-right: -24rem !important
    }

    .me-lg-npx {
        margin-right: -1px !important
    }

    .mb-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-lg-n3 {
        margin-bottom: -.75rem !important
    }

    .mb-lg-n4 {
        margin-bottom: -1rem !important
    }

    .mb-lg-n5 {
        margin-bottom: -1.25rem !important
    }

    .mb-lg-n6 {
        margin-bottom: -1.5rem !important
    }

    .mb-lg-n7 {
        margin-bottom: -1.75rem !important
    }

    .mb-lg-n8 {
        margin-bottom: -2rem !important
    }

    .mb-lg-n10 {
        margin-bottom: -2.5rem !important
    }

    .mb-lg-n12 {
        margin-bottom: -3rem !important
    }

    .mb-lg-n14 {
        margin-bottom: -3.5rem !important
    }

    .mb-lg-n16 {
        margin-bottom: -4rem !important
    }

    .mb-lg-n18 {
        margin-bottom: -4.5rem !important
    }

    .mb-lg-n20 {
        margin-bottom: -5rem !important
    }

    .mb-lg-n24 {
        margin-bottom: -6rem !important
    }

    .mb-lg-n32 {
        margin-bottom: -8rem !important
    }

    .mb-lg-n40 {
        margin-bottom: -10rem !important
    }

    .mb-lg-n48 {
        margin-bottom: -12rem !important
    }

    .mb-lg-n56 {
        margin-bottom: -14rem !important
    }

    .mb-lg-n64 {
        margin-bottom: -16rem !important
    }

    .mb-lg-n72 {
        margin-bottom: -18rem !important
    }

    .mb-lg-n80 {
        margin-bottom: -20rem !important
    }

    .mb-lg-n88 {
        margin-bottom: -22rem !important
    }

    .mb-lg-n96 {
        margin-bottom: -24rem !important
    }

    .mb-lg-npx {
        margin-bottom: -1px !important
    }

    .ms-lg-n1 {
        margin-left: -.25rem !important
    }

    .ms-lg-n2 {
        margin-left: -.5rem !important
    }

    .ms-lg-n3 {
        margin-left: -.75rem !important
    }

    .ms-lg-n4 {
        margin-left: -1rem !important
    }

    .ms-lg-n5 {
        margin-left: -1.25rem !important
    }

    .ms-lg-n6 {
        margin-left: -1.5rem !important
    }

    .ms-lg-n7 {
        margin-left: -1.75rem !important
    }

    .ms-lg-n8 {
        margin-left: -2rem !important
    }

    .ms-lg-n10 {
        margin-left: -2.5rem !important
    }

    .ms-lg-n12 {
        margin-left: -3rem !important
    }

    .ms-lg-n14 {
        margin-left: -3.5rem !important
    }

    .ms-lg-n16 {
        margin-left: -4rem !important
    }

    .ms-lg-n18 {
        margin-left: -4.5rem !important
    }

    .ms-lg-n20 {
        margin-left: -5rem !important
    }

    .ms-lg-n24 {
        margin-left: -6rem !important
    }

    .ms-lg-n32 {
        margin-left: -8rem !important
    }

    .ms-lg-n40 {
        margin-left: -10rem !important
    }

    .ms-lg-n48 {
        margin-left: -12rem !important
    }

    .ms-lg-n56 {
        margin-left: -14rem !important
    }

    .ms-lg-n64 {
        margin-left: -16rem !important
    }

    .ms-lg-n72 {
        margin-left: -18rem !important
    }

    .ms-lg-n80 {
        margin-left: -20rem !important
    }

    .ms-lg-n88 {
        margin-left: -22rem !important
    }

    .ms-lg-n96 {
        margin-left: -24rem !important
    }

    .ms-lg-npx {
        margin-left: -1px !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: .75rem !important
    }

    .p-lg-4 {
        padding: 1rem !important
    }

    .p-lg-5 {
        padding: 1.25rem !important
    }

    .p-lg-6 {
        padding: 1.5rem !important
    }

    .p-lg-7 {
        padding: 1.75rem !important
    }

    .p-lg-8 {
        padding: 2rem !important
    }

    .p-lg-10 {
        padding: 2.5rem !important
    }

    .p-lg-12 {
        padding: 3rem !important
    }

    .p-lg-14 {
        padding: 3.5rem !important
    }

    .p-lg-16 {
        padding: 4rem !important
    }

    .p-lg-18 {
        padding: 4.5rem !important
    }

    .p-lg-20 {
        padding: 5rem !important
    }

    .p-lg-24 {
        padding: 6rem !important
    }

    .p-lg-32 {
        padding: 8rem !important
    }

    .p-lg-40 {
        padding: 10rem !important
    }

    .p-lg-48 {
        padding: 12rem !important
    }

    .p-lg-56 {
        padding: 14rem !important
    }

    .p-lg-64 {
        padding: 16rem !important
    }

    .p-lg-72 {
        padding: 18rem !important
    }

    .p-lg-80 {
        padding: 20rem !important
    }

    .p-lg-88 {
        padding: 22rem !important
    }

    .p-lg-96 {
        padding: 24rem !important
    }

    .p-lg-px {
        padding: 1px !important
    }

    .px-lg-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-lg-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-lg-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-lg-3 {
        padding-left: .75rem !important;
        padding-right: .75rem !important
    }

    .px-lg-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-lg-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important
    }

    .px-lg-6 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-lg-7 {
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important
    }

    .px-lg-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-lg-10 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-lg-12 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-lg-14 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important
    }

    .px-lg-16 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-lg-18 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }

    .px-lg-20 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-lg-24 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .px-lg-32 {
        padding-left: 8rem !important;
        padding-right: 8rem !important
    }

    .px-lg-40 {
        padding-left: 10rem !important;
        padding-right: 10rem !important
    }

    .px-lg-48 {
        padding-left: 12rem !important;
        padding-right: 12rem !important
    }

    .px-lg-56 {
        padding-left: 14rem !important;
        padding-right: 14rem !important
    }

    .px-lg-64 {
        padding-left: 16rem !important;
        padding-right: 16rem !important
    }

    .px-lg-72 {
        padding-left: 18rem !important;
        padding-right: 18rem !important
    }

    .px-lg-80 {
        padding-left: 20rem !important;
        padding-right: 20rem !important
    }

    .px-lg-88 {
        padding-left: 22rem !important;
        padding-right: 22rem !important
    }

    .px-lg-96 {
        padding-left: 24rem !important;
        padding-right: 24rem !important
    }

    .px-lg-px {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .py-lg-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-lg-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-lg-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-lg-3 {
        padding-bottom: .75rem !important;
        padding-top: .75rem !important
    }

    .py-lg-4 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-lg-5 {
        padding-bottom: 1.25rem !important;
        padding-top: 1.25rem !important
    }

    .py-lg-6 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-lg-7 {
        padding-bottom: 1.75rem !important;
        padding-top: 1.75rem !important
    }

    .py-lg-8 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-lg-10 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-lg-12 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-lg-14 {
        padding-bottom: 3.5rem !important;
        padding-top: 3.5rem !important
    }

    .py-lg-16 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-lg-18 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }

    .py-lg-20 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-lg-24 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .py-lg-32 {
        padding-bottom: 8rem !important;
        padding-top: 8rem !important
    }

    .py-lg-40 {
        padding-bottom: 10rem !important;
        padding-top: 10rem !important
    }

    .py-lg-48 {
        padding-bottom: 12rem !important;
        padding-top: 12rem !important
    }

    .py-lg-56 {
        padding-bottom: 14rem !important;
        padding-top: 14rem !important
    }

    .py-lg-64 {
        padding-bottom: 16rem !important;
        padding-top: 16rem !important
    }

    .py-lg-72 {
        padding-bottom: 18rem !important;
        padding-top: 18rem !important
    }

    .py-lg-80 {
        padding-bottom: 20rem !important;
        padding-top: 20rem !important
    }

    .py-lg-88 {
        padding-bottom: 22rem !important;
        padding-top: 22rem !important
    }

    .py-lg-96 {
        padding-bottom: 24rem !important;
        padding-top: 24rem !important
    }

    .py-lg-px {
        padding-bottom: 1px !important;
        padding-top: 1px !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: .75rem !important
    }

    .pt-lg-4 {
        padding-top: 1rem !important
    }

    .pt-lg-5 {
        padding-top: 1.25rem !important
    }

    .pt-lg-6 {
        padding-top: 1.5rem !important
    }

    .pt-lg-7 {
        padding-top: 1.75rem !important
    }

    .pt-lg-8 {
        padding-top: 2rem !important
    }

    .pt-lg-10 {
        padding-top: 2.5rem !important
    }

    .pt-lg-12 {
        padding-top: 3rem !important
    }

    .pt-lg-14 {
        padding-top: 3.5rem !important
    }

    .pt-lg-16 {
        padding-top: 4rem !important
    }

    .pt-lg-18 {
        padding-top: 4.5rem !important
    }

    .pt-lg-20 {
        padding-top: 5rem !important
    }

    .pt-lg-24 {
        padding-top: 6rem !important
    }

    .pt-lg-32 {
        padding-top: 8rem !important
    }

    .pt-lg-40 {
        padding-top: 10rem !important
    }

    .pt-lg-48 {
        padding-top: 12rem !important
    }

    .pt-lg-56 {
        padding-top: 14rem !important
    }

    .pt-lg-64 {
        padding-top: 16rem !important
    }

    .pt-lg-72 {
        padding-top: 18rem !important
    }

    .pt-lg-80 {
        padding-top: 20rem !important
    }

    .pt-lg-88 {
        padding-top: 22rem !important
    }

    .pt-lg-96 {
        padding-top: 24rem !important
    }

    .pt-lg-px {
        padding-top: 1px !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: .75rem !important
    }

    .pe-lg-4 {
        padding-right: 1rem !important
    }

    .pe-lg-5 {
        padding-right: 1.25rem !important
    }

    .pe-lg-6 {
        padding-right: 1.5rem !important
    }

    .pe-lg-7 {
        padding-right: 1.75rem !important
    }

    .pe-lg-8 {
        padding-right: 2rem !important
    }

    .pe-lg-10 {
        padding-right: 2.5rem !important
    }

    .pe-lg-12 {
        padding-right: 3rem !important
    }

    .pe-lg-14 {
        padding-right: 3.5rem !important
    }

    .pe-lg-16 {
        padding-right: 4rem !important
    }

    .pe-lg-18 {
        padding-right: 4.5rem !important
    }

    .pe-lg-20 {
        padding-right: 5rem !important
    }

    .pe-lg-24 {
        padding-right: 6rem !important
    }

    .pe-lg-32 {
        padding-right: 8rem !important
    }

    .pe-lg-40 {
        padding-right: 10rem !important
    }

    .pe-lg-48 {
        padding-right: 12rem !important
    }

    .pe-lg-56 {
        padding-right: 14rem !important
    }

    .pe-lg-64 {
        padding-right: 16rem !important
    }

    .pe-lg-72 {
        padding-right: 18rem !important
    }

    .pe-lg-80 {
        padding-right: 20rem !important
    }

    .pe-lg-88 {
        padding-right: 22rem !important
    }

    .pe-lg-96 {
        padding-right: 24rem !important
    }

    .pe-lg-px {
        padding-right: 1px !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: .75rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1rem !important
    }

    .pb-lg-5 {
        padding-bottom: 1.25rem !important
    }

    .pb-lg-6 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-7 {
        padding-bottom: 1.75rem !important
    }

    .pb-lg-8 {
        padding-bottom: 2rem !important
    }

    .pb-lg-10 {
        padding-bottom: 2.5rem !important
    }

    .pb-lg-12 {
        padding-bottom: 3rem !important
    }

    .pb-lg-14 {
        padding-bottom: 3.5rem !important
    }

    .pb-lg-16 {
        padding-bottom: 4rem !important
    }

    .pb-lg-18 {
        padding-bottom: 4.5rem !important
    }

    .pb-lg-20 {
        padding-bottom: 5rem !important
    }

    .pb-lg-24 {
        padding-bottom: 6rem !important
    }

    .pb-lg-32 {
        padding-bottom: 8rem !important
    }

    .pb-lg-40 {
        padding-bottom: 10rem !important
    }

    .pb-lg-48 {
        padding-bottom: 12rem !important
    }

    .pb-lg-56 {
        padding-bottom: 14rem !important
    }

    .pb-lg-64 {
        padding-bottom: 16rem !important
    }

    .pb-lg-72 {
        padding-bottom: 18rem !important
    }

    .pb-lg-80 {
        padding-bottom: 20rem !important
    }

    .pb-lg-88 {
        padding-bottom: 22rem !important
    }

    .pb-lg-96 {
        padding-bottom: 24rem !important
    }

    .pb-lg-px {
        padding-bottom: 1px !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: .75rem !important
    }

    .ps-lg-4 {
        padding-left: 1rem !important
    }

    .ps-lg-5 {
        padding-left: 1.25rem !important
    }

    .ps-lg-6 {
        padding-left: 1.5rem !important
    }

    .ps-lg-7 {
        padding-left: 1.75rem !important
    }

    .ps-lg-8 {
        padding-left: 2rem !important
    }

    .ps-lg-10 {
        padding-left: 2.5rem !important
    }

    .ps-lg-12 {
        padding-left: 3rem !important
    }

    .ps-lg-14 {
        padding-left: 3.5rem !important
    }

    .ps-lg-16 {
        padding-left: 4rem !important
    }

    .ps-lg-18 {
        padding-left: 4.5rem !important
    }

    .ps-lg-20 {
        padding-left: 5rem !important
    }

    .ps-lg-24 {
        padding-left: 6rem !important
    }

    .ps-lg-32 {
        padding-left: 8rem !important
    }

    .ps-lg-40 {
        padding-left: 10rem !important
    }

    .ps-lg-48 {
        padding-left: 12rem !important
    }

    .ps-lg-56 {
        padding-left: 14rem !important
    }

    .ps-lg-64 {
        padding-left: 16rem !important
    }

    .ps-lg-72 {
        padding-left: 18rem !important
    }

    .ps-lg-80 {
        padding-left: 20rem !important
    }

    .ps-lg-88 {
        padding-left: 22rem !important
    }

    .ps-lg-96 {
        padding-left: 24rem !important
    }

    .ps-lg-px {
        padding-left: 1px !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }

    .text-lg-primary {
        --x-text-opacity: 1
    }

    .text-lg-primary, .text-lg-primary-focus:focus, .text-lg-primary-hover:hover {
        color: rgba(var(--x-primary-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-secondary {
        --x-text-opacity: 1
    }

    .text-lg-secondary, .text-lg-secondary-focus:focus, .text-lg-secondary-hover:hover {
        color: rgba(var(--x-secondary-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-tertiary {
        --x-text-opacity: 1
    }

    .text-lg-tertiary, .text-lg-tertiary-focus:focus, .text-lg-tertiary-hover:hover {
        color: rgba(var(--x-tertiary-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-success {
        --x-text-opacity: 1
    }

    .text-lg-success, .text-lg-success-focus:focus, .text-lg-success-hover:hover {
        color: rgba(var(--x-success-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-info {
        --x-text-opacity: 1
    }

    .text-lg-info, .text-lg-info-focus:focus, .text-lg-info-hover:hover {
        color: rgba(var(--x-info-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-warning {
        --x-text-opacity: 1
    }

    .text-lg-warning, .text-lg-warning-focus:focus, .text-lg-warning-hover:hover {
        color: rgba(var(--x-warning-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-danger {
        --x-text-opacity: 1
    }

    .text-lg-danger, .text-lg-danger-focus:focus, .text-lg-danger-hover:hover {
        color: rgba(var(--x-danger-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-white {
        --x-text-opacity: 1
    }

    .text-lg-white, .text-lg-white-focus:focus, .text-lg-white-hover:hover {
        color: rgba(var(--x-white-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-light {
        --x-text-opacity: 1
    }

    .text-lg-light, .text-lg-light-focus:focus, .text-lg-light-hover:hover {
        color: rgba(var(--x-light-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-dark {
        --x-text-opacity: 1
    }

    .text-lg-dark, .text-lg-dark-focus:focus, .text-lg-dark-hover:hover {
        color: rgba(var(--x-dark-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-black {
        --x-text-opacity: 1
    }

    .text-lg-black, .text-lg-black-focus:focus, .text-lg-black-hover:hover {
        color: rgba(var(--x-black-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-body {
        --x-text-opacity: 1
    }

    .text-lg-body, .text-lg-body-focus:focus, .text-lg-body-hover:hover {
        color: rgba(var(--x-body-color-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-muted {
        --x-text-opacity: 1
    }

    .text-lg-muted, .text-lg-muted-focus:focus, .text-lg-muted-hover:hover {
        color: rgba(var(--x-muted-rgb),var(--x-text-opacity)) !important
    }

    .text-lg-heading {
        --x-text-opacity: 1
    }

    .text-lg-heading, .text-lg-heading-focus:focus, .text-lg-heading-hover:hover {
        color: rgba(var(--x-heading-rgb),var(--x-text-opacity)) !important
    }

    .rounded-lg {
        border-radius: .375rem !important
    }

    .rounded-lg-0 {
        border-radius: 0 !important
    }

    .rounded-lg-1 {
        border-radius: .25rem !important
    }

    .rounded-lg-2 {
        border-radius: .5rem !important
    }

    .rounded-lg-3 {
        border-radius: .75rem !important
    }

    .rounded-lg-4 {
        border-radius: 1rem !important
    }

    .rounded-lg-5 {
        border-radius: 1.5rem !important
    }

    .rounded-lg-6 {
        border-radius: 3rem !important
    }

    .rounded-lg-7 {
        border-radius: 4rem !important
    }

    .rounded-lg-8 {
        border-radius: 5rem !important
    }

    .rounded-lg-9 {
        border-radius: 6rem !important
    }

    .rounded-lg-10 {
        border-radius: 7rem !important
    }

    .rounded-lg-pill {
        border-radius: 50rem !important
    }

    .rounded-lg-circle {
        border-radius: 50% !important
    }

    .rounded-lg-card {
        border-radius: .75rem !important
    }

    .rounded-top-lg {
        border-top-left-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-top-lg-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-lg-1 {
        border-top-left-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-top-lg-2 {
        border-top-left-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-top-lg-3 {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-top-lg-4 {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-top-lg-5 {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-lg-6 {
        border-top-left-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-top-lg-7 {
        border-top-left-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-top-lg-8 {
        border-top-left-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-top-lg-9 {
        border-top-left-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-top-lg-10 {
        border-top-left-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-top-lg-pill {
        border-top-left-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-top-lg-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-lg-card {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-lg {
        border-bottom-right-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-end-lg-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-lg-1 {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-end-lg-2 {
        border-bottom-right-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-end-lg-3 {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-lg-4 {
        border-bottom-right-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-end-lg-5 {
        border-bottom-right-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-end-lg-6 {
        border-bottom-right-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-end-lg-7 {
        border-bottom-right-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-end-lg-8 {
        border-bottom-right-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-end-lg-9 {
        border-bottom-right-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-end-lg-10 {
        border-bottom-right-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-end-lg-pill {
        border-bottom-right-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-end-lg-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-lg-card {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-lg {
        border-bottom-left-radius: .375rem !important;
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-lg-1 {
        border-bottom-left-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-lg-2 {
        border-bottom-left-radius: .5rem !important;
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-lg-3 {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-lg-4 {
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-lg-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-lg-6 {
        border-bottom-left-radius: 3rem !important;
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-lg-7 {
        border-bottom-left-radius: 4rem !important;
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-lg-8 {
        border-bottom-left-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-lg-9 {
        border-bottom-left-radius: 6rem !important;
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-lg-10 {
        border-bottom-left-radius: 7rem !important;
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-lg-pill {
        border-bottom-left-radius: 50rem !important;
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-lg-card {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-start-lg {
        border-bottom-left-radius: .375rem !important;
        border-top-left-radius: .375rem !important
    }

    .rounded-start-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-lg-1 {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important
    }

    .rounded-start-lg-2 {
        border-bottom-left-radius: .5rem !important;
        border-top-left-radius: .5rem !important
    }

    .rounded-start-lg-3 {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-start-lg-4 {
        border-bottom-left-radius: 1rem !important;
        border-top-left-radius: 1rem !important
    }

    .rounded-start-lg-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important
    }

    .rounded-start-lg-6 {
        border-bottom-left-radius: 3rem !important;
        border-top-left-radius: 3rem !important
    }

    .rounded-start-lg-7 {
        border-bottom-left-radius: 4rem !important;
        border-top-left-radius: 4rem !important
    }

    .rounded-start-lg-8 {
        border-bottom-left-radius: 5rem !important;
        border-top-left-radius: 5rem !important
    }

    .rounded-start-lg-9 {
        border-bottom-left-radius: 6rem !important;
        border-top-left-radius: 6rem !important
    }

    .rounded-start-lg-10 {
        border-bottom-left-radius: 7rem !important;
        border-top-left-radius: 7rem !important
    }

    .rounded-start-lg-pill {
        border-bottom-left-radius: 50rem !important;
        border-top-left-radius: 50rem !important
    }

    .rounded-start-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-lg-card {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-lg {
        border-top-left-radius: .375rem !important
    }

    .rounded-top-start-lg-0 {
        border-top-left-radius: 0 !important
    }

    .rounded-top-start-lg-1 {
        border-top-left-radius: .25rem !important
    }

    .rounded-top-start-lg-2 {
        border-top-left-radius: .5rem !important
    }

    .rounded-top-start-lg-3 {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-lg-4 {
        border-top-left-radius: 1rem !important
    }

    .rounded-top-start-lg-5 {
        border-top-left-radius: 1.5rem !important
    }

    .rounded-top-start-lg-6 {
        border-top-left-radius: 3rem !important
    }

    .rounded-top-start-lg-7 {
        border-top-left-radius: 4rem !important
    }

    .rounded-top-start-lg-8 {
        border-top-left-radius: 5rem !important
    }

    .rounded-top-start-lg-9 {
        border-top-left-radius: 6rem !important
    }

    .rounded-top-start-lg-10 {
        border-top-left-radius: 7rem !important
    }

    .rounded-top-start-lg-pill {
        border-top-left-radius: 50rem !important
    }

    .rounded-top-start-lg-circle {
        border-top-left-radius: 50% !important
    }

    .rounded-top-start-lg-card {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-end-lg {
        border-top-right-radius: .375rem !important
    }

    .rounded-top-end-lg-0 {
        border-top-right-radius: 0 !important
    }

    .rounded-top-end-lg-1 {
        border-top-right-radius: .25rem !important
    }

    .rounded-top-end-lg-2 {
        border-top-right-radius: .5rem !important
    }

    .rounded-top-end-lg-3 {
        border-top-right-radius: .75rem !important
    }

    .rounded-top-end-lg-4 {
        border-top-right-radius: 1rem !important
    }

    .rounded-top-end-lg-5 {
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-end-lg-6 {
        border-top-right-radius: 3rem !important
    }

    .rounded-top-end-lg-7 {
        border-top-right-radius: 4rem !important
    }

    .rounded-top-end-lg-8 {
        border-top-right-radius: 5rem !important
    }

    .rounded-top-end-lg-9 {
        border-top-right-radius: 6rem !important
    }

    .rounded-top-end-lg-10 {
        border-top-right-radius: 7rem !important
    }

    .rounded-top-end-lg-pill {
        border-top-right-radius: 50rem !important
    }

    .rounded-top-end-lg-circle {
        border-top-right-radius: 50% !important
    }

    .rounded-top-end-lg-card {
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-end-lg {
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-end-lg-0 {
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-end-lg-1 {
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-end-lg-2 {
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-end-lg-3 {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-end-lg-4 {
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-end-lg-5 {
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-end-lg-6 {
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-end-lg-7 {
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-end-lg-8 {
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-end-lg-9 {
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-end-lg-10 {
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-end-lg-pill {
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-end-lg-circle {
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-end-lg-card {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-start-lg {
        border-bottom-left-radius: .375rem !important
    }

    .rounded-bottom-start-lg-0 {
        border-bottom-left-radius: 0 !important
    }

    .rounded-bottom-start-lg-1 {
        border-bottom-left-radius: .25rem !important
    }

    .rounded-bottom-start-lg-2 {
        border-bottom-left-radius: .5rem !important
    }

    .rounded-bottom-start-lg-3 {
        border-bottom-left-radius: .75rem !important
    }

    .rounded-bottom-start-lg-4 {
        border-bottom-left-radius: 1rem !important
    }

    .rounded-bottom-start-lg-5 {
        border-bottom-left-radius: 1.5rem !important
    }

    .rounded-bottom-start-lg-6 {
        border-bottom-left-radius: 3rem !important
    }

    .rounded-bottom-start-lg-7 {
        border-bottom-left-radius: 4rem !important
    }

    .rounded-bottom-start-lg-8 {
        border-bottom-left-radius: 5rem !important
    }

    .rounded-bottom-start-lg-9 {
        border-bottom-left-radius: 6rem !important
    }

    .rounded-bottom-start-lg-10 {
        border-bottom-left-radius: 7rem !important
    }

    .rounded-bottom-start-lg-pill {
        border-bottom-left-radius: 50rem !important
    }

    .rounded-bottom-start-lg-circle {
        border-bottom-left-radius: 50% !important
    }

    .rounded-bottom-start-lg-card {
        border-bottom-left-radius: .75rem !important
    }

    .border-lg-solid {
        border-style: solid !important
    }

    .border-lg-dashed {
        border-style: dashed !important
    }

    .border-lg-dotted {
        border-style: dotted !important
    }

    .border-lg-double {
        border-style: double !important
    }

    .border-lg-groove {
        border-style: groove !important
    }

    .border-lg-none {
        border-style: none !important
    }

    .text-lg-transparent, .text-lg-transparent-focus:focus, .text-lg-transparent-hover:hover {
        color: transparent !important
    }

    .text-lg-current, .text-lg-current-focus:focus, .text-lg-current-hover:hover {
        color: currentColor !important
    }

    .text-lg-reset, .text-lg-reset-focus:focus, .text-lg-reset-hover:hover {
        color: inherit !important
    }

    .shadow-soft-lg-1, .shadow-soft-lg-1-focus:focus, .shadow-soft-lg-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-lg-2, .shadow-soft-lg-2-focus:focus, .shadow-soft-lg-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-lg-3, .shadow-soft-lg-3-focus:focus, .shadow-soft-lg-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-lg-4, .shadow-soft-lg-4-focus:focus, .shadow-soft-lg-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-lg-5, .shadow-soft-lg-5-focus:focus, .shadow-soft-lg-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.04) !important
    }

    .shadow-soft-lg-6, .shadow-soft-lg-6-focus:focus, .shadow-soft-lg-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-lg-none, .shadow-soft-lg-none-focus:focus, .shadow-soft-lg-none-hover:hover {
        box-shadow: none !important
    }

    .min-w-lg-0 {
        min-width: 0 !important
    }

    .min-w-lg-full {
        min-width: 100% !important
    }

    .min-w-lg-min {
        min-width: -webkit-min-content !important;
        min-width: -moz-min-content !important;
        min-width: min-content !important
    }

    .min-w-lg-max {
        min-width: -webkit-max-content !important;
        min-width: -moz-max-content !important;
        min-width: max-content !important
    }

    .min-h-lg-0 {
        min-height: 0 !important
    }

    .min-h-lg-full {
        min-height: 100% !important
    }

    .min-h-lg-screen {
        min-height: 100vh !important
    }

    .place-content-lg-start {
        place-content: flex-start !important
    }

    .place-content-lg-end {
        place-content: flex-end !important
    }

    .place-content-lg-center {
        place-content: center !important
    }

    .place-content-lg-between {
        place-content: space-between !important
    }

    .place-content-lg-around {
        place-content: space-around !important
    }

    .place-content-lg-evenly {
        place-content: space-evenly !important
    }

    .place-content-lg-stretch {
        place-content: stretch !important
    }

    .overflow-x-lg-auto {
        overflow-x: auto !important
    }

    .overflow-x-lg-hidden {
        overflow-x: hidden !important
    }

    .overflow-x-lg-visible {
        overflow-x: visible !important
    }

    .overflow-x-lg-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-lg-auto {
        overflow-y: auto !important
    }

    .overflow-y-lg-hidden {
        overflow-y: hidden !important
    }

    .overflow-y-lg-visible {
        overflow-y: visible !important
    }

    .overflow-y-lg-scroll {
        overflow-y: scroll !important
    }

    .transition-lg {
        transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform !important
    }

    .transition-lg-none {
        transition-property: none !important
    }

    .transition-lg-all {
        transition-property: all !important
    }

    .transition-lg-colors {
        transition-property: background-color,border-color,color,fill,stroke !important
    }

    .transition-lg-opacity {
        transition-property: opacity !important
    }

    .transition-lg-shadow {
        transition-property: box-shadow !important
    }

    .transition-lg-transform {
        transition-property: transform !important
    }

    .ease-lg-base {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .ease-lg-linear {
        transition-timing-function: linear !important
    }

    .ease-lg-in {
        transition-timing-function: cubic-bezier(.4,0,1,1) !important
    }

    .ease-lg-out {
        transition-timing-function: cubic-bezier(0,0,.2,1) !important
    }

    .ease-lg-in-out {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .transform-lg-none {
        transform: none !important
    }

    .origin-lg-center {
        transform-origin: center !important
    }

    .origin-lg-top {
        transform-origin: top !important
    }

    .origin-lg-top-right {
        transform-origin: top right !important
    }

    .origin-lg-right {
        transform-origin: right !important
    }

    .origin-lg-bottom-right {
        transform-origin: bottom right !important
    }

    .origin-lg-bottom {
        transform-origin: bottom !important
    }

    .origin-lg-bottom-left {
        transform-origin: bottom left !important
    }

    .origin-lg-left {
        transform-origin: left !important
    }

    .origin-lg-top-left {
        transform-origin: top left !important
    }

    .scale-lg-0, .scale-lg-0-focus:focus, .scale-lg-0-hover:hover {
        --x-scale-x: 0 !important;
        --x-scale-y: 0 !important
    }

    .scale-lg-25, .scale-lg-25-focus:focus, .scale-lg-25-hover:hover {
        --x-scale-x: 0.25 !important;
        --x-scale-y: 0.25 !important
    }

    .scale-lg-50, .scale-lg-50-focus:focus, .scale-lg-50-hover:hover {
        --x-scale-x: 0.5 !important;
        --x-scale-y: 0.5 !important
    }

    .scale-lg-75, .scale-lg-75-focus:focus, .scale-lg-75-hover:hover {
        --x-scale-x: 0.75 !important;
        --x-scale-y: 0.75 !important
    }

    .scale-lg-90, .scale-lg-90-focus:focus, .scale-lg-90-hover:hover {
        --x-scale-x: 0.9 !important;
        --x-scale-y: 0.9 !important
    }

    .scale-lg-95, .scale-lg-95-focus:focus, .scale-lg-95-hover:hover {
        --x-scale-x: 0.95 !important;
        --x-scale-y: 0.95 !important
    }

    .scale-lg-100, .scale-lg-100-focus:focus, .scale-lg-100-hover:hover {
        --x-scale-x: 1 !important;
        --x-scale-y: 1 !important
    }

    .scale-lg-105, .scale-lg-105-focus:focus, .scale-lg-105-hover:hover {
        --x-scale-x: 1.05 !important;
        --x-scale-y: 1.05 !important
    }

    .scale-lg-110, .scale-lg-110-focus:focus, .scale-lg-110-hover:hover {
        --x-scale-x: 1.1 !important;
        --x-scale-y: 1.1 !important
    }

    .scale-lg-125, .scale-lg-125-focus:focus, .scale-lg-125-hover:hover {
        --x-scale-x: 1.25 !important;
        --x-scale-y: 1.25 !important
    }

    .scale-lg-150, .scale-lg-150-focus:focus, .scale-lg-150-hover:hover {
        --x-scale-x: 1.5 !important;
        --x-scale-y: 1.5 !important
    }

    .scale-lg-200, .scale-lg-200-focus:focus, .scale-lg-200-hover:hover {
        --x-scale-x: 2 !important;
        --x-scale-y: 2 !important
    }

    .scale-y-lg-0, .scale-y-lg-0-focus:focus, .scale-y-lg-0-hover:hover {
        --x--scale-y: 0 !important
    }

    .scale-y-lg-25, .scale-y-lg-25-focus:focus, .scale-y-lg-25-hover:hover {
        --x--scale-y: 0.25 !important
    }

    .scale-y-lg-50, .scale-y-lg-50-focus:focus, .scale-y-lg-50-hover:hover {
        --x--scale-y: 0.5 !important
    }

    .scale-y-lg-75, .scale-y-lg-75-focus:focus, .scale-y-lg-75-hover:hover {
        --x--scale-y: 0.75 !important
    }

    .scale-y-lg-90, .scale-y-lg-90-focus:focus, .scale-y-lg-90-hover:hover {
        --x--scale-y: 0.9 !important
    }

    .scale-y-lg-95, .scale-y-lg-95-focus:focus, .scale-y-lg-95-hover:hover {
        --x--scale-y: 0.95 !important
    }

    .scale-y-lg-100, .scale-y-lg-100-focus:focus, .scale-y-lg-100-hover:hover {
        --x--scale-y: 1 !important
    }

    .scale-y-lg-105, .scale-y-lg-105-focus:focus, .scale-y-lg-105-hover:hover {
        --x--scale-y: 1.05 !important
    }

    .scale-y-lg-110, .scale-y-lg-110-focus:focus, .scale-y-lg-110-hover:hover {
        --x--scale-y: 1.1 !important
    }

    .scale-y-lg-125, .scale-y-lg-125-focus:focus, .scale-y-lg-125-hover:hover {
        --x--scale-y: 1.25 !important
    }

    .scale-y-lg-150, .scale-y-lg-150-focus:focus, .scale-y-lg-150-hover:hover {
        --x--scale-y: 1.5 !important
    }

    .scale-y-lg-200, .scale-y-lg-200-focus:focus, .scale-y-lg-200-hover:hover {
        --x--scale-y: 2 !important
    }

    .scale-x-lg-0, .scale-x-lg-0-focus:focus, .scale-x-lg-0-hover:hover {
        --x--scale-x: 0 !important
    }

    .scale-x-lg-25, .scale-x-lg-25-focus:focus, .scale-x-lg-25-hover:hover {
        --x--scale-x: 0.25 !important
    }

    .scale-x-lg-50, .scale-x-lg-50-focus:focus, .scale-x-lg-50-hover:hover {
        --x--scale-x: 0.5 !important
    }

    .scale-x-lg-75, .scale-x-lg-75-focus:focus, .scale-x-lg-75-hover:hover {
        --x--scale-x: 0.75 !important
    }

    .scale-x-lg-90, .scale-x-lg-90-focus:focus, .scale-x-lg-90-hover:hover {
        --x--scale-x: 0.9 !important
    }

    .scale-x-lg-95, .scale-x-lg-95-focus:focus, .scale-x-lg-95-hover:hover {
        --x--scale-x: 0.95 !important
    }

    .scale-x-lg-100, .scale-x-lg-100-focus:focus, .scale-x-lg-100-hover:hover {
        --x--scale-x: 1 !important
    }

    .scale-x-lg-105, .scale-x-lg-105-focus:focus, .scale-x-lg-105-hover:hover {
        --x--scale-x: 1.05 !important
    }

    .scale-x-lg-110, .scale-x-lg-110-focus:focus, .scale-x-lg-110-hover:hover {
        --x--scale-x: 1.1 !important
    }

    .scale-x-lg-125, .scale-x-lg-125-focus:focus, .scale-x-lg-125-hover:hover {
        --x--scale-x: 1.25 !important
    }

    .scale-x-lg-150, .scale-x-lg-150-focus:focus, .scale-x-lg-150-hover:hover {
        --x--scale-x: 1.5 !important
    }

    .scale-x-lg-200, .scale-x-lg-200-focus:focus, .scale-x-lg-200-hover:hover {
        --x--scale-x: 2 !important
    }

    .rotate-lg-0, .rotate-lg-0-focus:focus, .rotate-lg-0-hover:hover {
        --x-rotate: 0deg !important
    }

    .rotate-lg-1, .rotate-lg-1-focus:focus, .rotate-lg-1-hover:hover {
        --x-rotate: 1deg !important
    }

    .rotate-lg-2, .rotate-lg-2-focus:focus, .rotate-lg-2-hover:hover {
        --x-rotate: 2deg !important
    }

    .rotate-lg-3, .rotate-lg-3-focus:focus, .rotate-lg-3-hover:hover {
        --x-rotate: 3deg !important
    }

    .rotate-lg-6, .rotate-lg-6-focus:focus, .rotate-lg-6-hover:hover {
        --x-rotate: 6deg !important
    }

    .rotate-lg-12, .rotate-lg-12-focus:focus, .rotate-lg-12-hover:hover {
        --x-rotate: 12deg !important
    }

    .rotate-lg-30, .rotate-lg-30-focus:focus, .rotate-lg-30-hover:hover {
        --x-rotate: 30deg !important
    }

    .rotate-lg-45, .rotate-lg-45-focus:focus, .rotate-lg-45-hover:hover {
        --x-rotate: 45deg !important
    }

    .rotate-lg-90, .rotate-lg-90-focus:focus, .rotate-lg-90-hover:hover {
        --x-rotate: 90deg !important
    }

    .rotate-lg-180, .rotate-lg-180-focus:focus, .rotate-lg-180-hover:hover {
        --x-rotate: 180deg !important
    }

    .rotate-lg-n1, .rotate-lg-n1-focus:focus, .rotate-lg-n1-hover:hover {
        --x-rotate: -1deg !important
    }

    .rotate-lg-n2, .rotate-lg-n2-focus:focus, .rotate-lg-n2-hover:hover {
        --x-rotate: -2deg !important
    }

    .rotate-lg-n3, .rotate-lg-n3-focus:focus, .rotate-lg-n3-hover:hover {
        --x-rotate: -3deg !important
    }

    .rotate-lg-n6, .rotate-lg-n6-focus:focus, .rotate-lg-n6-hover:hover {
        --x-rotate: -6deg !important
    }

    .rotate-lg-n12, .rotate-lg-n12-focus:focus, .rotate-lg-n12-hover:hover {
        --x-rotate: -12deg !important
    }

    .rotate-lg-n30, .rotate-lg-n30-focus:focus, .rotate-lg-n30-hover:hover {
        --x-rotate: -30deg !important
    }

    .rotate-lg-n45, .rotate-lg-n45-focus:focus, .rotate-lg-n45-hover:hover {
        --x-rotate: -45deg !important
    }

    .rotate-lg-n90, .rotate-lg-n90-focus:focus, .rotate-lg-n90-hover:hover {
        --x-rotate: -90deg !important
    }

    .rotate-lg-n180, .rotate-lg-n180-focus:focus, .rotate-lg-n180-hover:hover {
        --x-rotate: -180deg !important
    }

    .rotate-y-lg-0, .rotate-y-lg-0-focus:focus, .rotate-y-lg-0-hover:hover {
        --x-rotate-y: 0deg !important
    }

    .rotate-y-lg-1, .rotate-y-lg-1-focus:focus, .rotate-y-lg-1-hover:hover {
        --x-rotate-y: 1deg !important
    }

    .rotate-y-lg-2, .rotate-y-lg-2-focus:focus, .rotate-y-lg-2-hover:hover {
        --x-rotate-y: 2deg !important
    }

    .rotate-y-lg-3, .rotate-y-lg-3-focus:focus, .rotate-y-lg-3-hover:hover {
        --x-rotate-y: 3deg !important
    }

    .rotate-y-lg-6, .rotate-y-lg-6-focus:focus, .rotate-y-lg-6-hover:hover {
        --x-rotate-y: 6deg !important
    }

    .rotate-y-lg-12, .rotate-y-lg-12-focus:focus, .rotate-y-lg-12-hover:hover {
        --x-rotate-y: 12deg !important
    }

    .rotate-y-lg-30, .rotate-y-lg-30-focus:focus, .rotate-y-lg-30-hover:hover {
        --x-rotate-y: 30deg !important
    }

    .rotate-y-lg-45, .rotate-y-lg-45-focus:focus, .rotate-y-lg-45-hover:hover {
        --x-rotate-y: 45deg !important
    }

    .rotate-y-lg-90, .rotate-y-lg-90-focus:focus, .rotate-y-lg-90-hover:hover {
        --x-rotate-y: 90deg !important
    }

    .rotate-y-lg-180, .rotate-y-lg-180-focus:focus, .rotate-y-lg-180-hover:hover {
        --x-rotate-y: 180deg !important
    }

    .rotate-y-lg-n1, .rotate-y-lg-n1-focus:focus, .rotate-y-lg-n1-hover:hover {
        --x-rotate-y: -1deg !important
    }

    .rotate-y-lg-n2, .rotate-y-lg-n2-focus:focus, .rotate-y-lg-n2-hover:hover {
        --x-rotate-y: -2deg !important
    }

    .rotate-y-lg-n3, .rotate-y-lg-n3-focus:focus, .rotate-y-lg-n3-hover:hover {
        --x-rotate-y: -3deg !important
    }

    .rotate-y-lg-n6, .rotate-y-lg-n6-focus:focus, .rotate-y-lg-n6-hover:hover {
        --x-rotate-y: -6deg !important
    }

    .rotate-y-lg-n12, .rotate-y-lg-n12-focus:focus, .rotate-y-lg-n12-hover:hover {
        --x-rotate-y: -12deg !important
    }

    .rotate-y-lg-n30, .rotate-y-lg-n30-focus:focus, .rotate-y-lg-n30-hover:hover {
        --x-rotate-y: -30deg !important
    }

    .rotate-y-lg-n45, .rotate-y-lg-n45-focus:focus, .rotate-y-lg-n45-hover:hover {
        --x-rotate-y: -45deg !important
    }

    .rotate-y-lg-n90, .rotate-y-lg-n90-focus:focus, .rotate-y-lg-n90-hover:hover {
        --x-rotate-y: -90deg !important
    }

    .rotate-y-lg-n180, .rotate-y-lg-n180-focus:focus, .rotate-y-lg-n180-hover:hover {
        --x-rotate-y: -180deg !important
    }

    .rotate-x-lg-0, .rotate-x-lg-0-focus:focus, .rotate-x-lg-0-hover:hover {
        --x-rotate-x: 0deg !important
    }

    .rotate-x-lg-1, .rotate-x-lg-1-focus:focus, .rotate-x-lg-1-hover:hover {
        --x-rotate-x: 1deg !important
    }

    .rotate-x-lg-2, .rotate-x-lg-2-focus:focus, .rotate-x-lg-2-hover:hover {
        --x-rotate-x: 2deg !important
    }

    .rotate-x-lg-3, .rotate-x-lg-3-focus:focus, .rotate-x-lg-3-hover:hover {
        --x-rotate-x: 3deg !important
    }

    .rotate-x-lg-6, .rotate-x-lg-6-focus:focus, .rotate-x-lg-6-hover:hover {
        --x-rotate-x: 6deg !important
    }

    .rotate-x-lg-12, .rotate-x-lg-12-focus:focus, .rotate-x-lg-12-hover:hover {
        --x-rotate-x: 12deg !important
    }

    .rotate-x-lg-30, .rotate-x-lg-30-focus:focus, .rotate-x-lg-30-hover:hover {
        --x-rotate-x: 30deg !important
    }

    .rotate-x-lg-45, .rotate-x-lg-45-focus:focus, .rotate-x-lg-45-hover:hover {
        --x-rotate-x: 45deg !important
    }

    .rotate-x-lg-90, .rotate-x-lg-90-focus:focus, .rotate-x-lg-90-hover:hover {
        --x-rotate-x: 90deg !important
    }

    .rotate-x-lg-180, .rotate-x-lg-180-focus:focus, .rotate-x-lg-180-hover:hover {
        --x-rotate-x: 180deg !important
    }

    .rotate-x-lg-n1, .rotate-x-lg-n1-focus:focus, .rotate-x-lg-n1-hover:hover {
        --x-rotate-x: -1deg !important
    }

    .rotate-x-lg-n2, .rotate-x-lg-n2-focus:focus, .rotate-x-lg-n2-hover:hover {
        --x-rotate-x: -2deg !important
    }

    .rotate-x-lg-n3, .rotate-x-lg-n3-focus:focus, .rotate-x-lg-n3-hover:hover {
        --x-rotate-x: -3deg !important
    }

    .rotate-x-lg-n6, .rotate-x-lg-n6-focus:focus, .rotate-x-lg-n6-hover:hover {
        --x-rotate-x: -6deg !important
    }

    .rotate-x-lg-n12, .rotate-x-lg-n12-focus:focus, .rotate-x-lg-n12-hover:hover {
        --x-rotate-x: -12deg !important
    }

    .rotate-x-lg-n30, .rotate-x-lg-n30-focus:focus, .rotate-x-lg-n30-hover:hover {
        --x-rotate-x: -30deg !important
    }

    .rotate-x-lg-n45, .rotate-x-lg-n45-focus:focus, .rotate-x-lg-n45-hover:hover {
        --x-rotate-x: -45deg !important
    }

    .rotate-x-lg-n90, .rotate-x-lg-n90-focus:focus, .rotate-x-lg-n90-hover:hover {
        --x-rotate-x: -90deg !important
    }

    .rotate-x-lg-n180, .rotate-x-lg-n180-focus:focus, .rotate-x-lg-n180-hover:hover {
        --x-rotate-x: -180deg !important
    }

    .perspective-lg-100, .perspective-lg-100-focus:focus, .perspective-lg-100-hover:hover {
        --x-perspective: 100px !important
    }

    .perspective-lg-200, .perspective-lg-200-focus:focus, .perspective-lg-200-hover:hover {
        --x-perspective: 200px !important
    }

    .perspective-lg-300, .perspective-lg-300-focus:focus, .perspective-lg-300-hover:hover {
        --x-perspective: 300px !important
    }

    .perspective-lg-400, .perspective-lg-400-focus:focus, .perspective-lg-400-hover:hover {
        --x-perspective: 400px !important
    }

    .perspective-lg-500, .perspective-lg-500-focus:focus, .perspective-lg-500-hover:hover {
        --x-perspective: 500px !important
    }

    .perspective-lg-600, .perspective-lg-600-focus:focus, .perspective-lg-600-hover:hover {
        --x-perspective: 600px !important
    }

    .perspective-lg-700, .perspective-lg-700-focus:focus, .perspective-lg-700-hover:hover {
        --x-perspective: 700px !important
    }

    .perspective-lg-800, .perspective-lg-800-focus:focus, .perspective-lg-800-hover:hover {
        --x-perspective: 800px !important
    }

    .perspective-lg-1000, .perspective-lg-1000-focus:focus, .perspective-lg-1000-hover:hover {
        --x-perspective: 1000px !important
    }

    .perspective-lg-1250, .perspective-lg-1250-focus:focus, .perspective-lg-1250-hover:hover {
        --x-perspective: 1250px !important
    }

    .perspective-lg-1500, .perspective-lg-1500-focus:focus, .perspective-lg-1500-hover:hover {
        --x-perspective: 1500px !important
    }

    .translate-y-lg-0, .translate-y-lg-0-focus:focus, .translate-y-lg-0-hover:hover {
        --x-translate-y: 0 !important
    }

    .translate-y-lg-1, .translate-y-lg-1-focus:focus, .translate-y-lg-1-hover:hover {
        --x-translate-y: 0.25rem !important
    }

    .translate-y-lg-2, .translate-y-lg-2-focus:focus, .translate-y-lg-2-hover:hover {
        --x-translate-y: 0.5rem !important
    }

    .translate-y-lg-3, .translate-y-lg-3-focus:focus, .translate-y-lg-3-hover:hover {
        --x-translate-y: 0.75rem !important
    }

    .translate-y-lg-4, .translate-y-lg-4-focus:focus, .translate-y-lg-4-hover:hover {
        --x-translate-y: 1rem !important
    }

    .translate-y-lg-5, .translate-y-lg-5-focus:focus, .translate-y-lg-5-hover:hover {
        --x-translate-y: 1.25rem !important
    }

    .translate-y-lg-6, .translate-y-lg-6-focus:focus, .translate-y-lg-6-hover:hover {
        --x-translate-y: 1.5rem !important
    }

    .translate-y-lg-7, .translate-y-lg-7-focus:focus, .translate-y-lg-7-hover:hover {
        --x-translate-y: 1.75rem !important
    }

    .translate-y-lg-8, .translate-y-lg-8-focus:focus, .translate-y-lg-8-hover:hover {
        --x-translate-y: 2rem !important
    }

    .translate-y-lg-10, .translate-y-lg-10-focus:focus, .translate-y-lg-10-hover:hover {
        --x-translate-y: 2.5rem !important
    }

    .translate-y-lg-12, .translate-y-lg-12-focus:focus, .translate-y-lg-12-hover:hover {
        --x-translate-y: 3rem !important
    }

    .translate-y-lg-14, .translate-y-lg-14-focus:focus, .translate-y-lg-14-hover:hover {
        --x-translate-y: 3.5rem !important
    }

    .translate-y-lg-16, .translate-y-lg-16-focus:focus, .translate-y-lg-16-hover:hover {
        --x-translate-y: 4rem !important
    }

    .translate-y-lg-18, .translate-y-lg-18-focus:focus, .translate-y-lg-18-hover:hover {
        --x-translate-y: 4.5rem !important
    }

    .translate-y-lg-20, .translate-y-lg-20-focus:focus, .translate-y-lg-20-hover:hover {
        --x-translate-y: 5rem !important
    }

    .translate-y-lg-24, .translate-y-lg-24-focus:focus, .translate-y-lg-24-hover:hover {
        --x-translate-y: 6rem !important
    }

    .translate-y-lg-32, .translate-y-lg-32-focus:focus, .translate-y-lg-32-hover:hover {
        --x-translate-y: 8rem !important
    }

    .translate-y-lg-40, .translate-y-lg-40-focus:focus, .translate-y-lg-40-hover:hover {
        --x-translate-y: 10rem !important
    }

    .translate-y-lg-48, .translate-y-lg-48-focus:focus, .translate-y-lg-48-hover:hover {
        --x-translate-y: 12rem !important
    }

    .translate-y-lg-56, .translate-y-lg-56-focus:focus, .translate-y-lg-56-hover:hover {
        --x-translate-y: 14rem !important
    }

    .translate-y-lg-64, .translate-y-lg-64-focus:focus, .translate-y-lg-64-hover:hover {
        --x-translate-y: 16rem !important
    }

    .translate-y-lg-72, .translate-y-lg-72-focus:focus, .translate-y-lg-72-hover:hover {
        --x-translate-y: 18rem !important
    }

    .translate-y-lg-80, .translate-y-lg-80-focus:focus, .translate-y-lg-80-hover:hover {
        --x-translate-y: 20rem !important
    }

    .translate-y-lg-88, .translate-y-lg-88-focus:focus, .translate-y-lg-88-hover:hover {
        --x-translate-y: 22rem !important
    }

    .translate-y-lg-96, .translate-y-lg-96-focus:focus, .translate-y-lg-96-hover:hover {
        --x-translate-y: 24rem !important
    }

    .translate-y-lg-px, .translate-y-lg-px-focus:focus, .translate-y-lg-px-hover:hover {
        --x-translate-y: 1px !important
    }

    .translate-y-lg-1\/2, .translate-y-lg-1\/2-focus:focus, .translate-y-lg-1\/2-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-lg-1\/3, .translate-y-lg-1\/3-focus:focus, .translate-y-lg-1\/3-hover:hover {
        --x-translate-y: 33.333333% !important
    }

    .translate-y-lg-1\/4, .translate-y-lg-1\/4-focus:focus, .translate-y-lg-1\/4-hover:hover {
        --x-translate-y: 25% !important
    }

    .translate-y-lg-1\/5, .translate-y-lg-1\/5-focus:focus, .translate-y-lg-1\/5-hover:hover {
        --x-translate-y: 20% !important
    }

    .translate-y-lg-2\/3, .translate-y-lg-2\/3-focus:focus, .translate-y-lg-2\/3-hover:hover {
        --x-translate-y: 66.666667% !important
    }

    .translate-y-lg-2\/5, .translate-y-lg-2\/5-focus:focus, .translate-y-lg-2\/5-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-lg-3\/4, .translate-y-lg-3\/4-focus:focus, .translate-y-lg-3\/4-hover:hover {
        --x-translate-y: 75% !important
    }

    .translate-y-lg-full, .translate-y-lg-full-focus:focus, .translate-y-lg-full-hover:hover {
        --x-translate-y: 100% !important
    }

    .translate-y-lg-n1, .translate-y-lg-n1-focus:focus, .translate-y-lg-n1-hover:hover {
        --x-translate-y: -0.25rem !important
    }

    .translate-y-lg-n2, .translate-y-lg-n2-focus:focus, .translate-y-lg-n2-hover:hover {
        --x-translate-y: -0.5rem !important
    }

    .translate-y-lg-n3, .translate-y-lg-n3-focus:focus, .translate-y-lg-n3-hover:hover {
        --x-translate-y: -0.75rem !important
    }

    .translate-y-lg-n4, .translate-y-lg-n4-focus:focus, .translate-y-lg-n4-hover:hover {
        --x-translate-y: -1rem !important
    }

    .translate-y-lg-n5, .translate-y-lg-n5-focus:focus, .translate-y-lg-n5-hover:hover {
        --x-translate-y: -1.25rem !important
    }

    .translate-y-lg-n6, .translate-y-lg-n6-focus:focus, .translate-y-lg-n6-hover:hover {
        --x-translate-y: -1.5rem !important
    }

    .translate-y-lg-n7, .translate-y-lg-n7-focus:focus, .translate-y-lg-n7-hover:hover {
        --x-translate-y: -1.75rem !important
    }

    .translate-y-lg-n8, .translate-y-lg-n8-focus:focus, .translate-y-lg-n8-hover:hover {
        --x-translate-y: -2rem !important
    }

    .translate-y-lg-n10, .translate-y-lg-n10-focus:focus, .translate-y-lg-n10-hover:hover {
        --x-translate-y: -2.5rem !important
    }

    .translate-y-lg-n12, .translate-y-lg-n12-focus:focus, .translate-y-lg-n12-hover:hover {
        --x-translate-y: -3rem !important
    }

    .translate-y-lg-n14, .translate-y-lg-n14-focus:focus, .translate-y-lg-n14-hover:hover {
        --x-translate-y: -3.5rem !important
    }

    .translate-y-lg-n16, .translate-y-lg-n16-focus:focus, .translate-y-lg-n16-hover:hover {
        --x-translate-y: -4rem !important
    }

    .translate-y-lg-n18, .translate-y-lg-n18-focus:focus, .translate-y-lg-n18-hover:hover {
        --x-translate-y: -4.5rem !important
    }

    .translate-y-lg-n20, .translate-y-lg-n20-focus:focus, .translate-y-lg-n20-hover:hover {
        --x-translate-y: -5rem !important
    }

    .translate-y-lg-n24, .translate-y-lg-n24-focus:focus, .translate-y-lg-n24-hover:hover {
        --x-translate-y: -6rem !important
    }

    .translate-y-lg-n32, .translate-y-lg-n32-focus:focus, .translate-y-lg-n32-hover:hover {
        --x-translate-y: -8rem !important
    }

    .translate-y-lg-n40, .translate-y-lg-n40-focus:focus, .translate-y-lg-n40-hover:hover {
        --x-translate-y: -10rem !important
    }

    .translate-y-lg-n48, .translate-y-lg-n48-focus:focus, .translate-y-lg-n48-hover:hover {
        --x-translate-y: -12rem !important
    }

    .translate-y-lg-n56, .translate-y-lg-n56-focus:focus, .translate-y-lg-n56-hover:hover {
        --x-translate-y: -14rem !important
    }

    .translate-y-lg-n64, .translate-y-lg-n64-focus:focus, .translate-y-lg-n64-hover:hover {
        --x-translate-y: -16rem !important
    }

    .translate-y-lg-n72, .translate-y-lg-n72-focus:focus, .translate-y-lg-n72-hover:hover {
        --x-translate-y: -18rem !important
    }

    .translate-y-lg-n80, .translate-y-lg-n80-focus:focus, .translate-y-lg-n80-hover:hover {
        --x-translate-y: -20rem !important
    }

    .translate-y-lg-n88, .translate-y-lg-n88-focus:focus, .translate-y-lg-n88-hover:hover {
        --x-translate-y: -22rem !important
    }

    .translate-y-lg-n96, .translate-y-lg-n96-focus:focus, .translate-y-lg-n96-hover:hover {
        --x-translate-y: -24rem !important
    }

    .translate-y-lg-npx, .translate-y-lg-npx-focus:focus, .translate-y-lg-npx-hover:hover {
        --x-translate-y: -1px !important
    }

    .translate-y-lg-n1\/2, .translate-y-lg-n1\/2-focus:focus, .translate-y-lg-n1\/2-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-lg-n1\/3, .translate-y-lg-n1\/3-focus:focus, .translate-y-lg-n1\/3-hover:hover {
        --x-translate-y: -33.333333% !important
    }

    .translate-y-lg-n1\/4, .translate-y-lg-n1\/4-focus:focus, .translate-y-lg-n1\/4-hover:hover {
        --x-translate-y: -25% !important
    }

    .translate-y-lg-n1\/5, .translate-y-lg-n1\/5-focus:focus, .translate-y-lg-n1\/5-hover:hover {
        --x-translate-y: -20% !important
    }

    .translate-y-lg-n2\/3, .translate-y-lg-n2\/3-focus:focus, .translate-y-lg-n2\/3-hover:hover {
        --x-translate-y: -66.666667% !important
    }

    .translate-y-lg-n2\/5, .translate-y-lg-n2\/5-focus:focus, .translate-y-lg-n2\/5-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-lg-n3\/4, .translate-y-lg-n3\/4-focus:focus, .translate-y-lg-n3\/4-hover:hover {
        --x-translate-y: -75% !important
    }

    .translate-y-lg-nfull, .translate-y-lg-nfull-focus:focus, .translate-y-lg-nfull-hover:hover {
        --x-translate-y: -100% !important
    }

    .translate-x-lg-0, .translate-x-lg-0-focus:focus, .translate-x-lg-0-hover:hover {
        --x-translate-x: 0 !important
    }

    .translate-x-lg-1, .translate-x-lg-1-focus:focus, .translate-x-lg-1-hover:hover {
        --x-translate-x: 0.25rem !important
    }

    .translate-x-lg-2, .translate-x-lg-2-focus:focus, .translate-x-lg-2-hover:hover {
        --x-translate-x: 0.5rem !important
    }

    .translate-x-lg-3, .translate-x-lg-3-focus:focus, .translate-x-lg-3-hover:hover {
        --x-translate-x: 0.75rem !important
    }

    .translate-x-lg-4, .translate-x-lg-4-focus:focus, .translate-x-lg-4-hover:hover {
        --x-translate-x: 1rem !important
    }

    .translate-x-lg-5, .translate-x-lg-5-focus:focus, .translate-x-lg-5-hover:hover {
        --x-translate-x: 1.25rem !important
    }

    .translate-x-lg-6, .translate-x-lg-6-focus:focus, .translate-x-lg-6-hover:hover {
        --x-translate-x: 1.5rem !important
    }

    .translate-x-lg-7, .translate-x-lg-7-focus:focus, .translate-x-lg-7-hover:hover {
        --x-translate-x: 1.75rem !important
    }

    .translate-x-lg-8, .translate-x-lg-8-focus:focus, .translate-x-lg-8-hover:hover {
        --x-translate-x: 2rem !important
    }

    .translate-x-lg-10, .translate-x-lg-10-focus:focus, .translate-x-lg-10-hover:hover {
        --x-translate-x: 2.5rem !important
    }

    .translate-x-lg-12, .translate-x-lg-12-focus:focus, .translate-x-lg-12-hover:hover {
        --x-translate-x: 3rem !important
    }

    .translate-x-lg-14, .translate-x-lg-14-focus:focus, .translate-x-lg-14-hover:hover {
        --x-translate-x: 3.5rem !important
    }

    .translate-x-lg-16, .translate-x-lg-16-focus:focus, .translate-x-lg-16-hover:hover {
        --x-translate-x: 4rem !important
    }

    .translate-x-lg-18, .translate-x-lg-18-focus:focus, .translate-x-lg-18-hover:hover {
        --x-translate-x: 4.5rem !important
    }

    .translate-x-lg-20, .translate-x-lg-20-focus:focus, .translate-x-lg-20-hover:hover {
        --x-translate-x: 5rem !important
    }

    .translate-x-lg-24, .translate-x-lg-24-focus:focus, .translate-x-lg-24-hover:hover {
        --x-translate-x: 6rem !important
    }

    .translate-x-lg-32, .translate-x-lg-32-focus:focus, .translate-x-lg-32-hover:hover {
        --x-translate-x: 8rem !important
    }

    .translate-x-lg-40, .translate-x-lg-40-focus:focus, .translate-x-lg-40-hover:hover {
        --x-translate-x: 10rem !important
    }

    .translate-x-lg-48, .translate-x-lg-48-focus:focus, .translate-x-lg-48-hover:hover {
        --x-translate-x: 12rem !important
    }

    .translate-x-lg-56, .translate-x-lg-56-focus:focus, .translate-x-lg-56-hover:hover {
        --x-translate-x: 14rem !important
    }

    .translate-x-lg-64, .translate-x-lg-64-focus:focus, .translate-x-lg-64-hover:hover {
        --x-translate-x: 16rem !important
    }

    .translate-x-lg-72, .translate-x-lg-72-focus:focus, .translate-x-lg-72-hover:hover {
        --x-translate-x: 18rem !important
    }

    .translate-x-lg-80, .translate-x-lg-80-focus:focus, .translate-x-lg-80-hover:hover {
        --x-translate-x: 20rem !important
    }

    .translate-x-lg-88, .translate-x-lg-88-focus:focus, .translate-x-lg-88-hover:hover {
        --x-translate-x: 22rem !important
    }

    .translate-x-lg-96, .translate-x-lg-96-focus:focus, .translate-x-lg-96-hover:hover {
        --x-translate-x: 24rem !important
    }

    .translate-x-lg-px, .translate-x-lg-px-focus:focus, .translate-x-lg-px-hover:hover {
        --x-translate-x: 1px !important
    }

    .translate-x-lg-1\/2, .translate-x-lg-1\/2-focus:focus, .translate-x-lg-1\/2-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-lg-1\/3, .translate-x-lg-1\/3-focus:focus, .translate-x-lg-1\/3-hover:hover {
        --x-translate-x: 33.333333% !important
    }

    .translate-x-lg-1\/4, .translate-x-lg-1\/4-focus:focus, .translate-x-lg-1\/4-hover:hover {
        --x-translate-x: 25% !important
    }

    .translate-x-lg-1\/5, .translate-x-lg-1\/5-focus:focus, .translate-x-lg-1\/5-hover:hover {
        --x-translate-x: 20% !important
    }

    .translate-x-lg-2\/3, .translate-x-lg-2\/3-focus:focus, .translate-x-lg-2\/3-hover:hover {
        --x-translate-x: 66.666667% !important
    }

    .translate-x-lg-2\/5, .translate-x-lg-2\/5-focus:focus, .translate-x-lg-2\/5-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-lg-3\/4, .translate-x-lg-3\/4-focus:focus, .translate-x-lg-3\/4-hover:hover {
        --x-translate-x: 75% !important
    }

    .translate-x-lg-full, .translate-x-lg-full-focus:focus, .translate-x-lg-full-hover:hover {
        --x-translate-x: 100% !important
    }

    .translate-x-lg-n1, .translate-x-lg-n1-focus:focus, .translate-x-lg-n1-hover:hover {
        --x-translate-x: -0.25rem !important
    }

    .translate-x-lg-n2, .translate-x-lg-n2-focus:focus, .translate-x-lg-n2-hover:hover {
        --x-translate-x: -0.5rem !important
    }

    .translate-x-lg-n3, .translate-x-lg-n3-focus:focus, .translate-x-lg-n3-hover:hover {
        --x-translate-x: -0.75rem !important
    }

    .translate-x-lg-n4, .translate-x-lg-n4-focus:focus, .translate-x-lg-n4-hover:hover {
        --x-translate-x: -1rem !important
    }

    .translate-x-lg-n5, .translate-x-lg-n5-focus:focus, .translate-x-lg-n5-hover:hover {
        --x-translate-x: -1.25rem !important
    }

    .translate-x-lg-n6, .translate-x-lg-n6-focus:focus, .translate-x-lg-n6-hover:hover {
        --x-translate-x: -1.5rem !important
    }

    .translate-x-lg-n7, .translate-x-lg-n7-focus:focus, .translate-x-lg-n7-hover:hover {
        --x-translate-x: -1.75rem !important
    }

    .translate-x-lg-n8, .translate-x-lg-n8-focus:focus, .translate-x-lg-n8-hover:hover {
        --x-translate-x: -2rem !important
    }

    .translate-x-lg-n10, .translate-x-lg-n10-focus:focus, .translate-x-lg-n10-hover:hover {
        --x-translate-x: -2.5rem !important
    }

    .translate-x-lg-n12, .translate-x-lg-n12-focus:focus, .translate-x-lg-n12-hover:hover {
        --x-translate-x: -3rem !important
    }

    .translate-x-lg-n14, .translate-x-lg-n14-focus:focus, .translate-x-lg-n14-hover:hover {
        --x-translate-x: -3.5rem !important
    }

    .translate-x-lg-n16, .translate-x-lg-n16-focus:focus, .translate-x-lg-n16-hover:hover {
        --x-translate-x: -4rem !important
    }

    .translate-x-lg-n18, .translate-x-lg-n18-focus:focus, .translate-x-lg-n18-hover:hover {
        --x-translate-x: -4.5rem !important
    }

    .translate-x-lg-n20, .translate-x-lg-n20-focus:focus, .translate-x-lg-n20-hover:hover {
        --x-translate-x: -5rem !important
    }

    .translate-x-lg-n24, .translate-x-lg-n24-focus:focus, .translate-x-lg-n24-hover:hover {
        --x-translate-x: -6rem !important
    }

    .translate-x-lg-n32, .translate-x-lg-n32-focus:focus, .translate-x-lg-n32-hover:hover {
        --x-translate-x: -8rem !important
    }

    .translate-x-lg-n40, .translate-x-lg-n40-focus:focus, .translate-x-lg-n40-hover:hover {
        --x-translate-x: -10rem !important
    }

    .translate-x-lg-n48, .translate-x-lg-n48-focus:focus, .translate-x-lg-n48-hover:hover {
        --x-translate-x: -12rem !important
    }

    .translate-x-lg-n56, .translate-x-lg-n56-focus:focus, .translate-x-lg-n56-hover:hover {
        --x-translate-x: -14rem !important
    }

    .translate-x-lg-n64, .translate-x-lg-n64-focus:focus, .translate-x-lg-n64-hover:hover {
        --x-translate-x: -16rem !important
    }

    .translate-x-lg-n72, .translate-x-lg-n72-focus:focus, .translate-x-lg-n72-hover:hover {
        --x-translate-x: -18rem !important
    }

    .translate-x-lg-n80, .translate-x-lg-n80-focus:focus, .translate-x-lg-n80-hover:hover {
        --x-translate-x: -20rem !important
    }

    .translate-x-lg-n88, .translate-x-lg-n88-focus:focus, .translate-x-lg-n88-hover:hover {
        --x-translate-x: -22rem !important
    }

    .translate-x-lg-n96, .translate-x-lg-n96-focus:focus, .translate-x-lg-n96-hover:hover {
        --x-translate-x: -24rem !important
    }

    .translate-x-lg-npx, .translate-x-lg-npx-focus:focus, .translate-x-lg-npx-hover:hover {
        --x-translate-x: -1px !important
    }

    .translate-x-lg-n1\/2, .translate-x-lg-n1\/2-focus:focus, .translate-x-lg-n1\/2-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-lg-n1\/3, .translate-x-lg-n1\/3-focus:focus, .translate-x-lg-n1\/3-hover:hover {
        --x-translate-x: -33.333333% !important
    }

    .translate-x-lg-n1\/4, .translate-x-lg-n1\/4-focus:focus, .translate-x-lg-n1\/4-hover:hover {
        --x-translate-x: -25% !important
    }

    .translate-x-lg-n1\/5, .translate-x-lg-n1\/5-focus:focus, .translate-x-lg-n1\/5-hover:hover {
        --x-translate-x: -20% !important
    }

    .translate-x-lg-n2\/3, .translate-x-lg-n2\/3-focus:focus, .translate-x-lg-n2\/3-hover:hover {
        --x-translate-x: -66.666667% !important
    }

    .translate-x-lg-n2\/5, .translate-x-lg-n2\/5-focus:focus, .translate-x-lg-n2\/5-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-lg-n3\/4, .translate-x-lg-n3\/4-focus:focus, .translate-x-lg-n3\/4-hover:hover {
        --x-translate-x: -75% !important
    }

    .translate-x-lg-nfull, .translate-x-lg-nfull-focus:focus, .translate-x-lg-nfull-hover:hover {
        --x-translate-x: -100% !important
    }
}

@media (min-width:1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .overflow-xl-auto {
        overflow: auto !important
    }

    .overflow-xl-hidden {
        overflow: hidden !important
    }

    .overflow-xl-visible {
        overflow: visible !important
    }

    .overflow-xl-scroll {
        overflow: scroll !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .shadow-xl, .shadow-xl-focus:focus, .shadow-xl-hover:hover {
        box-shadow: 0 3px 3px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xl-1, .shadow-xl-1-focus:focus, .shadow-xl-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xl-2, .shadow-xl-2-focus:focus, .shadow-xl-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xl-3, .shadow-xl-3-focus:focus, .shadow-xl-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xl-4, .shadow-xl-4-focus:focus, .shadow-xl-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xl-5, .shadow-xl-5-focus:focus, .shadow-xl-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xl-6, .shadow-xl-6-focus:focus, .shadow-xl-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xl-inset, .shadow-xl-inset-focus:focus, .shadow-xl-inset-hover:hover {
        box-shadow: inset 0 1px 2px rgba(0,0,0,.075) !important
    }

    .shadow-xl-focus, .shadow-xl-focus-focus:focus, .shadow-xl-focus-hover:hover, .shadow-xl-outline, .shadow-xl-outline-focus:focus, .shadow-xl-outline-hover:hover {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5) !important
    }

    .shadow-xl-none, .shadow-xl-none-focus:focus, .shadow-xl-none-hover:hover {
        box-shadow: none !important
    }

    .position-xl-static {
        position: static !important
    }

    .position-xl-relative {
        position: relative !important
    }

    .position-xl-absolute {
        position: absolute !important
    }

    .position-xl-fixed {
        position: fixed !important
    }

    .position-xl-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-xl-0 {
        top: 0 !important
    }

    .top-xl-1 {
        top: .25rem !important
    }

    .top-xl-2 {
        top: .5rem !important
    }

    .top-xl-3 {
        top: .75rem !important
    }

    .top-xl-4 {
        top: 1rem !important
    }

    .top-xl-5 {
        top: 1.25rem !important
    }

    .top-xl-6 {
        top: 1.5rem !important
    }

    .top-xl-7 {
        top: 1.75rem !important
    }

    .top-xl-8 {
        top: 2rem !important
    }

    .top-xl-10 {
        top: 2.5rem !important
    }

    .top-xl-12 {
        top: 3rem !important
    }

    .top-xl-14 {
        top: 3.5rem !important
    }

    .top-xl-16 {
        top: 4rem !important
    }

    .top-xl-18 {
        top: 4.5rem !important
    }

    .top-xl-20 {
        top: 5rem !important
    }

    .top-xl-24 {
        top: 6rem !important
    }

    .top-xl-32 {
        top: 8rem !important
    }

    .top-xl-40 {
        top: 10rem !important
    }

    .top-xl-48 {
        top: 12rem !important
    }

    .top-xl-56 {
        top: 14rem !important
    }

    .top-xl-64 {
        top: 16rem !important
    }

    .top-xl-72 {
        top: 18rem !important
    }

    .top-xl-80 {
        top: 20rem !important
    }

    .top-xl-88 {
        top: 22rem !important
    }

    .top-xl-96 {
        top: 24rem !important
    }

    .top-xl-px {
        top: 1px !important
    }

    .top-xl-1\/2 {
        top: 50% !important
    }

    .top-xl-full {
        top: 100% !important
    }

    .top-xl-auto {
        top: auto !important
    }

    .bottom-xl-0 {
        bottom: 0 !important
    }

    .bottom-xl-1 {
        bottom: .25rem !important
    }

    .bottom-xl-2 {
        bottom: .5rem !important
    }

    .bottom-xl-3 {
        bottom: .75rem !important
    }

    .bottom-xl-4 {
        bottom: 1rem !important
    }

    .bottom-xl-5 {
        bottom: 1.25rem !important
    }

    .bottom-xl-6 {
        bottom: 1.5rem !important
    }

    .bottom-xl-7 {
        bottom: 1.75rem !important
    }

    .bottom-xl-8 {
        bottom: 2rem !important
    }

    .bottom-xl-10 {
        bottom: 2.5rem !important
    }

    .bottom-xl-12 {
        bottom: 3rem !important
    }

    .bottom-xl-14 {
        bottom: 3.5rem !important
    }

    .bottom-xl-16 {
        bottom: 4rem !important
    }

    .bottom-xl-18 {
        bottom: 4.5rem !important
    }

    .bottom-xl-20 {
        bottom: 5rem !important
    }

    .bottom-xl-24 {
        bottom: 6rem !important
    }

    .bottom-xl-32 {
        bottom: 8rem !important
    }

    .bottom-xl-40 {
        bottom: 10rem !important
    }

    .bottom-xl-48 {
        bottom: 12rem !important
    }

    .bottom-xl-56 {
        bottom: 14rem !important
    }

    .bottom-xl-64 {
        bottom: 16rem !important
    }

    .bottom-xl-72 {
        bottom: 18rem !important
    }

    .bottom-xl-80 {
        bottom: 20rem !important
    }

    .bottom-xl-88 {
        bottom: 22rem !important
    }

    .bottom-xl-96 {
        bottom: 24rem !important
    }

    .bottom-xl-px {
        bottom: 1px !important
    }

    .bottom-xl-1\/2 {
        bottom: 50% !important
    }

    .bottom-xl-full {
        bottom: 100% !important
    }

    .bottom-xl-auto {
        bottom: auto !important
    }

    .start-xl-0 {
        left: 0 !important
    }

    .start-xl-1 {
        left: .25rem !important
    }

    .start-xl-2 {
        left: .5rem !important
    }

    .start-xl-3 {
        left: .75rem !important
    }

    .start-xl-4 {
        left: 1rem !important
    }

    .start-xl-5 {
        left: 1.25rem !important
    }

    .start-xl-6 {
        left: 1.5rem !important
    }

    .start-xl-7 {
        left: 1.75rem !important
    }

    .start-xl-8 {
        left: 2rem !important
    }

    .start-xl-10 {
        left: 2.5rem !important
    }

    .start-xl-12 {
        left: 3rem !important
    }

    .start-xl-14 {
        left: 3.5rem !important
    }

    .start-xl-16 {
        left: 4rem !important
    }

    .start-xl-18 {
        left: 4.5rem !important
    }

    .start-xl-20 {
        left: 5rem !important
    }

    .start-xl-24 {
        left: 6rem !important
    }

    .start-xl-32 {
        left: 8rem !important
    }

    .start-xl-40 {
        left: 10rem !important
    }

    .start-xl-48 {
        left: 12rem !important
    }

    .start-xl-56 {
        left: 14rem !important
    }

    .start-xl-64 {
        left: 16rem !important
    }

    .start-xl-72 {
        left: 18rem !important
    }

    .start-xl-80 {
        left: 20rem !important
    }

    .start-xl-88 {
        left: 22rem !important
    }

    .start-xl-96 {
        left: 24rem !important
    }

    .start-xl-px {
        left: 1px !important
    }

    .start-xl-1\/2 {
        left: 50% !important
    }

    .start-xl-full {
        left: 100% !important
    }

    .start-xl-auto {
        left: auto !important
    }

    .end-xl-0 {
        right: 0 !important
    }

    .end-xl-1 {
        right: .25rem !important
    }

    .end-xl-2 {
        right: .5rem !important
    }

    .end-xl-3 {
        right: .75rem !important
    }

    .end-xl-4 {
        right: 1rem !important
    }

    .end-xl-5 {
        right: 1.25rem !important
    }

    .end-xl-6 {
        right: 1.5rem !important
    }

    .end-xl-7 {
        right: 1.75rem !important
    }

    .end-xl-8 {
        right: 2rem !important
    }

    .end-xl-10 {
        right: 2.5rem !important
    }

    .end-xl-12 {
        right: 3rem !important
    }

    .end-xl-14 {
        right: 3.5rem !important
    }

    .end-xl-16 {
        right: 4rem !important
    }

    .end-xl-18 {
        right: 4.5rem !important
    }

    .end-xl-20 {
        right: 5rem !important
    }

    .end-xl-24 {
        right: 6rem !important
    }

    .end-xl-32 {
        right: 8rem !important
    }

    .end-xl-40 {
        right: 10rem !important
    }

    .end-xl-48 {
        right: 12rem !important
    }

    .end-xl-56 {
        right: 14rem !important
    }

    .end-xl-64 {
        right: 16rem !important
    }

    .end-xl-72 {
        right: 18rem !important
    }

    .end-xl-80 {
        right: 20rem !important
    }

    .end-xl-88 {
        right: 22rem !important
    }

    .end-xl-96 {
        right: 24rem !important
    }

    .end-xl-px {
        right: 1px !important
    }

    .end-xl-1\/2 {
        right: 50% !important
    }

    .end-xl-full {
        right: 100% !important
    }

    .end-xl-auto {
        right: auto !important
    }

    .border-top-xl-0, .border-top-xl-0-focus:focus, .border-top-xl-0-hover:hover {
        border-top-width: 0 !important
    }

    .border-top-xl, .border-top-xl-focus:focus, .border-top-xl-hover:hover {
        border-top-width: 1px !important
    }

    .border-top-xl-2, .border-top-xl-2-focus:focus, .border-top-xl-2-hover:hover {
        border-top-width: 2px !important
    }

    .border-top-xl-3, .border-top-xl-3-focus:focus, .border-top-xl-3-hover:hover {
        border-top-width: 3px !important
    }

    .border-top-xl-4, .border-top-xl-4-focus:focus, .border-top-xl-4-hover:hover {
        border-top-width: 4px !important
    }

    .border-top-xl-5, .border-top-xl-5-focus:focus, .border-top-xl-5-hover:hover {
        border-top-width: 5px !important
    }

    .border-top-xl-6, .border-top-xl-6-focus:focus, .border-top-xl-6-hover:hover {
        border-top-width: 6px !important
    }

    .border-top-xl-7, .border-top-xl-7-focus:focus, .border-top-xl-7-hover:hover {
        border-top-width: 7px !important
    }

    .border-top-xl-8, .border-top-xl-8-focus:focus, .border-top-xl-8-hover:hover {
        border-top-width: 8px !important
    }

    .border-end-xl-0, .border-end-xl-0-focus:focus, .border-end-xl-0-hover:hover {
        border-right-width: 0 !important
    }

    .border-end-xl, .border-end-xl-focus:focus, .border-end-xl-hover:hover {
        border-right-width: 1px !important
    }

    .border-end-xl-2, .border-end-xl-2-focus:focus, .border-end-xl-2-hover:hover {
        border-right-width: 2px !important
    }

    .border-end-xl-3, .border-end-xl-3-focus:focus, .border-end-xl-3-hover:hover {
        border-right-width: 3px !important
    }

    .border-end-xl-4, .border-end-xl-4-focus:focus, .border-end-xl-4-hover:hover {
        border-right-width: 4px !important
    }

    .border-end-xl-5, .border-end-xl-5-focus:focus, .border-end-xl-5-hover:hover {
        border-right-width: 5px !important
    }

    .border-end-xl-6, .border-end-xl-6-focus:focus, .border-end-xl-6-hover:hover {
        border-right-width: 6px !important
    }

    .border-end-xl-7, .border-end-xl-7-focus:focus, .border-end-xl-7-hover:hover {
        border-right-width: 7px !important
    }

    .border-end-xl-8, .border-end-xl-8-focus:focus, .border-end-xl-8-hover:hover {
        border-right-width: 8px !important
    }

    .border-bottom-xl-0, .border-bottom-xl-0-focus:focus, .border-bottom-xl-0-hover:hover {
        border-bottom-width: 0 !important
    }

    .border-bottom-xl, .border-bottom-xl-focus:focus, .border-bottom-xl-hover:hover {
        border-bottom-width: 1px !important
    }

    .border-bottom-xl-2, .border-bottom-xl-2-focus:focus, .border-bottom-xl-2-hover:hover {
        border-bottom-width: 2px !important
    }

    .border-bottom-xl-3, .border-bottom-xl-3-focus:focus, .border-bottom-xl-3-hover:hover {
        border-bottom-width: 3px !important
    }

    .border-bottom-xl-4, .border-bottom-xl-4-focus:focus, .border-bottom-xl-4-hover:hover {
        border-bottom-width: 4px !important
    }

    .border-bottom-xl-5, .border-bottom-xl-5-focus:focus, .border-bottom-xl-5-hover:hover {
        border-bottom-width: 5px !important
    }

    .border-bottom-xl-6, .border-bottom-xl-6-focus:focus, .border-bottom-xl-6-hover:hover {
        border-bottom-width: 6px !important
    }

    .border-bottom-xl-7, .border-bottom-xl-7-focus:focus, .border-bottom-xl-7-hover:hover {
        border-bottom-width: 7px !important
    }

    .border-bottom-xl-8, .border-bottom-xl-8-focus:focus, .border-bottom-xl-8-hover:hover {
        border-bottom-width: 8px !important
    }

    .border-start-xl-0, .border-start-xl-0-focus:focus, .border-start-xl-0-hover:hover {
        border-left-width: 0 !important
    }

    .border-start-xl, .border-start-xl-focus:focus, .border-start-xl-hover:hover {
        border-left-width: 1px !important
    }

    .border-start-xl-2, .border-start-xl-2-focus:focus, .border-start-xl-2-hover:hover {
        border-left-width: 2px !important
    }

    .border-start-xl-3, .border-start-xl-3-focus:focus, .border-start-xl-3-hover:hover {
        border-left-width: 3px !important
    }

    .border-start-xl-4, .border-start-xl-4-focus:focus, .border-start-xl-4-hover:hover {
        border-left-width: 4px !important
    }

    .border-start-xl-5, .border-start-xl-5-focus:focus, .border-start-xl-5-hover:hover {
        border-left-width: 5px !important
    }

    .border-start-xl-6, .border-start-xl-6-focus:focus, .border-start-xl-6-hover:hover {
        border-left-width: 6px !important
    }

    .border-start-xl-7, .border-start-xl-7-focus:focus, .border-start-xl-7-hover:hover {
        border-left-width: 7px !important
    }

    .border-start-xl-8, .border-start-xl-8-focus:focus, .border-start-xl-8-hover:hover {
        border-left-width: 8px !important
    }

    .border-xl-primary, .border-xl-primary-focus:focus, .border-xl-primary-hover:hover {
        border-color: #5c60f5 !important
    }

    .border-xl-primary-focus-within:focus-within {
        border-color: #5c60f5 !important
    }

    .border-xl-secondary, .border-xl-secondary-focus:focus, .border-xl-secondary-hover:hover {
        border-color: #cfd6df !important
    }

    .border-xl-secondary-focus-within:focus-within {
        border-color: #cfd6df !important
    }

    .border-xl-tertiary, .border-xl-tertiary-focus:focus, .border-xl-tertiary-hover:hover {
        border-color: #ff579a !important
    }

    .border-xl-tertiary-focus-within:focus-within {
        border-color: #ff579a !important
    }

    .border-xl-success, .border-xl-success-focus:focus, .border-xl-success-hover:hover {
        border-color: #0c8 !important
    }

    .border-xl-success-focus-within:focus-within {
        border-color: #0c8 !important
    }

    .border-xl-info, .border-xl-info-focus:focus, .border-xl-info-hover:hover {
        border-color: #00d4ff !important
    }

    .border-xl-info-focus-within:focus-within {
        border-color: #00d4ff !important
    }

    .border-xl-warning, .border-xl-warning-focus:focus, .border-xl-warning-hover:hover {
        border-color: #ff8c00 !important
    }

    .border-xl-warning-focus-within:focus-within {
        border-color: #ff8c00 !important
    }

    .border-xl-danger, .border-xl-danger-focus:focus, .border-xl-danger-hover:hover {
        border-color: #f36 !important
    }

    .border-xl-danger-focus-within:focus-within {
        border-color: #f36 !important
    }

    .border-xl-white, .border-xl-white-focus:focus, .border-xl-white-hover:hover {
        border-color: #fff !important
    }

    .border-xl-white-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xl-light, .border-xl-light-focus:focus, .border-xl-light-hover:hover {
        border-color: #fff !important
    }

    .border-xl-light-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xl-dark, .border-xl-dark-focus:focus, .border-xl-dark-hover:hover {
        border-color: #16192c !important
    }

    .border-xl-dark-focus-within:focus-within {
        border-color: #16192c !important
    }

    .border-xl-transparent, .border-xl-transparent-focus:focus, .border-xl-transparent-hover:hover {
        border-color: transparent !important
    }

    .border-xl-transparent-focus-within:focus-within {
        border-color: transparent !important
    }

    .border-xl-current, .border-xl-current-focus:focus, .border-xl-current-hover:hover {
        border-color: currentColor !important
    }

    .border-xl-current-focus-within:focus-within {
        border-color: currentColor !important
    }

    .border-xl-base, .border-xl-base-focus:focus, .border-xl-base-hover:hover {
        border-color: #e7eaf0 !important
    }

    .border-xl-base-focus-within:focus-within {
        border-color: #e7eaf0 !important
    }

    .border-xl-body, .border-xl-body-focus:focus, .border-xl-body-hover:hover {
        border-color: #fff !important
    }

    .border-xl-body-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xl-card, .border-xl-card-focus:focus, .border-xl-card-hover:hover {
        border-color: #fff !important
    }

    .border-xl-card-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xl-black, .border-xl-black-focus:focus, .border-xl-black-hover:hover {
        border-color: #000 !important
    }

    .border-xl-black-focus-within:focus-within {
        border-color: #000 !important
    }

    .border-xl-0, .border-xl-0-focus:focus, .border-xl-0-hover:hover {
        border-width: 0 !important
    }

    .border-xl, .border-xl-focus:focus, .border-xl-hover:hover {
        border-width: 1px !important
    }

    .border-xl-2, .border-xl-2-focus:focus, .border-xl-2-hover:hover {
        border-width: 2px !important
    }

    .border-xl-3, .border-xl-3-focus:focus, .border-xl-3-hover:hover {
        border-width: 3px !important
    }

    .border-xl-4, .border-xl-4-focus:focus, .border-xl-4-hover:hover {
        border-width: 4px !important
    }

    .border-xl-5, .border-xl-5-focus:focus, .border-xl-5-hover:hover {
        border-width: 5px !important
    }

    .border-xl-6, .border-xl-6-focus:focus, .border-xl-6-hover:hover {
        border-width: 6px !important
    }

    .border-xl-7, .border-xl-7-focus:focus, .border-xl-7-hover:hover {
        border-width: 7px !important
    }

    .border-xl-8, .border-xl-8-focus:focus, .border-xl-8-hover:hover {
        border-width: 8px !important
    }

    .w-xl-0 {
        width: 0 !important
    }

    .w-xl-1 {
        width: .25rem !important
    }

    .w-xl-2 {
        width: .5rem !important
    }

    .w-xl-3 {
        width: .75rem !important
    }

    .w-xl-4 {
        width: 1rem !important
    }

    .w-xl-5 {
        width: 1.25rem !important
    }

    .w-xl-6 {
        width: 1.5rem !important
    }

    .w-xl-7 {
        width: 1.75rem !important
    }

    .w-xl-8 {
        width: 2rem !important
    }

    .w-xl-10 {
        width: 2.5rem !important
    }

    .w-xl-12 {
        width: 3rem !important
    }

    .w-xl-14 {
        width: 3.5rem !important
    }

    .w-xl-16 {
        width: 4rem !important
    }

    .w-xl-18 {
        width: 4.5rem !important
    }

    .w-xl-20 {
        width: 5rem !important
    }

    .w-xl-24 {
        width: 6rem !important
    }

    .w-xl-32 {
        width: 8rem !important
    }

    .w-xl-40 {
        width: 10rem !important
    }

    .w-xl-48 {
        width: 12rem !important
    }

    .w-xl-56 {
        width: 14rem !important
    }

    .w-xl-64 {
        width: 16rem !important
    }

    .w-xl-72 {
        width: 18rem !important
    }

    .w-xl-80 {
        width: 20rem !important
    }

    .w-xl-88 {
        width: 22rem !important
    }

    .w-xl-96 {
        width: 24rem !important
    }

    .w-xl-px {
        width: 1px !important
    }

    .w-xl-1\/2 {
        width: 50% !important
    }

    .w-xl-1\/3 {
        width: 33.3333333333% !important
    }

    .w-xl-2\/3 {
        width: 66.6666666667% !important
    }

    .w-xl-1\/4 {
        width: 25% !important
    }

    .w-xl-2\/4 {
        width: 50% !important
    }

    .w-xl-3\/4 {
        width: 75% !important
    }

    .w-xl-1\/5 {
        width: 20% !important
    }

    .w-xl-2\/5 {
        width: 40% !important
    }

    .w-xl-3\/5 {
        width: 60% !important
    }

    .w-xl-4\/5 {
        width: 80% !important
    }

    .w-xl-1\/6 {
        width: 16.6666666667% !important
    }

    .w-xl-2\/6 {
        width: 33.3333333333% !important
    }

    .w-xl-3\/6 {
        width: 50% !important
    }

    .w-xl-4\/6 {
        width: 66.6666666667% !important
    }

    .w-xl-5\/6 {
        width: 83.3333333333% !important
    }

    .w-xl-11\/10 {
        width: 110% !important
    }

    .w-xl-12\/10 {
        width: 120% !important
    }

    .w-xl-13\/10 {
        width: 130% !important
    }

    .w-xl-14\/10 {
        width: 140% !important
    }

    .w-xl-15\/10 {
        width: 150% !important
    }

    .w-xl-screen-sm {
        width: 640px !important
    }

    .w-xl-screen-md {
        width: 768px !important
    }

    .w-xl-screen-lg {
        width: 1024px !important
    }

    .w-xl-screen-xl {
        width: 1280px !important
    }

    .w-xl-screen-xxl {
        width: 1536px !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .w-xl-full {
        width: 100% !important
    }

    .w-xl-screen {
        width: 100vw !important
    }

    .w-xl-min {
        width: -webkit-min-content !important;
        width: -moz-min-content !important;
        width: min-content !important
    }

    .w-xl-max {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important
    }

    .max-w-xl-screen-sm {
        max-width: 640px !important
    }

    .max-w-xl-screen-md {
        max-width: 768px !important
    }

    .max-w-xl-screen-lg {
        max-width: 1024px !important
    }

    .max-w-xl-screen-xl {
        max-width: 1280px !important
    }

    .max-w-xl-screen-xxl {
        max-width: 1536px !important
    }

    .max-w-xl-0 {
        max-width: 0 !important
    }

    .max-w-xl-full {
        max-width: 100% !important
    }

    .max-w-xl-read {
        max-width: 65ch !important
    }

    .max-w-xl-min {
        max-width: -webkit-min-content !important;
        max-width: -moz-min-content !important;
        max-width: min-content !important
    }

    .max-w-xl-max {
        max-width: -webkit-max-content !important;
        max-width: -moz-max-content !important;
        max-width: max-content !important
    }

    .h-xl-0 {
        height: 0 !important
    }

    .h-xl-1 {
        height: .25rem !important
    }

    .h-xl-2 {
        height: .5rem !important
    }

    .h-xl-3 {
        height: .75rem !important
    }

    .h-xl-4 {
        height: 1rem !important
    }

    .h-xl-5 {
        height: 1.25rem !important
    }

    .h-xl-6 {
        height: 1.5rem !important
    }

    .h-xl-7 {
        height: 1.75rem !important
    }

    .h-xl-8 {
        height: 2rem !important
    }

    .h-xl-10 {
        height: 2.5rem !important
    }

    .h-xl-12 {
        height: 3rem !important
    }

    .h-xl-14 {
        height: 3.5rem !important
    }

    .h-xl-16 {
        height: 4rem !important
    }

    .h-xl-18 {
        height: 4.5rem !important
    }

    .h-xl-20 {
        height: 5rem !important
    }

    .h-xl-24 {
        height: 6rem !important
    }

    .h-xl-32 {
        height: 8rem !important
    }

    .h-xl-40 {
        height: 10rem !important
    }

    .h-xl-48 {
        height: 12rem !important
    }

    .h-xl-56 {
        height: 14rem !important
    }

    .h-xl-64 {
        height: 16rem !important
    }

    .h-xl-72 {
        height: 18rem !important
    }

    .h-xl-80 {
        height: 20rem !important
    }

    .h-xl-88 {
        height: 22rem !important
    }

    .h-xl-96 {
        height: 24rem !important
    }

    .h-xl-px {
        height: 1px !important
    }

    .h-xl-1\/2 {
        height: 50% !important
    }

    .h-xl-1\/3 {
        height: 33.3333333333% !important
    }

    .h-xl-2\/3 {
        height: 66.6666666667% !important
    }

    .h-xl-1\/4 {
        height: 25% !important
    }

    .h-xl-2\/4 {
        height: 50% !important
    }

    .h-xl-3\/4 {
        height: 75% !important
    }

    .h-xl-1\/5 {
        height: 20% !important
    }

    .h-xl-2\/5 {
        height: 40% !important
    }

    .h-xl-3\/5 {
        height: 60% !important
    }

    .h-xl-4\/5 {
        height: 80% !important
    }

    .h-xl-1\/6 {
        height: 16.6666666667% !important
    }

    .h-xl-2\/6 {
        height: 33.3333333333% !important
    }

    .h-xl-3\/6 {
        height: 50% !important
    }

    .h-xl-4\/6 {
        height: 66.6666666667% !important
    }

    .h-xl-5\/6 {
        height: 83.3333333333% !important
    }

    .h-xl-11\/10 {
        height: 110% !important
    }

    .h-xl-12\/10 {
        height: 120% !important
    }

    .h-xl-13\/10 {
        height: 130% !important
    }

    .h-xl-14\/10 {
        height: 140% !important
    }

    .h-xl-15\/10 {
        height: 150% !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .h-xl-full {
        height: 100% !important
    }

    .h-xl-screen {
        height: 100vh !important
    }

    .max-h-xl-0 {
        max-height: 0 !important
    }

    .max-h-xl-1 {
        max-height: .25rem !important
    }

    .max-h-xl-2 {
        max-height: .5rem !important
    }

    .max-h-xl-3 {
        max-height: .75rem !important
    }

    .max-h-xl-4 {
        max-height: 1rem !important
    }

    .max-h-xl-5 {
        max-height: 1.25rem !important
    }

    .max-h-xl-6 {
        max-height: 1.5rem !important
    }

    .max-h-xl-7 {
        max-height: 1.75rem !important
    }

    .max-h-xl-8 {
        max-height: 2rem !important
    }

    .max-h-xl-10 {
        max-height: 2.5rem !important
    }

    .max-h-xl-12 {
        max-height: 3rem !important
    }

    .max-h-xl-14 {
        max-height: 3.5rem !important
    }

    .max-h-xl-16 {
        max-height: 4rem !important
    }

    .max-h-xl-18 {
        max-height: 4.5rem !important
    }

    .max-h-xl-20 {
        max-height: 5rem !important
    }

    .max-h-xl-24 {
        max-height: 6rem !important
    }

    .max-h-xl-32 {
        max-height: 8rem !important
    }

    .max-h-xl-40 {
        max-height: 10rem !important
    }

    .max-h-xl-48 {
        max-height: 12rem !important
    }

    .max-h-xl-56 {
        max-height: 14rem !important
    }

    .max-h-xl-64 {
        max-height: 16rem !important
    }

    .max-h-xl-72 {
        max-height: 18rem !important
    }

    .max-h-xl-80 {
        max-height: 20rem !important
    }

    .max-h-xl-88 {
        max-height: 22rem !important
    }

    .max-h-xl-96 {
        max-height: 24rem !important
    }

    .max-h-xl-px {
        max-height: 1px !important
    }

    .max-h-xl-full {
        max-height: 100% !important
    }

    .max-h-xl-screen {
        max-height: 100vh !important
    }

    .flex-xl-1 {
        flex: 1 1 0% !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-initial {
        flex: 0 1 auto !important
    }

    .flex-xl-none {
        flex: none !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: .75rem !important
    }

    .gap-xl-4 {
        gap: 1rem !important
    }

    .gap-xl-5 {
        gap: 1.25rem !important
    }

    .gap-xl-6 {
        gap: 1.5rem !important
    }

    .gap-xl-7 {
        gap: 1.75rem !important
    }

    .gap-xl-8 {
        gap: 2rem !important
    }

    .gap-xl-10 {
        gap: 2.5rem !important
    }

    .gap-xl-12 {
        gap: 3rem !important
    }

    .gap-xl-14 {
        gap: 3.5rem !important
    }

    .gap-xl-16 {
        gap: 4rem !important
    }

    .gap-xl-18 {
        gap: 4.5rem !important
    }

    .gap-xl-20 {
        gap: 5rem !important
    }

    .gap-xl-24 {
        gap: 6rem !important
    }

    .gap-xl-32 {
        gap: 8rem !important
    }

    .gap-xl-40 {
        gap: 10rem !important
    }

    .gap-xl-48 {
        gap: 12rem !important
    }

    .gap-xl-56 {
        gap: 14rem !important
    }

    .gap-xl-64 {
        gap: 16rem !important
    }

    .gap-xl-72 {
        gap: 18rem !important
    }

    .gap-xl-80 {
        gap: 20rem !important
    }

    .gap-xl-88 {
        gap: 22rem !important
    }

    .gap-xl-96 {
        gap: 24rem !important
    }

    .gap-xl-px {
        gap: 1px !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-evenly {
        align-content: space-evenly !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: .75rem !important
    }

    .m-xl-4 {
        margin: 1rem !important
    }

    .m-xl-5 {
        margin: 1.25rem !important
    }

    .m-xl-6 {
        margin: 1.5rem !important
    }

    .m-xl-7 {
        margin: 1.75rem !important
    }

    .m-xl-8 {
        margin: 2rem !important
    }

    .m-xl-10 {
        margin: 2.5rem !important
    }

    .m-xl-12 {
        margin: 3rem !important
    }

    .m-xl-14 {
        margin: 3.5rem !important
    }

    .m-xl-16 {
        margin: 4rem !important
    }

    .m-xl-18 {
        margin: 4.5rem !important
    }

    .m-xl-20 {
        margin: 5rem !important
    }

    .m-xl-24 {
        margin: 6rem !important
    }

    .m-xl-32 {
        margin: 8rem !important
    }

    .m-xl-40 {
        margin: 10rem !important
    }

    .m-xl-48 {
        margin: 12rem !important
    }

    .m-xl-56 {
        margin: 14rem !important
    }

    .m-xl-64 {
        margin: 16rem !important
    }

    .m-xl-72 {
        margin: 18rem !important
    }

    .m-xl-80 {
        margin: 20rem !important
    }

    .m-xl-88 {
        margin: 22rem !important
    }

    .m-xl-96 {
        margin: 24rem !important
    }

    .m-xl-px {
        margin: 1px !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-xl-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-xl-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-xl-3 {
        margin-left: .75rem !important;
        margin-right: .75rem !important
    }

    .mx-xl-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-xl-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important
    }

    .mx-xl-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-xl-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important
    }

    .mx-xl-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-xl-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-xl-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-xl-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important
    }

    .mx-xl-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-xl-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }

    .mx-xl-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-xl-24 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-xl-32 {
        margin-left: 8rem !important;
        margin-right: 8rem !important
    }

    .mx-xl-40 {
        margin-left: 10rem !important;
        margin-right: 10rem !important
    }

    .mx-xl-48 {
        margin-left: 12rem !important;
        margin-right: 12rem !important
    }

    .mx-xl-56 {
        margin-left: 14rem !important;
        margin-right: 14rem !important
    }

    .mx-xl-64 {
        margin-left: 16rem !important;
        margin-right: 16rem !important
    }

    .mx-xl-72 {
        margin-left: 18rem !important;
        margin-right: 18rem !important
    }

    .mx-xl-80 {
        margin-left: 20rem !important;
        margin-right: 20rem !important
    }

    .mx-xl-88 {
        margin-left: 22rem !important;
        margin-right: 22rem !important
    }

    .mx-xl-96 {
        margin-left: 24rem !important;
        margin-right: 24rem !important
    }

    .mx-xl-px {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mx-xl-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-xl-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-xl-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-xl-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-xl-3 {
        margin-bottom: .75rem !important;
        margin-top: .75rem !important
    }

    .my-xl-4 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-xl-5 {
        margin-bottom: 1.25rem !important;
        margin-top: 1.25rem !important
    }

    .my-xl-6 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-xl-7 {
        margin-bottom: 1.75rem !important;
        margin-top: 1.75rem !important
    }

    .my-xl-8 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-xl-10 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-xl-12 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-xl-14 {
        margin-bottom: 3.5rem !important;
        margin-top: 3.5rem !important
    }

    .my-xl-16 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-xl-18 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }

    .my-xl-20 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-xl-24 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-xl-32 {
        margin-bottom: 8rem !important;
        margin-top: 8rem !important
    }

    .my-xl-40 {
        margin-bottom: 10rem !important;
        margin-top: 10rem !important
    }

    .my-xl-48 {
        margin-bottom: 12rem !important;
        margin-top: 12rem !important
    }

    .my-xl-56 {
        margin-bottom: 14rem !important;
        margin-top: 14rem !important
    }

    .my-xl-64 {
        margin-bottom: 16rem !important;
        margin-top: 16rem !important
    }

    .my-xl-72 {
        margin-bottom: 18rem !important;
        margin-top: 18rem !important
    }

    .my-xl-80 {
        margin-bottom: 20rem !important;
        margin-top: 20rem !important
    }

    .my-xl-88 {
        margin-bottom: 22rem !important;
        margin-top: 22rem !important
    }

    .my-xl-96 {
        margin-bottom: 24rem !important;
        margin-top: 24rem !important
    }

    .my-xl-px {
        margin-bottom: 1px !important;
        margin-top: 1px !important
    }

    .my-xl-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: .75rem !important
    }

    .mt-xl-4 {
        margin-top: 1rem !important
    }

    .mt-xl-5 {
        margin-top: 1.25rem !important
    }

    .mt-xl-6 {
        margin-top: 1.5rem !important
    }

    .mt-xl-7 {
        margin-top: 1.75rem !important
    }

    .mt-xl-8 {
        margin-top: 2rem !important
    }

    .mt-xl-10 {
        margin-top: 2.5rem !important
    }

    .mt-xl-12 {
        margin-top: 3rem !important
    }

    .mt-xl-14 {
        margin-top: 3.5rem !important
    }

    .mt-xl-16 {
        margin-top: 4rem !important
    }

    .mt-xl-18 {
        margin-top: 4.5rem !important
    }

    .mt-xl-20 {
        margin-top: 5rem !important
    }

    .mt-xl-24 {
        margin-top: 6rem !important
    }

    .mt-xl-32 {
        margin-top: 8rem !important
    }

    .mt-xl-40 {
        margin-top: 10rem !important
    }

    .mt-xl-48 {
        margin-top: 12rem !important
    }

    .mt-xl-56 {
        margin-top: 14rem !important
    }

    .mt-xl-64 {
        margin-top: 16rem !important
    }

    .mt-xl-72 {
        margin-top: 18rem !important
    }

    .mt-xl-80 {
        margin-top: 20rem !important
    }

    .mt-xl-88 {
        margin-top: 22rem !important
    }

    .mt-xl-96 {
        margin-top: 24rem !important
    }

    .mt-xl-px {
        margin-top: 1px !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: .75rem !important
    }

    .me-xl-4 {
        margin-right: 1rem !important
    }

    .me-xl-5 {
        margin-right: 1.25rem !important
    }

    .me-xl-6 {
        margin-right: 1.5rem !important
    }

    .me-xl-7 {
        margin-right: 1.75rem !important
    }

    .me-xl-8 {
        margin-right: 2rem !important
    }

    .me-xl-10 {
        margin-right: 2.5rem !important
    }

    .me-xl-12 {
        margin-right: 3rem !important
    }

    .me-xl-14 {
        margin-right: 3.5rem !important
    }

    .me-xl-16 {
        margin-right: 4rem !important
    }

    .me-xl-18 {
        margin-right: 4.5rem !important
    }

    .me-xl-20 {
        margin-right: 5rem !important
    }

    .me-xl-24 {
        margin-right: 6rem !important
    }

    .me-xl-32 {
        margin-right: 8rem !important
    }

    .me-xl-40 {
        margin-right: 10rem !important
    }

    .me-xl-48 {
        margin-right: 12rem !important
    }

    .me-xl-56 {
        margin-right: 14rem !important
    }

    .me-xl-64 {
        margin-right: 16rem !important
    }

    .me-xl-72 {
        margin-right: 18rem !important
    }

    .me-xl-80 {
        margin-right: 20rem !important
    }

    .me-xl-88 {
        margin-right: 22rem !important
    }

    .me-xl-96 {
        margin-right: 24rem !important
    }

    .me-xl-px {
        margin-right: 1px !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: .75rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1rem !important
    }

    .mb-xl-5 {
        margin-bottom: 1.25rem !important
    }

    .mb-xl-6 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-7 {
        margin-bottom: 1.75rem !important
    }

    .mb-xl-8 {
        margin-bottom: 2rem !important
    }

    .mb-xl-10 {
        margin-bottom: 2.5rem !important
    }

    .mb-xl-12 {
        margin-bottom: 3rem !important
    }

    .mb-xl-14 {
        margin-bottom: 3.5rem !important
    }

    .mb-xl-16 {
        margin-bottom: 4rem !important
    }

    .mb-xl-18 {
        margin-bottom: 4.5rem !important
    }

    .mb-xl-20 {
        margin-bottom: 5rem !important
    }

    .mb-xl-24 {
        margin-bottom: 6rem !important
    }

    .mb-xl-32 {
        margin-bottom: 8rem !important
    }

    .mb-xl-40 {
        margin-bottom: 10rem !important
    }

    .mb-xl-48 {
        margin-bottom: 12rem !important
    }

    .mb-xl-56 {
        margin-bottom: 14rem !important
    }

    .mb-xl-64 {
        margin-bottom: 16rem !important
    }

    .mb-xl-72 {
        margin-bottom: 18rem !important
    }

    .mb-xl-80 {
        margin-bottom: 20rem !important
    }

    .mb-xl-88 {
        margin-bottom: 22rem !important
    }

    .mb-xl-96 {
        margin-bottom: 24rem !important
    }

    .mb-xl-px {
        margin-bottom: 1px !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: .75rem !important
    }

    .ms-xl-4 {
        margin-left: 1rem !important
    }

    .ms-xl-5 {
        margin-left: 1.25rem !important
    }

    .ms-xl-6 {
        margin-left: 1.5rem !important
    }

    .ms-xl-7 {
        margin-left: 1.75rem !important
    }

    .ms-xl-8 {
        margin-left: 2rem !important
    }

    .ms-xl-10 {
        margin-left: 2.5rem !important
    }

    .ms-xl-12 {
        margin-left: 3rem !important
    }

    .ms-xl-14 {
        margin-left: 3.5rem !important
    }

    .ms-xl-16 {
        margin-left: 4rem !important
    }

    .ms-xl-18 {
        margin-left: 4.5rem !important
    }

    .ms-xl-20 {
        margin-left: 5rem !important
    }

    .ms-xl-24 {
        margin-left: 6rem !important
    }

    .ms-xl-32 {
        margin-left: 8rem !important
    }

    .ms-xl-40 {
        margin-left: 10rem !important
    }

    .ms-xl-48 {
        margin-left: 12rem !important
    }

    .ms-xl-56 {
        margin-left: 14rem !important
    }

    .ms-xl-64 {
        margin-left: 16rem !important
    }

    .ms-xl-72 {
        margin-left: 18rem !important
    }

    .ms-xl-80 {
        margin-left: 20rem !important
    }

    .ms-xl-88 {
        margin-left: 22rem !important
    }

    .ms-xl-96 {
        margin-left: 24rem !important
    }

    .ms-xl-px {
        margin-left: 1px !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .m-xl-n3 {
        margin: -.75rem !important
    }

    .m-xl-n4 {
        margin: -1rem !important
    }

    .m-xl-n5 {
        margin: -1.25rem !important
    }

    .m-xl-n6 {
        margin: -1.5rem !important
    }

    .m-xl-n7 {
        margin: -1.75rem !important
    }

    .m-xl-n8 {
        margin: -2rem !important
    }

    .m-xl-n10 {
        margin: -2.5rem !important
    }

    .m-xl-n12 {
        margin: -3rem !important
    }

    .m-xl-n14 {
        margin: -3.5rem !important
    }

    .m-xl-n16 {
        margin: -4rem !important
    }

    .m-xl-n18 {
        margin: -4.5rem !important
    }

    .m-xl-n20 {
        margin: -5rem !important
    }

    .m-xl-n24 {
        margin: -6rem !important
    }

    .m-xl-n32 {
        margin: -8rem !important
    }

    .m-xl-n40 {
        margin: -10rem !important
    }

    .m-xl-n48 {
        margin: -12rem !important
    }

    .m-xl-n56 {
        margin: -14rem !important
    }

    .m-xl-n64 {
        margin: -16rem !important
    }

    .m-xl-n72 {
        margin: -18rem !important
    }

    .m-xl-n80 {
        margin: -20rem !important
    }

    .m-xl-n88 {
        margin: -22rem !important
    }

    .m-xl-n96 {
        margin: -24rem !important
    }

    .m-xl-npx {
        margin: -1px !important
    }

    .mx-xl-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-xl-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-xl-n3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important
    }

    .mx-xl-n4 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-xl-n5 {
        margin-left: -1.25rem !important;
        margin-right: -1.25rem !important
    }

    .mx-xl-n6 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-xl-n7 {
        margin-left: -1.75rem !important;
        margin-right: -1.75rem !important
    }

    .mx-xl-n8 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-xl-n10 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-xl-n12 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-xl-n14 {
        margin-left: -3.5rem !important;
        margin-right: -3.5rem !important
    }

    .mx-xl-n16 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-xl-n18 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }

    .mx-xl-n20 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-xl-n24 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .mx-xl-n32 {
        margin-left: -8rem !important;
        margin-right: -8rem !important
    }

    .mx-xl-n40 {
        margin-left: -10rem !important;
        margin-right: -10rem !important
    }

    .mx-xl-n48 {
        margin-left: -12rem !important;
        margin-right: -12rem !important
    }

    .mx-xl-n56 {
        margin-left: -14rem !important;
        margin-right: -14rem !important
    }

    .mx-xl-n64 {
        margin-left: -16rem !important;
        margin-right: -16rem !important
    }

    .mx-xl-n72 {
        margin-left: -18rem !important;
        margin-right: -18rem !important
    }

    .mx-xl-n80 {
        margin-left: -20rem !important;
        margin-right: -20rem !important
    }

    .mx-xl-n88 {
        margin-left: -22rem !important;
        margin-right: -22rem !important
    }

    .mx-xl-n96 {
        margin-left: -24rem !important;
        margin-right: -24rem !important
    }

    .mx-xl-npx {
        margin-left: -1px !important;
        margin-right: -1px !important
    }

    .my-xl-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-xl-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-xl-n3 {
        margin-bottom: -.75rem !important;
        margin-top: -.75rem !important
    }

    .my-xl-n4 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-xl-n5 {
        margin-bottom: -1.25rem !important;
        margin-top: -1.25rem !important
    }

    .my-xl-n6 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-xl-n7 {
        margin-bottom: -1.75rem !important;
        margin-top: -1.75rem !important
    }

    .my-xl-n8 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-xl-n10 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-xl-n12 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-xl-n14 {
        margin-bottom: -3.5rem !important;
        margin-top: -3.5rem !important
    }

    .my-xl-n16 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-xl-n18 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }

    .my-xl-n20 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-xl-n24 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .my-xl-n32 {
        margin-bottom: -8rem !important;
        margin-top: -8rem !important
    }

    .my-xl-n40 {
        margin-bottom: -10rem !important;
        margin-top: -10rem !important
    }

    .my-xl-n48 {
        margin-bottom: -12rem !important;
        margin-top: -12rem !important
    }

    .my-xl-n56 {
        margin-bottom: -14rem !important;
        margin-top: -14rem !important
    }

    .my-xl-n64 {
        margin-bottom: -16rem !important;
        margin-top: -16rem !important
    }

    .my-xl-n72 {
        margin-bottom: -18rem !important;
        margin-top: -18rem !important
    }

    .my-xl-n80 {
        margin-bottom: -20rem !important;
        margin-top: -20rem !important
    }

    .my-xl-n88 {
        margin-bottom: -22rem !important;
        margin-top: -22rem !important
    }

    .my-xl-n96 {
        margin-bottom: -24rem !important;
        margin-top: -24rem !important
    }

    .my-xl-npx {
        margin-bottom: -1px !important;
        margin-top: -1px !important
    }

    .mt-xl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xl-n3 {
        margin-top: -.75rem !important
    }

    .mt-xl-n4 {
        margin-top: -1rem !important
    }

    .mt-xl-n5 {
        margin-top: -1.25rem !important
    }

    .mt-xl-n6 {
        margin-top: -1.5rem !important
    }

    .mt-xl-n7 {
        margin-top: -1.75rem !important
    }

    .mt-xl-n8 {
        margin-top: -2rem !important
    }

    .mt-xl-n10 {
        margin-top: -2.5rem !important
    }

    .mt-xl-n12 {
        margin-top: -3rem !important
    }

    .mt-xl-n14 {
        margin-top: -3.5rem !important
    }

    .mt-xl-n16 {
        margin-top: -4rem !important
    }

    .mt-xl-n18 {
        margin-top: -4.5rem !important
    }

    .mt-xl-n20 {
        margin-top: -5rem !important
    }

    .mt-xl-n24 {
        margin-top: -6rem !important
    }

    .mt-xl-n32 {
        margin-top: -8rem !important
    }

    .mt-xl-n40 {
        margin-top: -10rem !important
    }

    .mt-xl-n48 {
        margin-top: -12rem !important
    }

    .mt-xl-n56 {
        margin-top: -14rem !important
    }

    .mt-xl-n64 {
        margin-top: -16rem !important
    }

    .mt-xl-n72 {
        margin-top: -18rem !important
    }

    .mt-xl-n80 {
        margin-top: -20rem !important
    }

    .mt-xl-n88 {
        margin-top: -22rem !important
    }

    .mt-xl-n96 {
        margin-top: -24rem !important
    }

    .mt-xl-npx {
        margin-top: -1px !important
    }

    .me-xl-n1 {
        margin-right: -.25rem !important
    }

    .me-xl-n2 {
        margin-right: -.5rem !important
    }

    .me-xl-n3 {
        margin-right: -.75rem !important
    }

    .me-xl-n4 {
        margin-right: -1rem !important
    }

    .me-xl-n5 {
        margin-right: -1.25rem !important
    }

    .me-xl-n6 {
        margin-right: -1.5rem !important
    }

    .me-xl-n7 {
        margin-right: -1.75rem !important
    }

    .me-xl-n8 {
        margin-right: -2rem !important
    }

    .me-xl-n10 {
        margin-right: -2.5rem !important
    }

    .me-xl-n12 {
        margin-right: -3rem !important
    }

    .me-xl-n14 {
        margin-right: -3.5rem !important
    }

    .me-xl-n16 {
        margin-right: -4rem !important
    }

    .me-xl-n18 {
        margin-right: -4.5rem !important
    }

    .me-xl-n20 {
        margin-right: -5rem !important
    }

    .me-xl-n24 {
        margin-right: -6rem !important
    }

    .me-xl-n32 {
        margin-right: -8rem !important
    }

    .me-xl-n40 {
        margin-right: -10rem !important
    }

    .me-xl-n48 {
        margin-right: -12rem !important
    }

    .me-xl-n56 {
        margin-right: -14rem !important
    }

    .me-xl-n64 {
        margin-right: -16rem !important
    }

    .me-xl-n72 {
        margin-right: -18rem !important
    }

    .me-xl-n80 {
        margin-right: -20rem !important
    }

    .me-xl-n88 {
        margin-right: -22rem !important
    }

    .me-xl-n96 {
        margin-right: -24rem !important
    }

    .me-xl-npx {
        margin-right: -1px !important
    }

    .mb-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xl-n3 {
        margin-bottom: -.75rem !important
    }

    .mb-xl-n4 {
        margin-bottom: -1rem !important
    }

    .mb-xl-n5 {
        margin-bottom: -1.25rem !important
    }

    .mb-xl-n6 {
        margin-bottom: -1.5rem !important
    }

    .mb-xl-n7 {
        margin-bottom: -1.75rem !important
    }

    .mb-xl-n8 {
        margin-bottom: -2rem !important
    }

    .mb-xl-n10 {
        margin-bottom: -2.5rem !important
    }

    .mb-xl-n12 {
        margin-bottom: -3rem !important
    }

    .mb-xl-n14 {
        margin-bottom: -3.5rem !important
    }

    .mb-xl-n16 {
        margin-bottom: -4rem !important
    }

    .mb-xl-n18 {
        margin-bottom: -4.5rem !important
    }

    .mb-xl-n20 {
        margin-bottom: -5rem !important
    }

    .mb-xl-n24 {
        margin-bottom: -6rem !important
    }

    .mb-xl-n32 {
        margin-bottom: -8rem !important
    }

    .mb-xl-n40 {
        margin-bottom: -10rem !important
    }

    .mb-xl-n48 {
        margin-bottom: -12rem !important
    }

    .mb-xl-n56 {
        margin-bottom: -14rem !important
    }

    .mb-xl-n64 {
        margin-bottom: -16rem !important
    }

    .mb-xl-n72 {
        margin-bottom: -18rem !important
    }

    .mb-xl-n80 {
        margin-bottom: -20rem !important
    }

    .mb-xl-n88 {
        margin-bottom: -22rem !important
    }

    .mb-xl-n96 {
        margin-bottom: -24rem !important
    }

    .mb-xl-npx {
        margin-bottom: -1px !important
    }

    .ms-xl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xl-n3 {
        margin-left: -.75rem !important
    }

    .ms-xl-n4 {
        margin-left: -1rem !important
    }

    .ms-xl-n5 {
        margin-left: -1.25rem !important
    }

    .ms-xl-n6 {
        margin-left: -1.5rem !important
    }

    .ms-xl-n7 {
        margin-left: -1.75rem !important
    }

    .ms-xl-n8 {
        margin-left: -2rem !important
    }

    .ms-xl-n10 {
        margin-left: -2.5rem !important
    }

    .ms-xl-n12 {
        margin-left: -3rem !important
    }

    .ms-xl-n14 {
        margin-left: -3.5rem !important
    }

    .ms-xl-n16 {
        margin-left: -4rem !important
    }

    .ms-xl-n18 {
        margin-left: -4.5rem !important
    }

    .ms-xl-n20 {
        margin-left: -5rem !important
    }

    .ms-xl-n24 {
        margin-left: -6rem !important
    }

    .ms-xl-n32 {
        margin-left: -8rem !important
    }

    .ms-xl-n40 {
        margin-left: -10rem !important
    }

    .ms-xl-n48 {
        margin-left: -12rem !important
    }

    .ms-xl-n56 {
        margin-left: -14rem !important
    }

    .ms-xl-n64 {
        margin-left: -16rem !important
    }

    .ms-xl-n72 {
        margin-left: -18rem !important
    }

    .ms-xl-n80 {
        margin-left: -20rem !important
    }

    .ms-xl-n88 {
        margin-left: -22rem !important
    }

    .ms-xl-n96 {
        margin-left: -24rem !important
    }

    .ms-xl-npx {
        margin-left: -1px !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: .75rem !important
    }

    .p-xl-4 {
        padding: 1rem !important
    }

    .p-xl-5 {
        padding: 1.25rem !important
    }

    .p-xl-6 {
        padding: 1.5rem !important
    }

    .p-xl-7 {
        padding: 1.75rem !important
    }

    .p-xl-8 {
        padding: 2rem !important
    }

    .p-xl-10 {
        padding: 2.5rem !important
    }

    .p-xl-12 {
        padding: 3rem !important
    }

    .p-xl-14 {
        padding: 3.5rem !important
    }

    .p-xl-16 {
        padding: 4rem !important
    }

    .p-xl-18 {
        padding: 4.5rem !important
    }

    .p-xl-20 {
        padding: 5rem !important
    }

    .p-xl-24 {
        padding: 6rem !important
    }

    .p-xl-32 {
        padding: 8rem !important
    }

    .p-xl-40 {
        padding: 10rem !important
    }

    .p-xl-48 {
        padding: 12rem !important
    }

    .p-xl-56 {
        padding: 14rem !important
    }

    .p-xl-64 {
        padding: 16rem !important
    }

    .p-xl-72 {
        padding: 18rem !important
    }

    .p-xl-80 {
        padding: 20rem !important
    }

    .p-xl-88 {
        padding: 22rem !important
    }

    .p-xl-96 {
        padding: 24rem !important
    }

    .p-xl-px {
        padding: 1px !important
    }

    .px-xl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-xl-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-xl-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-xl-3 {
        padding-left: .75rem !important;
        padding-right: .75rem !important
    }

    .px-xl-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-xl-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important
    }

    .px-xl-6 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-xl-7 {
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important
    }

    .px-xl-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-xl-10 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-xl-12 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-xl-14 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important
    }

    .px-xl-16 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-xl-18 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }

    .px-xl-20 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-xl-24 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .px-xl-32 {
        padding-left: 8rem !important;
        padding-right: 8rem !important
    }

    .px-xl-40 {
        padding-left: 10rem !important;
        padding-right: 10rem !important
    }

    .px-xl-48 {
        padding-left: 12rem !important;
        padding-right: 12rem !important
    }

    .px-xl-56 {
        padding-left: 14rem !important;
        padding-right: 14rem !important
    }

    .px-xl-64 {
        padding-left: 16rem !important;
        padding-right: 16rem !important
    }

    .px-xl-72 {
        padding-left: 18rem !important;
        padding-right: 18rem !important
    }

    .px-xl-80 {
        padding-left: 20rem !important;
        padding-right: 20rem !important
    }

    .px-xl-88 {
        padding-left: 22rem !important;
        padding-right: 22rem !important
    }

    .px-xl-96 {
        padding-left: 24rem !important;
        padding-right: 24rem !important
    }

    .px-xl-px {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .py-xl-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-xl-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-xl-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-xl-3 {
        padding-bottom: .75rem !important;
        padding-top: .75rem !important
    }

    .py-xl-4 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-xl-5 {
        padding-bottom: 1.25rem !important;
        padding-top: 1.25rem !important
    }

    .py-xl-6 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-xl-7 {
        padding-bottom: 1.75rem !important;
        padding-top: 1.75rem !important
    }

    .py-xl-8 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-xl-10 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-xl-12 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-xl-14 {
        padding-bottom: 3.5rem !important;
        padding-top: 3.5rem !important
    }

    .py-xl-16 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-xl-18 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }

    .py-xl-20 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-xl-24 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .py-xl-32 {
        padding-bottom: 8rem !important;
        padding-top: 8rem !important
    }

    .py-xl-40 {
        padding-bottom: 10rem !important;
        padding-top: 10rem !important
    }

    .py-xl-48 {
        padding-bottom: 12rem !important;
        padding-top: 12rem !important
    }

    .py-xl-56 {
        padding-bottom: 14rem !important;
        padding-top: 14rem !important
    }

    .py-xl-64 {
        padding-bottom: 16rem !important;
        padding-top: 16rem !important
    }

    .py-xl-72 {
        padding-bottom: 18rem !important;
        padding-top: 18rem !important
    }

    .py-xl-80 {
        padding-bottom: 20rem !important;
        padding-top: 20rem !important
    }

    .py-xl-88 {
        padding-bottom: 22rem !important;
        padding-top: 22rem !important
    }

    .py-xl-96 {
        padding-bottom: 24rem !important;
        padding-top: 24rem !important
    }

    .py-xl-px {
        padding-bottom: 1px !important;
        padding-top: 1px !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: .75rem !important
    }

    .pt-xl-4 {
        padding-top: 1rem !important
    }

    .pt-xl-5 {
        padding-top: 1.25rem !important
    }

    .pt-xl-6 {
        padding-top: 1.5rem !important
    }

    .pt-xl-7 {
        padding-top: 1.75rem !important
    }

    .pt-xl-8 {
        padding-top: 2rem !important
    }

    .pt-xl-10 {
        padding-top: 2.5rem !important
    }

    .pt-xl-12 {
        padding-top: 3rem !important
    }

    .pt-xl-14 {
        padding-top: 3.5rem !important
    }

    .pt-xl-16 {
        padding-top: 4rem !important
    }

    .pt-xl-18 {
        padding-top: 4.5rem !important
    }

    .pt-xl-20 {
        padding-top: 5rem !important
    }

    .pt-xl-24 {
        padding-top: 6rem !important
    }

    .pt-xl-32 {
        padding-top: 8rem !important
    }

    .pt-xl-40 {
        padding-top: 10rem !important
    }

    .pt-xl-48 {
        padding-top: 12rem !important
    }

    .pt-xl-56 {
        padding-top: 14rem !important
    }

    .pt-xl-64 {
        padding-top: 16rem !important
    }

    .pt-xl-72 {
        padding-top: 18rem !important
    }

    .pt-xl-80 {
        padding-top: 20rem !important
    }

    .pt-xl-88 {
        padding-top: 22rem !important
    }

    .pt-xl-96 {
        padding-top: 24rem !important
    }

    .pt-xl-px {
        padding-top: 1px !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: .75rem !important
    }

    .pe-xl-4 {
        padding-right: 1rem !important
    }

    .pe-xl-5 {
        padding-right: 1.25rem !important
    }

    .pe-xl-6 {
        padding-right: 1.5rem !important
    }

    .pe-xl-7 {
        padding-right: 1.75rem !important
    }

    .pe-xl-8 {
        padding-right: 2rem !important
    }

    .pe-xl-10 {
        padding-right: 2.5rem !important
    }

    .pe-xl-12 {
        padding-right: 3rem !important
    }

    .pe-xl-14 {
        padding-right: 3.5rem !important
    }

    .pe-xl-16 {
        padding-right: 4rem !important
    }

    .pe-xl-18 {
        padding-right: 4.5rem !important
    }

    .pe-xl-20 {
        padding-right: 5rem !important
    }

    .pe-xl-24 {
        padding-right: 6rem !important
    }

    .pe-xl-32 {
        padding-right: 8rem !important
    }

    .pe-xl-40 {
        padding-right: 10rem !important
    }

    .pe-xl-48 {
        padding-right: 12rem !important
    }

    .pe-xl-56 {
        padding-right: 14rem !important
    }

    .pe-xl-64 {
        padding-right: 16rem !important
    }

    .pe-xl-72 {
        padding-right: 18rem !important
    }

    .pe-xl-80 {
        padding-right: 20rem !important
    }

    .pe-xl-88 {
        padding-right: 22rem !important
    }

    .pe-xl-96 {
        padding-right: 24rem !important
    }

    .pe-xl-px {
        padding-right: 1px !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: .75rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1rem !important
    }

    .pb-xl-5 {
        padding-bottom: 1.25rem !important
    }

    .pb-xl-6 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-7 {
        padding-bottom: 1.75rem !important
    }

    .pb-xl-8 {
        padding-bottom: 2rem !important
    }

    .pb-xl-10 {
        padding-bottom: 2.5rem !important
    }

    .pb-xl-12 {
        padding-bottom: 3rem !important
    }

    .pb-xl-14 {
        padding-bottom: 3.5rem !important
    }

    .pb-xl-16 {
        padding-bottom: 4rem !important
    }

    .pb-xl-18 {
        padding-bottom: 4.5rem !important
    }

    .pb-xl-20 {
        padding-bottom: 5rem !important
    }

    .pb-xl-24 {
        padding-bottom: 6rem !important
    }

    .pb-xl-32 {
        padding-bottom: 8rem !important
    }

    .pb-xl-40 {
        padding-bottom: 10rem !important
    }

    .pb-xl-48 {
        padding-bottom: 12rem !important
    }

    .pb-xl-56 {
        padding-bottom: 14rem !important
    }

    .pb-xl-64 {
        padding-bottom: 16rem !important
    }

    .pb-xl-72 {
        padding-bottom: 18rem !important
    }

    .pb-xl-80 {
        padding-bottom: 20rem !important
    }

    .pb-xl-88 {
        padding-bottom: 22rem !important
    }

    .pb-xl-96 {
        padding-bottom: 24rem !important
    }

    .pb-xl-px {
        padding-bottom: 1px !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: .75rem !important
    }

    .ps-xl-4 {
        padding-left: 1rem !important
    }

    .ps-xl-5 {
        padding-left: 1.25rem !important
    }

    .ps-xl-6 {
        padding-left: 1.5rem !important
    }

    .ps-xl-7 {
        padding-left: 1.75rem !important
    }

    .ps-xl-8 {
        padding-left: 2rem !important
    }

    .ps-xl-10 {
        padding-left: 2.5rem !important
    }

    .ps-xl-12 {
        padding-left: 3rem !important
    }

    .ps-xl-14 {
        padding-left: 3.5rem !important
    }

    .ps-xl-16 {
        padding-left: 4rem !important
    }

    .ps-xl-18 {
        padding-left: 4.5rem !important
    }

    .ps-xl-20 {
        padding-left: 5rem !important
    }

    .ps-xl-24 {
        padding-left: 6rem !important
    }

    .ps-xl-32 {
        padding-left: 8rem !important
    }

    .ps-xl-40 {
        padding-left: 10rem !important
    }

    .ps-xl-48 {
        padding-left: 12rem !important
    }

    .ps-xl-56 {
        padding-left: 14rem !important
    }

    .ps-xl-64 {
        padding-left: 16rem !important
    }

    .ps-xl-72 {
        padding-left: 18rem !important
    }

    .ps-xl-80 {
        padding-left: 20rem !important
    }

    .ps-xl-88 {
        padding-left: 22rem !important
    }

    .ps-xl-96 {
        padding-left: 24rem !important
    }

    .ps-xl-px {
        padding-left: 1px !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }

    .text-xl-primary {
        --x-text-opacity: 1
    }

    .text-xl-primary, .text-xl-primary-focus:focus, .text-xl-primary-hover:hover {
        color: rgba(var(--x-primary-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-secondary {
        --x-text-opacity: 1
    }

    .text-xl-secondary, .text-xl-secondary-focus:focus, .text-xl-secondary-hover:hover {
        color: rgba(var(--x-secondary-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-tertiary {
        --x-text-opacity: 1
    }

    .text-xl-tertiary, .text-xl-tertiary-focus:focus, .text-xl-tertiary-hover:hover {
        color: rgba(var(--x-tertiary-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-success {
        --x-text-opacity: 1
    }

    .text-xl-success, .text-xl-success-focus:focus, .text-xl-success-hover:hover {
        color: rgba(var(--x-success-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-info {
        --x-text-opacity: 1
    }

    .text-xl-info, .text-xl-info-focus:focus, .text-xl-info-hover:hover {
        color: rgba(var(--x-info-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-warning {
        --x-text-opacity: 1
    }

    .text-xl-warning, .text-xl-warning-focus:focus, .text-xl-warning-hover:hover {
        color: rgba(var(--x-warning-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-danger {
        --x-text-opacity: 1
    }

    .text-xl-danger, .text-xl-danger-focus:focus, .text-xl-danger-hover:hover {
        color: rgba(var(--x-danger-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-white {
        --x-text-opacity: 1
    }

    .text-xl-white, .text-xl-white-focus:focus, .text-xl-white-hover:hover {
        color: rgba(var(--x-white-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-light {
        --x-text-opacity: 1
    }

    .text-xl-light, .text-xl-light-focus:focus, .text-xl-light-hover:hover {
        color: rgba(var(--x-light-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-dark {
        --x-text-opacity: 1
    }

    .text-xl-dark, .text-xl-dark-focus:focus, .text-xl-dark-hover:hover {
        color: rgba(var(--x-dark-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-black {
        --x-text-opacity: 1
    }

    .text-xl-black, .text-xl-black-focus:focus, .text-xl-black-hover:hover {
        color: rgba(var(--x-black-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-body {
        --x-text-opacity: 1
    }

    .text-xl-body, .text-xl-body-focus:focus, .text-xl-body-hover:hover {
        color: rgba(var(--x-body-color-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-muted {
        --x-text-opacity: 1
    }

    .text-xl-muted, .text-xl-muted-focus:focus, .text-xl-muted-hover:hover {
        color: rgba(var(--x-muted-rgb),var(--x-text-opacity)) !important
    }

    .text-xl-heading {
        --x-text-opacity: 1
    }

    .text-xl-heading, .text-xl-heading-focus:focus, .text-xl-heading-hover:hover {
        color: rgba(var(--x-heading-rgb),var(--x-text-opacity)) !important
    }

    .rounded-xl {
        border-radius: .375rem !important
    }

    .rounded-xl-0 {
        border-radius: 0 !important
    }

    .rounded-xl-1 {
        border-radius: .25rem !important
    }

    .rounded-xl-2 {
        border-radius: .5rem !important
    }

    .rounded-xl-3 {
        border-radius: .75rem !important
    }

    .rounded-xl-4 {
        border-radius: 1rem !important
    }

    .rounded-xl-5 {
        border-radius: 1.5rem !important
    }

    .rounded-xl-6 {
        border-radius: 3rem !important
    }

    .rounded-xl-7 {
        border-radius: 4rem !important
    }

    .rounded-xl-8 {
        border-radius: 5rem !important
    }

    .rounded-xl-9 {
        border-radius: 6rem !important
    }

    .rounded-xl-10 {
        border-radius: 7rem !important
    }

    .rounded-xl-pill {
        border-radius: 50rem !important
    }

    .rounded-xl-circle {
        border-radius: 50% !important
    }

    .rounded-xl-card {
        border-radius: .75rem !important
    }

    .rounded-top-xl {
        border-top-left-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-top-xl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-xl-1 {
        border-top-left-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-top-xl-2 {
        border-top-left-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-top-xl-3 {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-top-xl-4 {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-top-xl-5 {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-xl-6 {
        border-top-left-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-top-xl-7 {
        border-top-left-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-top-xl-8 {
        border-top-left-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-top-xl-9 {
        border-top-left-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-top-xl-10 {
        border-top-left-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-top-xl-pill {
        border-top-left-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-top-xl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-xl-card {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-xl {
        border-bottom-right-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-end-xl-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-xl-1 {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-end-xl-2 {
        border-bottom-right-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-end-xl-3 {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-xl-4 {
        border-bottom-right-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-end-xl-5 {
        border-bottom-right-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-end-xl-6 {
        border-bottom-right-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-end-xl-7 {
        border-bottom-right-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-end-xl-8 {
        border-bottom-right-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-end-xl-9 {
        border-bottom-right-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-end-xl-10 {
        border-bottom-right-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-end-xl-pill {
        border-bottom-right-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-end-xl-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-xl-card {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-xl {
        border-bottom-left-radius: .375rem !important;
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-xl-1 {
        border-bottom-left-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-xl-2 {
        border-bottom-left-radius: .5rem !important;
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-xl-3 {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-xl-4 {
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-xl-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-xl-6 {
        border-bottom-left-radius: 3rem !important;
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-xl-7 {
        border-bottom-left-radius: 4rem !important;
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-xl-8 {
        border-bottom-left-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-xl-9 {
        border-bottom-left-radius: 6rem !important;
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-xl-10 {
        border-bottom-left-radius: 7rem !important;
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-xl-pill {
        border-bottom-left-radius: 50rem !important;
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-xl-card {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-start-xl {
        border-bottom-left-radius: .375rem !important;
        border-top-left-radius: .375rem !important
    }

    .rounded-start-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-xl-1 {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important
    }

    .rounded-start-xl-2 {
        border-bottom-left-radius: .5rem !important;
        border-top-left-radius: .5rem !important
    }

    .rounded-start-xl-3 {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-start-xl-4 {
        border-bottom-left-radius: 1rem !important;
        border-top-left-radius: 1rem !important
    }

    .rounded-start-xl-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important
    }

    .rounded-start-xl-6 {
        border-bottom-left-radius: 3rem !important;
        border-top-left-radius: 3rem !important
    }

    .rounded-start-xl-7 {
        border-bottom-left-radius: 4rem !important;
        border-top-left-radius: 4rem !important
    }

    .rounded-start-xl-8 {
        border-bottom-left-radius: 5rem !important;
        border-top-left-radius: 5rem !important
    }

    .rounded-start-xl-9 {
        border-bottom-left-radius: 6rem !important;
        border-top-left-radius: 6rem !important
    }

    .rounded-start-xl-10 {
        border-bottom-left-radius: 7rem !important;
        border-top-left-radius: 7rem !important
    }

    .rounded-start-xl-pill {
        border-bottom-left-radius: 50rem !important;
        border-top-left-radius: 50rem !important
    }

    .rounded-start-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-xl-card {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-xl {
        border-top-left-radius: .375rem !important
    }

    .rounded-top-start-xl-0 {
        border-top-left-radius: 0 !important
    }

    .rounded-top-start-xl-1 {
        border-top-left-radius: .25rem !important
    }

    .rounded-top-start-xl-2 {
        border-top-left-radius: .5rem !important
    }

    .rounded-top-start-xl-3 {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-xl-4 {
        border-top-left-radius: 1rem !important
    }

    .rounded-top-start-xl-5 {
        border-top-left-radius: 1.5rem !important
    }

    .rounded-top-start-xl-6 {
        border-top-left-radius: 3rem !important
    }

    .rounded-top-start-xl-7 {
        border-top-left-radius: 4rem !important
    }

    .rounded-top-start-xl-8 {
        border-top-left-radius: 5rem !important
    }

    .rounded-top-start-xl-9 {
        border-top-left-radius: 6rem !important
    }

    .rounded-top-start-xl-10 {
        border-top-left-radius: 7rem !important
    }

    .rounded-top-start-xl-pill {
        border-top-left-radius: 50rem !important
    }

    .rounded-top-start-xl-circle {
        border-top-left-radius: 50% !important
    }

    .rounded-top-start-xl-card {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-end-xl {
        border-top-right-radius: .375rem !important
    }

    .rounded-top-end-xl-0 {
        border-top-right-radius: 0 !important
    }

    .rounded-top-end-xl-1 {
        border-top-right-radius: .25rem !important
    }

    .rounded-top-end-xl-2 {
        border-top-right-radius: .5rem !important
    }

    .rounded-top-end-xl-3 {
        border-top-right-radius: .75rem !important
    }

    .rounded-top-end-xl-4 {
        border-top-right-radius: 1rem !important
    }

    .rounded-top-end-xl-5 {
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-end-xl-6 {
        border-top-right-radius: 3rem !important
    }

    .rounded-top-end-xl-7 {
        border-top-right-radius: 4rem !important
    }

    .rounded-top-end-xl-8 {
        border-top-right-radius: 5rem !important
    }

    .rounded-top-end-xl-9 {
        border-top-right-radius: 6rem !important
    }

    .rounded-top-end-xl-10 {
        border-top-right-radius: 7rem !important
    }

    .rounded-top-end-xl-pill {
        border-top-right-radius: 50rem !important
    }

    .rounded-top-end-xl-circle {
        border-top-right-radius: 50% !important
    }

    .rounded-top-end-xl-card {
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-end-xl {
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-end-xl-0 {
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-end-xl-1 {
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-end-xl-2 {
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-end-xl-3 {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-end-xl-4 {
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-end-xl-5 {
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-end-xl-6 {
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-end-xl-7 {
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-end-xl-8 {
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-end-xl-9 {
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-end-xl-10 {
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-end-xl-pill {
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-end-xl-circle {
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-end-xl-card {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-start-xl {
        border-bottom-left-radius: .375rem !important
    }

    .rounded-bottom-start-xl-0 {
        border-bottom-left-radius: 0 !important
    }

    .rounded-bottom-start-xl-1 {
        border-bottom-left-radius: .25rem !important
    }

    .rounded-bottom-start-xl-2 {
        border-bottom-left-radius: .5rem !important
    }

    .rounded-bottom-start-xl-3 {
        border-bottom-left-radius: .75rem !important
    }

    .rounded-bottom-start-xl-4 {
        border-bottom-left-radius: 1rem !important
    }

    .rounded-bottom-start-xl-5 {
        border-bottom-left-radius: 1.5rem !important
    }

    .rounded-bottom-start-xl-6 {
        border-bottom-left-radius: 3rem !important
    }

    .rounded-bottom-start-xl-7 {
        border-bottom-left-radius: 4rem !important
    }

    .rounded-bottom-start-xl-8 {
        border-bottom-left-radius: 5rem !important
    }

    .rounded-bottom-start-xl-9 {
        border-bottom-left-radius: 6rem !important
    }

    .rounded-bottom-start-xl-10 {
        border-bottom-left-radius: 7rem !important
    }

    .rounded-bottom-start-xl-pill {
        border-bottom-left-radius: 50rem !important
    }

    .rounded-bottom-start-xl-circle {
        border-bottom-left-radius: 50% !important
    }

    .rounded-bottom-start-xl-card {
        border-bottom-left-radius: .75rem !important
    }

    .border-xl-solid {
        border-style: solid !important
    }

    .border-xl-dashed {
        border-style: dashed !important
    }

    .border-xl-dotted {
        border-style: dotted !important
    }

    .border-xl-double {
        border-style: double !important
    }

    .border-xl-groove {
        border-style: groove !important
    }

    .border-xl-none {
        border-style: none !important
    }

    .text-xl-transparent, .text-xl-transparent-focus:focus, .text-xl-transparent-hover:hover {
        color: transparent !important
    }

    .text-xl-current, .text-xl-current-focus:focus, .text-xl-current-hover:hover {
        color: currentColor !important
    }

    .text-xl-reset, .text-xl-reset-focus:focus, .text-xl-reset-hover:hover {
        color: inherit !important
    }

    .shadow-soft-xl-1, .shadow-soft-xl-1-focus:focus, .shadow-soft-xl-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-xl-2, .shadow-soft-xl-2-focus:focus, .shadow-soft-xl-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xl-3, .shadow-soft-xl-3-focus:focus, .shadow-soft-xl-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xl-4, .shadow-soft-xl-4-focus:focus, .shadow-soft-xl-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xl-5, .shadow-soft-xl-5-focus:focus, .shadow-soft-xl-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xl-6, .shadow-soft-xl-6-focus:focus, .shadow-soft-xl-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-xl-none, .shadow-soft-xl-none-focus:focus, .shadow-soft-xl-none-hover:hover {
        box-shadow: none !important
    }

    .min-w-xl-0 {
        min-width: 0 !important
    }

    .min-w-xl-full {
        min-width: 100% !important
    }

    .min-w-xl-min {
        min-width: -webkit-min-content !important;
        min-width: -moz-min-content !important;
        min-width: min-content !important
    }

    .min-w-xl-max {
        min-width: -webkit-max-content !important;
        min-width: -moz-max-content !important;
        min-width: max-content !important
    }

    .min-h-xl-0 {
        min-height: 0 !important
    }

    .min-h-xl-full {
        min-height: 100% !important
    }

    .min-h-xl-screen {
        min-height: 100vh !important
    }

    .place-content-xl-start {
        place-content: flex-start !important
    }

    .place-content-xl-end {
        place-content: flex-end !important
    }

    .place-content-xl-center {
        place-content: center !important
    }

    .place-content-xl-between {
        place-content: space-between !important
    }

    .place-content-xl-around {
        place-content: space-around !important
    }

    .place-content-xl-evenly {
        place-content: space-evenly !important
    }

    .place-content-xl-stretch {
        place-content: stretch !important
    }

    .overflow-x-xl-auto {
        overflow-x: auto !important
    }

    .overflow-x-xl-hidden {
        overflow-x: hidden !important
    }

    .overflow-x-xl-visible {
        overflow-x: visible !important
    }

    .overflow-x-xl-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-xl-auto {
        overflow-y: auto !important
    }

    .overflow-y-xl-hidden {
        overflow-y: hidden !important
    }

    .overflow-y-xl-visible {
        overflow-y: visible !important
    }

    .overflow-y-xl-scroll {
        overflow-y: scroll !important
    }

    .transition-xl {
        transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform !important
    }

    .transition-xl-none {
        transition-property: none !important
    }

    .transition-xl-all {
        transition-property: all !important
    }

    .transition-xl-colors {
        transition-property: background-color,border-color,color,fill,stroke !important
    }

    .transition-xl-opacity {
        transition-property: opacity !important
    }

    .transition-xl-shadow {
        transition-property: box-shadow !important
    }

    .transition-xl-transform {
        transition-property: transform !important
    }

    .ease-xl-base {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .ease-xl-linear {
        transition-timing-function: linear !important
    }

    .ease-xl-in {
        transition-timing-function: cubic-bezier(.4,0,1,1) !important
    }

    .ease-xl-out {
        transition-timing-function: cubic-bezier(0,0,.2,1) !important
    }

    .ease-xl-in-out {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .transform-xl-none {
        transform: none !important
    }

    .origin-xl-center {
        transform-origin: center !important
    }

    .origin-xl-top {
        transform-origin: top !important
    }

    .origin-xl-top-right {
        transform-origin: top right !important
    }

    .origin-xl-right {
        transform-origin: right !important
    }

    .origin-xl-bottom-right {
        transform-origin: bottom right !important
    }

    .origin-xl-bottom {
        transform-origin: bottom !important
    }

    .origin-xl-bottom-left {
        transform-origin: bottom left !important
    }

    .origin-xl-left {
        transform-origin: left !important
    }

    .origin-xl-top-left {
        transform-origin: top left !important
    }

    .scale-xl-0, .scale-xl-0-focus:focus, .scale-xl-0-hover:hover {
        --x-scale-x: 0 !important;
        --x-scale-y: 0 !important
    }

    .scale-xl-25, .scale-xl-25-focus:focus, .scale-xl-25-hover:hover {
        --x-scale-x: 0.25 !important;
        --x-scale-y: 0.25 !important
    }

    .scale-xl-50, .scale-xl-50-focus:focus, .scale-xl-50-hover:hover {
        --x-scale-x: 0.5 !important;
        --x-scale-y: 0.5 !important
    }

    .scale-xl-75, .scale-xl-75-focus:focus, .scale-xl-75-hover:hover {
        --x-scale-x: 0.75 !important;
        --x-scale-y: 0.75 !important
    }

    .scale-xl-90, .scale-xl-90-focus:focus, .scale-xl-90-hover:hover {
        --x-scale-x: 0.9 !important;
        --x-scale-y: 0.9 !important
    }

    .scale-xl-95, .scale-xl-95-focus:focus, .scale-xl-95-hover:hover {
        --x-scale-x: 0.95 !important;
        --x-scale-y: 0.95 !important
    }

    .scale-xl-100, .scale-xl-100-focus:focus, .scale-xl-100-hover:hover {
        --x-scale-x: 1 !important;
        --x-scale-y: 1 !important
    }

    .scale-xl-105, .scale-xl-105-focus:focus, .scale-xl-105-hover:hover {
        --x-scale-x: 1.05 !important;
        --x-scale-y: 1.05 !important
    }

    .scale-xl-110, .scale-xl-110-focus:focus, .scale-xl-110-hover:hover {
        --x-scale-x: 1.1 !important;
        --x-scale-y: 1.1 !important
    }

    .scale-xl-125, .scale-xl-125-focus:focus, .scale-xl-125-hover:hover {
        --x-scale-x: 1.25 !important;
        --x-scale-y: 1.25 !important
    }

    .scale-xl-150, .scale-xl-150-focus:focus, .scale-xl-150-hover:hover {
        --x-scale-x: 1.5 !important;
        --x-scale-y: 1.5 !important
    }

    .scale-xl-200, .scale-xl-200-focus:focus, .scale-xl-200-hover:hover {
        --x-scale-x: 2 !important;
        --x-scale-y: 2 !important
    }

    .scale-y-xl-0, .scale-y-xl-0-focus:focus, .scale-y-xl-0-hover:hover {
        --x--scale-y: 0 !important
    }

    .scale-y-xl-25, .scale-y-xl-25-focus:focus, .scale-y-xl-25-hover:hover {
        --x--scale-y: 0.25 !important
    }

    .scale-y-xl-50, .scale-y-xl-50-focus:focus, .scale-y-xl-50-hover:hover {
        --x--scale-y: 0.5 !important
    }

    .scale-y-xl-75, .scale-y-xl-75-focus:focus, .scale-y-xl-75-hover:hover {
        --x--scale-y: 0.75 !important
    }

    .scale-y-xl-90, .scale-y-xl-90-focus:focus, .scale-y-xl-90-hover:hover {
        --x--scale-y: 0.9 !important
    }

    .scale-y-xl-95, .scale-y-xl-95-focus:focus, .scale-y-xl-95-hover:hover {
        --x--scale-y: 0.95 !important
    }

    .scale-y-xl-100, .scale-y-xl-100-focus:focus, .scale-y-xl-100-hover:hover {
        --x--scale-y: 1 !important
    }

    .scale-y-xl-105, .scale-y-xl-105-focus:focus, .scale-y-xl-105-hover:hover {
        --x--scale-y: 1.05 !important
    }

    .scale-y-xl-110, .scale-y-xl-110-focus:focus, .scale-y-xl-110-hover:hover {
        --x--scale-y: 1.1 !important
    }

    .scale-y-xl-125, .scale-y-xl-125-focus:focus, .scale-y-xl-125-hover:hover {
        --x--scale-y: 1.25 !important
    }

    .scale-y-xl-150, .scale-y-xl-150-focus:focus, .scale-y-xl-150-hover:hover {
        --x--scale-y: 1.5 !important
    }

    .scale-y-xl-200, .scale-y-xl-200-focus:focus, .scale-y-xl-200-hover:hover {
        --x--scale-y: 2 !important
    }

    .scale-x-xl-0, .scale-x-xl-0-focus:focus, .scale-x-xl-0-hover:hover {
        --x--scale-x: 0 !important
    }

    .scale-x-xl-25, .scale-x-xl-25-focus:focus, .scale-x-xl-25-hover:hover {
        --x--scale-x: 0.25 !important
    }

    .scale-x-xl-50, .scale-x-xl-50-focus:focus, .scale-x-xl-50-hover:hover {
        --x--scale-x: 0.5 !important
    }

    .scale-x-xl-75, .scale-x-xl-75-focus:focus, .scale-x-xl-75-hover:hover {
        --x--scale-x: 0.75 !important
    }

    .scale-x-xl-90, .scale-x-xl-90-focus:focus, .scale-x-xl-90-hover:hover {
        --x--scale-x: 0.9 !important
    }

    .scale-x-xl-95, .scale-x-xl-95-focus:focus, .scale-x-xl-95-hover:hover {
        --x--scale-x: 0.95 !important
    }

    .scale-x-xl-100, .scale-x-xl-100-focus:focus, .scale-x-xl-100-hover:hover {
        --x--scale-x: 1 !important
    }

    .scale-x-xl-105, .scale-x-xl-105-focus:focus, .scale-x-xl-105-hover:hover {
        --x--scale-x: 1.05 !important
    }

    .scale-x-xl-110, .scale-x-xl-110-focus:focus, .scale-x-xl-110-hover:hover {
        --x--scale-x: 1.1 !important
    }

    .scale-x-xl-125, .scale-x-xl-125-focus:focus, .scale-x-xl-125-hover:hover {
        --x--scale-x: 1.25 !important
    }

    .scale-x-xl-150, .scale-x-xl-150-focus:focus, .scale-x-xl-150-hover:hover {
        --x--scale-x: 1.5 !important
    }

    .scale-x-xl-200, .scale-x-xl-200-focus:focus, .scale-x-xl-200-hover:hover {
        --x--scale-x: 2 !important
    }

    .rotate-xl-0, .rotate-xl-0-focus:focus, .rotate-xl-0-hover:hover {
        --x-rotate: 0deg !important
    }

    .rotate-xl-1, .rotate-xl-1-focus:focus, .rotate-xl-1-hover:hover {
        --x-rotate: 1deg !important
    }

    .rotate-xl-2, .rotate-xl-2-focus:focus, .rotate-xl-2-hover:hover {
        --x-rotate: 2deg !important
    }

    .rotate-xl-3, .rotate-xl-3-focus:focus, .rotate-xl-3-hover:hover {
        --x-rotate: 3deg !important
    }

    .rotate-xl-6, .rotate-xl-6-focus:focus, .rotate-xl-6-hover:hover {
        --x-rotate: 6deg !important
    }

    .rotate-xl-12, .rotate-xl-12-focus:focus, .rotate-xl-12-hover:hover {
        --x-rotate: 12deg !important
    }

    .rotate-xl-30, .rotate-xl-30-focus:focus, .rotate-xl-30-hover:hover {
        --x-rotate: 30deg !important
    }

    .rotate-xl-45, .rotate-xl-45-focus:focus, .rotate-xl-45-hover:hover {
        --x-rotate: 45deg !important
    }

    .rotate-xl-90, .rotate-xl-90-focus:focus, .rotate-xl-90-hover:hover {
        --x-rotate: 90deg !important
    }

    .rotate-xl-180, .rotate-xl-180-focus:focus, .rotate-xl-180-hover:hover {
        --x-rotate: 180deg !important
    }

    .rotate-xl-n1, .rotate-xl-n1-focus:focus, .rotate-xl-n1-hover:hover {
        --x-rotate: -1deg !important
    }

    .rotate-xl-n2, .rotate-xl-n2-focus:focus, .rotate-xl-n2-hover:hover {
        --x-rotate: -2deg !important
    }

    .rotate-xl-n3, .rotate-xl-n3-focus:focus, .rotate-xl-n3-hover:hover {
        --x-rotate: -3deg !important
    }

    .rotate-xl-n6, .rotate-xl-n6-focus:focus, .rotate-xl-n6-hover:hover {
        --x-rotate: -6deg !important
    }

    .rotate-xl-n12, .rotate-xl-n12-focus:focus, .rotate-xl-n12-hover:hover {
        --x-rotate: -12deg !important
    }

    .rotate-xl-n30, .rotate-xl-n30-focus:focus, .rotate-xl-n30-hover:hover {
        --x-rotate: -30deg !important
    }

    .rotate-xl-n45, .rotate-xl-n45-focus:focus, .rotate-xl-n45-hover:hover {
        --x-rotate: -45deg !important
    }

    .rotate-xl-n90, .rotate-xl-n90-focus:focus, .rotate-xl-n90-hover:hover {
        --x-rotate: -90deg !important
    }

    .rotate-xl-n180, .rotate-xl-n180-focus:focus, .rotate-xl-n180-hover:hover {
        --x-rotate: -180deg !important
    }

    .rotate-y-xl-0, .rotate-y-xl-0-focus:focus, .rotate-y-xl-0-hover:hover {
        --x-rotate-y: 0deg !important
    }

    .rotate-y-xl-1, .rotate-y-xl-1-focus:focus, .rotate-y-xl-1-hover:hover {
        --x-rotate-y: 1deg !important
    }

    .rotate-y-xl-2, .rotate-y-xl-2-focus:focus, .rotate-y-xl-2-hover:hover {
        --x-rotate-y: 2deg !important
    }

    .rotate-y-xl-3, .rotate-y-xl-3-focus:focus, .rotate-y-xl-3-hover:hover {
        --x-rotate-y: 3deg !important
    }

    .rotate-y-xl-6, .rotate-y-xl-6-focus:focus, .rotate-y-xl-6-hover:hover {
        --x-rotate-y: 6deg !important
    }

    .rotate-y-xl-12, .rotate-y-xl-12-focus:focus, .rotate-y-xl-12-hover:hover {
        --x-rotate-y: 12deg !important
    }

    .rotate-y-xl-30, .rotate-y-xl-30-focus:focus, .rotate-y-xl-30-hover:hover {
        --x-rotate-y: 30deg !important
    }

    .rotate-y-xl-45, .rotate-y-xl-45-focus:focus, .rotate-y-xl-45-hover:hover {
        --x-rotate-y: 45deg !important
    }

    .rotate-y-xl-90, .rotate-y-xl-90-focus:focus, .rotate-y-xl-90-hover:hover {
        --x-rotate-y: 90deg !important
    }

    .rotate-y-xl-180, .rotate-y-xl-180-focus:focus, .rotate-y-xl-180-hover:hover {
        --x-rotate-y: 180deg !important
    }

    .rotate-y-xl-n1, .rotate-y-xl-n1-focus:focus, .rotate-y-xl-n1-hover:hover {
        --x-rotate-y: -1deg !important
    }

    .rotate-y-xl-n2, .rotate-y-xl-n2-focus:focus, .rotate-y-xl-n2-hover:hover {
        --x-rotate-y: -2deg !important
    }

    .rotate-y-xl-n3, .rotate-y-xl-n3-focus:focus, .rotate-y-xl-n3-hover:hover {
        --x-rotate-y: -3deg !important
    }

    .rotate-y-xl-n6, .rotate-y-xl-n6-focus:focus, .rotate-y-xl-n6-hover:hover {
        --x-rotate-y: -6deg !important
    }

    .rotate-y-xl-n12, .rotate-y-xl-n12-focus:focus, .rotate-y-xl-n12-hover:hover {
        --x-rotate-y: -12deg !important
    }

    .rotate-y-xl-n30, .rotate-y-xl-n30-focus:focus, .rotate-y-xl-n30-hover:hover {
        --x-rotate-y: -30deg !important
    }

    .rotate-y-xl-n45, .rotate-y-xl-n45-focus:focus, .rotate-y-xl-n45-hover:hover {
        --x-rotate-y: -45deg !important
    }

    .rotate-y-xl-n90, .rotate-y-xl-n90-focus:focus, .rotate-y-xl-n90-hover:hover {
        --x-rotate-y: -90deg !important
    }

    .rotate-y-xl-n180, .rotate-y-xl-n180-focus:focus, .rotate-y-xl-n180-hover:hover {
        --x-rotate-y: -180deg !important
    }

    .rotate-x-xl-0, .rotate-x-xl-0-focus:focus, .rotate-x-xl-0-hover:hover {
        --x-rotate-x: 0deg !important
    }

    .rotate-x-xl-1, .rotate-x-xl-1-focus:focus, .rotate-x-xl-1-hover:hover {
        --x-rotate-x: 1deg !important
    }

    .rotate-x-xl-2, .rotate-x-xl-2-focus:focus, .rotate-x-xl-2-hover:hover {
        --x-rotate-x: 2deg !important
    }

    .rotate-x-xl-3, .rotate-x-xl-3-focus:focus, .rotate-x-xl-3-hover:hover {
        --x-rotate-x: 3deg !important
    }

    .rotate-x-xl-6, .rotate-x-xl-6-focus:focus, .rotate-x-xl-6-hover:hover {
        --x-rotate-x: 6deg !important
    }

    .rotate-x-xl-12, .rotate-x-xl-12-focus:focus, .rotate-x-xl-12-hover:hover {
        --x-rotate-x: 12deg !important
    }

    .rotate-x-xl-30, .rotate-x-xl-30-focus:focus, .rotate-x-xl-30-hover:hover {
        --x-rotate-x: 30deg !important
    }

    .rotate-x-xl-45, .rotate-x-xl-45-focus:focus, .rotate-x-xl-45-hover:hover {
        --x-rotate-x: 45deg !important
    }

    .rotate-x-xl-90, .rotate-x-xl-90-focus:focus, .rotate-x-xl-90-hover:hover {
        --x-rotate-x: 90deg !important
    }

    .rotate-x-xl-180, .rotate-x-xl-180-focus:focus, .rotate-x-xl-180-hover:hover {
        --x-rotate-x: 180deg !important
    }

    .rotate-x-xl-n1, .rotate-x-xl-n1-focus:focus, .rotate-x-xl-n1-hover:hover {
        --x-rotate-x: -1deg !important
    }

    .rotate-x-xl-n2, .rotate-x-xl-n2-focus:focus, .rotate-x-xl-n2-hover:hover {
        --x-rotate-x: -2deg !important
    }

    .rotate-x-xl-n3, .rotate-x-xl-n3-focus:focus, .rotate-x-xl-n3-hover:hover {
        --x-rotate-x: -3deg !important
    }

    .rotate-x-xl-n6, .rotate-x-xl-n6-focus:focus, .rotate-x-xl-n6-hover:hover {
        --x-rotate-x: -6deg !important
    }

    .rotate-x-xl-n12, .rotate-x-xl-n12-focus:focus, .rotate-x-xl-n12-hover:hover {
        --x-rotate-x: -12deg !important
    }

    .rotate-x-xl-n30, .rotate-x-xl-n30-focus:focus, .rotate-x-xl-n30-hover:hover {
        --x-rotate-x: -30deg !important
    }

    .rotate-x-xl-n45, .rotate-x-xl-n45-focus:focus, .rotate-x-xl-n45-hover:hover {
        --x-rotate-x: -45deg !important
    }

    .rotate-x-xl-n90, .rotate-x-xl-n90-focus:focus, .rotate-x-xl-n90-hover:hover {
        --x-rotate-x: -90deg !important
    }

    .rotate-x-xl-n180, .rotate-x-xl-n180-focus:focus, .rotate-x-xl-n180-hover:hover {
        --x-rotate-x: -180deg !important
    }

    .perspective-xl-100, .perspective-xl-100-focus:focus, .perspective-xl-100-hover:hover {
        --x-perspective: 100px !important
    }

    .perspective-xl-200, .perspective-xl-200-focus:focus, .perspective-xl-200-hover:hover {
        --x-perspective: 200px !important
    }

    .perspective-xl-300, .perspective-xl-300-focus:focus, .perspective-xl-300-hover:hover {
        --x-perspective: 300px !important
    }

    .perspective-xl-400, .perspective-xl-400-focus:focus, .perspective-xl-400-hover:hover {
        --x-perspective: 400px !important
    }

    .perspective-xl-500, .perspective-xl-500-focus:focus, .perspective-xl-500-hover:hover {
        --x-perspective: 500px !important
    }

    .perspective-xl-600, .perspective-xl-600-focus:focus, .perspective-xl-600-hover:hover {
        --x-perspective: 600px !important
    }

    .perspective-xl-700, .perspective-xl-700-focus:focus, .perspective-xl-700-hover:hover {
        --x-perspective: 700px !important
    }

    .perspective-xl-800, .perspective-xl-800-focus:focus, .perspective-xl-800-hover:hover {
        --x-perspective: 800px !important
    }

    .perspective-xl-1000, .perspective-xl-1000-focus:focus, .perspective-xl-1000-hover:hover {
        --x-perspective: 1000px !important
    }

    .perspective-xl-1250, .perspective-xl-1250-focus:focus, .perspective-xl-1250-hover:hover {
        --x-perspective: 1250px !important
    }

    .perspective-xl-1500, .perspective-xl-1500-focus:focus, .perspective-xl-1500-hover:hover {
        --x-perspective: 1500px !important
    }

    .translate-y-xl-0, .translate-y-xl-0-focus:focus, .translate-y-xl-0-hover:hover {
        --x-translate-y: 0 !important
    }

    .translate-y-xl-1, .translate-y-xl-1-focus:focus, .translate-y-xl-1-hover:hover {
        --x-translate-y: 0.25rem !important
    }

    .translate-y-xl-2, .translate-y-xl-2-focus:focus, .translate-y-xl-2-hover:hover {
        --x-translate-y: 0.5rem !important
    }

    .translate-y-xl-3, .translate-y-xl-3-focus:focus, .translate-y-xl-3-hover:hover {
        --x-translate-y: 0.75rem !important
    }

    .translate-y-xl-4, .translate-y-xl-4-focus:focus, .translate-y-xl-4-hover:hover {
        --x-translate-y: 1rem !important
    }

    .translate-y-xl-5, .translate-y-xl-5-focus:focus, .translate-y-xl-5-hover:hover {
        --x-translate-y: 1.25rem !important
    }

    .translate-y-xl-6, .translate-y-xl-6-focus:focus, .translate-y-xl-6-hover:hover {
        --x-translate-y: 1.5rem !important
    }

    .translate-y-xl-7, .translate-y-xl-7-focus:focus, .translate-y-xl-7-hover:hover {
        --x-translate-y: 1.75rem !important
    }

    .translate-y-xl-8, .translate-y-xl-8-focus:focus, .translate-y-xl-8-hover:hover {
        --x-translate-y: 2rem !important
    }

    .translate-y-xl-10, .translate-y-xl-10-focus:focus, .translate-y-xl-10-hover:hover {
        --x-translate-y: 2.5rem !important
    }

    .translate-y-xl-12, .translate-y-xl-12-focus:focus, .translate-y-xl-12-hover:hover {
        --x-translate-y: 3rem !important
    }

    .translate-y-xl-14, .translate-y-xl-14-focus:focus, .translate-y-xl-14-hover:hover {
        --x-translate-y: 3.5rem !important
    }

    .translate-y-xl-16, .translate-y-xl-16-focus:focus, .translate-y-xl-16-hover:hover {
        --x-translate-y: 4rem !important
    }

    .translate-y-xl-18, .translate-y-xl-18-focus:focus, .translate-y-xl-18-hover:hover {
        --x-translate-y: 4.5rem !important
    }

    .translate-y-xl-20, .translate-y-xl-20-focus:focus, .translate-y-xl-20-hover:hover {
        --x-translate-y: 5rem !important
    }

    .translate-y-xl-24, .translate-y-xl-24-focus:focus, .translate-y-xl-24-hover:hover {
        --x-translate-y: 6rem !important
    }

    .translate-y-xl-32, .translate-y-xl-32-focus:focus, .translate-y-xl-32-hover:hover {
        --x-translate-y: 8rem !important
    }

    .translate-y-xl-40, .translate-y-xl-40-focus:focus, .translate-y-xl-40-hover:hover {
        --x-translate-y: 10rem !important
    }

    .translate-y-xl-48, .translate-y-xl-48-focus:focus, .translate-y-xl-48-hover:hover {
        --x-translate-y: 12rem !important
    }

    .translate-y-xl-56, .translate-y-xl-56-focus:focus, .translate-y-xl-56-hover:hover {
        --x-translate-y: 14rem !important
    }

    .translate-y-xl-64, .translate-y-xl-64-focus:focus, .translate-y-xl-64-hover:hover {
        --x-translate-y: 16rem !important
    }

    .translate-y-xl-72, .translate-y-xl-72-focus:focus, .translate-y-xl-72-hover:hover {
        --x-translate-y: 18rem !important
    }

    .translate-y-xl-80, .translate-y-xl-80-focus:focus, .translate-y-xl-80-hover:hover {
        --x-translate-y: 20rem !important
    }

    .translate-y-xl-88, .translate-y-xl-88-focus:focus, .translate-y-xl-88-hover:hover {
        --x-translate-y: 22rem !important
    }

    .translate-y-xl-96, .translate-y-xl-96-focus:focus, .translate-y-xl-96-hover:hover {
        --x-translate-y: 24rem !important
    }

    .translate-y-xl-px, .translate-y-xl-px-focus:focus, .translate-y-xl-px-hover:hover {
        --x-translate-y: 1px !important
    }

    .translate-y-xl-1\/2, .translate-y-xl-1\/2-focus:focus, .translate-y-xl-1\/2-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-xl-1\/3, .translate-y-xl-1\/3-focus:focus, .translate-y-xl-1\/3-hover:hover {
        --x-translate-y: 33.333333% !important
    }

    .translate-y-xl-1\/4, .translate-y-xl-1\/4-focus:focus, .translate-y-xl-1\/4-hover:hover {
        --x-translate-y: 25% !important
    }

    .translate-y-xl-1\/5, .translate-y-xl-1\/5-focus:focus, .translate-y-xl-1\/5-hover:hover {
        --x-translate-y: 20% !important
    }

    .translate-y-xl-2\/3, .translate-y-xl-2\/3-focus:focus, .translate-y-xl-2\/3-hover:hover {
        --x-translate-y: 66.666667% !important
    }

    .translate-y-xl-2\/5, .translate-y-xl-2\/5-focus:focus, .translate-y-xl-2\/5-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-xl-3\/4, .translate-y-xl-3\/4-focus:focus, .translate-y-xl-3\/4-hover:hover {
        --x-translate-y: 75% !important
    }

    .translate-y-xl-full, .translate-y-xl-full-focus:focus, .translate-y-xl-full-hover:hover {
        --x-translate-y: 100% !important
    }

    .translate-y-xl-n1, .translate-y-xl-n1-focus:focus, .translate-y-xl-n1-hover:hover {
        --x-translate-y: -0.25rem !important
    }

    .translate-y-xl-n2, .translate-y-xl-n2-focus:focus, .translate-y-xl-n2-hover:hover {
        --x-translate-y: -0.5rem !important
    }

    .translate-y-xl-n3, .translate-y-xl-n3-focus:focus, .translate-y-xl-n3-hover:hover {
        --x-translate-y: -0.75rem !important
    }

    .translate-y-xl-n4, .translate-y-xl-n4-focus:focus, .translate-y-xl-n4-hover:hover {
        --x-translate-y: -1rem !important
    }

    .translate-y-xl-n5, .translate-y-xl-n5-focus:focus, .translate-y-xl-n5-hover:hover {
        --x-translate-y: -1.25rem !important
    }

    .translate-y-xl-n6, .translate-y-xl-n6-focus:focus, .translate-y-xl-n6-hover:hover {
        --x-translate-y: -1.5rem !important
    }

    .translate-y-xl-n7, .translate-y-xl-n7-focus:focus, .translate-y-xl-n7-hover:hover {
        --x-translate-y: -1.75rem !important
    }

    .translate-y-xl-n8, .translate-y-xl-n8-focus:focus, .translate-y-xl-n8-hover:hover {
        --x-translate-y: -2rem !important
    }

    .translate-y-xl-n10, .translate-y-xl-n10-focus:focus, .translate-y-xl-n10-hover:hover {
        --x-translate-y: -2.5rem !important
    }

    .translate-y-xl-n12, .translate-y-xl-n12-focus:focus, .translate-y-xl-n12-hover:hover {
        --x-translate-y: -3rem !important
    }

    .translate-y-xl-n14, .translate-y-xl-n14-focus:focus, .translate-y-xl-n14-hover:hover {
        --x-translate-y: -3.5rem !important
    }

    .translate-y-xl-n16, .translate-y-xl-n16-focus:focus, .translate-y-xl-n16-hover:hover {
        --x-translate-y: -4rem !important
    }

    .translate-y-xl-n18, .translate-y-xl-n18-focus:focus, .translate-y-xl-n18-hover:hover {
        --x-translate-y: -4.5rem !important
    }

    .translate-y-xl-n20, .translate-y-xl-n20-focus:focus, .translate-y-xl-n20-hover:hover {
        --x-translate-y: -5rem !important
    }

    .translate-y-xl-n24, .translate-y-xl-n24-focus:focus, .translate-y-xl-n24-hover:hover {
        --x-translate-y: -6rem !important
    }

    .translate-y-xl-n32, .translate-y-xl-n32-focus:focus, .translate-y-xl-n32-hover:hover {
        --x-translate-y: -8rem !important
    }

    .translate-y-xl-n40, .translate-y-xl-n40-focus:focus, .translate-y-xl-n40-hover:hover {
        --x-translate-y: -10rem !important
    }

    .translate-y-xl-n48, .translate-y-xl-n48-focus:focus, .translate-y-xl-n48-hover:hover {
        --x-translate-y: -12rem !important
    }

    .translate-y-xl-n56, .translate-y-xl-n56-focus:focus, .translate-y-xl-n56-hover:hover {
        --x-translate-y: -14rem !important
    }

    .translate-y-xl-n64, .translate-y-xl-n64-focus:focus, .translate-y-xl-n64-hover:hover {
        --x-translate-y: -16rem !important
    }

    .translate-y-xl-n72, .translate-y-xl-n72-focus:focus, .translate-y-xl-n72-hover:hover {
        --x-translate-y: -18rem !important
    }

    .translate-y-xl-n80, .translate-y-xl-n80-focus:focus, .translate-y-xl-n80-hover:hover {
        --x-translate-y: -20rem !important
    }

    .translate-y-xl-n88, .translate-y-xl-n88-focus:focus, .translate-y-xl-n88-hover:hover {
        --x-translate-y: -22rem !important
    }

    .translate-y-xl-n96, .translate-y-xl-n96-focus:focus, .translate-y-xl-n96-hover:hover {
        --x-translate-y: -24rem !important
    }

    .translate-y-xl-npx, .translate-y-xl-npx-focus:focus, .translate-y-xl-npx-hover:hover {
        --x-translate-y: -1px !important
    }

    .translate-y-xl-n1\/2, .translate-y-xl-n1\/2-focus:focus, .translate-y-xl-n1\/2-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-xl-n1\/3, .translate-y-xl-n1\/3-focus:focus, .translate-y-xl-n1\/3-hover:hover {
        --x-translate-y: -33.333333% !important
    }

    .translate-y-xl-n1\/4, .translate-y-xl-n1\/4-focus:focus, .translate-y-xl-n1\/4-hover:hover {
        --x-translate-y: -25% !important
    }

    .translate-y-xl-n1\/5, .translate-y-xl-n1\/5-focus:focus, .translate-y-xl-n1\/5-hover:hover {
        --x-translate-y: -20% !important
    }

    .translate-y-xl-n2\/3, .translate-y-xl-n2\/3-focus:focus, .translate-y-xl-n2\/3-hover:hover {
        --x-translate-y: -66.666667% !important
    }

    .translate-y-xl-n2\/5, .translate-y-xl-n2\/5-focus:focus, .translate-y-xl-n2\/5-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-xl-n3\/4, .translate-y-xl-n3\/4-focus:focus, .translate-y-xl-n3\/4-hover:hover {
        --x-translate-y: -75% !important
    }

    .translate-y-xl-nfull, .translate-y-xl-nfull-focus:focus, .translate-y-xl-nfull-hover:hover {
        --x-translate-y: -100% !important
    }

    .translate-x-xl-0, .translate-x-xl-0-focus:focus, .translate-x-xl-0-hover:hover {
        --x-translate-x: 0 !important
    }

    .translate-x-xl-1, .translate-x-xl-1-focus:focus, .translate-x-xl-1-hover:hover {
        --x-translate-x: 0.25rem !important
    }

    .translate-x-xl-2, .translate-x-xl-2-focus:focus, .translate-x-xl-2-hover:hover {
        --x-translate-x: 0.5rem !important
    }

    .translate-x-xl-3, .translate-x-xl-3-focus:focus, .translate-x-xl-3-hover:hover {
        --x-translate-x: 0.75rem !important
    }

    .translate-x-xl-4, .translate-x-xl-4-focus:focus, .translate-x-xl-4-hover:hover {
        --x-translate-x: 1rem !important
    }

    .translate-x-xl-5, .translate-x-xl-5-focus:focus, .translate-x-xl-5-hover:hover {
        --x-translate-x: 1.25rem !important
    }

    .translate-x-xl-6, .translate-x-xl-6-focus:focus, .translate-x-xl-6-hover:hover {
        --x-translate-x: 1.5rem !important
    }

    .translate-x-xl-7, .translate-x-xl-7-focus:focus, .translate-x-xl-7-hover:hover {
        --x-translate-x: 1.75rem !important
    }

    .translate-x-xl-8, .translate-x-xl-8-focus:focus, .translate-x-xl-8-hover:hover {
        --x-translate-x: 2rem !important
    }

    .translate-x-xl-10, .translate-x-xl-10-focus:focus, .translate-x-xl-10-hover:hover {
        --x-translate-x: 2.5rem !important
    }

    .translate-x-xl-12, .translate-x-xl-12-focus:focus, .translate-x-xl-12-hover:hover {
        --x-translate-x: 3rem !important
    }

    .translate-x-xl-14, .translate-x-xl-14-focus:focus, .translate-x-xl-14-hover:hover {
        --x-translate-x: 3.5rem !important
    }

    .translate-x-xl-16, .translate-x-xl-16-focus:focus, .translate-x-xl-16-hover:hover {
        --x-translate-x: 4rem !important
    }

    .translate-x-xl-18, .translate-x-xl-18-focus:focus, .translate-x-xl-18-hover:hover {
        --x-translate-x: 4.5rem !important
    }

    .translate-x-xl-20, .translate-x-xl-20-focus:focus, .translate-x-xl-20-hover:hover {
        --x-translate-x: 5rem !important
    }

    .translate-x-xl-24, .translate-x-xl-24-focus:focus, .translate-x-xl-24-hover:hover {
        --x-translate-x: 6rem !important
    }

    .translate-x-xl-32, .translate-x-xl-32-focus:focus, .translate-x-xl-32-hover:hover {
        --x-translate-x: 8rem !important
    }

    .translate-x-xl-40, .translate-x-xl-40-focus:focus, .translate-x-xl-40-hover:hover {
        --x-translate-x: 10rem !important
    }

    .translate-x-xl-48, .translate-x-xl-48-focus:focus, .translate-x-xl-48-hover:hover {
        --x-translate-x: 12rem !important
    }

    .translate-x-xl-56, .translate-x-xl-56-focus:focus, .translate-x-xl-56-hover:hover {
        --x-translate-x: 14rem !important
    }

    .translate-x-xl-64, .translate-x-xl-64-focus:focus, .translate-x-xl-64-hover:hover {
        --x-translate-x: 16rem !important
    }

    .translate-x-xl-72, .translate-x-xl-72-focus:focus, .translate-x-xl-72-hover:hover {
        --x-translate-x: 18rem !important
    }

    .translate-x-xl-80, .translate-x-xl-80-focus:focus, .translate-x-xl-80-hover:hover {
        --x-translate-x: 20rem !important
    }

    .translate-x-xl-88, .translate-x-xl-88-focus:focus, .translate-x-xl-88-hover:hover {
        --x-translate-x: 22rem !important
    }

    .translate-x-xl-96, .translate-x-xl-96-focus:focus, .translate-x-xl-96-hover:hover {
        --x-translate-x: 24rem !important
    }

    .translate-x-xl-px, .translate-x-xl-px-focus:focus, .translate-x-xl-px-hover:hover {
        --x-translate-x: 1px !important
    }

    .translate-x-xl-1\/2, .translate-x-xl-1\/2-focus:focus, .translate-x-xl-1\/2-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-xl-1\/3, .translate-x-xl-1\/3-focus:focus, .translate-x-xl-1\/3-hover:hover {
        --x-translate-x: 33.333333% !important
    }

    .translate-x-xl-1\/4, .translate-x-xl-1\/4-focus:focus, .translate-x-xl-1\/4-hover:hover {
        --x-translate-x: 25% !important
    }

    .translate-x-xl-1\/5, .translate-x-xl-1\/5-focus:focus, .translate-x-xl-1\/5-hover:hover {
        --x-translate-x: 20% !important
    }

    .translate-x-xl-2\/3, .translate-x-xl-2\/3-focus:focus, .translate-x-xl-2\/3-hover:hover {
        --x-translate-x: 66.666667% !important
    }

    .translate-x-xl-2\/5, .translate-x-xl-2\/5-focus:focus, .translate-x-xl-2\/5-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-xl-3\/4, .translate-x-xl-3\/4-focus:focus, .translate-x-xl-3\/4-hover:hover {
        --x-translate-x: 75% !important
    }

    .translate-x-xl-full, .translate-x-xl-full-focus:focus, .translate-x-xl-full-hover:hover {
        --x-translate-x: 100% !important
    }

    .translate-x-xl-n1, .translate-x-xl-n1-focus:focus, .translate-x-xl-n1-hover:hover {
        --x-translate-x: -0.25rem !important
    }

    .translate-x-xl-n2, .translate-x-xl-n2-focus:focus, .translate-x-xl-n2-hover:hover {
        --x-translate-x: -0.5rem !important
    }

    .translate-x-xl-n3, .translate-x-xl-n3-focus:focus, .translate-x-xl-n3-hover:hover {
        --x-translate-x: -0.75rem !important
    }

    .translate-x-xl-n4, .translate-x-xl-n4-focus:focus, .translate-x-xl-n4-hover:hover {
        --x-translate-x: -1rem !important
    }

    .translate-x-xl-n5, .translate-x-xl-n5-focus:focus, .translate-x-xl-n5-hover:hover {
        --x-translate-x: -1.25rem !important
    }

    .translate-x-xl-n6, .translate-x-xl-n6-focus:focus, .translate-x-xl-n6-hover:hover {
        --x-translate-x: -1.5rem !important
    }

    .translate-x-xl-n7, .translate-x-xl-n7-focus:focus, .translate-x-xl-n7-hover:hover {
        --x-translate-x: -1.75rem !important
    }

    .translate-x-xl-n8, .translate-x-xl-n8-focus:focus, .translate-x-xl-n8-hover:hover {
        --x-translate-x: -2rem !important
    }

    .translate-x-xl-n10, .translate-x-xl-n10-focus:focus, .translate-x-xl-n10-hover:hover {
        --x-translate-x: -2.5rem !important
    }

    .translate-x-xl-n12, .translate-x-xl-n12-focus:focus, .translate-x-xl-n12-hover:hover {
        --x-translate-x: -3rem !important
    }

    .translate-x-xl-n14, .translate-x-xl-n14-focus:focus, .translate-x-xl-n14-hover:hover {
        --x-translate-x: -3.5rem !important
    }

    .translate-x-xl-n16, .translate-x-xl-n16-focus:focus, .translate-x-xl-n16-hover:hover {
        --x-translate-x: -4rem !important
    }

    .translate-x-xl-n18, .translate-x-xl-n18-focus:focus, .translate-x-xl-n18-hover:hover {
        --x-translate-x: -4.5rem !important
    }

    .translate-x-xl-n20, .translate-x-xl-n20-focus:focus, .translate-x-xl-n20-hover:hover {
        --x-translate-x: -5rem !important
    }

    .translate-x-xl-n24, .translate-x-xl-n24-focus:focus, .translate-x-xl-n24-hover:hover {
        --x-translate-x: -6rem !important
    }

    .translate-x-xl-n32, .translate-x-xl-n32-focus:focus, .translate-x-xl-n32-hover:hover {
        --x-translate-x: -8rem !important
    }

    .translate-x-xl-n40, .translate-x-xl-n40-focus:focus, .translate-x-xl-n40-hover:hover {
        --x-translate-x: -10rem !important
    }

    .translate-x-xl-n48, .translate-x-xl-n48-focus:focus, .translate-x-xl-n48-hover:hover {
        --x-translate-x: -12rem !important
    }

    .translate-x-xl-n56, .translate-x-xl-n56-focus:focus, .translate-x-xl-n56-hover:hover {
        --x-translate-x: -14rem !important
    }

    .translate-x-xl-n64, .translate-x-xl-n64-focus:focus, .translate-x-xl-n64-hover:hover {
        --x-translate-x: -16rem !important
    }

    .translate-x-xl-n72, .translate-x-xl-n72-focus:focus, .translate-x-xl-n72-hover:hover {
        --x-translate-x: -18rem !important
    }

    .translate-x-xl-n80, .translate-x-xl-n80-focus:focus, .translate-x-xl-n80-hover:hover {
        --x-translate-x: -20rem !important
    }

    .translate-x-xl-n88, .translate-x-xl-n88-focus:focus, .translate-x-xl-n88-hover:hover {
        --x-translate-x: -22rem !important
    }

    .translate-x-xl-n96, .translate-x-xl-n96-focus:focus, .translate-x-xl-n96-hover:hover {
        --x-translate-x: -24rem !important
    }

    .translate-x-xl-npx, .translate-x-xl-npx-focus:focus, .translate-x-xl-npx-hover:hover {
        --x-translate-x: -1px !important
    }

    .translate-x-xl-n1\/2, .translate-x-xl-n1\/2-focus:focus, .translate-x-xl-n1\/2-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-xl-n1\/3, .translate-x-xl-n1\/3-focus:focus, .translate-x-xl-n1\/3-hover:hover {
        --x-translate-x: -33.333333% !important
    }

    .translate-x-xl-n1\/4, .translate-x-xl-n1\/4-focus:focus, .translate-x-xl-n1\/4-hover:hover {
        --x-translate-x: -25% !important
    }

    .translate-x-xl-n1\/5, .translate-x-xl-n1\/5-focus:focus, .translate-x-xl-n1\/5-hover:hover {
        --x-translate-x: -20% !important
    }

    .translate-x-xl-n2\/3, .translate-x-xl-n2\/3-focus:focus, .translate-x-xl-n2\/3-hover:hover {
        --x-translate-x: -66.666667% !important
    }

    .translate-x-xl-n2\/5, .translate-x-xl-n2\/5-focus:focus, .translate-x-xl-n2\/5-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-xl-n3\/4, .translate-x-xl-n3\/4-focus:focus, .translate-x-xl-n3\/4-hover:hover {
        --x-translate-x: -75% !important
    }

    .translate-x-xl-nfull, .translate-x-xl-nfull-focus:focus, .translate-x-xl-nfull-hover:hover {
        --x-translate-x: -100% !important
    }
}

@media (min-width:1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .overflow-xxl-auto {
        overflow: auto !important
    }

    .overflow-xxl-hidden {
        overflow: hidden !important
    }

    .overflow-xxl-visible {
        overflow: visible !important
    }

    .overflow-xxl-scroll {
        overflow: scroll !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .shadow-xxl, .shadow-xxl-focus:focus, .shadow-xxl-hover:hover {
        box-shadow: 0 3px 3px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xxl-1, .shadow-xxl-1-focus:focus, .shadow-xxl-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xxl-2, .shadow-xxl-2-focus:focus, .shadow-xxl-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xxl-3, .shadow-xxl-3-focus:focus, .shadow-xxl-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xxl-4, .shadow-xxl-4-focus:focus, .shadow-xxl-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xxl-5, .shadow-xxl-5-focus:focus, .shadow-xxl-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xxl-6, .shadow-xxl-6-focus:focus, .shadow-xxl-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.1),0 0 1px 0 rgba(10,22,70,.06) !important
    }

    .shadow-xxl-inset, .shadow-xxl-inset-focus:focus, .shadow-xxl-inset-hover:hover {
        box-shadow: inset 0 1px 2px rgba(0,0,0,.075) !important
    }

    .shadow-xxl-focus, .shadow-xxl-focus-focus:focus, .shadow-xxl-focus-hover:hover, .shadow-xxl-outline, .shadow-xxl-outline-focus:focus, .shadow-xxl-outline-hover:hover {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5) !important
    }

    .shadow-xxl-none, .shadow-xxl-none-focus:focus, .shadow-xxl-none-hover:hover {
        box-shadow: none !important
    }

    .position-xxl-static {
        position: static !important
    }

    .position-xxl-relative {
        position: relative !important
    }

    .position-xxl-absolute {
        position: absolute !important
    }

    .position-xxl-fixed {
        position: fixed !important
    }

    .position-xxl-sticky {
        position: -webkit-sticky !important;
        position: sticky !important
    }

    .top-xxl-0 {
        top: 0 !important
    }

    .top-xxl-1 {
        top: .25rem !important
    }

    .top-xxl-2 {
        top: .5rem !important
    }

    .top-xxl-3 {
        top: .75rem !important
    }

    .top-xxl-4 {
        top: 1rem !important
    }

    .top-xxl-5 {
        top: 1.25rem !important
    }

    .top-xxl-6 {
        top: 1.5rem !important
    }

    .top-xxl-7 {
        top: 1.75rem !important
    }

    .top-xxl-8 {
        top: 2rem !important
    }

    .top-xxl-10 {
        top: 2.5rem !important
    }

    .top-xxl-12 {
        top: 3rem !important
    }

    .top-xxl-14 {
        top: 3.5rem !important
    }

    .top-xxl-16 {
        top: 4rem !important
    }

    .top-xxl-18 {
        top: 4.5rem !important
    }

    .top-xxl-20 {
        top: 5rem !important
    }

    .top-xxl-24 {
        top: 6rem !important
    }

    .top-xxl-32 {
        top: 8rem !important
    }

    .top-xxl-40 {
        top: 10rem !important
    }

    .top-xxl-48 {
        top: 12rem !important
    }

    .top-xxl-56 {
        top: 14rem !important
    }

    .top-xxl-64 {
        top: 16rem !important
    }

    .top-xxl-72 {
        top: 18rem !important
    }

    .top-xxl-80 {
        top: 20rem !important
    }

    .top-xxl-88 {
        top: 22rem !important
    }

    .top-xxl-96 {
        top: 24rem !important
    }

    .top-xxl-px {
        top: 1px !important
    }

    .top-xxl-1\/2 {
        top: 50% !important
    }

    .top-xxl-full {
        top: 100% !important
    }

    .top-xxl-auto {
        top: auto !important
    }

    .bottom-xxl-0 {
        bottom: 0 !important
    }

    .bottom-xxl-1 {
        bottom: .25rem !important
    }

    .bottom-xxl-2 {
        bottom: .5rem !important
    }

    .bottom-xxl-3 {
        bottom: .75rem !important
    }

    .bottom-xxl-4 {
        bottom: 1rem !important
    }

    .bottom-xxl-5 {
        bottom: 1.25rem !important
    }

    .bottom-xxl-6 {
        bottom: 1.5rem !important
    }

    .bottom-xxl-7 {
        bottom: 1.75rem !important
    }

    .bottom-xxl-8 {
        bottom: 2rem !important
    }

    .bottom-xxl-10 {
        bottom: 2.5rem !important
    }

    .bottom-xxl-12 {
        bottom: 3rem !important
    }

    .bottom-xxl-14 {
        bottom: 3.5rem !important
    }

    .bottom-xxl-16 {
        bottom: 4rem !important
    }

    .bottom-xxl-18 {
        bottom: 4.5rem !important
    }

    .bottom-xxl-20 {
        bottom: 5rem !important
    }

    .bottom-xxl-24 {
        bottom: 6rem !important
    }

    .bottom-xxl-32 {
        bottom: 8rem !important
    }

    .bottom-xxl-40 {
        bottom: 10rem !important
    }

    .bottom-xxl-48 {
        bottom: 12rem !important
    }

    .bottom-xxl-56 {
        bottom: 14rem !important
    }

    .bottom-xxl-64 {
        bottom: 16rem !important
    }

    .bottom-xxl-72 {
        bottom: 18rem !important
    }

    .bottom-xxl-80 {
        bottom: 20rem !important
    }

    .bottom-xxl-88 {
        bottom: 22rem !important
    }

    .bottom-xxl-96 {
        bottom: 24rem !important
    }

    .bottom-xxl-px {
        bottom: 1px !important
    }

    .bottom-xxl-1\/2 {
        bottom: 50% !important
    }

    .bottom-xxl-full {
        bottom: 100% !important
    }

    .bottom-xxl-auto {
        bottom: auto !important
    }

    .start-xxl-0 {
        left: 0 !important
    }

    .start-xxl-1 {
        left: .25rem !important
    }

    .start-xxl-2 {
        left: .5rem !important
    }

    .start-xxl-3 {
        left: .75rem !important
    }

    .start-xxl-4 {
        left: 1rem !important
    }

    .start-xxl-5 {
        left: 1.25rem !important
    }

    .start-xxl-6 {
        left: 1.5rem !important
    }

    .start-xxl-7 {
        left: 1.75rem !important
    }

    .start-xxl-8 {
        left: 2rem !important
    }

    .start-xxl-10 {
        left: 2.5rem !important
    }

    .start-xxl-12 {
        left: 3rem !important
    }

    .start-xxl-14 {
        left: 3.5rem !important
    }

    .start-xxl-16 {
        left: 4rem !important
    }

    .start-xxl-18 {
        left: 4.5rem !important
    }

    .start-xxl-20 {
        left: 5rem !important
    }

    .start-xxl-24 {
        left: 6rem !important
    }

    .start-xxl-32 {
        left: 8rem !important
    }

    .start-xxl-40 {
        left: 10rem !important
    }

    .start-xxl-48 {
        left: 12rem !important
    }

    .start-xxl-56 {
        left: 14rem !important
    }

    .start-xxl-64 {
        left: 16rem !important
    }

    .start-xxl-72 {
        left: 18rem !important
    }

    .start-xxl-80 {
        left: 20rem !important
    }

    .start-xxl-88 {
        left: 22rem !important
    }

    .start-xxl-96 {
        left: 24rem !important
    }

    .start-xxl-px {
        left: 1px !important
    }

    .start-xxl-1\/2 {
        left: 50% !important
    }

    .start-xxl-full {
        left: 100% !important
    }

    .start-xxl-auto {
        left: auto !important
    }

    .end-xxl-0 {
        right: 0 !important
    }

    .end-xxl-1 {
        right: .25rem !important
    }

    .end-xxl-2 {
        right: .5rem !important
    }

    .end-xxl-3 {
        right: .75rem !important
    }

    .end-xxl-4 {
        right: 1rem !important
    }

    .end-xxl-5 {
        right: 1.25rem !important
    }

    .end-xxl-6 {
        right: 1.5rem !important
    }

    .end-xxl-7 {
        right: 1.75rem !important
    }

    .end-xxl-8 {
        right: 2rem !important
    }

    .end-xxl-10 {
        right: 2.5rem !important
    }

    .end-xxl-12 {
        right: 3rem !important
    }

    .end-xxl-14 {
        right: 3.5rem !important
    }

    .end-xxl-16 {
        right: 4rem !important
    }

    .end-xxl-18 {
        right: 4.5rem !important
    }

    .end-xxl-20 {
        right: 5rem !important
    }

    .end-xxl-24 {
        right: 6rem !important
    }

    .end-xxl-32 {
        right: 8rem !important
    }

    .end-xxl-40 {
        right: 10rem !important
    }

    .end-xxl-48 {
        right: 12rem !important
    }

    .end-xxl-56 {
        right: 14rem !important
    }

    .end-xxl-64 {
        right: 16rem !important
    }

    .end-xxl-72 {
        right: 18rem !important
    }

    .end-xxl-80 {
        right: 20rem !important
    }

    .end-xxl-88 {
        right: 22rem !important
    }

    .end-xxl-96 {
        right: 24rem !important
    }

    .end-xxl-px {
        right: 1px !important
    }

    .end-xxl-1\/2 {
        right: 50% !important
    }

    .end-xxl-full {
        right: 100% !important
    }

    .end-xxl-auto {
        right: auto !important
    }

    .border-top-xxl-0, .border-top-xxl-0-focus:focus, .border-top-xxl-0-hover:hover {
        border-top-width: 0 !important
    }

    .border-top-xxl, .border-top-xxl-focus:focus, .border-top-xxl-hover:hover {
        border-top-width: 1px !important
    }

    .border-top-xxl-2, .border-top-xxl-2-focus:focus, .border-top-xxl-2-hover:hover {
        border-top-width: 2px !important
    }

    .border-top-xxl-3, .border-top-xxl-3-focus:focus, .border-top-xxl-3-hover:hover {
        border-top-width: 3px !important
    }

    .border-top-xxl-4, .border-top-xxl-4-focus:focus, .border-top-xxl-4-hover:hover {
        border-top-width: 4px !important
    }

    .border-top-xxl-5, .border-top-xxl-5-focus:focus, .border-top-xxl-5-hover:hover {
        border-top-width: 5px !important
    }

    .border-top-xxl-6, .border-top-xxl-6-focus:focus, .border-top-xxl-6-hover:hover {
        border-top-width: 6px !important
    }

    .border-top-xxl-7, .border-top-xxl-7-focus:focus, .border-top-xxl-7-hover:hover {
        border-top-width: 7px !important
    }

    .border-top-xxl-8, .border-top-xxl-8-focus:focus, .border-top-xxl-8-hover:hover {
        border-top-width: 8px !important
    }

    .border-end-xxl-0, .border-end-xxl-0-focus:focus, .border-end-xxl-0-hover:hover {
        border-right-width: 0 !important
    }

    .border-end-xxl, .border-end-xxl-focus:focus, .border-end-xxl-hover:hover {
        border-right-width: 1px !important
    }

    .border-end-xxl-2, .border-end-xxl-2-focus:focus, .border-end-xxl-2-hover:hover {
        border-right-width: 2px !important
    }

    .border-end-xxl-3, .border-end-xxl-3-focus:focus, .border-end-xxl-3-hover:hover {
        border-right-width: 3px !important
    }

    .border-end-xxl-4, .border-end-xxl-4-focus:focus, .border-end-xxl-4-hover:hover {
        border-right-width: 4px !important
    }

    .border-end-xxl-5, .border-end-xxl-5-focus:focus, .border-end-xxl-5-hover:hover {
        border-right-width: 5px !important
    }

    .border-end-xxl-6, .border-end-xxl-6-focus:focus, .border-end-xxl-6-hover:hover {
        border-right-width: 6px !important
    }

    .border-end-xxl-7, .border-end-xxl-7-focus:focus, .border-end-xxl-7-hover:hover {
        border-right-width: 7px !important
    }

    .border-end-xxl-8, .border-end-xxl-8-focus:focus, .border-end-xxl-8-hover:hover {
        border-right-width: 8px !important
    }

    .border-bottom-xxl-0, .border-bottom-xxl-0-focus:focus, .border-bottom-xxl-0-hover:hover {
        border-bottom-width: 0 !important
    }

    .border-bottom-xxl, .border-bottom-xxl-focus:focus, .border-bottom-xxl-hover:hover {
        border-bottom-width: 1px !important
    }

    .border-bottom-xxl-2, .border-bottom-xxl-2-focus:focus, .border-bottom-xxl-2-hover:hover {
        border-bottom-width: 2px !important
    }

    .border-bottom-xxl-3, .border-bottom-xxl-3-focus:focus, .border-bottom-xxl-3-hover:hover {
        border-bottom-width: 3px !important
    }

    .border-bottom-xxl-4, .border-bottom-xxl-4-focus:focus, .border-bottom-xxl-4-hover:hover {
        border-bottom-width: 4px !important
    }

    .border-bottom-xxl-5, .border-bottom-xxl-5-focus:focus, .border-bottom-xxl-5-hover:hover {
        border-bottom-width: 5px !important
    }

    .border-bottom-xxl-6, .border-bottom-xxl-6-focus:focus, .border-bottom-xxl-6-hover:hover {
        border-bottom-width: 6px !important
    }

    .border-bottom-xxl-7, .border-bottom-xxl-7-focus:focus, .border-bottom-xxl-7-hover:hover {
        border-bottom-width: 7px !important
    }

    .border-bottom-xxl-8, .border-bottom-xxl-8-focus:focus, .border-bottom-xxl-8-hover:hover {
        border-bottom-width: 8px !important
    }

    .border-start-xxl-0, .border-start-xxl-0-focus:focus, .border-start-xxl-0-hover:hover {
        border-left-width: 0 !important
    }

    .border-start-xxl, .border-start-xxl-focus:focus, .border-start-xxl-hover:hover {
        border-left-width: 1px !important
    }

    .border-start-xxl-2, .border-start-xxl-2-focus:focus, .border-start-xxl-2-hover:hover {
        border-left-width: 2px !important
    }

    .border-start-xxl-3, .border-start-xxl-3-focus:focus, .border-start-xxl-3-hover:hover {
        border-left-width: 3px !important
    }

    .border-start-xxl-4, .border-start-xxl-4-focus:focus, .border-start-xxl-4-hover:hover {
        border-left-width: 4px !important
    }

    .border-start-xxl-5, .border-start-xxl-5-focus:focus, .border-start-xxl-5-hover:hover {
        border-left-width: 5px !important
    }

    .border-start-xxl-6, .border-start-xxl-6-focus:focus, .border-start-xxl-6-hover:hover {
        border-left-width: 6px !important
    }

    .border-start-xxl-7, .border-start-xxl-7-focus:focus, .border-start-xxl-7-hover:hover {
        border-left-width: 7px !important
    }

    .border-start-xxl-8, .border-start-xxl-8-focus:focus, .border-start-xxl-8-hover:hover {
        border-left-width: 8px !important
    }

    .border-xxl-primary, .border-xxl-primary-focus:focus, .border-xxl-primary-hover:hover {
        border-color: #5c60f5 !important
    }

    .border-xxl-primary-focus-within:focus-within {
        border-color: #5c60f5 !important
    }

    .border-xxl-secondary, .border-xxl-secondary-focus:focus, .border-xxl-secondary-hover:hover {
        border-color: #cfd6df !important
    }

    .border-xxl-secondary-focus-within:focus-within {
        border-color: #cfd6df !important
    }

    .border-xxl-tertiary, .border-xxl-tertiary-focus:focus, .border-xxl-tertiary-hover:hover {
        border-color: #ff579a !important
    }

    .border-xxl-tertiary-focus-within:focus-within {
        border-color: #ff579a !important
    }

    .border-xxl-success, .border-xxl-success-focus:focus, .border-xxl-success-hover:hover {
        border-color: #0c8 !important
    }

    .border-xxl-success-focus-within:focus-within {
        border-color: #0c8 !important
    }

    .border-xxl-info, .border-xxl-info-focus:focus, .border-xxl-info-hover:hover {
        border-color: #00d4ff !important
    }

    .border-xxl-info-focus-within:focus-within {
        border-color: #00d4ff !important
    }

    .border-xxl-warning, .border-xxl-warning-focus:focus, .border-xxl-warning-hover:hover {
        border-color: #ff8c00 !important
    }

    .border-xxl-warning-focus-within:focus-within {
        border-color: #ff8c00 !important
    }

    .border-xxl-danger, .border-xxl-danger-focus:focus, .border-xxl-danger-hover:hover {
        border-color: #f36 !important
    }

    .border-xxl-danger-focus-within:focus-within {
        border-color: #f36 !important
    }

    .border-xxl-white, .border-xxl-white-focus:focus, .border-xxl-white-hover:hover {
        border-color: #fff !important
    }

    .border-xxl-white-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xxl-light, .border-xxl-light-focus:focus, .border-xxl-light-hover:hover {
        border-color: #fff !important
    }

    .border-xxl-light-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xxl-dark, .border-xxl-dark-focus:focus, .border-xxl-dark-hover:hover {
        border-color: #16192c !important
    }

    .border-xxl-dark-focus-within:focus-within {
        border-color: #16192c !important
    }

    .border-xxl-transparent, .border-xxl-transparent-focus:focus, .border-xxl-transparent-hover:hover {
        border-color: transparent !important
    }

    .border-xxl-transparent-focus-within:focus-within {
        border-color: transparent !important
    }

    .border-xxl-current, .border-xxl-current-focus:focus, .border-xxl-current-hover:hover {
        border-color: currentColor !important
    }

    .border-xxl-current-focus-within:focus-within {
        border-color: currentColor !important
    }

    .border-xxl-base, .border-xxl-base-focus:focus, .border-xxl-base-hover:hover {
        border-color: #e7eaf0 !important
    }

    .border-xxl-base-focus-within:focus-within {
        border-color: #e7eaf0 !important
    }

    .border-xxl-body, .border-xxl-body-focus:focus, .border-xxl-body-hover:hover {
        border-color: #fff !important
    }

    .border-xxl-body-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xxl-card, .border-xxl-card-focus:focus, .border-xxl-card-hover:hover {
        border-color: #fff !important
    }

    .border-xxl-card-focus-within:focus-within {
        border-color: #fff !important
    }

    .border-xxl-black, .border-xxl-black-focus:focus, .border-xxl-black-hover:hover {
        border-color: #000 !important
    }

    .border-xxl-black-focus-within:focus-within {
        border-color: #000 !important
    }

    .border-xxl-0, .border-xxl-0-focus:focus, .border-xxl-0-hover:hover {
        border-width: 0 !important
    }

    .border-xxl, .border-xxl-focus:focus, .border-xxl-hover:hover {
        border-width: 1px !important
    }

    .border-xxl-2, .border-xxl-2-focus:focus, .border-xxl-2-hover:hover {
        border-width: 2px !important
    }

    .border-xxl-3, .border-xxl-3-focus:focus, .border-xxl-3-hover:hover {
        border-width: 3px !important
    }

    .border-xxl-4, .border-xxl-4-focus:focus, .border-xxl-4-hover:hover {
        border-width: 4px !important
    }

    .border-xxl-5, .border-xxl-5-focus:focus, .border-xxl-5-hover:hover {
        border-width: 5px !important
    }

    .border-xxl-6, .border-xxl-6-focus:focus, .border-xxl-6-hover:hover {
        border-width: 6px !important
    }

    .border-xxl-7, .border-xxl-7-focus:focus, .border-xxl-7-hover:hover {
        border-width: 7px !important
    }

    .border-xxl-8, .border-xxl-8-focus:focus, .border-xxl-8-hover:hover {
        border-width: 8px !important
    }

    .w-xxl-0 {
        width: 0 !important
    }

    .w-xxl-1 {
        width: .25rem !important
    }

    .w-xxl-2 {
        width: .5rem !important
    }

    .w-xxl-3 {
        width: .75rem !important
    }

    .w-xxl-4 {
        width: 1rem !important
    }

    .w-xxl-5 {
        width: 1.25rem !important
    }

    .w-xxl-6 {
        width: 1.5rem !important
    }

    .w-xxl-7 {
        width: 1.75rem !important
    }

    .w-xxl-8 {
        width: 2rem !important
    }

    .w-xxl-10 {
        width: 2.5rem !important
    }

    .w-xxl-12 {
        width: 3rem !important
    }

    .w-xxl-14 {
        width: 3.5rem !important
    }

    .w-xxl-16 {
        width: 4rem !important
    }

    .w-xxl-18 {
        width: 4.5rem !important
    }

    .w-xxl-20 {
        width: 5rem !important
    }

    .w-xxl-24 {
        width: 6rem !important
    }

    .w-xxl-32 {
        width: 8rem !important
    }

    .w-xxl-40 {
        width: 10rem !important
    }

    .w-xxl-48 {
        width: 12rem !important
    }

    .w-xxl-56 {
        width: 14rem !important
    }

    .w-xxl-64 {
        width: 16rem !important
    }

    .w-xxl-72 {
        width: 18rem !important
    }

    .w-xxl-80 {
        width: 20rem !important
    }

    .w-xxl-88 {
        width: 22rem !important
    }

    .w-xxl-96 {
        width: 24rem !important
    }

    .w-xxl-px {
        width: 1px !important
    }

    .w-xxl-1\/2 {
        width: 50% !important
    }

    .w-xxl-1\/3 {
        width: 33.3333333333% !important
    }

    .w-xxl-2\/3 {
        width: 66.6666666667% !important
    }

    .w-xxl-1\/4 {
        width: 25% !important
    }

    .w-xxl-2\/4 {
        width: 50% !important
    }

    .w-xxl-3\/4 {
        width: 75% !important
    }

    .w-xxl-1\/5 {
        width: 20% !important
    }

    .w-xxl-2\/5 {
        width: 40% !important
    }

    .w-xxl-3\/5 {
        width: 60% !important
    }

    .w-xxl-4\/5 {
        width: 80% !important
    }

    .w-xxl-1\/6 {
        width: 16.6666666667% !important
    }

    .w-xxl-2\/6 {
        width: 33.3333333333% !important
    }

    .w-xxl-3\/6 {
        width: 50% !important
    }

    .w-xxl-4\/6 {
        width: 66.6666666667% !important
    }

    .w-xxl-5\/6 {
        width: 83.3333333333% !important
    }

    .w-xxl-11\/10 {
        width: 110% !important
    }

    .w-xxl-12\/10 {
        width: 120% !important
    }

    .w-xxl-13\/10 {
        width: 130% !important
    }

    .w-xxl-14\/10 {
        width: 140% !important
    }

    .w-xxl-15\/10 {
        width: 150% !important
    }

    .w-xxl-screen-sm {
        width: 640px !important
    }

    .w-xxl-screen-md {
        width: 768px !important
    }

    .w-xxl-screen-lg {
        width: 1024px !important
    }

    .w-xxl-screen-xl {
        width: 1280px !important
    }

    .w-xxl-screen-xxl {
        width: 1536px !important
    }

    .w-xxl-auto {
        width: auto !important
    }

    .w-xxl-full {
        width: 100% !important
    }

    .w-xxl-screen {
        width: 100vw !important
    }

    .w-xxl-min {
        width: -webkit-min-content !important;
        width: -moz-min-content !important;
        width: min-content !important
    }

    .w-xxl-max {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important
    }

    .max-w-xxl-screen-sm {
        max-width: 640px !important
    }

    .max-w-xxl-screen-md {
        max-width: 768px !important
    }

    .max-w-xxl-screen-lg {
        max-width: 1024px !important
    }

    .max-w-xxl-screen-xl {
        max-width: 1280px !important
    }

    .max-w-xxl-screen-xxl {
        max-width: 1536px !important
    }

    .max-w-xxl-0 {
        max-width: 0 !important
    }

    .max-w-xxl-full {
        max-width: 100% !important
    }

    .max-w-xxl-read {
        max-width: 65ch !important
    }

    .max-w-xxl-min {
        max-width: -webkit-min-content !important;
        max-width: -moz-min-content !important;
        max-width: min-content !important
    }

    .max-w-xxl-max {
        max-width: -webkit-max-content !important;
        max-width: -moz-max-content !important;
        max-width: max-content !important
    }

    .h-xxl-0 {
        height: 0 !important
    }

    .h-xxl-1 {
        height: .25rem !important
    }

    .h-xxl-2 {
        height: .5rem !important
    }

    .h-xxl-3 {
        height: .75rem !important
    }

    .h-xxl-4 {
        height: 1rem !important
    }

    .h-xxl-5 {
        height: 1.25rem !important
    }

    .h-xxl-6 {
        height: 1.5rem !important
    }

    .h-xxl-7 {
        height: 1.75rem !important
    }

    .h-xxl-8 {
        height: 2rem !important
    }

    .h-xxl-10 {
        height: 2.5rem !important
    }

    .h-xxl-12 {
        height: 3rem !important
    }

    .h-xxl-14 {
        height: 3.5rem !important
    }

    .h-xxl-16 {
        height: 4rem !important
    }

    .h-xxl-18 {
        height: 4.5rem !important
    }

    .h-xxl-20 {
        height: 5rem !important
    }

    .h-xxl-24 {
        height: 6rem !important
    }

    .h-xxl-32 {
        height: 8rem !important
    }

    .h-xxl-40 {
        height: 10rem !important
    }

    .h-xxl-48 {
        height: 12rem !important
    }

    .h-xxl-56 {
        height: 14rem !important
    }

    .h-xxl-64 {
        height: 16rem !important
    }

    .h-xxl-72 {
        height: 18rem !important
    }

    .h-xxl-80 {
        height: 20rem !important
    }

    .h-xxl-88 {
        height: 22rem !important
    }

    .h-xxl-96 {
        height: 24rem !important
    }

    .h-xxl-px {
        height: 1px !important
    }

    .h-xxl-1\/2 {
        height: 50% !important
    }

    .h-xxl-1\/3 {
        height: 33.3333333333% !important
    }

    .h-xxl-2\/3 {
        height: 66.6666666667% !important
    }

    .h-xxl-1\/4 {
        height: 25% !important
    }

    .h-xxl-2\/4 {
        height: 50% !important
    }

    .h-xxl-3\/4 {
        height: 75% !important
    }

    .h-xxl-1\/5 {
        height: 20% !important
    }

    .h-xxl-2\/5 {
        height: 40% !important
    }

    .h-xxl-3\/5 {
        height: 60% !important
    }

    .h-xxl-4\/5 {
        height: 80% !important
    }

    .h-xxl-1\/6 {
        height: 16.6666666667% !important
    }

    .h-xxl-2\/6 {
        height: 33.3333333333% !important
    }

    .h-xxl-3\/6 {
        height: 50% !important
    }

    .h-xxl-4\/6 {
        height: 66.6666666667% !important
    }

    .h-xxl-5\/6 {
        height: 83.3333333333% !important
    }

    .h-xxl-11\/10 {
        height: 110% !important
    }

    .h-xxl-12\/10 {
        height: 120% !important
    }

    .h-xxl-13\/10 {
        height: 130% !important
    }

    .h-xxl-14\/10 {
        height: 140% !important
    }

    .h-xxl-15\/10 {
        height: 150% !important
    }

    .h-xxl-auto {
        height: auto !important
    }

    .h-xxl-full {
        height: 100% !important
    }

    .h-xxl-screen {
        height: 100vh !important
    }

    .max-h-xxl-0 {
        max-height: 0 !important
    }

    .max-h-xxl-1 {
        max-height: .25rem !important
    }

    .max-h-xxl-2 {
        max-height: .5rem !important
    }

    .max-h-xxl-3 {
        max-height: .75rem !important
    }

    .max-h-xxl-4 {
        max-height: 1rem !important
    }

    .max-h-xxl-5 {
        max-height: 1.25rem !important
    }

    .max-h-xxl-6 {
        max-height: 1.5rem !important
    }

    .max-h-xxl-7 {
        max-height: 1.75rem !important
    }

    .max-h-xxl-8 {
        max-height: 2rem !important
    }

    .max-h-xxl-10 {
        max-height: 2.5rem !important
    }

    .max-h-xxl-12 {
        max-height: 3rem !important
    }

    .max-h-xxl-14 {
        max-height: 3.5rem !important
    }

    .max-h-xxl-16 {
        max-height: 4rem !important
    }

    .max-h-xxl-18 {
        max-height: 4.5rem !important
    }

    .max-h-xxl-20 {
        max-height: 5rem !important
    }

    .max-h-xxl-24 {
        max-height: 6rem !important
    }

    .max-h-xxl-32 {
        max-height: 8rem !important
    }

    .max-h-xxl-40 {
        max-height: 10rem !important
    }

    .max-h-xxl-48 {
        max-height: 12rem !important
    }

    .max-h-xxl-56 {
        max-height: 14rem !important
    }

    .max-h-xxl-64 {
        max-height: 16rem !important
    }

    .max-h-xxl-72 {
        max-height: 18rem !important
    }

    .max-h-xxl-80 {
        max-height: 20rem !important
    }

    .max-h-xxl-88 {
        max-height: 22rem !important
    }

    .max-h-xxl-96 {
        max-height: 24rem !important
    }

    .max-h-xxl-px {
        max-height: 1px !important
    }

    .max-h-xxl-full {
        max-height: 100% !important
    }

    .max-h-xxl-screen {
        max-height: 100vh !important
    }

    .flex-xxl-1 {
        flex: 1 1 0% !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-initial {
        flex: 0 1 auto !important
    }

    .flex-xxl-none {
        flex: none !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: .75rem !important
    }

    .gap-xxl-4 {
        gap: 1rem !important
    }

    .gap-xxl-5 {
        gap: 1.25rem !important
    }

    .gap-xxl-6 {
        gap: 1.5rem !important
    }

    .gap-xxl-7 {
        gap: 1.75rem !important
    }

    .gap-xxl-8 {
        gap: 2rem !important
    }

    .gap-xxl-10 {
        gap: 2.5rem !important
    }

    .gap-xxl-12 {
        gap: 3rem !important
    }

    .gap-xxl-14 {
        gap: 3.5rem !important
    }

    .gap-xxl-16 {
        gap: 4rem !important
    }

    .gap-xxl-18 {
        gap: 4.5rem !important
    }

    .gap-xxl-20 {
        gap: 5rem !important
    }

    .gap-xxl-24 {
        gap: 6rem !important
    }

    .gap-xxl-32 {
        gap: 8rem !important
    }

    .gap-xxl-40 {
        gap: 10rem !important
    }

    .gap-xxl-48 {
        gap: 12rem !important
    }

    .gap-xxl-56 {
        gap: 14rem !important
    }

    .gap-xxl-64 {
        gap: 16rem !important
    }

    .gap-xxl-72 {
        gap: 18rem !important
    }

    .gap-xxl-80 {
        gap: 20rem !important
    }

    .gap-xxl-88 {
        gap: 22rem !important
    }

    .gap-xxl-96 {
        gap: 24rem !important
    }

    .gap-xxl-px {
        gap: 1px !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-evenly {
        align-content: space-evenly !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: .75rem !important
    }

    .m-xxl-4 {
        margin: 1rem !important
    }

    .m-xxl-5 {
        margin: 1.25rem !important
    }

    .m-xxl-6 {
        margin: 1.5rem !important
    }

    .m-xxl-7 {
        margin: 1.75rem !important
    }

    .m-xxl-8 {
        margin: 2rem !important
    }

    .m-xxl-10 {
        margin: 2.5rem !important
    }

    .m-xxl-12 {
        margin: 3rem !important
    }

    .m-xxl-14 {
        margin: 3.5rem !important
    }

    .m-xxl-16 {
        margin: 4rem !important
    }

    .m-xxl-18 {
        margin: 4.5rem !important
    }

    .m-xxl-20 {
        margin: 5rem !important
    }

    .m-xxl-24 {
        margin: 6rem !important
    }

    .m-xxl-32 {
        margin: 8rem !important
    }

    .m-xxl-40 {
        margin: 10rem !important
    }

    .m-xxl-48 {
        margin: 12rem !important
    }

    .m-xxl-56 {
        margin: 14rem !important
    }

    .m-xxl-64 {
        margin: 16rem !important
    }

    .m-xxl-72 {
        margin: 18rem !important
    }

    .m-xxl-80 {
        margin: 20rem !important
    }

    .m-xxl-88 {
        margin: 22rem !important
    }

    .m-xxl-96 {
        margin: 24rem !important
    }

    .m-xxl-px {
        margin: 1px !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .mx-xxl-1 {
        margin-left: .25rem !important;
        margin-right: .25rem !important
    }

    .mx-xxl-2 {
        margin-left: .5rem !important;
        margin-right: .5rem !important
    }

    .mx-xxl-3 {
        margin-left: .75rem !important;
        margin-right: .75rem !important
    }

    .mx-xxl-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important
    }

    .mx-xxl-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important
    }

    .mx-xxl-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important
    }

    .mx-xxl-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important
    }

    .mx-xxl-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important
    }

    .mx-xxl-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important
    }

    .mx-xxl-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important
    }

    .mx-xxl-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important
    }

    .mx-xxl-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important
    }

    .mx-xxl-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important
    }

    .mx-xxl-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important
    }

    .mx-xxl-24 {
        margin-left: 6rem !important;
        margin-right: 6rem !important
    }

    .mx-xxl-32 {
        margin-left: 8rem !important;
        margin-right: 8rem !important
    }

    .mx-xxl-40 {
        margin-left: 10rem !important;
        margin-right: 10rem !important
    }

    .mx-xxl-48 {
        margin-left: 12rem !important;
        margin-right: 12rem !important
    }

    .mx-xxl-56 {
        margin-left: 14rem !important;
        margin-right: 14rem !important
    }

    .mx-xxl-64 {
        margin-left: 16rem !important;
        margin-right: 16rem !important
    }

    .mx-xxl-72 {
        margin-left: 18rem !important;
        margin-right: 18rem !important
    }

    .mx-xxl-80 {
        margin-left: 20rem !important;
        margin-right: 20rem !important
    }

    .mx-xxl-88 {
        margin-left: 22rem !important;
        margin-right: 22rem !important
    }

    .mx-xxl-96 {
        margin-left: 24rem !important;
        margin-right: 24rem !important
    }

    .mx-xxl-px {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mx-xxl-auto {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .my-xxl-0 {
        margin-bottom: 0 !important;
        margin-top: 0 !important
    }

    .my-xxl-1 {
        margin-bottom: .25rem !important;
        margin-top: .25rem !important
    }

    .my-xxl-2 {
        margin-bottom: .5rem !important;
        margin-top: .5rem !important
    }

    .my-xxl-3 {
        margin-bottom: .75rem !important;
        margin-top: .75rem !important
    }

    .my-xxl-4 {
        margin-bottom: 1rem !important;
        margin-top: 1rem !important
    }

    .my-xxl-5 {
        margin-bottom: 1.25rem !important;
        margin-top: 1.25rem !important
    }

    .my-xxl-6 {
        margin-bottom: 1.5rem !important;
        margin-top: 1.5rem !important
    }

    .my-xxl-7 {
        margin-bottom: 1.75rem !important;
        margin-top: 1.75rem !important
    }

    .my-xxl-8 {
        margin-bottom: 2rem !important;
        margin-top: 2rem !important
    }

    .my-xxl-10 {
        margin-bottom: 2.5rem !important;
        margin-top: 2.5rem !important
    }

    .my-xxl-12 {
        margin-bottom: 3rem !important;
        margin-top: 3rem !important
    }

    .my-xxl-14 {
        margin-bottom: 3.5rem !important;
        margin-top: 3.5rem !important
    }

    .my-xxl-16 {
        margin-bottom: 4rem !important;
        margin-top: 4rem !important
    }

    .my-xxl-18 {
        margin-bottom: 4.5rem !important;
        margin-top: 4.5rem !important
    }

    .my-xxl-20 {
        margin-bottom: 5rem !important;
        margin-top: 5rem !important
    }

    .my-xxl-24 {
        margin-bottom: 6rem !important;
        margin-top: 6rem !important
    }

    .my-xxl-32 {
        margin-bottom: 8rem !important;
        margin-top: 8rem !important
    }

    .my-xxl-40 {
        margin-bottom: 10rem !important;
        margin-top: 10rem !important
    }

    .my-xxl-48 {
        margin-bottom: 12rem !important;
        margin-top: 12rem !important
    }

    .my-xxl-56 {
        margin-bottom: 14rem !important;
        margin-top: 14rem !important
    }

    .my-xxl-64 {
        margin-bottom: 16rem !important;
        margin-top: 16rem !important
    }

    .my-xxl-72 {
        margin-bottom: 18rem !important;
        margin-top: 18rem !important
    }

    .my-xxl-80 {
        margin-bottom: 20rem !important;
        margin-top: 20rem !important
    }

    .my-xxl-88 {
        margin-bottom: 22rem !important;
        margin-top: 22rem !important
    }

    .my-xxl-96 {
        margin-bottom: 24rem !important;
        margin-top: 24rem !important
    }

    .my-xxl-px {
        margin-bottom: 1px !important;
        margin-top: 1px !important
    }

    .my-xxl-auto {
        margin-bottom: auto !important;
        margin-top: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: .75rem !important
    }

    .mt-xxl-4 {
        margin-top: 1rem !important
    }

    .mt-xxl-5 {
        margin-top: 1.25rem !important
    }

    .mt-xxl-6 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-7 {
        margin-top: 1.75rem !important
    }

    .mt-xxl-8 {
        margin-top: 2rem !important
    }

    .mt-xxl-10 {
        margin-top: 2.5rem !important
    }

    .mt-xxl-12 {
        margin-top: 3rem !important
    }

    .mt-xxl-14 {
        margin-top: 3.5rem !important
    }

    .mt-xxl-16 {
        margin-top: 4rem !important
    }

    .mt-xxl-18 {
        margin-top: 4.5rem !important
    }

    .mt-xxl-20 {
        margin-top: 5rem !important
    }

    .mt-xxl-24 {
        margin-top: 6rem !important
    }

    .mt-xxl-32 {
        margin-top: 8rem !important
    }

    .mt-xxl-40 {
        margin-top: 10rem !important
    }

    .mt-xxl-48 {
        margin-top: 12rem !important
    }

    .mt-xxl-56 {
        margin-top: 14rem !important
    }

    .mt-xxl-64 {
        margin-top: 16rem !important
    }

    .mt-xxl-72 {
        margin-top: 18rem !important
    }

    .mt-xxl-80 {
        margin-top: 20rem !important
    }

    .mt-xxl-88 {
        margin-top: 22rem !important
    }

    .mt-xxl-96 {
        margin-top: 24rem !important
    }

    .mt-xxl-px {
        margin-top: 1px !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: .75rem !important
    }

    .me-xxl-4 {
        margin-right: 1rem !important
    }

    .me-xxl-5 {
        margin-right: 1.25rem !important
    }

    .me-xxl-6 {
        margin-right: 1.5rem !important
    }

    .me-xxl-7 {
        margin-right: 1.75rem !important
    }

    .me-xxl-8 {
        margin-right: 2rem !important
    }

    .me-xxl-10 {
        margin-right: 2.5rem !important
    }

    .me-xxl-12 {
        margin-right: 3rem !important
    }

    .me-xxl-14 {
        margin-right: 3.5rem !important
    }

    .me-xxl-16 {
        margin-right: 4rem !important
    }

    .me-xxl-18 {
        margin-right: 4.5rem !important
    }

    .me-xxl-20 {
        margin-right: 5rem !important
    }

    .me-xxl-24 {
        margin-right: 6rem !important
    }

    .me-xxl-32 {
        margin-right: 8rem !important
    }

    .me-xxl-40 {
        margin-right: 10rem !important
    }

    .me-xxl-48 {
        margin-right: 12rem !important
    }

    .me-xxl-56 {
        margin-right: 14rem !important
    }

    .me-xxl-64 {
        margin-right: 16rem !important
    }

    .me-xxl-72 {
        margin-right: 18rem !important
    }

    .me-xxl-80 {
        margin-right: 20rem !important
    }

    .me-xxl-88 {
        margin-right: 22rem !important
    }

    .me-xxl-96 {
        margin-right: 24rem !important
    }

    .me-xxl-px {
        margin-right: 1px !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: .75rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 1.25rem !important
    }

    .mb-xxl-6 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-7 {
        margin-bottom: 1.75rem !important
    }

    .mb-xxl-8 {
        margin-bottom: 2rem !important
    }

    .mb-xxl-10 {
        margin-bottom: 2.5rem !important
    }

    .mb-xxl-12 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-14 {
        margin-bottom: 3.5rem !important
    }

    .mb-xxl-16 {
        margin-bottom: 4rem !important
    }

    .mb-xxl-18 {
        margin-bottom: 4.5rem !important
    }

    .mb-xxl-20 {
        margin-bottom: 5rem !important
    }

    .mb-xxl-24 {
        margin-bottom: 6rem !important
    }

    .mb-xxl-32 {
        margin-bottom: 8rem !important
    }

    .mb-xxl-40 {
        margin-bottom: 10rem !important
    }

    .mb-xxl-48 {
        margin-bottom: 12rem !important
    }

    .mb-xxl-56 {
        margin-bottom: 14rem !important
    }

    .mb-xxl-64 {
        margin-bottom: 16rem !important
    }

    .mb-xxl-72 {
        margin-bottom: 18rem !important
    }

    .mb-xxl-80 {
        margin-bottom: 20rem !important
    }

    .mb-xxl-88 {
        margin-bottom: 22rem !important
    }

    .mb-xxl-96 {
        margin-bottom: 24rem !important
    }

    .mb-xxl-px {
        margin-bottom: 1px !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: .75rem !important
    }

    .ms-xxl-4 {
        margin-left: 1rem !important
    }

    .ms-xxl-5 {
        margin-left: 1.25rem !important
    }

    .ms-xxl-6 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-7 {
        margin-left: 1.75rem !important
    }

    .ms-xxl-8 {
        margin-left: 2rem !important
    }

    .ms-xxl-10 {
        margin-left: 2.5rem !important
    }

    .ms-xxl-12 {
        margin-left: 3rem !important
    }

    .ms-xxl-14 {
        margin-left: 3.5rem !important
    }

    .ms-xxl-16 {
        margin-left: 4rem !important
    }

    .ms-xxl-18 {
        margin-left: 4.5rem !important
    }

    .ms-xxl-20 {
        margin-left: 5rem !important
    }

    .ms-xxl-24 {
        margin-left: 6rem !important
    }

    .ms-xxl-32 {
        margin-left: 8rem !important
    }

    .ms-xxl-40 {
        margin-left: 10rem !important
    }

    .ms-xxl-48 {
        margin-left: 12rem !important
    }

    .ms-xxl-56 {
        margin-left: 14rem !important
    }

    .ms-xxl-64 {
        margin-left: 16rem !important
    }

    .ms-xxl-72 {
        margin-left: 18rem !important
    }

    .ms-xxl-80 {
        margin-left: 20rem !important
    }

    .ms-xxl-88 {
        margin-left: 22rem !important
    }

    .ms-xxl-96 {
        margin-left: 24rem !important
    }

    .ms-xxl-px {
        margin-left: 1px !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .m-xxl-n1 {
        margin: -.25rem !important
    }

    .m-xxl-n2 {
        margin: -.5rem !important
    }

    .m-xxl-n3 {
        margin: -.75rem !important
    }

    .m-xxl-n4 {
        margin: -1rem !important
    }

    .m-xxl-n5 {
        margin: -1.25rem !important
    }

    .m-xxl-n6 {
        margin: -1.5rem !important
    }

    .m-xxl-n7 {
        margin: -1.75rem !important
    }

    .m-xxl-n8 {
        margin: -2rem !important
    }

    .m-xxl-n10 {
        margin: -2.5rem !important
    }

    .m-xxl-n12 {
        margin: -3rem !important
    }

    .m-xxl-n14 {
        margin: -3.5rem !important
    }

    .m-xxl-n16 {
        margin: -4rem !important
    }

    .m-xxl-n18 {
        margin: -4.5rem !important
    }

    .m-xxl-n20 {
        margin: -5rem !important
    }

    .m-xxl-n24 {
        margin: -6rem !important
    }

    .m-xxl-n32 {
        margin: -8rem !important
    }

    .m-xxl-n40 {
        margin: -10rem !important
    }

    .m-xxl-n48 {
        margin: -12rem !important
    }

    .m-xxl-n56 {
        margin: -14rem !important
    }

    .m-xxl-n64 {
        margin: -16rem !important
    }

    .m-xxl-n72 {
        margin: -18rem !important
    }

    .m-xxl-n80 {
        margin: -20rem !important
    }

    .m-xxl-n88 {
        margin: -22rem !important
    }

    .m-xxl-n96 {
        margin: -24rem !important
    }

    .m-xxl-npx {
        margin: -1px !important
    }

    .mx-xxl-n1 {
        margin-left: -.25rem !important;
        margin-right: -.25rem !important
    }

    .mx-xxl-n2 {
        margin-left: -.5rem !important;
        margin-right: -.5rem !important
    }

    .mx-xxl-n3 {
        margin-left: -.75rem !important;
        margin-right: -.75rem !important
    }

    .mx-xxl-n4 {
        margin-left: -1rem !important;
        margin-right: -1rem !important
    }

    .mx-xxl-n5 {
        margin-left: -1.25rem !important;
        margin-right: -1.25rem !important
    }

    .mx-xxl-n6 {
        margin-left: -1.5rem !important;
        margin-right: -1.5rem !important
    }

    .mx-xxl-n7 {
        margin-left: -1.75rem !important;
        margin-right: -1.75rem !important
    }

    .mx-xxl-n8 {
        margin-left: -2rem !important;
        margin-right: -2rem !important
    }

    .mx-xxl-n10 {
        margin-left: -2.5rem !important;
        margin-right: -2.5rem !important
    }

    .mx-xxl-n12 {
        margin-left: -3rem !important;
        margin-right: -3rem !important
    }

    .mx-xxl-n14 {
        margin-left: -3.5rem !important;
        margin-right: -3.5rem !important
    }

    .mx-xxl-n16 {
        margin-left: -4rem !important;
        margin-right: -4rem !important
    }

    .mx-xxl-n18 {
        margin-left: -4.5rem !important;
        margin-right: -4.5rem !important
    }

    .mx-xxl-n20 {
        margin-left: -5rem !important;
        margin-right: -5rem !important
    }

    .mx-xxl-n24 {
        margin-left: -6rem !important;
        margin-right: -6rem !important
    }

    .mx-xxl-n32 {
        margin-left: -8rem !important;
        margin-right: -8rem !important
    }

    .mx-xxl-n40 {
        margin-left: -10rem !important;
        margin-right: -10rem !important
    }

    .mx-xxl-n48 {
        margin-left: -12rem !important;
        margin-right: -12rem !important
    }

    .mx-xxl-n56 {
        margin-left: -14rem !important;
        margin-right: -14rem !important
    }

    .mx-xxl-n64 {
        margin-left: -16rem !important;
        margin-right: -16rem !important
    }

    .mx-xxl-n72 {
        margin-left: -18rem !important;
        margin-right: -18rem !important
    }

    .mx-xxl-n80 {
        margin-left: -20rem !important;
        margin-right: -20rem !important
    }

    .mx-xxl-n88 {
        margin-left: -22rem !important;
        margin-right: -22rem !important
    }

    .mx-xxl-n96 {
        margin-left: -24rem !important;
        margin-right: -24rem !important
    }

    .mx-xxl-npx {
        margin-left: -1px !important;
        margin-right: -1px !important
    }

    .my-xxl-n1 {
        margin-bottom: -.25rem !important;
        margin-top: -.25rem !important
    }

    .my-xxl-n2 {
        margin-bottom: -.5rem !important;
        margin-top: -.5rem !important
    }

    .my-xxl-n3 {
        margin-bottom: -.75rem !important;
        margin-top: -.75rem !important
    }

    .my-xxl-n4 {
        margin-bottom: -1rem !important;
        margin-top: -1rem !important
    }

    .my-xxl-n5 {
        margin-bottom: -1.25rem !important;
        margin-top: -1.25rem !important
    }

    .my-xxl-n6 {
        margin-bottom: -1.5rem !important;
        margin-top: -1.5rem !important
    }

    .my-xxl-n7 {
        margin-bottom: -1.75rem !important;
        margin-top: -1.75rem !important
    }

    .my-xxl-n8 {
        margin-bottom: -2rem !important;
        margin-top: -2rem !important
    }

    .my-xxl-n10 {
        margin-bottom: -2.5rem !important;
        margin-top: -2.5rem !important
    }

    .my-xxl-n12 {
        margin-bottom: -3rem !important;
        margin-top: -3rem !important
    }

    .my-xxl-n14 {
        margin-bottom: -3.5rem !important;
        margin-top: -3.5rem !important
    }

    .my-xxl-n16 {
        margin-bottom: -4rem !important;
        margin-top: -4rem !important
    }

    .my-xxl-n18 {
        margin-bottom: -4.5rem !important;
        margin-top: -4.5rem !important
    }

    .my-xxl-n20 {
        margin-bottom: -5rem !important;
        margin-top: -5rem !important
    }

    .my-xxl-n24 {
        margin-bottom: -6rem !important;
        margin-top: -6rem !important
    }

    .my-xxl-n32 {
        margin-bottom: -8rem !important;
        margin-top: -8rem !important
    }

    .my-xxl-n40 {
        margin-bottom: -10rem !important;
        margin-top: -10rem !important
    }

    .my-xxl-n48 {
        margin-bottom: -12rem !important;
        margin-top: -12rem !important
    }

    .my-xxl-n56 {
        margin-bottom: -14rem !important;
        margin-top: -14rem !important
    }

    .my-xxl-n64 {
        margin-bottom: -16rem !important;
        margin-top: -16rem !important
    }

    .my-xxl-n72 {
        margin-bottom: -18rem !important;
        margin-top: -18rem !important
    }

    .my-xxl-n80 {
        margin-bottom: -20rem !important;
        margin-top: -20rem !important
    }

    .my-xxl-n88 {
        margin-bottom: -22rem !important;
        margin-top: -22rem !important
    }

    .my-xxl-n96 {
        margin-bottom: -24rem !important;
        margin-top: -24rem !important
    }

    .my-xxl-npx {
        margin-bottom: -1px !important;
        margin-top: -1px !important
    }

    .mt-xxl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xxl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xxl-n3 {
        margin-top: -.75rem !important
    }

    .mt-xxl-n4 {
        margin-top: -1rem !important
    }

    .mt-xxl-n5 {
        margin-top: -1.25rem !important
    }

    .mt-xxl-n6 {
        margin-top: -1.5rem !important
    }

    .mt-xxl-n7 {
        margin-top: -1.75rem !important
    }

    .mt-xxl-n8 {
        margin-top: -2rem !important
    }

    .mt-xxl-n10 {
        margin-top: -2.5rem !important
    }

    .mt-xxl-n12 {
        margin-top: -3rem !important
    }

    .mt-xxl-n14 {
        margin-top: -3.5rem !important
    }

    .mt-xxl-n16 {
        margin-top: -4rem !important
    }

    .mt-xxl-n18 {
        margin-top: -4.5rem !important
    }

    .mt-xxl-n20 {
        margin-top: -5rem !important
    }

    .mt-xxl-n24 {
        margin-top: -6rem !important
    }

    .mt-xxl-n32 {
        margin-top: -8rem !important
    }

    .mt-xxl-n40 {
        margin-top: -10rem !important
    }

    .mt-xxl-n48 {
        margin-top: -12rem !important
    }

    .mt-xxl-n56 {
        margin-top: -14rem !important
    }

    .mt-xxl-n64 {
        margin-top: -16rem !important
    }

    .mt-xxl-n72 {
        margin-top: -18rem !important
    }

    .mt-xxl-n80 {
        margin-top: -20rem !important
    }

    .mt-xxl-n88 {
        margin-top: -22rem !important
    }

    .mt-xxl-n96 {
        margin-top: -24rem !important
    }

    .mt-xxl-npx {
        margin-top: -1px !important
    }

    .me-xxl-n1 {
        margin-right: -.25rem !important
    }

    .me-xxl-n2 {
        margin-right: -.5rem !important
    }

    .me-xxl-n3 {
        margin-right: -.75rem !important
    }

    .me-xxl-n4 {
        margin-right: -1rem !important
    }

    .me-xxl-n5 {
        margin-right: -1.25rem !important
    }

    .me-xxl-n6 {
        margin-right: -1.5rem !important
    }

    .me-xxl-n7 {
        margin-right: -1.75rem !important
    }

    .me-xxl-n8 {
        margin-right: -2rem !important
    }

    .me-xxl-n10 {
        margin-right: -2.5rem !important
    }

    .me-xxl-n12 {
        margin-right: -3rem !important
    }

    .me-xxl-n14 {
        margin-right: -3.5rem !important
    }

    .me-xxl-n16 {
        margin-right: -4rem !important
    }

    .me-xxl-n18 {
        margin-right: -4.5rem !important
    }

    .me-xxl-n20 {
        margin-right: -5rem !important
    }

    .me-xxl-n24 {
        margin-right: -6rem !important
    }

    .me-xxl-n32 {
        margin-right: -8rem !important
    }

    .me-xxl-n40 {
        margin-right: -10rem !important
    }

    .me-xxl-n48 {
        margin-right: -12rem !important
    }

    .me-xxl-n56 {
        margin-right: -14rem !important
    }

    .me-xxl-n64 {
        margin-right: -16rem !important
    }

    .me-xxl-n72 {
        margin-right: -18rem !important
    }

    .me-xxl-n80 {
        margin-right: -20rem !important
    }

    .me-xxl-n88 {
        margin-right: -22rem !important
    }

    .me-xxl-n96 {
        margin-right: -24rem !important
    }

    .me-xxl-npx {
        margin-right: -1px !important
    }

    .mb-xxl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xxl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xxl-n3 {
        margin-bottom: -.75rem !important
    }

    .mb-xxl-n4 {
        margin-bottom: -1rem !important
    }

    .mb-xxl-n5 {
        margin-bottom: -1.25rem !important
    }

    .mb-xxl-n6 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxl-n7 {
        margin-bottom: -1.75rem !important
    }

    .mb-xxl-n8 {
        margin-bottom: -2rem !important
    }

    .mb-xxl-n10 {
        margin-bottom: -2.5rem !important
    }

    .mb-xxl-n12 {
        margin-bottom: -3rem !important
    }

    .mb-xxl-n14 {
        margin-bottom: -3.5rem !important
    }

    .mb-xxl-n16 {
        margin-bottom: -4rem !important
    }

    .mb-xxl-n18 {
        margin-bottom: -4.5rem !important
    }

    .mb-xxl-n20 {
        margin-bottom: -5rem !important
    }

    .mb-xxl-n24 {
        margin-bottom: -6rem !important
    }

    .mb-xxl-n32 {
        margin-bottom: -8rem !important
    }

    .mb-xxl-n40 {
        margin-bottom: -10rem !important
    }

    .mb-xxl-n48 {
        margin-bottom: -12rem !important
    }

    .mb-xxl-n56 {
        margin-bottom: -14rem !important
    }

    .mb-xxl-n64 {
        margin-bottom: -16rem !important
    }

    .mb-xxl-n72 {
        margin-bottom: -18rem !important
    }

    .mb-xxl-n80 {
        margin-bottom: -20rem !important
    }

    .mb-xxl-n88 {
        margin-bottom: -22rem !important
    }

    .mb-xxl-n96 {
        margin-bottom: -24rem !important
    }

    .mb-xxl-npx {
        margin-bottom: -1px !important
    }

    .ms-xxl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xxl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xxl-n3 {
        margin-left: -.75rem !important
    }

    .ms-xxl-n4 {
        margin-left: -1rem !important
    }

    .ms-xxl-n5 {
        margin-left: -1.25rem !important
    }

    .ms-xxl-n6 {
        margin-left: -1.5rem !important
    }

    .ms-xxl-n7 {
        margin-left: -1.75rem !important
    }

    .ms-xxl-n8 {
        margin-left: -2rem !important
    }

    .ms-xxl-n10 {
        margin-left: -2.5rem !important
    }

    .ms-xxl-n12 {
        margin-left: -3rem !important
    }

    .ms-xxl-n14 {
        margin-left: -3.5rem !important
    }

    .ms-xxl-n16 {
        margin-left: -4rem !important
    }

    .ms-xxl-n18 {
        margin-left: -4.5rem !important
    }

    .ms-xxl-n20 {
        margin-left: -5rem !important
    }

    .ms-xxl-n24 {
        margin-left: -6rem !important
    }

    .ms-xxl-n32 {
        margin-left: -8rem !important
    }

    .ms-xxl-n40 {
        margin-left: -10rem !important
    }

    .ms-xxl-n48 {
        margin-left: -12rem !important
    }

    .ms-xxl-n56 {
        margin-left: -14rem !important
    }

    .ms-xxl-n64 {
        margin-left: -16rem !important
    }

    .ms-xxl-n72 {
        margin-left: -18rem !important
    }

    .ms-xxl-n80 {
        margin-left: -20rem !important
    }

    .ms-xxl-n88 {
        margin-left: -22rem !important
    }

    .ms-xxl-n96 {
        margin-left: -24rem !important
    }

    .ms-xxl-npx {
        margin-left: -1px !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: .75rem !important
    }

    .p-xxl-4 {
        padding: 1rem !important
    }

    .p-xxl-5 {
        padding: 1.25rem !important
    }

    .p-xxl-6 {
        padding: 1.5rem !important
    }

    .p-xxl-7 {
        padding: 1.75rem !important
    }

    .p-xxl-8 {
        padding: 2rem !important
    }

    .p-xxl-10 {
        padding: 2.5rem !important
    }

    .p-xxl-12 {
        padding: 3rem !important
    }

    .p-xxl-14 {
        padding: 3.5rem !important
    }

    .p-xxl-16 {
        padding: 4rem !important
    }

    .p-xxl-18 {
        padding: 4.5rem !important
    }

    .p-xxl-20 {
        padding: 5rem !important
    }

    .p-xxl-24 {
        padding: 6rem !important
    }

    .p-xxl-32 {
        padding: 8rem !important
    }

    .p-xxl-40 {
        padding: 10rem !important
    }

    .p-xxl-48 {
        padding: 12rem !important
    }

    .p-xxl-56 {
        padding: 14rem !important
    }

    .p-xxl-64 {
        padding: 16rem !important
    }

    .p-xxl-72 {
        padding: 18rem !important
    }

    .p-xxl-80 {
        padding: 20rem !important
    }

    .p-xxl-88 {
        padding: 22rem !important
    }

    .p-xxl-96 {
        padding: 24rem !important
    }

    .p-xxl-px {
        padding: 1px !important
    }

    .px-xxl-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .px-xxl-1 {
        padding-left: .25rem !important;
        padding-right: .25rem !important
    }

    .px-xxl-2 {
        padding-left: .5rem !important;
        padding-right: .5rem !important
    }

    .px-xxl-3 {
        padding-left: .75rem !important;
        padding-right: .75rem !important
    }

    .px-xxl-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important
    }

    .px-xxl-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important
    }

    .px-xxl-6 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important
    }

    .px-xxl-7 {
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important
    }

    .px-xxl-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important
    }

    .px-xxl-10 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important
    }

    .px-xxl-12 {
        padding-left: 3rem !important;
        padding-right: 3rem !important
    }

    .px-xxl-14 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important
    }

    .px-xxl-16 {
        padding-left: 4rem !important;
        padding-right: 4rem !important
    }

    .px-xxl-18 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important
    }

    .px-xxl-20 {
        padding-left: 5rem !important;
        padding-right: 5rem !important
    }

    .px-xxl-24 {
        padding-left: 6rem !important;
        padding-right: 6rem !important
    }

    .px-xxl-32 {
        padding-left: 8rem !important;
        padding-right: 8rem !important
    }

    .px-xxl-40 {
        padding-left: 10rem !important;
        padding-right: 10rem !important
    }

    .px-xxl-48 {
        padding-left: 12rem !important;
        padding-right: 12rem !important
    }

    .px-xxl-56 {
        padding-left: 14rem !important;
        padding-right: 14rem !important
    }

    .px-xxl-64 {
        padding-left: 16rem !important;
        padding-right: 16rem !important
    }

    .px-xxl-72 {
        padding-left: 18rem !important;
        padding-right: 18rem !important
    }

    .px-xxl-80 {
        padding-left: 20rem !important;
        padding-right: 20rem !important
    }

    .px-xxl-88 {
        padding-left: 22rem !important;
        padding-right: 22rem !important
    }

    .px-xxl-96 {
        padding-left: 24rem !important;
        padding-right: 24rem !important
    }

    .px-xxl-px {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .py-xxl-0 {
        padding-bottom: 0 !important;
        padding-top: 0 !important
    }

    .py-xxl-1 {
        padding-bottom: .25rem !important;
        padding-top: .25rem !important
    }

    .py-xxl-2 {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important
    }

    .py-xxl-3 {
        padding-bottom: .75rem !important;
        padding-top: .75rem !important
    }

    .py-xxl-4 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important
    }

    .py-xxl-5 {
        padding-bottom: 1.25rem !important;
        padding-top: 1.25rem !important
    }

    .py-xxl-6 {
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important
    }

    .py-xxl-7 {
        padding-bottom: 1.75rem !important;
        padding-top: 1.75rem !important
    }

    .py-xxl-8 {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important
    }

    .py-xxl-10 {
        padding-bottom: 2.5rem !important;
        padding-top: 2.5rem !important
    }

    .py-xxl-12 {
        padding-bottom: 3rem !important;
        padding-top: 3rem !important
    }

    .py-xxl-14 {
        padding-bottom: 3.5rem !important;
        padding-top: 3.5rem !important
    }

    .py-xxl-16 {
        padding-bottom: 4rem !important;
        padding-top: 4rem !important
    }

    .py-xxl-18 {
        padding-bottom: 4.5rem !important;
        padding-top: 4.5rem !important
    }

    .py-xxl-20 {
        padding-bottom: 5rem !important;
        padding-top: 5rem !important
    }

    .py-xxl-24 {
        padding-bottom: 6rem !important;
        padding-top: 6rem !important
    }

    .py-xxl-32 {
        padding-bottom: 8rem !important;
        padding-top: 8rem !important
    }

    .py-xxl-40 {
        padding-bottom: 10rem !important;
        padding-top: 10rem !important
    }

    .py-xxl-48 {
        padding-bottom: 12rem !important;
        padding-top: 12rem !important
    }

    .py-xxl-56 {
        padding-bottom: 14rem !important;
        padding-top: 14rem !important
    }

    .py-xxl-64 {
        padding-bottom: 16rem !important;
        padding-top: 16rem !important
    }

    .py-xxl-72 {
        padding-bottom: 18rem !important;
        padding-top: 18rem !important
    }

    .py-xxl-80 {
        padding-bottom: 20rem !important;
        padding-top: 20rem !important
    }

    .py-xxl-88 {
        padding-bottom: 22rem !important;
        padding-top: 22rem !important
    }

    .py-xxl-96 {
        padding-bottom: 24rem !important;
        padding-top: 24rem !important
    }

    .py-xxl-px {
        padding-bottom: 1px !important;
        padding-top: 1px !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: .75rem !important
    }

    .pt-xxl-4 {
        padding-top: 1rem !important
    }

    .pt-xxl-5 {
        padding-top: 1.25rem !important
    }

    .pt-xxl-6 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-7 {
        padding-top: 1.75rem !important
    }

    .pt-xxl-8 {
        padding-top: 2rem !important
    }

    .pt-xxl-10 {
        padding-top: 2.5rem !important
    }

    .pt-xxl-12 {
        padding-top: 3rem !important
    }

    .pt-xxl-14 {
        padding-top: 3.5rem !important
    }

    .pt-xxl-16 {
        padding-top: 4rem !important
    }

    .pt-xxl-18 {
        padding-top: 4.5rem !important
    }

    .pt-xxl-20 {
        padding-top: 5rem !important
    }

    .pt-xxl-24 {
        padding-top: 6rem !important
    }

    .pt-xxl-32 {
        padding-top: 8rem !important
    }

    .pt-xxl-40 {
        padding-top: 10rem !important
    }

    .pt-xxl-48 {
        padding-top: 12rem !important
    }

    .pt-xxl-56 {
        padding-top: 14rem !important
    }

    .pt-xxl-64 {
        padding-top: 16rem !important
    }

    .pt-xxl-72 {
        padding-top: 18rem !important
    }

    .pt-xxl-80 {
        padding-top: 20rem !important
    }

    .pt-xxl-88 {
        padding-top: 22rem !important
    }

    .pt-xxl-96 {
        padding-top: 24rem !important
    }

    .pt-xxl-px {
        padding-top: 1px !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: .75rem !important
    }

    .pe-xxl-4 {
        padding-right: 1rem !important
    }

    .pe-xxl-5 {
        padding-right: 1.25rem !important
    }

    .pe-xxl-6 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-7 {
        padding-right: 1.75rem !important
    }

    .pe-xxl-8 {
        padding-right: 2rem !important
    }

    .pe-xxl-10 {
        padding-right: 2.5rem !important
    }

    .pe-xxl-12 {
        padding-right: 3rem !important
    }

    .pe-xxl-14 {
        padding-right: 3.5rem !important
    }

    .pe-xxl-16 {
        padding-right: 4rem !important
    }

    .pe-xxl-18 {
        padding-right: 4.5rem !important
    }

    .pe-xxl-20 {
        padding-right: 5rem !important
    }

    .pe-xxl-24 {
        padding-right: 6rem !important
    }

    .pe-xxl-32 {
        padding-right: 8rem !important
    }

    .pe-xxl-40 {
        padding-right: 10rem !important
    }

    .pe-xxl-48 {
        padding-right: 12rem !important
    }

    .pe-xxl-56 {
        padding-right: 14rem !important
    }

    .pe-xxl-64 {
        padding-right: 16rem !important
    }

    .pe-xxl-72 {
        padding-right: 18rem !important
    }

    .pe-xxl-80 {
        padding-right: 20rem !important
    }

    .pe-xxl-88 {
        padding-right: 22rem !important
    }

    .pe-xxl-96 {
        padding-right: 24rem !important
    }

    .pe-xxl-px {
        padding-right: 1px !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: .75rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 1.25rem !important
    }

    .pb-xxl-6 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-7 {
        padding-bottom: 1.75rem !important
    }

    .pb-xxl-8 {
        padding-bottom: 2rem !important
    }

    .pb-xxl-10 {
        padding-bottom: 2.5rem !important
    }

    .pb-xxl-12 {
        padding-bottom: 3rem !important
    }

    .pb-xxl-14 {
        padding-bottom: 3.5rem !important
    }

    .pb-xxl-16 {
        padding-bottom: 4rem !important
    }

    .pb-xxl-18 {
        padding-bottom: 4.5rem !important
    }

    .pb-xxl-20 {
        padding-bottom: 5rem !important
    }

    .pb-xxl-24 {
        padding-bottom: 6rem !important
    }

    .pb-xxl-32 {
        padding-bottom: 8rem !important
    }

    .pb-xxl-40 {
        padding-bottom: 10rem !important
    }

    .pb-xxl-48 {
        padding-bottom: 12rem !important
    }

    .pb-xxl-56 {
        padding-bottom: 14rem !important
    }

    .pb-xxl-64 {
        padding-bottom: 16rem !important
    }

    .pb-xxl-72 {
        padding-bottom: 18rem !important
    }

    .pb-xxl-80 {
        padding-bottom: 20rem !important
    }

    .pb-xxl-88 {
        padding-bottom: 22rem !important
    }

    .pb-xxl-96 {
        padding-bottom: 24rem !important
    }

    .pb-xxl-px {
        padding-bottom: 1px !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: .75rem !important
    }

    .ps-xxl-4 {
        padding-left: 1rem !important
    }

    .ps-xxl-5 {
        padding-left: 1.25rem !important
    }

    .ps-xxl-6 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-7 {
        padding-left: 1.75rem !important
    }

    .ps-xxl-8 {
        padding-left: 2rem !important
    }

    .ps-xxl-10 {
        padding-left: 2.5rem !important
    }

    .ps-xxl-12 {
        padding-left: 3rem !important
    }

    .ps-xxl-14 {
        padding-left: 3.5rem !important
    }

    .ps-xxl-16 {
        padding-left: 4rem !important
    }

    .ps-xxl-18 {
        padding-left: 4.5rem !important
    }

    .ps-xxl-20 {
        padding-left: 5rem !important
    }

    .ps-xxl-24 {
        padding-left: 6rem !important
    }

    .ps-xxl-32 {
        padding-left: 8rem !important
    }

    .ps-xxl-40 {
        padding-left: 10rem !important
    }

    .ps-xxl-48 {
        padding-left: 12rem !important
    }

    .ps-xxl-56 {
        padding-left: 14rem !important
    }

    .ps-xxl-64 {
        padding-left: 16rem !important
    }

    .ps-xxl-72 {
        padding-left: 18rem !important
    }

    .ps-xxl-80 {
        padding-left: 20rem !important
    }

    .ps-xxl-88 {
        padding-left: 22rem !important
    }

    .ps-xxl-96 {
        padding-left: 24rem !important
    }

    .ps-xxl-px {
        padding-left: 1px !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }

    .text-xxl-primary {
        --x-text-opacity: 1
    }

    .text-xxl-primary, .text-xxl-primary-focus:focus, .text-xxl-primary-hover:hover {
        color: rgba(var(--x-primary-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-secondary {
        --x-text-opacity: 1
    }

    .text-xxl-secondary, .text-xxl-secondary-focus:focus, .text-xxl-secondary-hover:hover {
        color: rgba(var(--x-secondary-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-tertiary {
        --x-text-opacity: 1
    }

    .text-xxl-tertiary, .text-xxl-tertiary-focus:focus, .text-xxl-tertiary-hover:hover {
        color: rgba(var(--x-tertiary-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-success {
        --x-text-opacity: 1
    }

    .text-xxl-success, .text-xxl-success-focus:focus, .text-xxl-success-hover:hover {
        color: rgba(var(--x-success-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-info {
        --x-text-opacity: 1
    }

    .text-xxl-info, .text-xxl-info-focus:focus, .text-xxl-info-hover:hover {
        color: rgba(var(--x-info-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-warning {
        --x-text-opacity: 1
    }

    .text-xxl-warning, .text-xxl-warning-focus:focus, .text-xxl-warning-hover:hover {
        color: rgba(var(--x-warning-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-danger {
        --x-text-opacity: 1
    }

    .text-xxl-danger, .text-xxl-danger-focus:focus, .text-xxl-danger-hover:hover {
        color: rgba(var(--x-danger-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-white {
        --x-text-opacity: 1
    }

    .text-xxl-white, .text-xxl-white-focus:focus, .text-xxl-white-hover:hover {
        color: rgba(var(--x-white-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-light {
        --x-text-opacity: 1
    }

    .text-xxl-light, .text-xxl-light-focus:focus, .text-xxl-light-hover:hover {
        color: rgba(var(--x-light-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-dark {
        --x-text-opacity: 1
    }

    .text-xxl-dark, .text-xxl-dark-focus:focus, .text-xxl-dark-hover:hover {
        color: rgba(var(--x-dark-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-black {
        --x-text-opacity: 1
    }

    .text-xxl-black, .text-xxl-black-focus:focus, .text-xxl-black-hover:hover {
        color: rgba(var(--x-black-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-body {
        --x-text-opacity: 1
    }

    .text-xxl-body, .text-xxl-body-focus:focus, .text-xxl-body-hover:hover {
        color: rgba(var(--x-body-color-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-muted {
        --x-text-opacity: 1
    }

    .text-xxl-muted, .text-xxl-muted-focus:focus, .text-xxl-muted-hover:hover {
        color: rgba(var(--x-muted-rgb),var(--x-text-opacity)) !important
    }

    .text-xxl-heading {
        --x-text-opacity: 1
    }

    .text-xxl-heading, .text-xxl-heading-focus:focus, .text-xxl-heading-hover:hover {
        color: rgba(var(--x-heading-rgb),var(--x-text-opacity)) !important
    }

    .rounded-xxl {
        border-radius: .375rem !important
    }

    .rounded-xxl-0 {
        border-radius: 0 !important
    }

    .rounded-xxl-1 {
        border-radius: .25rem !important
    }

    .rounded-xxl-2 {
        border-radius: .5rem !important
    }

    .rounded-xxl-3 {
        border-radius: .75rem !important
    }

    .rounded-xxl-4 {
        border-radius: 1rem !important
    }

    .rounded-xxl-5 {
        border-radius: 1.5rem !important
    }

    .rounded-xxl-6 {
        border-radius: 3rem !important
    }

    .rounded-xxl-7 {
        border-radius: 4rem !important
    }

    .rounded-xxl-8 {
        border-radius: 5rem !important
    }

    .rounded-xxl-9 {
        border-radius: 6rem !important
    }

    .rounded-xxl-10 {
        border-radius: 7rem !important
    }

    .rounded-xxl-pill {
        border-radius: 50rem !important
    }

    .rounded-xxl-circle {
        border-radius: 50% !important
    }

    .rounded-xxl-card {
        border-radius: .75rem !important
    }

    .rounded-top-xxl {
        border-top-left-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-top-xxl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-top-xxl-1 {
        border-top-left-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-top-xxl-2 {
        border-top-left-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-top-xxl-3 {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-top-xxl-4 {
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-top-xxl-5 {
        border-top-left-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-xxl-6 {
        border-top-left-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-top-xxl-7 {
        border-top-left-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-top-xxl-8 {
        border-top-left-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-top-xxl-9 {
        border-top-left-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-top-xxl-10 {
        border-top-left-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-top-xxl-pill {
        border-top-left-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-top-xxl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-top-xxl-card {
        border-top-left-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-xxl {
        border-bottom-right-radius: .375rem !important;
        border-top-right-radius: .375rem !important
    }

    .rounded-end-xxl-0 {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .rounded-end-xxl-1 {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important
    }

    .rounded-end-xxl-2 {
        border-bottom-right-radius: .5rem !important;
        border-top-right-radius: .5rem !important
    }

    .rounded-end-xxl-3 {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-end-xxl-4 {
        border-bottom-right-radius: 1rem !important;
        border-top-right-radius: 1rem !important
    }

    .rounded-end-xxl-5 {
        border-bottom-right-radius: 1.5rem !important;
        border-top-right-radius: 1.5rem !important
    }

    .rounded-end-xxl-6 {
        border-bottom-right-radius: 3rem !important;
        border-top-right-radius: 3rem !important
    }

    .rounded-end-xxl-7 {
        border-bottom-right-radius: 4rem !important;
        border-top-right-radius: 4rem !important
    }

    .rounded-end-xxl-8 {
        border-bottom-right-radius: 5rem !important;
        border-top-right-radius: 5rem !important
    }

    .rounded-end-xxl-9 {
        border-bottom-right-radius: 6rem !important;
        border-top-right-radius: 6rem !important
    }

    .rounded-end-xxl-10 {
        border-bottom-right-radius: 7rem !important;
        border-top-right-radius: 7rem !important
    }

    .rounded-end-xxl-pill {
        border-bottom-right-radius: 50rem !important;
        border-top-right-radius: 50rem !important
    }

    .rounded-end-xxl-circle {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important
    }

    .rounded-end-xxl-card {
        border-bottom-right-radius: .75rem !important;
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-xxl {
        border-bottom-left-radius: .375rem !important;
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-xxl-1 {
        border-bottom-left-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-xxl-2 {
        border-bottom-left-radius: .5rem !important;
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-xxl-3 {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-xxl-4 {
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-xxl-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-xxl-6 {
        border-bottom-left-radius: 3rem !important;
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-xxl-7 {
        border-bottom-left-radius: 4rem !important;
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-xxl-8 {
        border-bottom-left-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-xxl-9 {
        border-bottom-left-radius: 6rem !important;
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-xxl-10 {
        border-bottom-left-radius: 7rem !important;
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-xxl-pill {
        border-bottom-left-radius: 50rem !important;
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-xxl-card {
        border-bottom-left-radius: .75rem !important;
        border-bottom-right-radius: .75rem !important
    }

    .rounded-start-xxl {
        border-bottom-left-radius: .375rem !important;
        border-top-left-radius: .375rem !important
    }

    .rounded-start-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important
    }

    .rounded-start-xxl-1 {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important
    }

    .rounded-start-xxl-2 {
        border-bottom-left-radius: .5rem !important;
        border-top-left-radius: .5rem !important
    }

    .rounded-start-xxl-3 {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-start-xxl-4 {
        border-bottom-left-radius: 1rem !important;
        border-top-left-radius: 1rem !important
    }

    .rounded-start-xxl-5 {
        border-bottom-left-radius: 1.5rem !important;
        border-top-left-radius: 1.5rem !important
    }

    .rounded-start-xxl-6 {
        border-bottom-left-radius: 3rem !important;
        border-top-left-radius: 3rem !important
    }

    .rounded-start-xxl-7 {
        border-bottom-left-radius: 4rem !important;
        border-top-left-radius: 4rem !important
    }

    .rounded-start-xxl-8 {
        border-bottom-left-radius: 5rem !important;
        border-top-left-radius: 5rem !important
    }

    .rounded-start-xxl-9 {
        border-bottom-left-radius: 6rem !important;
        border-top-left-radius: 6rem !important
    }

    .rounded-start-xxl-10 {
        border-bottom-left-radius: 7rem !important;
        border-top-left-radius: 7rem !important
    }

    .rounded-start-xxl-pill {
        border-bottom-left-radius: 50rem !important;
        border-top-left-radius: 50rem !important
    }

    .rounded-start-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important
    }

    .rounded-start-xxl-card {
        border-bottom-left-radius: .75rem !important;
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-xxl {
        border-top-left-radius: .375rem !important
    }

    .rounded-top-start-xxl-0 {
        border-top-left-radius: 0 !important
    }

    .rounded-top-start-xxl-1 {
        border-top-left-radius: .25rem !important
    }

    .rounded-top-start-xxl-2 {
        border-top-left-radius: .5rem !important
    }

    .rounded-top-start-xxl-3 {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-start-xxl-4 {
        border-top-left-radius: 1rem !important
    }

    .rounded-top-start-xxl-5 {
        border-top-left-radius: 1.5rem !important
    }

    .rounded-top-start-xxl-6 {
        border-top-left-radius: 3rem !important
    }

    .rounded-top-start-xxl-7 {
        border-top-left-radius: 4rem !important
    }

    .rounded-top-start-xxl-8 {
        border-top-left-radius: 5rem !important
    }

    .rounded-top-start-xxl-9 {
        border-top-left-radius: 6rem !important
    }

    .rounded-top-start-xxl-10 {
        border-top-left-radius: 7rem !important
    }

    .rounded-top-start-xxl-pill {
        border-top-left-radius: 50rem !important
    }

    .rounded-top-start-xxl-circle {
        border-top-left-radius: 50% !important
    }

    .rounded-top-start-xxl-card {
        border-top-left-radius: .75rem !important
    }

    .rounded-top-end-xxl {
        border-top-right-radius: .375rem !important
    }

    .rounded-top-end-xxl-0 {
        border-top-right-radius: 0 !important
    }

    .rounded-top-end-xxl-1 {
        border-top-right-radius: .25rem !important
    }

    .rounded-top-end-xxl-2 {
        border-top-right-radius: .5rem !important
    }

    .rounded-top-end-xxl-3 {
        border-top-right-radius: .75rem !important
    }

    .rounded-top-end-xxl-4 {
        border-top-right-radius: 1rem !important
    }

    .rounded-top-end-xxl-5 {
        border-top-right-radius: 1.5rem !important
    }

    .rounded-top-end-xxl-6 {
        border-top-right-radius: 3rem !important
    }

    .rounded-top-end-xxl-7 {
        border-top-right-radius: 4rem !important
    }

    .rounded-top-end-xxl-8 {
        border-top-right-radius: 5rem !important
    }

    .rounded-top-end-xxl-9 {
        border-top-right-radius: 6rem !important
    }

    .rounded-top-end-xxl-10 {
        border-top-right-radius: 7rem !important
    }

    .rounded-top-end-xxl-pill {
        border-top-right-radius: 50rem !important
    }

    .rounded-top-end-xxl-circle {
        border-top-right-radius: 50% !important
    }

    .rounded-top-end-xxl-card {
        border-top-right-radius: .75rem !important
    }

    .rounded-bottom-end-xxl {
        border-bottom-right-radius: .375rem !important
    }

    .rounded-bottom-end-xxl-0 {
        border-bottom-right-radius: 0 !important
    }

    .rounded-bottom-end-xxl-1 {
        border-bottom-right-radius: .25rem !important
    }

    .rounded-bottom-end-xxl-2 {
        border-bottom-right-radius: .5rem !important
    }

    .rounded-bottom-end-xxl-3 {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-end-xxl-4 {
        border-bottom-right-radius: 1rem !important
    }

    .rounded-bottom-end-xxl-5 {
        border-bottom-right-radius: 1.5rem !important
    }

    .rounded-bottom-end-xxl-6 {
        border-bottom-right-radius: 3rem !important
    }

    .rounded-bottom-end-xxl-7 {
        border-bottom-right-radius: 4rem !important
    }

    .rounded-bottom-end-xxl-8 {
        border-bottom-right-radius: 5rem !important
    }

    .rounded-bottom-end-xxl-9 {
        border-bottom-right-radius: 6rem !important
    }

    .rounded-bottom-end-xxl-10 {
        border-bottom-right-radius: 7rem !important
    }

    .rounded-bottom-end-xxl-pill {
        border-bottom-right-radius: 50rem !important
    }

    .rounded-bottom-end-xxl-circle {
        border-bottom-right-radius: 50% !important
    }

    .rounded-bottom-end-xxl-card {
        border-bottom-right-radius: .75rem !important
    }

    .rounded-bottom-start-xxl {
        border-bottom-left-radius: .375rem !important
    }

    .rounded-bottom-start-xxl-0 {
        border-bottom-left-radius: 0 !important
    }

    .rounded-bottom-start-xxl-1 {
        border-bottom-left-radius: .25rem !important
    }

    .rounded-bottom-start-xxl-2 {
        border-bottom-left-radius: .5rem !important
    }

    .rounded-bottom-start-xxl-3 {
        border-bottom-left-radius: .75rem !important
    }

    .rounded-bottom-start-xxl-4 {
        border-bottom-left-radius: 1rem !important
    }

    .rounded-bottom-start-xxl-5 {
        border-bottom-left-radius: 1.5rem !important
    }

    .rounded-bottom-start-xxl-6 {
        border-bottom-left-radius: 3rem !important
    }

    .rounded-bottom-start-xxl-7 {
        border-bottom-left-radius: 4rem !important
    }

    .rounded-bottom-start-xxl-8 {
        border-bottom-left-radius: 5rem !important
    }

    .rounded-bottom-start-xxl-9 {
        border-bottom-left-radius: 6rem !important
    }

    .rounded-bottom-start-xxl-10 {
        border-bottom-left-radius: 7rem !important
    }

    .rounded-bottom-start-xxl-pill {
        border-bottom-left-radius: 50rem !important
    }

    .rounded-bottom-start-xxl-circle {
        border-bottom-left-radius: 50% !important
    }

    .rounded-bottom-start-xxl-card {
        border-bottom-left-radius: .75rem !important
    }

    .border-xxl-solid {
        border-style: solid !important
    }

    .border-xxl-dashed {
        border-style: dashed !important
    }

    .border-xxl-dotted {
        border-style: dotted !important
    }

    .border-xxl-double {
        border-style: double !important
    }

    .border-xxl-groove {
        border-style: groove !important
    }

    .border-xxl-none {
        border-style: none !important
    }

    .text-xxl-transparent, .text-xxl-transparent-focus:focus, .text-xxl-transparent-hover:hover {
        color: transparent !important
    }

    .text-xxl-current, .text-xxl-current-focus:focus, .text-xxl-current-hover:hover {
        color: currentColor !important
    }

    .text-xxl-reset, .text-xxl-reset-focus:focus, .text-xxl-reset-hover:hover {
        color: inherit !important
    }

    .shadow-soft-xxl-1, .shadow-soft-xxl-1-focus:focus, .shadow-soft-xxl-1-hover:hover {
        box-shadow: 0 1px 1px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-xxl-2, .shadow-soft-xxl-2-focus:focus, .shadow-soft-xxl-2-hover:hover {
        box-shadow: 0 6px 6px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xxl-3, .shadow-soft-xxl-3-focus:focus, .shadow-soft-xxl-3-hover:hover {
        box-shadow: 0 9px 9px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xxl-4, .shadow-soft-xxl-4-focus:focus, .shadow-soft-xxl-4-hover:hover {
        box-shadow: 0 16px 16px -1px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xxl-5, .shadow-soft-xxl-5-focus:focus, .shadow-soft-xxl-5-hover:hover {
        box-shadow: 0 32px 40px -2px rgba(10,22,70,.04) !important
    }

    .shadow-soft-xxl-6, .shadow-soft-xxl-6-focus:focus, .shadow-soft-xxl-6-hover:hover {
        box-shadow: 0 36px 50px 0 rgba(10,22,70,.04) !important
    }

    .shadow-soft-xxl-none, .shadow-soft-xxl-none-focus:focus, .shadow-soft-xxl-none-hover:hover {
        box-shadow: none !important
    }

    .min-w-xxl-0 {
        min-width: 0 !important
    }

    .min-w-xxl-full {
        min-width: 100% !important
    }

    .min-w-xxl-min {
        min-width: -webkit-min-content !important;
        min-width: -moz-min-content !important;
        min-width: min-content !important
    }

    .min-w-xxl-max {
        min-width: -webkit-max-content !important;
        min-width: -moz-max-content !important;
        min-width: max-content !important
    }

    .min-h-xxl-0 {
        min-height: 0 !important
    }

    .min-h-xxl-full {
        min-height: 100% !important
    }

    .min-h-xxl-screen {
        min-height: 100vh !important
    }

    .place-content-xxl-start {
        place-content: flex-start !important
    }

    .place-content-xxl-end {
        place-content: flex-end !important
    }

    .place-content-xxl-center {
        place-content: center !important
    }

    .place-content-xxl-between {
        place-content: space-between !important
    }

    .place-content-xxl-around {
        place-content: space-around !important
    }

    .place-content-xxl-evenly {
        place-content: space-evenly !important
    }

    .place-content-xxl-stretch {
        place-content: stretch !important
    }

    .overflow-x-xxl-auto {
        overflow-x: auto !important
    }

    .overflow-x-xxl-hidden {
        overflow-x: hidden !important
    }

    .overflow-x-xxl-visible {
        overflow-x: visible !important
    }

    .overflow-x-xxl-scroll {
        overflow-x: scroll !important
    }

    .overflow-y-xxl-auto {
        overflow-y: auto !important
    }

    .overflow-y-xxl-hidden {
        overflow-y: hidden !important
    }

    .overflow-y-xxl-visible {
        overflow-y: visible !important
    }

    .overflow-y-xxl-scroll {
        overflow-y: scroll !important
    }

    .transition-xxl {
        transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform !important
    }

    .transition-xxl-none {
        transition-property: none !important
    }

    .transition-xxl-all {
        transition-property: all !important
    }

    .transition-xxl-colors {
        transition-property: background-color,border-color,color,fill,stroke !important
    }

    .transition-xxl-opacity {
        transition-property: opacity !important
    }

    .transition-xxl-shadow {
        transition-property: box-shadow !important
    }

    .transition-xxl-transform {
        transition-property: transform !important
    }

    .ease-xxl-base {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .ease-xxl-linear {
        transition-timing-function: linear !important
    }

    .ease-xxl-in {
        transition-timing-function: cubic-bezier(.4,0,1,1) !important
    }

    .ease-xxl-out {
        transition-timing-function: cubic-bezier(0,0,.2,1) !important
    }

    .ease-xxl-in-out {
        transition-timing-function: cubic-bezier(.4,0,.2,1) !important
    }

    .transform-xxl-none {
        transform: none !important
    }

    .origin-xxl-center {
        transform-origin: center !important
    }

    .origin-xxl-top {
        transform-origin: top !important
    }

    .origin-xxl-top-right {
        transform-origin: top right !important
    }

    .origin-xxl-right {
        transform-origin: right !important
    }

    .origin-xxl-bottom-right {
        transform-origin: bottom right !important
    }

    .origin-xxl-bottom {
        transform-origin: bottom !important
    }

    .origin-xxl-bottom-left {
        transform-origin: bottom left !important
    }

    .origin-xxl-left {
        transform-origin: left !important
    }

    .origin-xxl-top-left {
        transform-origin: top left !important
    }

    .scale-xxl-0, .scale-xxl-0-focus:focus, .scale-xxl-0-hover:hover {
        --x-scale-x: 0 !important;
        --x-scale-y: 0 !important
    }

    .scale-xxl-25, .scale-xxl-25-focus:focus, .scale-xxl-25-hover:hover {
        --x-scale-x: 0.25 !important;
        --x-scale-y: 0.25 !important
    }

    .scale-xxl-50, .scale-xxl-50-focus:focus, .scale-xxl-50-hover:hover {
        --x-scale-x: 0.5 !important;
        --x-scale-y: 0.5 !important
    }

    .scale-xxl-75, .scale-xxl-75-focus:focus, .scale-xxl-75-hover:hover {
        --x-scale-x: 0.75 !important;
        --x-scale-y: 0.75 !important
    }

    .scale-xxl-90, .scale-xxl-90-focus:focus, .scale-xxl-90-hover:hover {
        --x-scale-x: 0.9 !important;
        --x-scale-y: 0.9 !important
    }

    .scale-xxl-95, .scale-xxl-95-focus:focus, .scale-xxl-95-hover:hover {
        --x-scale-x: 0.95 !important;
        --x-scale-y: 0.95 !important
    }

    .scale-xxl-100, .scale-xxl-100-focus:focus, .scale-xxl-100-hover:hover {
        --x-scale-x: 1 !important;
        --x-scale-y: 1 !important
    }

    .scale-xxl-105, .scale-xxl-105-focus:focus, .scale-xxl-105-hover:hover {
        --x-scale-x: 1.05 !important;
        --x-scale-y: 1.05 !important
    }

    .scale-xxl-110, .scale-xxl-110-focus:focus, .scale-xxl-110-hover:hover {
        --x-scale-x: 1.1 !important;
        --x-scale-y: 1.1 !important
    }

    .scale-xxl-125, .scale-xxl-125-focus:focus, .scale-xxl-125-hover:hover {
        --x-scale-x: 1.25 !important;
        --x-scale-y: 1.25 !important
    }

    .scale-xxl-150, .scale-xxl-150-focus:focus, .scale-xxl-150-hover:hover {
        --x-scale-x: 1.5 !important;
        --x-scale-y: 1.5 !important
    }

    .scale-xxl-200, .scale-xxl-200-focus:focus, .scale-xxl-200-hover:hover {
        --x-scale-x: 2 !important;
        --x-scale-y: 2 !important
    }

    .scale-y-xxl-0, .scale-y-xxl-0-focus:focus, .scale-y-xxl-0-hover:hover {
        --x--scale-y: 0 !important
    }

    .scale-y-xxl-25, .scale-y-xxl-25-focus:focus, .scale-y-xxl-25-hover:hover {
        --x--scale-y: 0.25 !important
    }

    .scale-y-xxl-50, .scale-y-xxl-50-focus:focus, .scale-y-xxl-50-hover:hover {
        --x--scale-y: 0.5 !important
    }

    .scale-y-xxl-75, .scale-y-xxl-75-focus:focus, .scale-y-xxl-75-hover:hover {
        --x--scale-y: 0.75 !important
    }

    .scale-y-xxl-90, .scale-y-xxl-90-focus:focus, .scale-y-xxl-90-hover:hover {
        --x--scale-y: 0.9 !important
    }

    .scale-y-xxl-95, .scale-y-xxl-95-focus:focus, .scale-y-xxl-95-hover:hover {
        --x--scale-y: 0.95 !important
    }

    .scale-y-xxl-100, .scale-y-xxl-100-focus:focus, .scale-y-xxl-100-hover:hover {
        --x--scale-y: 1 !important
    }

    .scale-y-xxl-105, .scale-y-xxl-105-focus:focus, .scale-y-xxl-105-hover:hover {
        --x--scale-y: 1.05 !important
    }

    .scale-y-xxl-110, .scale-y-xxl-110-focus:focus, .scale-y-xxl-110-hover:hover {
        --x--scale-y: 1.1 !important
    }

    .scale-y-xxl-125, .scale-y-xxl-125-focus:focus, .scale-y-xxl-125-hover:hover {
        --x--scale-y: 1.25 !important
    }

    .scale-y-xxl-150, .scale-y-xxl-150-focus:focus, .scale-y-xxl-150-hover:hover {
        --x--scale-y: 1.5 !important
    }

    .scale-y-xxl-200, .scale-y-xxl-200-focus:focus, .scale-y-xxl-200-hover:hover {
        --x--scale-y: 2 !important
    }

    .scale-x-xxl-0, .scale-x-xxl-0-focus:focus, .scale-x-xxl-0-hover:hover {
        --x--scale-x: 0 !important
    }

    .scale-x-xxl-25, .scale-x-xxl-25-focus:focus, .scale-x-xxl-25-hover:hover {
        --x--scale-x: 0.25 !important
    }

    .scale-x-xxl-50, .scale-x-xxl-50-focus:focus, .scale-x-xxl-50-hover:hover {
        --x--scale-x: 0.5 !important
    }

    .scale-x-xxl-75, .scale-x-xxl-75-focus:focus, .scale-x-xxl-75-hover:hover {
        --x--scale-x: 0.75 !important
    }

    .scale-x-xxl-90, .scale-x-xxl-90-focus:focus, .scale-x-xxl-90-hover:hover {
        --x--scale-x: 0.9 !important
    }

    .scale-x-xxl-95, .scale-x-xxl-95-focus:focus, .scale-x-xxl-95-hover:hover {
        --x--scale-x: 0.95 !important
    }

    .scale-x-xxl-100, .scale-x-xxl-100-focus:focus, .scale-x-xxl-100-hover:hover {
        --x--scale-x: 1 !important
    }

    .scale-x-xxl-105, .scale-x-xxl-105-focus:focus, .scale-x-xxl-105-hover:hover {
        --x--scale-x: 1.05 !important
    }

    .scale-x-xxl-110, .scale-x-xxl-110-focus:focus, .scale-x-xxl-110-hover:hover {
        --x--scale-x: 1.1 !important
    }

    .scale-x-xxl-125, .scale-x-xxl-125-focus:focus, .scale-x-xxl-125-hover:hover {
        --x--scale-x: 1.25 !important
    }

    .scale-x-xxl-150, .scale-x-xxl-150-focus:focus, .scale-x-xxl-150-hover:hover {
        --x--scale-x: 1.5 !important
    }

    .scale-x-xxl-200, .scale-x-xxl-200-focus:focus, .scale-x-xxl-200-hover:hover {
        --x--scale-x: 2 !important
    }

    .rotate-xxl-0, .rotate-xxl-0-focus:focus, .rotate-xxl-0-hover:hover {
        --x-rotate: 0deg !important
    }

    .rotate-xxl-1, .rotate-xxl-1-focus:focus, .rotate-xxl-1-hover:hover {
        --x-rotate: 1deg !important
    }

    .rotate-xxl-2, .rotate-xxl-2-focus:focus, .rotate-xxl-2-hover:hover {
        --x-rotate: 2deg !important
    }

    .rotate-xxl-3, .rotate-xxl-3-focus:focus, .rotate-xxl-3-hover:hover {
        --x-rotate: 3deg !important
    }

    .rotate-xxl-6, .rotate-xxl-6-focus:focus, .rotate-xxl-6-hover:hover {
        --x-rotate: 6deg !important
    }

    .rotate-xxl-12, .rotate-xxl-12-focus:focus, .rotate-xxl-12-hover:hover {
        --x-rotate: 12deg !important
    }

    .rotate-xxl-30, .rotate-xxl-30-focus:focus, .rotate-xxl-30-hover:hover {
        --x-rotate: 30deg !important
    }

    .rotate-xxl-45, .rotate-xxl-45-focus:focus, .rotate-xxl-45-hover:hover {
        --x-rotate: 45deg !important
    }

    .rotate-xxl-90, .rotate-xxl-90-focus:focus, .rotate-xxl-90-hover:hover {
        --x-rotate: 90deg !important
    }

    .rotate-xxl-180, .rotate-xxl-180-focus:focus, .rotate-xxl-180-hover:hover {
        --x-rotate: 180deg !important
    }

    .rotate-xxl-n1, .rotate-xxl-n1-focus:focus, .rotate-xxl-n1-hover:hover {
        --x-rotate: -1deg !important
    }

    .rotate-xxl-n2, .rotate-xxl-n2-focus:focus, .rotate-xxl-n2-hover:hover {
        --x-rotate: -2deg !important
    }

    .rotate-xxl-n3, .rotate-xxl-n3-focus:focus, .rotate-xxl-n3-hover:hover {
        --x-rotate: -3deg !important
    }

    .rotate-xxl-n6, .rotate-xxl-n6-focus:focus, .rotate-xxl-n6-hover:hover {
        --x-rotate: -6deg !important
    }

    .rotate-xxl-n12, .rotate-xxl-n12-focus:focus, .rotate-xxl-n12-hover:hover {
        --x-rotate: -12deg !important
    }

    .rotate-xxl-n30, .rotate-xxl-n30-focus:focus, .rotate-xxl-n30-hover:hover {
        --x-rotate: -30deg !important
    }

    .rotate-xxl-n45, .rotate-xxl-n45-focus:focus, .rotate-xxl-n45-hover:hover {
        --x-rotate: -45deg !important
    }

    .rotate-xxl-n90, .rotate-xxl-n90-focus:focus, .rotate-xxl-n90-hover:hover {
        --x-rotate: -90deg !important
    }

    .rotate-xxl-n180, .rotate-xxl-n180-focus:focus, .rotate-xxl-n180-hover:hover {
        --x-rotate: -180deg !important
    }

    .rotate-y-xxl-0, .rotate-y-xxl-0-focus:focus, .rotate-y-xxl-0-hover:hover {
        --x-rotate-y: 0deg !important
    }

    .rotate-y-xxl-1, .rotate-y-xxl-1-focus:focus, .rotate-y-xxl-1-hover:hover {
        --x-rotate-y: 1deg !important
    }

    .rotate-y-xxl-2, .rotate-y-xxl-2-focus:focus, .rotate-y-xxl-2-hover:hover {
        --x-rotate-y: 2deg !important
    }

    .rotate-y-xxl-3, .rotate-y-xxl-3-focus:focus, .rotate-y-xxl-3-hover:hover {
        --x-rotate-y: 3deg !important
    }

    .rotate-y-xxl-6, .rotate-y-xxl-6-focus:focus, .rotate-y-xxl-6-hover:hover {
        --x-rotate-y: 6deg !important
    }

    .rotate-y-xxl-12, .rotate-y-xxl-12-focus:focus, .rotate-y-xxl-12-hover:hover {
        --x-rotate-y: 12deg !important
    }

    .rotate-y-xxl-30, .rotate-y-xxl-30-focus:focus, .rotate-y-xxl-30-hover:hover {
        --x-rotate-y: 30deg !important
    }

    .rotate-y-xxl-45, .rotate-y-xxl-45-focus:focus, .rotate-y-xxl-45-hover:hover {
        --x-rotate-y: 45deg !important
    }

    .rotate-y-xxl-90, .rotate-y-xxl-90-focus:focus, .rotate-y-xxl-90-hover:hover {
        --x-rotate-y: 90deg !important
    }

    .rotate-y-xxl-180, .rotate-y-xxl-180-focus:focus, .rotate-y-xxl-180-hover:hover {
        --x-rotate-y: 180deg !important
    }

    .rotate-y-xxl-n1, .rotate-y-xxl-n1-focus:focus, .rotate-y-xxl-n1-hover:hover {
        --x-rotate-y: -1deg !important
    }

    .rotate-y-xxl-n2, .rotate-y-xxl-n2-focus:focus, .rotate-y-xxl-n2-hover:hover {
        --x-rotate-y: -2deg !important
    }

    .rotate-y-xxl-n3, .rotate-y-xxl-n3-focus:focus, .rotate-y-xxl-n3-hover:hover {
        --x-rotate-y: -3deg !important
    }

    .rotate-y-xxl-n6, .rotate-y-xxl-n6-focus:focus, .rotate-y-xxl-n6-hover:hover {
        --x-rotate-y: -6deg !important
    }

    .rotate-y-xxl-n12, .rotate-y-xxl-n12-focus:focus, .rotate-y-xxl-n12-hover:hover {
        --x-rotate-y: -12deg !important
    }

    .rotate-y-xxl-n30, .rotate-y-xxl-n30-focus:focus, .rotate-y-xxl-n30-hover:hover {
        --x-rotate-y: -30deg !important
    }

    .rotate-y-xxl-n45, .rotate-y-xxl-n45-focus:focus, .rotate-y-xxl-n45-hover:hover {
        --x-rotate-y: -45deg !important
    }

    .rotate-y-xxl-n90, .rotate-y-xxl-n90-focus:focus, .rotate-y-xxl-n90-hover:hover {
        --x-rotate-y: -90deg !important
    }

    .rotate-y-xxl-n180, .rotate-y-xxl-n180-focus:focus, .rotate-y-xxl-n180-hover:hover {
        --x-rotate-y: -180deg !important
    }

    .rotate-x-xxl-0, .rotate-x-xxl-0-focus:focus, .rotate-x-xxl-0-hover:hover {
        --x-rotate-x: 0deg !important
    }

    .rotate-x-xxl-1, .rotate-x-xxl-1-focus:focus, .rotate-x-xxl-1-hover:hover {
        --x-rotate-x: 1deg !important
    }

    .rotate-x-xxl-2, .rotate-x-xxl-2-focus:focus, .rotate-x-xxl-2-hover:hover {
        --x-rotate-x: 2deg !important
    }

    .rotate-x-xxl-3, .rotate-x-xxl-3-focus:focus, .rotate-x-xxl-3-hover:hover {
        --x-rotate-x: 3deg !important
    }

    .rotate-x-xxl-6, .rotate-x-xxl-6-focus:focus, .rotate-x-xxl-6-hover:hover {
        --x-rotate-x: 6deg !important
    }

    .rotate-x-xxl-12, .rotate-x-xxl-12-focus:focus, .rotate-x-xxl-12-hover:hover {
        --x-rotate-x: 12deg !important
    }

    .rotate-x-xxl-30, .rotate-x-xxl-30-focus:focus, .rotate-x-xxl-30-hover:hover {
        --x-rotate-x: 30deg !important
    }

    .rotate-x-xxl-45, .rotate-x-xxl-45-focus:focus, .rotate-x-xxl-45-hover:hover {
        --x-rotate-x: 45deg !important
    }

    .rotate-x-xxl-90, .rotate-x-xxl-90-focus:focus, .rotate-x-xxl-90-hover:hover {
        --x-rotate-x: 90deg !important
    }

    .rotate-x-xxl-180, .rotate-x-xxl-180-focus:focus, .rotate-x-xxl-180-hover:hover {
        --x-rotate-x: 180deg !important
    }

    .rotate-x-xxl-n1, .rotate-x-xxl-n1-focus:focus, .rotate-x-xxl-n1-hover:hover {
        --x-rotate-x: -1deg !important
    }

    .rotate-x-xxl-n2, .rotate-x-xxl-n2-focus:focus, .rotate-x-xxl-n2-hover:hover {
        --x-rotate-x: -2deg !important
    }

    .rotate-x-xxl-n3, .rotate-x-xxl-n3-focus:focus, .rotate-x-xxl-n3-hover:hover {
        --x-rotate-x: -3deg !important
    }

    .rotate-x-xxl-n6, .rotate-x-xxl-n6-focus:focus, .rotate-x-xxl-n6-hover:hover {
        --x-rotate-x: -6deg !important
    }

    .rotate-x-xxl-n12, .rotate-x-xxl-n12-focus:focus, .rotate-x-xxl-n12-hover:hover {
        --x-rotate-x: -12deg !important
    }

    .rotate-x-xxl-n30, .rotate-x-xxl-n30-focus:focus, .rotate-x-xxl-n30-hover:hover {
        --x-rotate-x: -30deg !important
    }

    .rotate-x-xxl-n45, .rotate-x-xxl-n45-focus:focus, .rotate-x-xxl-n45-hover:hover {
        --x-rotate-x: -45deg !important
    }

    .rotate-x-xxl-n90, .rotate-x-xxl-n90-focus:focus, .rotate-x-xxl-n90-hover:hover {
        --x-rotate-x: -90deg !important
    }

    .rotate-x-xxl-n180, .rotate-x-xxl-n180-focus:focus, .rotate-x-xxl-n180-hover:hover {
        --x-rotate-x: -180deg !important
    }

    .perspective-xxl-100, .perspective-xxl-100-focus:focus, .perspective-xxl-100-hover:hover {
        --x-perspective: 100px !important
    }

    .perspective-xxl-200, .perspective-xxl-200-focus:focus, .perspective-xxl-200-hover:hover {
        --x-perspective: 200px !important
    }

    .perspective-xxl-300, .perspective-xxl-300-focus:focus, .perspective-xxl-300-hover:hover {
        --x-perspective: 300px !important
    }

    .perspective-xxl-400, .perspective-xxl-400-focus:focus, .perspective-xxl-400-hover:hover {
        --x-perspective: 400px !important
    }

    .perspective-xxl-500, .perspective-xxl-500-focus:focus, .perspective-xxl-500-hover:hover {
        --x-perspective: 500px !important
    }

    .perspective-xxl-600, .perspective-xxl-600-focus:focus, .perspective-xxl-600-hover:hover {
        --x-perspective: 600px !important
    }

    .perspective-xxl-700, .perspective-xxl-700-focus:focus, .perspective-xxl-700-hover:hover {
        --x-perspective: 700px !important
    }

    .perspective-xxl-800, .perspective-xxl-800-focus:focus, .perspective-xxl-800-hover:hover {
        --x-perspective: 800px !important
    }

    .perspective-xxl-1000, .perspective-xxl-1000-focus:focus, .perspective-xxl-1000-hover:hover {
        --x-perspective: 1000px !important
    }

    .perspective-xxl-1250, .perspective-xxl-1250-focus:focus, .perspective-xxl-1250-hover:hover {
        --x-perspective: 1250px !important
    }

    .perspective-xxl-1500, .perspective-xxl-1500-focus:focus, .perspective-xxl-1500-hover:hover {
        --x-perspective: 1500px !important
    }

    .translate-y-xxl-0, .translate-y-xxl-0-focus:focus, .translate-y-xxl-0-hover:hover {
        --x-translate-y: 0 !important
    }

    .translate-y-xxl-1, .translate-y-xxl-1-focus:focus, .translate-y-xxl-1-hover:hover {
        --x-translate-y: 0.25rem !important
    }

    .translate-y-xxl-2, .translate-y-xxl-2-focus:focus, .translate-y-xxl-2-hover:hover {
        --x-translate-y: 0.5rem !important
    }

    .translate-y-xxl-3, .translate-y-xxl-3-focus:focus, .translate-y-xxl-3-hover:hover {
        --x-translate-y: 0.75rem !important
    }

    .translate-y-xxl-4, .translate-y-xxl-4-focus:focus, .translate-y-xxl-4-hover:hover {
        --x-translate-y: 1rem !important
    }

    .translate-y-xxl-5, .translate-y-xxl-5-focus:focus, .translate-y-xxl-5-hover:hover {
        --x-translate-y: 1.25rem !important
    }

    .translate-y-xxl-6, .translate-y-xxl-6-focus:focus, .translate-y-xxl-6-hover:hover {
        --x-translate-y: 1.5rem !important
    }

    .translate-y-xxl-7, .translate-y-xxl-7-focus:focus, .translate-y-xxl-7-hover:hover {
        --x-translate-y: 1.75rem !important
    }

    .translate-y-xxl-8, .translate-y-xxl-8-focus:focus, .translate-y-xxl-8-hover:hover {
        --x-translate-y: 2rem !important
    }

    .translate-y-xxl-10, .translate-y-xxl-10-focus:focus, .translate-y-xxl-10-hover:hover {
        --x-translate-y: 2.5rem !important
    }

    .translate-y-xxl-12, .translate-y-xxl-12-focus:focus, .translate-y-xxl-12-hover:hover {
        --x-translate-y: 3rem !important
    }

    .translate-y-xxl-14, .translate-y-xxl-14-focus:focus, .translate-y-xxl-14-hover:hover {
        --x-translate-y: 3.5rem !important
    }

    .translate-y-xxl-16, .translate-y-xxl-16-focus:focus, .translate-y-xxl-16-hover:hover {
        --x-translate-y: 4rem !important
    }

    .translate-y-xxl-18, .translate-y-xxl-18-focus:focus, .translate-y-xxl-18-hover:hover {
        --x-translate-y: 4.5rem !important
    }

    .translate-y-xxl-20, .translate-y-xxl-20-focus:focus, .translate-y-xxl-20-hover:hover {
        --x-translate-y: 5rem !important
    }

    .translate-y-xxl-24, .translate-y-xxl-24-focus:focus, .translate-y-xxl-24-hover:hover {
        --x-translate-y: 6rem !important
    }

    .translate-y-xxl-32, .translate-y-xxl-32-focus:focus, .translate-y-xxl-32-hover:hover {
        --x-translate-y: 8rem !important
    }

    .translate-y-xxl-40, .translate-y-xxl-40-focus:focus, .translate-y-xxl-40-hover:hover {
        --x-translate-y: 10rem !important
    }

    .translate-y-xxl-48, .translate-y-xxl-48-focus:focus, .translate-y-xxl-48-hover:hover {
        --x-translate-y: 12rem !important
    }

    .translate-y-xxl-56, .translate-y-xxl-56-focus:focus, .translate-y-xxl-56-hover:hover {
        --x-translate-y: 14rem !important
    }

    .translate-y-xxl-64, .translate-y-xxl-64-focus:focus, .translate-y-xxl-64-hover:hover {
        --x-translate-y: 16rem !important
    }

    .translate-y-xxl-72, .translate-y-xxl-72-focus:focus, .translate-y-xxl-72-hover:hover {
        --x-translate-y: 18rem !important
    }

    .translate-y-xxl-80, .translate-y-xxl-80-focus:focus, .translate-y-xxl-80-hover:hover {
        --x-translate-y: 20rem !important
    }

    .translate-y-xxl-88, .translate-y-xxl-88-focus:focus, .translate-y-xxl-88-hover:hover {
        --x-translate-y: 22rem !important
    }

    .translate-y-xxl-96, .translate-y-xxl-96-focus:focus, .translate-y-xxl-96-hover:hover {
        --x-translate-y: 24rem !important
    }

    .translate-y-xxl-px, .translate-y-xxl-px-focus:focus, .translate-y-xxl-px-hover:hover {
        --x-translate-y: 1px !important
    }

    .translate-y-xxl-1\/2, .translate-y-xxl-1\/2-focus:focus, .translate-y-xxl-1\/2-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-xxl-1\/3, .translate-y-xxl-1\/3-focus:focus, .translate-y-xxl-1\/3-hover:hover {
        --x-translate-y: 33.333333% !important
    }

    .translate-y-xxl-1\/4, .translate-y-xxl-1\/4-focus:focus, .translate-y-xxl-1\/4-hover:hover {
        --x-translate-y: 25% !important
    }

    .translate-y-xxl-1\/5, .translate-y-xxl-1\/5-focus:focus, .translate-y-xxl-1\/5-hover:hover {
        --x-translate-y: 20% !important
    }

    .translate-y-xxl-2\/3, .translate-y-xxl-2\/3-focus:focus, .translate-y-xxl-2\/3-hover:hover {
        --x-translate-y: 66.666667% !important
    }

    .translate-y-xxl-2\/5, .translate-y-xxl-2\/5-focus:focus, .translate-y-xxl-2\/5-hover:hover {
        --x-translate-y: 50% !important
    }

    .translate-y-xxl-3\/4, .translate-y-xxl-3\/4-focus:focus, .translate-y-xxl-3\/4-hover:hover {
        --x-translate-y: 75% !important
    }

    .translate-y-xxl-full, .translate-y-xxl-full-focus:focus, .translate-y-xxl-full-hover:hover {
        --x-translate-y: 100% !important
    }

    .translate-y-xxl-n1, .translate-y-xxl-n1-focus:focus, .translate-y-xxl-n1-hover:hover {
        --x-translate-y: -0.25rem !important
    }

    .translate-y-xxl-n2, .translate-y-xxl-n2-focus:focus, .translate-y-xxl-n2-hover:hover {
        --x-translate-y: -0.5rem !important
    }

    .translate-y-xxl-n3, .translate-y-xxl-n3-focus:focus, .translate-y-xxl-n3-hover:hover {
        --x-translate-y: -0.75rem !important
    }

    .translate-y-xxl-n4, .translate-y-xxl-n4-focus:focus, .translate-y-xxl-n4-hover:hover {
        --x-translate-y: -1rem !important
    }

    .translate-y-xxl-n5, .translate-y-xxl-n5-focus:focus, .translate-y-xxl-n5-hover:hover {
        --x-translate-y: -1.25rem !important
    }

    .translate-y-xxl-n6, .translate-y-xxl-n6-focus:focus, .translate-y-xxl-n6-hover:hover {
        --x-translate-y: -1.5rem !important
    }

    .translate-y-xxl-n7, .translate-y-xxl-n7-focus:focus, .translate-y-xxl-n7-hover:hover {
        --x-translate-y: -1.75rem !important
    }

    .translate-y-xxl-n8, .translate-y-xxl-n8-focus:focus, .translate-y-xxl-n8-hover:hover {
        --x-translate-y: -2rem !important
    }

    .translate-y-xxl-n10, .translate-y-xxl-n10-focus:focus, .translate-y-xxl-n10-hover:hover {
        --x-translate-y: -2.5rem !important
    }

    .translate-y-xxl-n12, .translate-y-xxl-n12-focus:focus, .translate-y-xxl-n12-hover:hover {
        --x-translate-y: -3rem !important
    }

    .translate-y-xxl-n14, .translate-y-xxl-n14-focus:focus, .translate-y-xxl-n14-hover:hover {
        --x-translate-y: -3.5rem !important
    }

    .translate-y-xxl-n16, .translate-y-xxl-n16-focus:focus, .translate-y-xxl-n16-hover:hover {
        --x-translate-y: -4rem !important
    }

    .translate-y-xxl-n18, .translate-y-xxl-n18-focus:focus, .translate-y-xxl-n18-hover:hover {
        --x-translate-y: -4.5rem !important
    }

    .translate-y-xxl-n20, .translate-y-xxl-n20-focus:focus, .translate-y-xxl-n20-hover:hover {
        --x-translate-y: -5rem !important
    }

    .translate-y-xxl-n24, .translate-y-xxl-n24-focus:focus, .translate-y-xxl-n24-hover:hover {
        --x-translate-y: -6rem !important
    }

    .translate-y-xxl-n32, .translate-y-xxl-n32-focus:focus, .translate-y-xxl-n32-hover:hover {
        --x-translate-y: -8rem !important
    }

    .translate-y-xxl-n40, .translate-y-xxl-n40-focus:focus, .translate-y-xxl-n40-hover:hover {
        --x-translate-y: -10rem !important
    }

    .translate-y-xxl-n48, .translate-y-xxl-n48-focus:focus, .translate-y-xxl-n48-hover:hover {
        --x-translate-y: -12rem !important
    }

    .translate-y-xxl-n56, .translate-y-xxl-n56-focus:focus, .translate-y-xxl-n56-hover:hover {
        --x-translate-y: -14rem !important
    }

    .translate-y-xxl-n64, .translate-y-xxl-n64-focus:focus, .translate-y-xxl-n64-hover:hover {
        --x-translate-y: -16rem !important
    }

    .translate-y-xxl-n72, .translate-y-xxl-n72-focus:focus, .translate-y-xxl-n72-hover:hover {
        --x-translate-y: -18rem !important
    }

    .translate-y-xxl-n80, .translate-y-xxl-n80-focus:focus, .translate-y-xxl-n80-hover:hover {
        --x-translate-y: -20rem !important
    }

    .translate-y-xxl-n88, .translate-y-xxl-n88-focus:focus, .translate-y-xxl-n88-hover:hover {
        --x-translate-y: -22rem !important
    }

    .translate-y-xxl-n96, .translate-y-xxl-n96-focus:focus, .translate-y-xxl-n96-hover:hover {
        --x-translate-y: -24rem !important
    }

    .translate-y-xxl-npx, .translate-y-xxl-npx-focus:focus, .translate-y-xxl-npx-hover:hover {
        --x-translate-y: -1px !important
    }

    .translate-y-xxl-n1\/2, .translate-y-xxl-n1\/2-focus:focus, .translate-y-xxl-n1\/2-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-xxl-n1\/3, .translate-y-xxl-n1\/3-focus:focus, .translate-y-xxl-n1\/3-hover:hover {
        --x-translate-y: -33.333333% !important
    }

    .translate-y-xxl-n1\/4, .translate-y-xxl-n1\/4-focus:focus, .translate-y-xxl-n1\/4-hover:hover {
        --x-translate-y: -25% !important
    }

    .translate-y-xxl-n1\/5, .translate-y-xxl-n1\/5-focus:focus, .translate-y-xxl-n1\/5-hover:hover {
        --x-translate-y: -20% !important
    }

    .translate-y-xxl-n2\/3, .translate-y-xxl-n2\/3-focus:focus, .translate-y-xxl-n2\/3-hover:hover {
        --x-translate-y: -66.666667% !important
    }

    .translate-y-xxl-n2\/5, .translate-y-xxl-n2\/5-focus:focus, .translate-y-xxl-n2\/5-hover:hover {
        --x-translate-y: -50% !important
    }

    .translate-y-xxl-n3\/4, .translate-y-xxl-n3\/4-focus:focus, .translate-y-xxl-n3\/4-hover:hover {
        --x-translate-y: -75% !important
    }

    .translate-y-xxl-nfull, .translate-y-xxl-nfull-focus:focus, .translate-y-xxl-nfull-hover:hover {
        --x-translate-y: -100% !important
    }

    .translate-x-xxl-0, .translate-x-xxl-0-focus:focus, .translate-x-xxl-0-hover:hover {
        --x-translate-x: 0 !important
    }

    .translate-x-xxl-1, .translate-x-xxl-1-focus:focus, .translate-x-xxl-1-hover:hover {
        --x-translate-x: 0.25rem !important
    }

    .translate-x-xxl-2, .translate-x-xxl-2-focus:focus, .translate-x-xxl-2-hover:hover {
        --x-translate-x: 0.5rem !important
    }

    .translate-x-xxl-3, .translate-x-xxl-3-focus:focus, .translate-x-xxl-3-hover:hover {
        --x-translate-x: 0.75rem !important
    }

    .translate-x-xxl-4, .translate-x-xxl-4-focus:focus, .translate-x-xxl-4-hover:hover {
        --x-translate-x: 1rem !important
    }

    .translate-x-xxl-5, .translate-x-xxl-5-focus:focus, .translate-x-xxl-5-hover:hover {
        --x-translate-x: 1.25rem !important
    }

    .translate-x-xxl-6, .translate-x-xxl-6-focus:focus, .translate-x-xxl-6-hover:hover {
        --x-translate-x: 1.5rem !important
    }

    .translate-x-xxl-7, .translate-x-xxl-7-focus:focus, .translate-x-xxl-7-hover:hover {
        --x-translate-x: 1.75rem !important
    }

    .translate-x-xxl-8, .translate-x-xxl-8-focus:focus, .translate-x-xxl-8-hover:hover {
        --x-translate-x: 2rem !important
    }

    .translate-x-xxl-10, .translate-x-xxl-10-focus:focus, .translate-x-xxl-10-hover:hover {
        --x-translate-x: 2.5rem !important
    }

    .translate-x-xxl-12, .translate-x-xxl-12-focus:focus, .translate-x-xxl-12-hover:hover {
        --x-translate-x: 3rem !important
    }

    .translate-x-xxl-14, .translate-x-xxl-14-focus:focus, .translate-x-xxl-14-hover:hover {
        --x-translate-x: 3.5rem !important
    }

    .translate-x-xxl-16, .translate-x-xxl-16-focus:focus, .translate-x-xxl-16-hover:hover {
        --x-translate-x: 4rem !important
    }

    .translate-x-xxl-18, .translate-x-xxl-18-focus:focus, .translate-x-xxl-18-hover:hover {
        --x-translate-x: 4.5rem !important
    }

    .translate-x-xxl-20, .translate-x-xxl-20-focus:focus, .translate-x-xxl-20-hover:hover {
        --x-translate-x: 5rem !important
    }

    .translate-x-xxl-24, .translate-x-xxl-24-focus:focus, .translate-x-xxl-24-hover:hover {
        --x-translate-x: 6rem !important
    }

    .translate-x-xxl-32, .translate-x-xxl-32-focus:focus, .translate-x-xxl-32-hover:hover {
        --x-translate-x: 8rem !important
    }

    .translate-x-xxl-40, .translate-x-xxl-40-focus:focus, .translate-x-xxl-40-hover:hover {
        --x-translate-x: 10rem !important
    }

    .translate-x-xxl-48, .translate-x-xxl-48-focus:focus, .translate-x-xxl-48-hover:hover {
        --x-translate-x: 12rem !important
    }

    .translate-x-xxl-56, .translate-x-xxl-56-focus:focus, .translate-x-xxl-56-hover:hover {
        --x-translate-x: 14rem !important
    }

    .translate-x-xxl-64, .translate-x-xxl-64-focus:focus, .translate-x-xxl-64-hover:hover {
        --x-translate-x: 16rem !important
    }

    .translate-x-xxl-72, .translate-x-xxl-72-focus:focus, .translate-x-xxl-72-hover:hover {
        --x-translate-x: 18rem !important
    }

    .translate-x-xxl-80, .translate-x-xxl-80-focus:focus, .translate-x-xxl-80-hover:hover {
        --x-translate-x: 20rem !important
    }

    .translate-x-xxl-88, .translate-x-xxl-88-focus:focus, .translate-x-xxl-88-hover:hover {
        --x-translate-x: 22rem !important
    }

    .translate-x-xxl-96, .translate-x-xxl-96-focus:focus, .translate-x-xxl-96-hover:hover {
        --x-translate-x: 24rem !important
    }

    .translate-x-xxl-px, .translate-x-xxl-px-focus:focus, .translate-x-xxl-px-hover:hover {
        --x-translate-x: 1px !important
    }

    .translate-x-xxl-1\/2, .translate-x-xxl-1\/2-focus:focus, .translate-x-xxl-1\/2-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-xxl-1\/3, .translate-x-xxl-1\/3-focus:focus, .translate-x-xxl-1\/3-hover:hover {
        --x-translate-x: 33.333333% !important
    }

    .translate-x-xxl-1\/4, .translate-x-xxl-1\/4-focus:focus, .translate-x-xxl-1\/4-hover:hover {
        --x-translate-x: 25% !important
    }

    .translate-x-xxl-1\/5, .translate-x-xxl-1\/5-focus:focus, .translate-x-xxl-1\/5-hover:hover {
        --x-translate-x: 20% !important
    }

    .translate-x-xxl-2\/3, .translate-x-xxl-2\/3-focus:focus, .translate-x-xxl-2\/3-hover:hover {
        --x-translate-x: 66.666667% !important
    }

    .translate-x-xxl-2\/5, .translate-x-xxl-2\/5-focus:focus, .translate-x-xxl-2\/5-hover:hover {
        --x-translate-x: 50% !important
    }

    .translate-x-xxl-3\/4, .translate-x-xxl-3\/4-focus:focus, .translate-x-xxl-3\/4-hover:hover {
        --x-translate-x: 75% !important
    }

    .translate-x-xxl-full, .translate-x-xxl-full-focus:focus, .translate-x-xxl-full-hover:hover {
        --x-translate-x: 100% !important
    }

    .translate-x-xxl-n1, .translate-x-xxl-n1-focus:focus, .translate-x-xxl-n1-hover:hover {
        --x-translate-x: -0.25rem !important
    }

    .translate-x-xxl-n2, .translate-x-xxl-n2-focus:focus, .translate-x-xxl-n2-hover:hover {
        --x-translate-x: -0.5rem !important
    }

    .translate-x-xxl-n3, .translate-x-xxl-n3-focus:focus, .translate-x-xxl-n3-hover:hover {
        --x-translate-x: -0.75rem !important
    }

    .translate-x-xxl-n4, .translate-x-xxl-n4-focus:focus, .translate-x-xxl-n4-hover:hover {
        --x-translate-x: -1rem !important
    }

    .translate-x-xxl-n5, .translate-x-xxl-n5-focus:focus, .translate-x-xxl-n5-hover:hover {
        --x-translate-x: -1.25rem !important
    }

    .translate-x-xxl-n6, .translate-x-xxl-n6-focus:focus, .translate-x-xxl-n6-hover:hover {
        --x-translate-x: -1.5rem !important
    }

    .translate-x-xxl-n7, .translate-x-xxl-n7-focus:focus, .translate-x-xxl-n7-hover:hover {
        --x-translate-x: -1.75rem !important
    }

    .translate-x-xxl-n8, .translate-x-xxl-n8-focus:focus, .translate-x-xxl-n8-hover:hover {
        --x-translate-x: -2rem !important
    }

    .translate-x-xxl-n10, .translate-x-xxl-n10-focus:focus, .translate-x-xxl-n10-hover:hover {
        --x-translate-x: -2.5rem !important
    }

    .translate-x-xxl-n12, .translate-x-xxl-n12-focus:focus, .translate-x-xxl-n12-hover:hover {
        --x-translate-x: -3rem !important
    }

    .translate-x-xxl-n14, .translate-x-xxl-n14-focus:focus, .translate-x-xxl-n14-hover:hover {
        --x-translate-x: -3.5rem !important
    }

    .translate-x-xxl-n16, .translate-x-xxl-n16-focus:focus, .translate-x-xxl-n16-hover:hover {
        --x-translate-x: -4rem !important
    }

    .translate-x-xxl-n18, .translate-x-xxl-n18-focus:focus, .translate-x-xxl-n18-hover:hover {
        --x-translate-x: -4.5rem !important
    }

    .translate-x-xxl-n20, .translate-x-xxl-n20-focus:focus, .translate-x-xxl-n20-hover:hover {
        --x-translate-x: -5rem !important
    }

    .translate-x-xxl-n24, .translate-x-xxl-n24-focus:focus, .translate-x-xxl-n24-hover:hover {
        --x-translate-x: -6rem !important
    }

    .translate-x-xxl-n32, .translate-x-xxl-n32-focus:focus, .translate-x-xxl-n32-hover:hover {
        --x-translate-x: -8rem !important
    }

    .translate-x-xxl-n40, .translate-x-xxl-n40-focus:focus, .translate-x-xxl-n40-hover:hover {
        --x-translate-x: -10rem !important
    }

    .translate-x-xxl-n48, .translate-x-xxl-n48-focus:focus, .translate-x-xxl-n48-hover:hover {
        --x-translate-x: -12rem !important
    }

    .translate-x-xxl-n56, .translate-x-xxl-n56-focus:focus, .translate-x-xxl-n56-hover:hover {
        --x-translate-x: -14rem !important
    }

    .translate-x-xxl-n64, .translate-x-xxl-n64-focus:focus, .translate-x-xxl-n64-hover:hover {
        --x-translate-x: -16rem !important
    }

    .translate-x-xxl-n72, .translate-x-xxl-n72-focus:focus, .translate-x-xxl-n72-hover:hover {
        --x-translate-x: -18rem !important
    }

    .translate-x-xxl-n80, .translate-x-xxl-n80-focus:focus, .translate-x-xxl-n80-hover:hover {
        --x-translate-x: -20rem !important
    }

    .translate-x-xxl-n88, .translate-x-xxl-n88-focus:focus, .translate-x-xxl-n88-hover:hover {
        --x-translate-x: -22rem !important
    }

    .translate-x-xxl-n96, .translate-x-xxl-n96-focus:focus, .translate-x-xxl-n96-hover:hover {
        --x-translate-x: -24rem !important
    }

    .translate-x-xxl-npx, .translate-x-xxl-npx-focus:focus, .translate-x-xxl-npx-hover:hover {
        --x-translate-x: -1px !important
    }

    .translate-x-xxl-n1\/2, .translate-x-xxl-n1\/2-focus:focus, .translate-x-xxl-n1\/2-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-xxl-n1\/3, .translate-x-xxl-n1\/3-focus:focus, .translate-x-xxl-n1\/3-hover:hover {
        --x-translate-x: -33.333333% !important
    }

    .translate-x-xxl-n1\/4, .translate-x-xxl-n1\/4-focus:focus, .translate-x-xxl-n1\/4-hover:hover {
        --x-translate-x: -25% !important
    }

    .translate-x-xxl-n1\/5, .translate-x-xxl-n1\/5-focus:focus, .translate-x-xxl-n1\/5-hover:hover {
        --x-translate-x: -20% !important
    }

    .translate-x-xxl-n2\/3, .translate-x-xxl-n2\/3-focus:focus, .translate-x-xxl-n2\/3-hover:hover {
        --x-translate-x: -66.666667% !important
    }

    .translate-x-xxl-n2\/5, .translate-x-xxl-n2\/5-focus:focus, .translate-x-xxl-n2\/5-hover:hover {
        --x-translate-x: -50% !important
    }

    .translate-x-xxl-n3\/4, .translate-x-xxl-n3\/4-focus:focus, .translate-x-xxl-n3\/4-hover:hover {
        --x-translate-x: -75% !important
    }

    .translate-x-xxl-nfull, .translate-x-xxl-nfull-focus:focus, .translate-x-xxl-nfull-hover:hover {
        --x-translate-x: -100% !important
    }
}

@media (min-width:1200px) {
    .text-xl {
        font-size: 1.5rem !important
    }

    .text-2xl {
        font-size: 2rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}
