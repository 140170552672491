.hilaki {
    padding-left: 80px;
    padding-right: 80px;
}
.dashboad-outer-cont-dash {
    background-image: linear-gradient(45deg, #00025d, #5c60f5);
    background-size: auto;
    border-bottom: 1px solid #c8c8c8 !important;
}
.bg-cust-tag {
    background-color: rgb(242, 242, 242);
}
.loadingStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 24px;
}
.purple1 {
   background-color: #4a4dc4
}
.purple2 {
    background-color: #e7f0ff
}
.authStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 24px;
}
.text-cust-d-pur {
   color: #241644 !important;
}
.alert-wrapper-below {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
    width: 90%; 
    text-align: center;
    max-width: 600px; 
}
@media (min-width: 768px) {
    .alert-wrapper-below {
        width: 30%; 
    }
}
.personInfo_em a {
    color: #fff;
}

.personInfo_em a:hover {
    color: #fff;
    text-decoration: underline;
}
.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}
.fontWeightS {
    font-weight: 700;
}
.error-content {
    width: 50%;
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

    .error-content h1 {
        font-size: 2em;
        margin-bottom: 20px;
        color: #e74c3c; /* You can adjust this color */
    }

    .error-content p {
        font-size: 1.2em;
        color: #555;
    }

.tooltips{
    padding:3px;
}
.dashboad-outer-cont-dash h1, h2, h3, h4, h5, h6, p {
    color: #fff !important;
}
    .dashboad-outer-cont-dash h6.text-xs.text-uppercase.mb-1 {
        color: #fff !important;
    }
    .dashboad-outer-cont-dash .title-fleek {
        color: #fff !important;
    }
    .dashboad-outer-cont-dash .pref_do, .pref_dl, .pref_sl, .pref_sh {
        color: #fff !important;
    }
    .dashboad-outer-cont-dash .dgfsdfgsdfg p{
        color: #fff !important;
    }
.title_vt {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: -0.25px;
    font-family: var(--x-font-talentup);
    color: #141C33;
    font-weight: 800;
    text-align: left !important;
}

.mid-but-tog {
    background-color: #0e091a;
}
.light-tal-pur-back {
    background-color: #9672ff !important;
}
.title_wt {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: -0.25px;
    font-family: var(--x-font-talentup);
    color: #fff !important;
    font-weight: 800;
    text-align: center !important;
}

.form_bu_asdfhhvf {
    text-align: center;
}

.profile-dash-asdlf{
    text-align:center;
    margin-bottom:20px;
}

.uiPojd {
    box-sizing: border-box;
    -webkit-text-fill-color: currentcolor;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.HuSection-inner-grb {
    background-color: #fff;
}


.HuSection-inner-hcp {
    display: flex;
    flex-direction: row;
    margin: auto;
}



.HuSection_createprofile_l_POlj {
    position: relative;
    border-radius: 20px;
    height: 590px;
    width: 100%;
    overflow-y: hidden;
    background-color: rgb(231, 240, 255);
}

.HuSection-inner-grb h2 {
    color: black;
}

.HuSection-inner-vt {
    padding: 0px 4px;
}

.HuSection-inner-st {
    justify-content: center;
    padding: 10px;
}

.center-block {
    align-items: center;
    justify-content: center;
}

.dark-grey-border {
    border: 2px solid #28184d !important;
}

.secondary-font-color {
    color: #28184d !important;
}

.bg-secondary {
    background-color: rgb(40 24 77 / 0%) !important;
}

    .bg-secondary:hover {
        background-color: rgb(40 24 77 / 20%) !important;
    }

.bg-transi {
    transition: background-color .3s ease-in;
}

.container-index-w234 {
    max-width: 1320px;
    justify-content: center;
}

.homepage-content-wrapper {
}

.secondary-bg-color-light {
    background-color: rgb(231, 240, 255) !important;
}

.alert-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 0.75rem;
    position: sticky;
    top: 0;
    z-index: 100;
}

.footer-logo {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.profile-button-w200 {
    width: 200px;
}

social-footer-inner {
    justify-content: left;
}

@media screen and (max-width: 500px) {
    .social-footer {
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .social-footer-inner {
        justify-content: center;
    }
}

.djjlks_cu_err_pa {
    background-color: #241644;
}

.centered-div-error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 100vh; /* Set the height to the full viewport height */
}

.cu_err_pa > h1, h2, h4 {
    color: #fff;
    text-align: center !important;
}

.cu_err_404 > h1 {
    color: #fff;
    font-weight: 800;
    font-size: 4em;
}

@media screen and (max-width: 1024px) {
    .cu_err_404 h1 {
        font-size: 3em;
    }
}

@media screen and (max-width: 1024px) {
    .centered-div-error {
        height: 30vh;
    }
}

.cu_err_bu_ds {
    text-align: center;
}

.btn.edit-but:focus {
    box-shadow: none !important;
}

.btn.delete-but:focus {
    box-shadow: none !important;
}

.btn.edit-but:hover {
    color: #5c60f5;
}

.btn.delete-but:hover {
    color: #5c60f5;
}

.f-row {
    width: 1000px;
    max-width: 100%;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 60px;
}

.social-footer {
}
.job-card-slw {
   border-bottom: 1px solid #c8c8c8 !important;
}
.job_post_body_descr {
    margin-top: 1.25rem !important;
}
.foo_ls_lskui {
    background-color: #0e091a;
    padding: 20px;
    color: #fff;
}

    .foo_ls_lskui h1, h2, h3, h4, h5 {
        color: #fff;
    }

    .foo_ls_lskui a {
        color: #fff;
    }

.centered-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 70vh; /* Set the height to the full viewport height */
}

.err_lk_lasdnv_lkk h1, h2, h4 {
    text-align: center;
}

.fade-out-tu {
    opacity: 0;
    transition: opacity 0.5s;
}

.persInfoCard p {
    color: black !important;
}

.right-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.education-create-kksj {
    margin: 10px;
}

.experience-create-kksj {
    margin: 10px;
}

.summary-create-kksj {
    margin: 10px;
}

.social-create-kksj {
    margin: 10px;
}

.pref-create-kksj {
    margin: 10px;
}

.skill-create-kksj {
    margin: 10px;
}

.oaosdhfk {
    position: relative;
}

.edit-icon {
    position: absolute;
    top: 4px;
    right: 15px;
    z-index: 10;
}

.chev_up {
    display: block;
}

.response-message {
    display: none;
}

.Exp-Title {
    display: flex;
    flex-direction: row;
}

.current-Title-la {
    margin-left: 0px;
}

.un_ill_dash {
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 10px;
    padding: 10px;
}

.active-edit-item {
    border-left: 2px solid purple !important;
    transition: border-left 0.3s ease-in-out;
}

.profile-lalk_lo .card-body-iajj {
    padding: 20px;
}

.profile-lalk_lo .card-header:first-of-type {
    border-bottom: 1px solid #c8c8c8 !important;
}
.profile-lalk_bus .bg-surface-secondary .bnasdf_asldf .card-body {
    border-bottom: 1px solid #c8c8c8;
}

.profile-lalk_bus .bg-surface-secondary .bnasdf_asldf .car-dlj-ds {
    border-bottom: 1px solid #c8c8c8;
}

.profile-lalk_bus .bg-surface-secondary .education-item:last-child .bnasdf_asldf .car-dlj-ds {
    border-bottom: none;
}
.profile-lalk_bus .bg-surface-secondary .experience-item:last-child .bnasdf_asldf .car-dlj-ds {
    border-bottom: none;
}
.profile-lalk_bus .bg-surface-secondary .experience-item:last-child .bnasdf_asldf .car-dlj-ds {
    border-bottom: none;
}
.profile-lalk_bus .bg-surface-secondary .certificate-item:last-child .bnasdf_asldf .car-dlj-ds {
    border-bottom: none;
}
.profile-lalk_bus .bg-surface-secondary .skill-item:last-child .bnasdf_asldf .car-dlj-ds {
    border-bottom: none;
}
.profile-lalk_bus .bg-surface-secondary .summary-item:last-child .bnasdf_asldf .car-dlj-ds {
    border-bottom: none;
}
/*.profile-lalk_lo .profile-lalk_bus .card-body:first-of-type {
    border-bottom: 1px solid #c8c8c8 !important;
}*/
.profile-lalk_lo .card {
    border: 1px solid #c8c8c8 !important;
}

.as_ksk_iu.bi-chevron-down::before {
    margin-right: 4px !important;
}

.form-item-checkable label.form-item span.form-item-click {
    background-color: transparent !important;
    color: #fff !important;
}

.me-2-15 {
    margin-right: -1.5rem !important;
}

.form-item-check:checked + .form-item .form-item-click, .form-item-check:hover + .form-item .form-item-click {
    border-color: #fff !important;
    background-color: #fff !important;
    color: #160934 !important;
}

.accordion-button:after {
    right: 20px !important;
}

.csshomelogo {
    height: 60px !important;
}

.collapse.navbar-collapse .profile-progress span.pp_done i.bi-check-circle-fill {
    color: #5c60f5;
    font-size: 0.9em;
}

.collapse.navbar-collapse .profile-progress span.pp_done {
    color: black;
}

.collapse.navbar-collapse .profile-progress span.pp_tobedone i.bi-check-circle-fill {
    color: dimgrey;
    font-size: 0.9em;
}

.collapse.navbar-collapse .profile-progress span.pp_tobedone {
    color: dimgrey;
}

.collapse.navbar-collapse .profile-progress span {
    display: block;
    font-size: 0.9em;
}

.profile-progress {
    font-size: .875rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    white-space: nowrap;
    width:100%;
}
.ProfilePPAr {
    width: 100%;
}
.profile-progress h4 {
    text-align: left !important;
    color: black;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 0rem;
}

#add-ProfilePic {
    display: none;
}

.experience-item-read .btn.as_ksk_iu:focus {
    box-shadow: none !important;
}

.experience-item-read .btn.edit-experience:focus {
    box-shadow: none !important;
}

.education-item .btn.edit-education:focus {
    box-shadow: none !important;
}

.tumaithbak {
    background-color: #262869;
}

button.btn.as_ksk_iu {
    padding: .75rem 0rem !important;
}

.image-upload > input {
    display: none;
}

.ProPicSub {
    display: none;
}

.image-upload label {
    height: 120px;
    width: 120px;
    left: 50%;
    top: 0px;
    z-index: 1001;
    padding: 10px;
}

    .image-upload label img {
        border-radius: 50%;
        box-shadow: 0px 0px 5px 0px #c1c1c1;
        cursor: pointer;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 1px solid purple;
    }
.bus-profile-tlal{
    text-align:center;
}
.PlaceHolder-Sub-PP {
    background-color: purple;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px #c1c1c1;
    cursor: pointer;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PlaceHolder-PP {
    font-size: 2em;
    color: #fff;
}

.flink-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}

.title {
    font-size: calc(1.3rem + .6vw);
    color: #16192c;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 0;
}

.css-1bx3outerJob {
    margin-top: 40px;
}

.css-1dzn5ey:last-child {
    padding-bottom: 17px;
}

.css-1dzn5ey {
    padding: 17px 24px;
}

.form-group-aj {
    border-top: 1px solid #c8c8c8;
    padding: 6px 15px;
}

    .form-group-aj:last-child {
        border-bottom: 0px;
    }

.css-1dzn5ey-asid:last-child {
    padding-bottom: 17px;
}

.css-1dzn5ey-asid {
    padding: 0;
}

@media (min-width: 600px) {
    .css-vk9kdq {
        flex-direction: row;
    }
}


.edit-but {
    padding-left: 20px;
}

    .edit-but:before {
        content: "\F4CB"; /* use the Unicode value for the icon you want */
        transform: translateY(-50%);
        font-family: "bootstrap-icons";
        font-size: 16px;
    }

.download-but:before {
    content: "\F30A"; /* use the Unicode value for the icon you want */
    transform: translateY(-50%);
    font-family: "bootstrap-icons";
    font-size: 16px;
}

.make-default:before {
    content: "\F588"; /* use the Unicode value for the icon you want */
    transform: translateY(-50%);
    font-family: "bootstrap-icons";
    font-size: 16px;
}

.delete-but {
    padding-left: 20px; /* increase this value if needed */
}

    .delete-but:before {
        content: "\F5DE"; /* use the Unicode value for the icon you want */
        transform: translateY(-50%);
        font-family: "bootstrap-icons";
        font-size: 16px;
    }

.top-data-search-inner {
    margin: 0;
    padding: 10px;
}

.list-top-search-buttons .list-group-item {
    padding: 1.125rem 1rem 1.125rem 0.1rem !important;
}

.list-top-search-buttons ul li {
    display: table-cell
}

.list-top-search-buttons .list-group-item {
    border: none !important;
}

.top-search-cats h5 {
    color: #fff;
}

.list-top-search-buttons {
    list-style: none;
}

/*.homepage-content-wrapper-outer {
    color: #fff !important;
}*/

.homepage-content-wrapper-inner {
    padding-bottom: 48px;
    padding-left: 24px;
}

.filterclust {
    display: flex;
}

    .filterclust .subMenu-bor {
        border-left: 1px solid #ffffff47;
    }

        .filterclust .subMenu-bor:last-child {
            border-right: 1px solid #ffffff47;
        }

.subMenu-pad-t {
    padding-bottom: 16px;
    padding-top: 16px;
}

    .subMenu-pad-t a span {
        color: white;
    }

.iconrotate {
    -moz-transition: all 2s linear;
    -webkit-transition: all 2s linear;
    transition: all 2s linear;
}

    .iconrotate.icondown {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

.subMenu-pad-l {
    padding-left: 20px;
}

.subMenu-pad-r {
    padding-right: 20px;
}

#filtercContent > div {
    display: none;
}

.searchpage-content-wrapper-outer {
    background-color: #160934 !important;
    color: #fff !important;
}

.searchpage-content-wrapper-inner {
    padding-bottom: 5px;
    padding-left: 24px;
}

.mid-search-filters {
    background-color: #28184d !important;
    color: #fff !important;
}

.bg-purple {
    background-color: #5c60f5 !important;
    color: #fff !important;
}

.rounded-tag {
    border-radius: 0.375rem !important;
}

.text-tag-xs {
    font-size: .75rem !important;
}

.css-vk9kdq {
    display: flex;
}

.css-dlxf46 {
    background-color: rgb(255, 255, 255);
    color: rgb(18, 24, 40);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 8px;
    box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px;
    background-image: none;
    overflow: hidden;
    border: 1px solid #c8c8c859 !important;
}

.css-dlxf46:hover {
box-shadow: rgba(33, 33, 33, 0.25) 0px 6px 15px;
transform: translateY(-2px);
transition: all 300ms ease-in-out;
}

.css-dlxf47 {
    background-color: rgb(255, 255, 255);
    color: rgb(18, 24, 40);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px;
    background-image: none;
    overflow: hidden;
    border: 1px solid #c8c8c8 !important;
}
@media (min-width: 1000px) {
    .css-1ym2ckz {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 767px) {
    .refine-search-crit {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .refine-search-gridy {
    }
}

.css-dlxf79 {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid #c8c8c8 !important;
    box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px;
    background-image: none;
    overflow: hidden;
    margin: 32px 17px 0px 17px;
}

.css-1ym2ckz {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    line-height: 1;
    border-radius: 8px;
    overflow: hidden;
    user-select: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    background: #c071cf;
    margin-right: 16px;
}

.css-1hy9t21 {
    text-align: center;
    object-fit: cover;
    color: white;
}
/*.css-1hy9t21 {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}*/
.css-1xn7pn4 {
    margin: 0px;
    font-weight: 600;
    font-size: 1.0625rem;
    line-height: 1.375;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: rgb(18, 24, 40);
    text-decoration: none;
}

@media (min-width: 1200px) {
    .css-1xn7pn4 {
        font-size: 1.0909rem;
    }
}

.css-sns183 {
    margin: 0px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.57;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.css-1b7e7zf {
    -webkit-box-align: center;
    align-items: center;
    /* display: flex;*/
    flex-wrap: wrap;
    margin-left: -24px;
}

.css-fv3lde {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}


.css-1b7e7zf > * {
    margin-left: 24px;
    margin-top: 8px;
}

.css-12qu23a {
    margin: 0px;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 2.5;
    text-transform: uppercase;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(101, 116, 139);
}

.css-1yuhvjn {
    margin-top: 16px;
}

.css-s3m8m5 {
    background-color: rgb(255, 255, 255);
    color: rgb(18, 24, 40);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
}

.css-qpklrw {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
}
.rotate-icon {
    transform: rotate(45deg) !important; 
}
.css-hyy82 {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgb(230, 232, 240);
}
.background-grey-result {
   background-color: rgb(242, 242, 242);
}
.css-12koetd {
    margin: 0 0 0 0;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.4;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
h2.css-12koetd a {
    color: #241644 !important;
}
    .css-12koeto {
        margin: 0 0 10px 0;
        font-size: 0.95rem;
        font-weight: 700;
        line-height: 1.4;
        font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    }

.css-1kjyc92 {
    margin: 0px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: rgb(101, 116, 139);
}

.css-12u4qe3 {
    margin: 0px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
}

.css-c3e73q {
    margin: 0px 16px 0px 0px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    color: rgb(101, 116, 139);
}

.css-63lmbd {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 600;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    border-radius: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(80, 72, 229);
    box-shadow: none;
    text-transform: none;
    padding: 9px 16px;
}


/*job page*/

@media (min-width: 1200px) {
    .left30pad {
        padding-left: 30px;
    }
}

.css-xsuhrp {
    box-sizing: border-box;
    margin: 0px;
    max-width: 100%;
}

.css-1bx3nlx {
    background-color: rgb(255, 255, 255);
    color: rgb(18, 24, 40);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px;
    background-image: none;
    overflow: hidden;
}

css-3rd7fc {
    -webkit-box-align: center;
    align-items: center;
    padding: 32px 24px;
}
/*.css-1dzn5ey:last-child {
    padding-bottom: 32px;
}*/

@media (min-width: 1200px) {
    .css-m4v4nc {
        font-size: 1.4545rem;
    }
}

.css-m4v4nc {
    margin: 0px;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.375;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.css-178yklu {
    margin-top: 24px;
}

.css-6k75gh {
    color: rgb(101, 116, 139);
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

    .css-6k75gh p {
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 16px;
    }

.css-inner-job-tob {
    align-items: center;
    padding: 32px 24px;
}


/*Search section*/
@media screen and (min-width: 768px) {
    .search-section-outer {
        padding: 0px;
        min-height: 560px;
    }
}

.search-section-outer {
    align-items: center;
    padding: 0px 24px;
    min-height: 384px;
}

@media screen and (min-width: 1024px) {
    .search-section-desk {
        display: block;
        width: 100%;
    }
}
/*.search-section-desk {
    display: none;
}*/

.search-section-inner-grb {
    background-color: #47308b;
    color: rgb(255, 255, 255);
}

.css-sc-hd_21 {
    color: #9672ff;
}

@media screen and (min-width: 767px) {
    .search-section-vt {
        padding: 48px 64px 56px;
    }
}

.search-section-vt {
    box-sizing: border-box;
    min-width: 0;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}

.search-section-vt-form-al {
    padding: 0 !important;
}

@media screen and (min-width: 740px) {
    .pasdk_slk {
        flex-direction: row;
        display: flex;
    }
}

@media screen and (min-width: 740px) {
    .pasdk_slk {
        flex-direction: row;
        display: flex;
    }
}

@media screen and (max-width: 740px) {
    .search-section-sb-outer {
        padding: 20px;
    }
}


.search-section-vt-form-button button.btn-primary.btn-lg {
    width: 100%;
    line-height: inherit !important;
}

.search-section-vt-form-int {
    align-items: flex-end;
    position: relative
}

.search-section-vt-form-what {
    margin-right: 0.875rem;
    margin-bottom: 1rem;
    flex: 1;
}

.search-section-vt-form-where {
    margin-right: 0.875rem;
    margin-bottom: 1rem;
    flex: 1;
}

.search-section-vt-form-button {
    margin-right: 0;
    margin-bottom: 1rem;
    flex: 0;
}

@media screen and (min-width: 1024px) {
    .search-section-hd h2 {
        font-size: 40px;
    }
}

.search-section-hd {
    font-size: 32px;
    display: block;
}

@media screen and (max-width: 740px) {
    .search-section-hd {
        display: none;
    }
}

.HuSection {
    /*max-width:100%;*/
}

@media screen and (min-width: 768px) {
    .HuSection {
        padding: 100px 0px 100px 0px;
        display: flex;
        flex-direction: row;
        margin: auto;
    }
}

@media screen and (min-width: 768px) {
    .HuSection_createprofile_l_lasd {
        flex: 1 1 0%;
    }
}

.HuSection_createprofile_l_lasd {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .HuSection_createprofile_r_lasd {
        flex: 1 1 0%;
        row-gap: 39px;
    }
}

.HuSection_createprofile_r_lasd {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}



.HuSection_ho_23 {
    background-color: #241644;
    border-radius: 16px;
}

.css-sc-hd {
    font-size: 38px;
    font-family: var(--x-font-talentup);
    letter-spacing: -0.25px;
    font-weight: bold;
    line-height: 20px;
    color: #fff !important;
    margin-bottom: 32px;
}
.avatar-background {
    background-color: #5c60f5 !important;
    color:#fff !important;
}
.top_header-attapo{
    background-color:#fff;
}
.ms-slider_parent {
    color: #a4a6df;
}

.search-section-vt-flipper {
    display: inline-block;
    height: 1.5em;
    overflow: hidden;
    vertical-align: middle;
    mask-image: linear-gradient(transparent, white, white, white, transparent);
    mask-type: luminance;
    mask-mode: alpha;
}

.ms-slider_parent {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    animation-name: wordSlider;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 7s;
}

.ms-slider_flipper-word {
    display: block;
    text-align: left;
    color: #fff;
}

@keyframes wordSlider {
    0%, 27% {
        transform: translateY(0%);
    }

    33%, 60% {
        transform: translateY(-25%);
    }

    66%, 93% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(-75%);
    }
}

.search-section-sb-inner-dt {
    display: block;
}

.search-section-ac-form {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 55px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 80px;
}

.search-section-ac-form-ss1 {
    flex: 99 1 0%;
    display: flex;
}

.ssacfsslb {
    flex: 1 1 0%;
    position: relative;
    display: flex;
}

    .ssacfsslb > div {
        width: 100%;
    }

        .ssacfsslb > div > div {
            width: 100%;
        }


/*.ssacfsstinto > div {
    border: none;
    padding: 0px;
    background-color: transparent;
}*/
.ssacfsstbraz {
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.ssacfsslb label {
    display: flex;
}

.ssacfsstlabsc {
    display: block;
    font-weight: 400;
    font-size: 16px;
    color: rgb(33, 33, 33);
    line-height: 24px;
    text-transform: none;
}

.ssacfsstspalab {
}

.ssacfsstsinputla {
}

.ssacfsstinto > div {
    border: none;
    padding: 0px;
    background-color: transparent;
}

.ssacfsforinri {
    cursor: pointer;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    border: 1px solid rgb(230, 231, 232);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    -webkit-box-flex: 1;
    flex-grow: 1;
    box-sizing: content-box;
}

@media screen and (min-width: 1200px) {
    .ssacfsslb:first-child input {
        width: 100%;
    }
}

.ssacfsstinto input, .ssacfsstinto input:focus, .ssacfsstinto input:active {
    font-weight: 400;
    line-height: 27px;
    font-size: 21px;
    color: rgb(66, 66, 66);
    border: none;
    padding: 24px;
    outline: none;
    box-sizing: border-box;
}

.ssacfsstinto input {
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 80px;
    transition: widistdth 0.3s ease-in-out 0s;
    width: 100%;
    min-width: 200px;
}

.ssacfsstsinputla_input_jo {
}

.title-fleek {
    color: black;
    font-weight: 600;
}

.ssacfsstuy * {
    box-sizing: content-box;
}

.input_jo_s {
    box-sizing: content-box;
    display: inline-block;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: rgb(66, 66, 66);
    line-height: 24px;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    padding: 0px;
    border: transparent;
    border-radius: 4px;
    background-color: transparent;
}

.se_muj_as023 {
    background-color: #fff;
}

.ssacfsslb:first-child::after {
    position: absolute;
    content: "";
    width: 1px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgb(230, 231, 232);
    margin: 16px 0px;
}

.ssacfssspboxti {
    display: none;
}

#search-results {
}

.search-results-tag {
    font-weight: 700;
    text-transform: capitalize;
}

.list-group-item {
    border-bottom: 1px solid black;
}

.ssri-subbut-inner, ssri-subbut-inner:visited {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    border: 2px solid #5c60f5;
    border-radius: 50px;
    background-color: #5c60f5;
    font-weight: 600;
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 24px;
    text-transform: none;
}

.form-ssri-subbut, .form-ssri-subbut:hover, .form-ssri-subbut:focus {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 18px;
    text-transform: uppercase;
    max-width: 100%;
    margin: 12px;
    padding: 0px 12px;
    transition: max-width 0.3s ease-in-out 0s, background-color;
    border-color: transparent;
}

@media screen and (min-width: 1200px) {
    .ssri-subbut-inner-span {
        width: 100%;
        opacity: 1;
        overflow: visible;
        padding: 0px 8px 0px 4px;
        transition: width 0.3s ease-in-out 0s, opacity;
    }
}

.dashboad-outer-cont-dash .soc_asdl-owpc div.ps-4 a {
    color: #fff !important;
}

.skeleton-loader {
    padding: 2px;
    max-width: 100%;
    margin: 0 auto;
}
.skeleton-loader-profile {
    padding: 2px;
    width: 100%;
    margin: 0 auto;
}
.skeleton-header, .skeleton-line {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 4px;
    height: 20px;
    margin: 10px 0;
}
.skeleton-header-profile, .skeleton-line-profile {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 4px;
    height: 20px;
    margin: 10px 0;
}

.skeleton-header {
    width: 50%;
    height: 40px;
}

.skeleton-line-profile {
    width: 100%;
    height: 20px;
}

@keyframes skeleton-loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}


::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0.5);
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar {
    width: 8px;
    height: 12px;
}

*::-webkit-scrollbar-track {
    background: var(--scroll-bar-bg-color);
}

    *::-webkit-scrollbar-thumb {
        background-color: var(--scroll-bar-color);
        border-radius: 20px;
        border: 2px solid var(--scroll-bar-bg-color);
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
*::-webkit-scrollbar-thumb:hover {
    background-color: var(--scroll-bar-color-hover);
}

.social-link {
    display: inline-block;
    max-width: 200px; /* Adjust as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 576px) {
    .social-link {
        max-width: 120px; /* Shorten more for small screens */
    }
}
